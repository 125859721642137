import React, { FC, useState } from 'react';
import { BiCheck, BiCheckCircle, BiLoader } from 'react-icons/bi';
import ModalOverlay from '../UI/ModalOverlay';
import { StepProps } from '../../pages/Auth/Register';
import { useDispatch } from 'react-redux';
import { verifyOTP } from '../../api/thunks/auth/auth.service';
import { useLocation, useSearchParams } from 'react-router-dom';
import OTPInput from 'react-otp-input';

interface verificationModalProps extends StepProps {
  closeModal: () => void;
}

type inputValuesProps = { id: number; value: string };

const VerificationModal: FC<verificationModalProps> = ({
  increaseStep,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [focused, setFocused] = useState<number>(0);
  const [otp, setOtp] = useState<string>('');

  const [searchParams, setSearchParams] = useSearchParams();

  const verifyAccount = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const email = new URLSearchParams(location.search).get('email') as string;
    const userId = new URLSearchParams(location.search).get('userId') as string;
    const data = await dispatch(verifyOTP({ otp, email }));

    if (!data.error) {
      closeModal();
      increaseStep();
      setSearchParams({ email, userId, token: data?.payload?.token });
    }
    setLoading(false);
  };

  return (
    <ModalOverlay modalWidth={700}>
      <header className='mb-3'>
        <h1
          className={`text-[24px] font-bold text-center ${
            error ? 'text-red-600' : 'text-blackText'
          }`}
        >
          {!loading && !error && 'Verify Account'}
          {loading && !error && 'Verifying your account'}
          {!loading && error && 'Account not verified'}
        </h1>
        <p className='text-center leading-[1.2] mt-2 text-darkerGray font-bold'>
          {!loading && !error && (
            <>
              An email verification OTP has been sent to your email address{' '}
              <br /> Input the OTP to verify your account
            </>
          )}
        </p>
      </header>
      <form className='w-full' onSubmit={verifyAccount}>
        <div className='w-full flex items-center justify-center gap-x-4 gap-y-4 flex-wrap mt-6 max-w-[100%]'>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputType='number'
            renderInput={(props) => (
              <input
                {...props}
                className={`min-w-[50px] m-2 h-[50px] rounded-md focus:border-blackText border-[1.5px] outline-none p-2 text-[1.25rem] text-center`}
              />
            )}
          />
        </div>
        <div className='flex items-center justify-center flex-wrap w-full mt-6 gap-x-4 gap-y-4'>
          <button type='submit' className={styles.button}>
            {loading ? 'Verifying' : 'Verify Account'}
            <span className={`text-[1.25rem] ${loading && 'animate-spin'}`}>
              {loading ? <BiLoader /> : <BiCheckCircle />}
            </span>
          </button>
        </div>
      </form>
    </ModalOverlay>
  );
};

const styles = {
  button:
    'block w-full min-w-[150px] max-w-[250px] text-center  bg-black text-white rounded-md p-3 flex items-center gap-x-3 justify-center',
};

export default VerificationModal;
