import React, { useState } from "react";
import { BiChevronLeft, BiPaperPlane, BiPencil, BiPlus, BiTrash } from "react-icons/bi";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import image from "../../assets/images/profile-img.jpg";
import { ButtonFilled, OutlineButton } from "../../components/UI/Buttons";
import { TabsProps } from "../../types/static.types";
import { EmploymentRecord, LessonNotes, Profile } from "../../components/Admin/StaffDetailsTabs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSingleStaff } from "../../api/hooks/admin/staffs/staffs.service";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleStaff } from "../../store/slices/get/staff.slice";
import UserImageModal from "../../components/UI/UserImageModal";
import BackButton from "../../components/UI/BackButton";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import { deleteStaff } from "../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import { addStaffToLocalStorage } from "../../utils/localStorage";
import ResetPasswordButton from "../../components/Admin/ResetPassword";
import ChangeUserPhoneNumber from "../../components/Admin/ParentDetailsTabs/ChangeUserPhoneNumber";

const tabs: TabsProps[] = [
    { title: "Profile", component: <Profile /> },
    { title: "Employment Documents", component: <EmploymentRecord /> },
];

const SingleStaff = () => {
    const dispatch = useDispatch();
    const staff = useSelector((state: RootState) => state.getStaff);
    const location = useLocation();
    const navigate = useNavigate();

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [openedTab, setOpenedTab] = useState<TabsProps>({
        title: "Profile",
        component: <Profile />,
    });

    const switchTab = (tab: TabsProps) => {
        setOpenedTab(tab);
    };

    const { id } = useParams();

    const { data, isLoading, isError } = useGetSingleStaff(id as string);

    const deleteAccount = async () => {
        setDeleteModal(false);
        const data = await dispatch(deleteStaff(id));

        if (!data?.error) {
            Swal.fire({
                icon: "success",
                title: "Delete Staff's Account",
                text: "Staff's account deleted successfully",
                timer: 2000,
                showConfirmButton: false,
            });
            setTimeout(() => {
                navigate("/admin/staffs");
            }, 2000);
        }
    };

    React.useEffect(() => {
        if (data && !isLoading) {
            addStaffToLocalStorage(data);
            dispatch(updateSingleStaff(data));
        }
    }, [data]);

    return (
        <DashboardLayout
            documentTitle="Staff Profile"
            pageTitle={
                <>
                    Staff Members/
                    <span className="font-bold text-black">Staff Profile</span>
                </>
            }
        >
            <>
                {imageModalOpen && (
                    <UserImageModal
                        firstName={staff?.firstName}
                        lastName={staff?.lastName}
                        imageUrl={staff?.imageUrl}
                        username={staff?.email}
                        closeModal={() => {
                            setImageModalOpen(false);
                        }}
                    />
                )}

                {data && deleteModal && (
                    <ConfirmationModal
                        onCancel={() => {
                            setDeleteModal(false);
                        }}
                        onConfirm={deleteAccount}
                        text={"Are you sure you want to delete this staff's account?"}
                    />
                )}
            </>
            <section className="sm:p-6 py-6 min-h-screen">
                <header className={styles.header}>
                    <div className="flex items-center gap-x-3">
                        <img
                            className="w-[80px] h-[80px] cursor-pointer rounded-full object-fit object-cover"
                            alt={staff?.email}
                            src={staff?.imageUrl}
                            loading="lazy"
                            onClick={() => {
                                setImageModalOpen(true);
                            }}
                        />
                        <div className="flex-col gap-y-1">
                            <h2 className="text-[1.2rem] font-bold text-blackText">
                                {staff?.firstName} {staff?.lastName}
                            </h2>
                            <p className="text-darkGray text-[1rem] capitalize">{staff?.role}</p>
                            {staff?.monthOfEmployment && (
                                <p className="text-[0.8rem] mt-1">
                                    <i>Started</i>
                                    <span className="font-bold inline-block ml-[6px]">
                                        {staff?.monthOfEmployment}, {staff?.yearOfEmployment}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                    {location.pathname.includes("/admin") && (
                        <div className=" flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap items-end justify-end">
                            <ButtonFilled
                                icon={<BiTrash />}
                                text={"Delete Staff"}
                                onClick={() => setDeleteModal(true)}
                            />

                            <Link to={"/admin/staffs/edit/" + staff?._id}>
                                <ButtonFilled icon={<BiPencil />} text={"Edit Staff Ptofile"} />
                            </Link>

                            <ChangeUserPhoneNumber userId={staff?.userId} />
                            <ResetPasswordButton userId={staff?.userId} />
                        </div>
                    )}
                </header>

                {/* tabs */}
                <div className="mt-8 sm:px-0 px-4">
                    <div className="w-full max-w-full overflow-x-scroll border-b-[1.5px] flex items-center gap-x-4 pb-2">
                        {tabs?.map((tab, index) => {
                            return (
                                <h3
                                    key={index}
                                    className={`pb-1 mb-0  leading-[1] border-b-[1.5px] cursor-pointer ${
                                        index === 0 ? "min-w-[11.25rem]" : "min-w-fit"
                                    } font-bold text-center ${
                                        openedTab.title === tab.title
                                            ? "border-black text-blackText"
                                            : "border-transparent text-darkerGray"
                                    }`}
                                    onClick={() => {
                                        switchTab(tab);
                                    }}
                                >
                                    {tab.title}
                                </h3>
                            );
                        })}
                    </div>
                </div>
                <section className="w-full mt-8">{openedTab.component}</section>
            </section>
        </DashboardLayout>
    );
};

const styles = {
    header: "mt-8 flex lg:items-center gap-x-4 gap-y-4 md:flex-row flex-col justify-between",
};

export default SingleStaff;
