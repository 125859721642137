import React, { useState, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import swal from "sweetalert";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { useDispatch } from "react-redux";
import { login } from "../../api/thunks/auth/auth.service";
import { addTokenToLocalStorage, addUserToLocalStorage } from "../../utils/localStorage";
import PasswordInput from "../../components/UI/PasswordInput";

const Login = () => {
    const [credential, setCredential] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const dispatch = useDispatch();

    const handleClick = async (e: any) => {
        e.preventDefault();
        const data = await dispatch(login({ credential, password }));

        if (!data?.error) {
            const user = data?.payload?.user;

            const nextRoute =
                user?.role === "admin" || user.role === "superAdmin"
                    ? "/admin/school"
                    : user?.role === "parent"
                    ? "/parent/kids"
                    : user?.role === "teacher"
                    ? "/teacher/profile"
                    : user?.role === "student"
                    ? "student/profile"
                    : "";

            addTokenToLocalStorage(data?.payload?.token);

            addUserToLocalStorage(user);
            Swal.fire({
                icon: "success",
                title: "Login Successful",
                showConfirmButton: false,
                text: "Next, you can choose to reset your password or go to dashboard",
                footer: `<div className="flex items-center justify-center gap-x-6 gap-y-6">
            <a href=${nextRoute} style="margin-right:12px">Go To Dashboard</a>
            <a href="/password-reset" style="display:inline-block;">Change Your Password</a>
          </div>`,
            });
        }
    };

    return (
        <AuthLayout>
            <form className="flex justify-center w-[100%] md:w-[450px] sm:mx-auto  ">
                <div className="bg-[#FFFFFF] shadow-xl h-[500px] w-[450px] flex mx-[10px]  flex-col pl-5 md:pl-12 pr-9  ">
                    <h3 className="font-extrabold mt-9">USER LOG IN</h3>
                    <div className="bg-[#D9E2EC] h-[3px] w-full mt-2">
                        <div className="h-[100%] bg-black relative max-w-[80px]"></div>
                    </div>
                    <div className="mt-16 w-full ">
                        <p className="text-[#636363] text-[15px]">Email/Username/Phone Number</p>
                        <input
                            className={`input-field ${credential ? "border-[#000000]" : "border-[#2277c038]"}`}
                            type="text"
                            name="email"
                            value={credential}
                            onChange={(e) => setCredential(e.target.value)}
                            placeholder="Enter email/username/phone number"
                            required
                        />
                    </div>
                    <div className="mt-12 w-full">
                        <p className="text-[#636363] text-[15px]">Password</p>
                        <PasswordInput
                            name="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            placeholder="******"
                            className={password ? "border-[#000000]" : "border-[#2277c038]"}
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={credential && password ? false : true}
                        className="bg-[#000000] text-white mt-16 py-2 rounded-lg disabled:bg-[#AAAAAA] font-semibold cursor-pointer"
                        onClick={handleClick}
                    >
                        LOGIN
                    </button>

                    <div className="flex justify-between mt-4">
                        <Link to="/register" className="hover:underline cursor-pointer">
                            Create School
                        </Link>
                        <Link to="/forgot-password" className="hover:underline cursor-pointer">
                            Forgot Password
                        </Link>
                    </div>
                </div>
            </form>
        </AuthLayout>
    );
};

export default Login;
