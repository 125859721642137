import React, { FC } from "react";
import { BiLoaderAlt } from "react-icons/bi";

interface InputProps {
    type: string;
    name: string;
    value: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    max?: number;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    isPhoneNumber?: boolean;
    minLength?: number;
    maxLength?: number;
    isLoading?: boolean;
    loadingText?: string;
    isError?: boolean;
    errorText?: string;
    isWarning?: boolean;
    warningText?: string;
}
const Input: FC<InputProps> = ({
    type,
    name,
    value,
    placeholder,
    onChange,
    max,
    disabled,
    required,
    className,
    isPhoneNumber,
    minLength,
    maxLength,
    isLoading,
    loadingText,
    isError,
    errorText,
    isWarning,
    warningText,
}) => {
    return (
        <div className={`w-full flex max-w-full`}>
            {isPhoneNumber && (
                <div className="font-bold bg-[#f1f1f1] rounded-l-md text-sm flex items-center justify-center p-2">
                    +234
                </div>
            )}

            <div className="max-w-full w-full">
                <input
                    className={`input-field disabled:bg-[rgb(235,235,228)] disabled:font-bold ${
                        isError && "!border-red-600"
                    } ${isWarning && "!border-yellow-600"} ${className} ${isPhoneNumber && "!rounded-l-none"} ${
                        type == "date" && "!py-[7px]"
                    } w-full`}
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={onChange}
                    max={max}
                    required={required === undefined ? true : required}
                    disabled={disabled}
                    {...(minLength ? { minLength } : {})}
                    {...(maxLength ? { maxLength } : {})}
                    title={warningText ? warningText : errorText ? errorText : ""}
                />
                {isError && !isWarning && !isLoading && (
                    <p className="truncate text-red-600" title={errorText}>
                        {errorText}
                    </p>
                )}
                {isWarning && !isError && !isLoading && (
                    <p className="truncate text-yellow-600" title={warningText}>
                        {warningText}
                    </p>
                )}
                {isLoading && !isError && !isWarning && (
                    <div className=" items-center flex justify-start gap-x-2">
                        <BiLoaderAlt className="animate-spin" />
                        <p>{loadingText}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Input;
