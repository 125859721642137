import React, { useEffect, useState } from "react";
import Header from "../../components/ReportCard/Header";
import FirstTerm from "../../components/ReportCard/Result/FirstTerm";
import SecondTerm from "../../components/ReportCard/Result/SecondTerm";
import ThirdTerm from "../../components/ReportCard/Result/ThirdTerm";
import AffectiveDomain from "../../components/ReportCard/AffectiveDomain";
import Attendance from "../../components/ReportCard/Attendance";
import Footer from "../../components/ReportCard/Footer";
import GradeAnalysis from "../../components/ReportCard/GradeAnalysis";
import GradeScale from "../../components/ReportCard/GradeScale";
import PerformanceSummary from "../../components/ReportCard/PerformanceSummary";
import RatingIndices from "../../components/ReportCard/RatingIndices";
import Remarks from "../../components/ReportCard/Remarks";
import { BiDownload } from "react-icons/bi";
import exportPDF from "../../utils/exportPDF";
import useFetchResult, {
  IRequestsToBeMade,
} from "../../utils/hooks/useFetchResult";
import { ImSpinner8 } from "react-icons/im";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { IAffectiveDomains } from "../../types/static.types";
import { ISingleStudentAttendance, IStudentScores } from "../../types/db.types";
import { fuseSubjectAndScores } from "../../utils/dataHandling";
import { useSearchParams } from "react-router-dom";

const ReportCard = () => {
  const { school } = getUserFromLocalStorage();

  const [scores, setScores] = useState<IStudentScores[]>([]);

  const [searchParams] = useSearchParams();

  const term = (searchParams.get("term") as string) || school?.term;
  const session = (searchParams.get("session") as string) || school?.session;

  const {
    completed,
    scores: scoreData,
    subjects,
    termlyInfo,
    profile,
    attendance,
    schoolAttendance,
    studentClass,
  } = useFetchResult();

  const downloadPdf = async () => {
    const container = document.getElementById("report-card-container");
    const reportCard = document.getElementById("report-card") as HTMLElement;
    const downloadBtn = document.getElementById("download-btn") as HTMLElement;

    // the comments would increase the length
    if (!termlyInfo?.classTeacherComment && !termlyInfo?.adminComment) {
      reportCard.style.transform = "scaleX(0.75) scaleY(0.8)";
    } else if (termlyInfo.classTeacherComment && !termlyInfo?.adminComment) {
      reportCard.style.transform =
        "scaleX(0.75) scaleY(0.75) translateY(-240px)";
    } else if (!termlyInfo?.classTeacherComment && termlyInfo.adminComment) {
      reportCard.style.transform =
        "scaleX(0.75) scaleY(0.75) translateY(-250px)";
    } else if (termlyInfo?.classTeacherComment && termlyInfo?.adminComment) {
      reportCard.style.transform =
        "scaleX(0.78) scaleY(0.7) translateY(-300px)";
    }

    downloadBtn.style.display = "hidden";

    await exportPDF(container);

    reportCard.style.transform = "scale(1) translateY(0)";
    downloadBtn.style.display = "flex";
  };

  useEffect(() => {
    if (scoreData && subjects) {
      setScores(fuseSubjectAndScores(subjects, scoreData));
    }
  }, [scoreData, subjects]);

  if (completed.length < Object.keys(IRequestsToBeMade).length) {
    return (
      <main className="px-[1rem] py-[1rem] text-center">
        <div className="flex items-center gap-[1rem]">
          <ImSpinner8 className="animate-spin duration-300" />
          <p>Fetching Results...</p>
        </div>
      </main>
    );
  }

  return (
    <main
      id="report-card-container"
      className="w-full flex justify-center max-h-fit"
    >
      <div
        id="report-card"
        className="min-w-[1000px] max-w-[1000px] mx-auto bg-white px-[1rem] py-[2rem]"
      >
        <button
          id="download-btn"
          className="w-[50px] h-[50px] rounded-full text-white bg-blackText flex items-center justify-center fixed top-0 right-0 z-[20]"
          onClick={downloadPdf}
        >
          <BiDownload />
        </button>

        <Header
          term={term}
          session={session}
          profile={profile}
          termlyInfo={termlyInfo}
        />

        {/* main stuffs */}
        <section className="flex">
          <div className="flex-1 pb-4">
            {term === "1st" ? (
              <FirstTerm scores={scores} />
            ) : term === "2nd" ? (
              <SecondTerm scores={scores} />
            ) : (
              <ThirdTerm scores={scores} />
            )}
          </div>
          <div className="w-[300px] ml-2">
            <Attendance
              profile={profile}
              schoolAttendance={schoolAttendance}
              studentAttendance={attendance as ISingleStudentAttendance[]}
            />
            {termlyInfo?.affectiveDomains && (
              <AffectiveDomain
                affectiveDomains={
                  termlyInfo?.affectiveDomains as IAffectiveDomains
                }
              />
            )}
            <RatingIndices />
          </div>
        </section>

        <section className=" flex">
          {/* performance */}
          <div className="flex-1">
            <PerformanceSummary scores={scores} />
            <GradeScale />
          </div>
          {/* grade analysis */}
          <div className="ml-4 w-[350px] max-w-[350px] overflow-x-scroll">
            <GradeAnalysis scores={scores} />
          </div>
        </section>

        <section className="mt-6">
          <Remarks studentClass={studentClass} termlyInfo={termlyInfo} />
          <Footer termlyInfo={termlyInfo} profile={profile} />
        </section>
      </div>
    </main>
  );
};

export default ReportCard;
