import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseHttp } from '../../../axios.config';
import {
  closePreloader,
  openErrorModal,
  openPreloader,
} from '../../../store/slices/others/handlerSlice';
import { IMakePayment, IPaymentSession } from './parent.types';

export const makePayment: any = createAsyncThunk(
  '/parent/make-payment',
  async (data: IMakePayment[], thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: 'Getting payment session' }));

    try {
      const response = await baseHttp.post('/wallet/paystack/pay', data);

      dispatch(closePreloader());

      return response?.data;
    } catch (error: any) {
      dispatch(closePreloader());
      dispatch(openErrorModal({ errorText: error.response.data.message }));
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
