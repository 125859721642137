import React, { useState } from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../components/UI/Buttons";
import { GiPaperPlane } from "react-icons/gi";
import UserSearch from "../../components/Chat/UserSearch";
import Conversations from "../../components/Chat/Conversations";
import BulkMessageModal from "../../components/Chat/BulkMessageModal";
import { IUserTypes } from "../../utils/hooks/useFetchResult";

const AdminChats = () => {
    const [bulkMessageUser, setBulkMessageUser] = useState<IUserTypes | null>(null);

    return (
        <DashboardLayout pageTitle="Chat">
            <>
                {bulkMessageUser && (
                    <BulkMessageModal userType={bulkMessageUser} closeModal={() => setBulkMessageUser(null)} />
                )}
            </>
            <section className="w-full min-h-screen py-6 md:p-6">
                <div className="flex flex-wrap gap-4">
                    <ButtonFilled
                        text={"Message All Users"}
                        icon={<GiPaperPlane />}
                        onClick={() => {
                            setBulkMessageUser(IUserTypes.users);
                        }}
                    />
                    <ButtonFilled
                        text={"Message All Admins"}
                        icon={<GiPaperPlane />}
                        onClick={() => {
                            setBulkMessageUser(IUserTypes.admin);
                        }}
                    />
                    <ButtonFilled
                        text={"Message All Staff"}
                        icon={<GiPaperPlane />}
                        onClick={() => {
                            setBulkMessageUser(IUserTypes.teacher);
                        }}
                    />
                    <ButtonFilled
                        text={"Message All Students"}
                        icon={<GiPaperPlane />}
                        onClick={() => {
                            setBulkMessageUser(IUserTypes.student);
                        }}
                    />
                    <ButtonFilled
                        text={"Message All Parents"}
                        icon={<GiPaperPlane />}
                        onClick={() => {
                            setBulkMessageUser(IUserTypes.parent);
                        }}
                    />
                </div>

                <UserSearch />

                <Conversations />
            </section>
        </DashboardLayout>
    );
};

export default AdminChats;
