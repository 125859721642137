import React from "react";
import { tableHeaders } from "../../../types/static.types";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../Layouts/TableLayout";
import {
  IGeneratedSubjects,
  IStudentScores,
  IUser,
} from "../../../types/db.types";
import {
  useGetStudentScore,
  useGetStudentSubjects,
} from "../../../api/hooks/admin/students/students.service";
import {
  IRequestsToBeMade,
  IUserTypes,
  StudentLinks,
} from "../../../utils/hooks/useFetchResult";
import { useParams, useSearchParams } from "react-router-dom";
import { getUserFromLocalStorage } from "../../../utils/localStorage";
import { fuseSubjectAndScores } from "../../../utils/dataHandling";
import { getGrade, getRemark } from "../../../utils/report-card";

interface Props {
  scores?: IStudentScores[];
}

const defaultTableHeader: tableHeaders[] = [
  { text: "SUBJECTS", width: 200 },
  { text: "CA", width: 30 },
  { text: "EXAM", width: 30 },
  { text: "TOTAL", subtext: "100", width: 70.0 },

  {
    text: (
      <>
        1<sup>st</sup> <br /> term
      </>
    ),
    width: 30,
  },
  {
    text: "Average",
    width: 60,
  },
  {
    text: "Grade",
    width: 50,
  },
  {
    text: "Remarks",
    width: 100,
  },
];

const SecondTerm = ({ scores }: Props) => {
  const { studentId } = useParams();

  const [firstTerm, setFirstTerm] = React.useState<IStudentScores[]>([]);

  const [searchParams] = useSearchParams();

  const { getLink } = new StudentLinks(
    studentId as string,
    searchParams.get("session") as string,
    "1st"
  );

  const { data: firstTermSubjects, isLoading: subjectLoading } =
    useGetStudentSubjects(getLink(IRequestsToBeMade.fetchSubjects));

  const { data: firstTermScores, isLoading: scoresLoading } =
    useGetStudentScore(getLink(IRequestsToBeMade.fetchScores));

  const getAverage = (second: number, first?: number | string) => {
    if (first === "") return second;
    else return (second + (first as number)) / 2;
  };

  React.useEffect(() => {
    if (
      !subjectLoading &&
      !scoresLoading &&
      firstTermScores &&
      firstTermSubjects
    ) {
      setFirstTerm(fuseSubjectAndScores(firstTermSubjects, firstTermScores));
    }
  }, [subjectLoading, scoresLoading]);

  return (
    <article className={`flex-1 h-full`}>
      <TableLayout className="flex flex-col h-full">
        <TableHeader>
          {defaultTableHeader?.map((header, index) => {
            return (
              <SingleTableHeader
                bg="#181619"
                color="white"
                key={index}
                noPadding={true}
                text={
                  <p className="text-[.75rem] h-full px-[8px] py-[2px] pb-[6px]">
                    {header.text} {header.subtext ? `(${header.subtext})` : ""}
                  </p>
                }
                width={header.width}
              />
            );
          })}
        </TableHeader>

        <TableRowsContainer className="flex-1 justify-between">
          {scores?.map((score, index) => {
            const total =
              score?.firstCA +
              score?.secondCA +
              score?.thirdCA +
              score?.examScore;

            const firstTermScore = firstTermScores?.find(
              (firstScore) => firstScore?.subjectId._id === score?.subjectId._id
            );

            const firstTermTotal = firstTermScore
              ? firstTermScore?.firstCA +
                firstTermScore?.secondCA +
                firstTermScore?.thirdCA +
                firstTermScore?.examScore
              : "";

            return (
              <TableRow
                key={index}
                className="items-center justify-center flex-1"
              >
                <SingleTableRowItem
                  width={defaultTableHeader[0].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{score?.subjectId?.title}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[1].width}
                  noPadding={true}
                >
                  <p className={styles.p}>
                    {score?.firstCA + score?.secondCA + score?.thirdCA}
                  </p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[2].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{score?.examScore}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[3].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{total}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[4].width}
                  noPadding={true}
                >
                  <p className={styles.p}>
                    {firstTermTotal === "" ? "-" : firstTermTotal}
                  </p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[5].width}
                  noPadding={true}
                >
                  <p className={styles.p}>
                    {getAverage(total, firstTermTotal).toFixed(1)}
                  </p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[6].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{getGrade(total)}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[7].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{getRemark(total)}</p>
                </SingleTableRowItem>
              </TableRow>
            );
          })}
        </TableRowsContainer>
      </TableLayout>
    </article>
  );
};

const styles = {
  p: "p-2 text-[.8rem] font-bold",
};

export default SecondTerm;
