import React from 'react';

type NoItemProps = {
  title: string;
  subtitle: string;
};

const NoItem = (props: NoItemProps) => {
  return (
    <div className='w-full h-[80vh] flex justify-center items-center flex-col gap-y-3'>
      <h2 className='text-darkGray text-[1.7rem] font-bold text-center'>
        {props?.title}
      </h2>
      <p className='text-blackText text-[1.1rem] font-bold text-center'>
        {props?.subtitle}
      </p>
    </div>
  );
};

export default NoItem;
