import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import DashboardLayout from '../../../../components/Layouts/DashboardLayout';
import { ButtonFilled } from '../../../../components/UI/Buttons';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const SelectWeek = () => {
  const { classId } = useParams();

  return (
    <DashboardLayout pageTitle={'Class Attendance'}>
      <section className='py-6 sm:p-6 min-h-screen'>
        <div className='flex items-center gap-x-5 gap-y-5 flex-wrap justify-center mt-6'>
          {/* Display all the weeks and five days for each of 'em */}
          {Array(14)
            .fill(null)
            .map((week, index) => {
              return (
                <article
                  key={index}
                  className='w-full sm:max-w-[300px] flex flex-col justify-between px-6 py-4 rounded-md border-[1.5px] border-blackText bg-white'
                >
                  <h1 className='text-[1.2rem] text-blackText font-bold w-full text-center'>
                    Week {index + 1}
                  </h1>
                  <div className='w-full flex h-[50px] justify-self-end items-end'>
                    {days.map((day, dayIndex) => {
                      // Appened the week and the day to the url. They'll be used to send data to the backend
                      return (
                        <Link
                          to={`/teacher/class/attendance/${classId}/mark-attendance?week=${
                            index + 1
                          }&day=${day}`}
                          className='flex-1 p-2 text-[13px] font-bold text-blackText hover:underline cursor-pointer'
                          key={dayIndex}
                        >
                          {day.slice(0, 3)}
                        </Link>
                      );
                    })}
                  </div>
                </article>
              );
            })}
        </div>
      </section>
    </DashboardLayout>
  );
};

export default SelectWeek;
