/**
 *  To display errors for fields that cannot be controlled by the form element
 *
 */

import { IAddStaffSlice, IAddStudentSlice } from "../types/states.types";
import { formatPhoneNumber } from "./dataHandling";

export const validateAddStaff = (data: IAddStaffSlice) => {
    if (!data?.country) {
        return `Select country`;
    } else if (!data?.picture) {
        return `Upload picture`;
    } else if (!data?.religion) {
        return `Select Religion`;
    } else if (!data?.yearOfEmployment) {
        return `Select year of employment`;
    } else if (!data?.monthOfEmployment) {
        return `Select month of employment`;
    } else if (!data?.role?.position) {
        return `Select position`;
    } else if (!data?.role?.signature) {
        return `Upload signature`;
    } else if (data?.role?.position === "Others" && !data?.role?.otherPosition) {
        return `Enter staff position`;
    } else if (data?.documents.length > 0) {
        const exists: boolean[] = [];
        data?.documents?.forEach((doc) => {
            if ((doc?.name === "" || doc?.name === " ") && doc?.file) {
                exists.push(true);
            } else if (doc?.name && !doc?.file) {
                exists.push(true);
            }
        });
        return exists?.length > 0 ? `Add document name & file` : undefined;
    } else {
        return undefined;
    }
};

export const validateAddStudent = (data: IAddStudentSlice) => {
    if (!data?.country) {
        return `Select country`;
    } else if (!data?.picture) {
        return `Upload picture`;
    } else if (!data?.religion) {
        return `Select religion`;
    } else if (!data?.class?.name) {
        return `Select class`;
    } else if (!data?.yearOfAdmission) {
        return `Select admission year`;
    } else if (!data?.monthOfAdmission) {
        return `Select admission month`;
    } else if (formatPhoneNumber(data.parent.phoneNumber as string) === formatPhoneNumber(data.phoneNumber as string)) {
        return `Parent phone number should not be the same as student phone number`;
    } else {
        return undefined;
    }
};
