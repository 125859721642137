import React, { useState } from 'react';
import { BiSave, BiTrash } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import {
  deletePayAdvice,
  editPayAdvice,
} from '../../api/thunks/admin/admin.service';
import { IGeneratedPayAdvice } from '../../types/db.types';
import ConfirmationModal from '../UI/ConfirmationModal';

type ContainerProps = {
  payAdvice: IGeneratedPayAdvice;
  refetch: () => void;
};

const EditPayAdviceContainers = (props: ContainerProps) => {
  const [item, setItem] = useState<string>(props?.payAdvice?.item || '');
  const [charge, setCharge] = useState<string>(
    props?.payAdvice?.charge?.toString() || ''
  );
  // For confirmation
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const edit = async () => {
    const data = await dispatch(
      editPayAdvice({
        payAdvice: { item, charge },
        id: props?.payAdvice?._id,
      })
    );
    if (!data?.error) {
      Swal.fire({
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
        title: 'Pay Advice Edited Successfully',
      });
      props?.refetch();
    }
  };

  const del = async () => {
    await dispatch(deletePayAdvice(props?.payAdvice?._id));
    props?.refetch();
    setModalOpen(false);
  };

  return (
    <div className='md:grid-cols-2 grid-cols-1 grid gap-x-4 gap-y-4 max-w-[1000px]'>
      {modalOpen && (
        <ConfirmationModal
          onConfirm={del}
          onCancel={() => {
            setModalOpen(false);
          }}
          text={`Are you sure you want to delete ${props?.payAdvice?.item}?`}
        />
      )}
      <div className='w-full relative'>
        <input
          type='text'
          className='input-field'
          placeholder='Enter item name'
          value={item}
          onChange={(e) => {
            setItem(e.target.value);
          }}
        />
      </div>
      <div className='flex items-center gap-x-4 gap-y-4'>
        <div className='flex-1'>
          <input
            type='number'
            className='input-field'
            placeholder='Enter Item charge'
            value={charge}
            onChange={(e) => {
              setCharge(e.target.value);
            }}
          />
        </div>

        <div className='flex items-center flex-1 max-w-fit min-w-fit gap-x-4 gap-y-4'>
          {/* Should be true only when it has been edited */}

          <span
            className='text-green-500 cursor-pointer text-[1.25rem]'
            title='Save'
            onClick={edit}
          >
            <BiSave />
          </span>

          <span
            className='text-red-500 cursor-pointer text-[1.25rem]'
            title='Delete'
            onClick={() => setModalOpen(true)}
          >
            <BiTrash />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditPayAdviceContainers;
