import { BiUser } from "react-icons/bi";
import { getDateInFull } from "../../../utils/getDateInFull";
import { SingleProfileDetail } from "../StaffDetailsTabs";
import {
  ContentBoxWithAvatar,
  ContentBoxWithAvatarHeader,
} from "../../UI/ContentBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export const Profile = () => {
  const student = useSelector((state: RootState) => state.getStudent);
  return (
    <>
      <div className="w-full flex gap-x-4 gap-y-4 xl:flex-row flex-col">
        <div className="flex-[60%]">
          <ContentBoxWithAvatar
            avatar={<BiUser />}
            header={"General Information"}
            hasButton={false}
          >
            <section className={styles.content_rows}>
              <SingleProfileDetail
                title={"Name"}
                subtitle={`${student?.firstName} ${student.middleName} ${student.lastName}`}
              />
              <SingleProfileDetail
                title={"Username"}
                subtitle={`${student?.userName}`}
              />
              <SingleProfileDetail
                title={"Gender"}
                subtitle={student?.gender}
              />

              {student?.admissionNumber && (
                <SingleProfileDetail
                  title={"Admission Number"}
                  subtitle={student?.admissionNumber}
                />
              )}
              <SingleProfileDetail
                title={"Religion"}
                subtitle={student?.religion}
              />
              <SingleProfileDetail
                title={"Date Of Birth"}
                subtitle={getDateInFull(student?.dateOfBirth) || ""}
              />
              <SingleProfileDetail
                title={"Telephone"}
                subtitle={student.phoneNumber as string}
              />
              <SingleProfileDetail
                title={"Address"}
                subtitle={student?.address}
              />
              {/* <SingleProfileDetail title={"Class"} subtitle={user.class} /> */}
            </section>
            <hr className="my-4 border-t-[1.5px] block w-[80%] mx-auto" />
            <section>
              <ContentBoxWithAvatarHeader
                avatar={<BiUser />}
                header={"Parent/Guardian Details"}
                hasButton={false}
              />
              <div className={styles.content_rows}>
                <SingleProfileDetail
                  title={"Name"}
                  subtitle={`${student?.parent?.firstName} ${student?.parent?.lastName}`}
                />
                <SingleProfileDetail
                  title={"Gender"}
                  subtitle={student?.parent?.role}
                />
                <SingleProfileDetail
                  title={"Telephone"}
                  subtitle={student?.parent?.phoneNumber as string}
                />
                <SingleProfileDetail
                  title={"Email"}
                  subtitle={student?.parent?.email}
                />
              </div>
            </section>
          </ContentBoxWithAvatar>
        </div>
        <div className="flex-[40%] gap-y-4">
          {/* --------- NOT IN MVP ----------- */}
          {/* <ContentBoxWithAvatar
              avatar={<BsPlusSquare />}
              header={"Sickbay History"}
              hasButton={false}
            >
              <section className={styles.content_rows}>
                <SingleProfileDetail
                  title={"Number of visits"}
                  subtitle={sickbayHistory.visits.toString()}
                />
                <SingleProfileDetail
                  title={"Last Visit Date"}
                  subtitle={sickbayHistory.lastVisited}
                />
                <SingleProfileDetail
                  title={"Medical Issue Treated"}
                  subtitle={sickbayHistory.issueTreated}
                />
                <SingleProfileDetail
                  title={"Attending Nurse"}
                  subtitle={sickbayHistory.nurse}
                />
              </section>
            </ContentBoxWithAvatar> */}
        </div>
      </div>
    </>
  );
};

const styles = {
  content_rows:
    "grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 p-4 gap-x-4 gap-y-5",
  content: "flex flex-col gap-y-[5px] block",
  content_title: "text-[0.94rem] text-darkGray capitalize font-bold",
  content_text: "text-blackText text-[1rem] capitalize",
  bold_font: "font-bold text-[1rem] text-darkerGray",
  buttons:
    "px-4 py-2 flex items-center border-[1.5px] border-blackText rounded-md font-bold gap-x-4 flex-nowrap sm:min-w-[150px] text-center justify-center w-full sm:w-fit",
};
