import { MdClose } from 'react-icons/md';
import ModalOverlay from '../UI/ModalOverlay';
import logo from '../../assets/images/logo.png';
import { IBank } from '../../types/db.types';

export interface IModalProps {
  onClose(): void;
}

export const WithdrawCash = (props: IModalProps) => {
  return (
    <ModalOverlay modalWidth={600}>
      <header className='flex items-center justify-between'>
        <h2>Withdraw Cash</h2>
        <i
          className='text-[22px] text-red-600'
          onClick={() => {
            props.onClose();
          }}
        >
          <MdClose />
        </i>
      </header>

      <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4'>
        <div className=''>
          <label htmlFor='' className='text-[1rem] text-blackText mb-1'>
            Initiator
          </label>

          <input type='text' className='input-field' placeholder='Full Name' />
        </div>
        <div className=''>
          <label htmlFor='' className='text-[1rem] text-blackText mb-1'>
            Withdrawal Description
          </label>

          <input
            type='text'
            className='input-field'
            placeholder='Description'
          />
        </div>
        <div className=''>
          <label htmlFor='' className='text-[1rem] text-blackText mb-1'>
            Amount (₦)
          </label>

          <input type='number' className='input-field' placeholder='Amount' />
        </div>
      </div>
      <button className='mt-3 bg-blackText rounded-md text-white font-semibold w-full p-3'>
        Withdraw Cash
      </button>
    </ModalOverlay>
  );
};

export const CashReceiptModal = (props: IModalProps) => {
  return (
    <ModalOverlay modalWidth={500}>
      <header className='flex items-center justify-between'>
        <h2 className='text-blackText font-bold'>Withdrawal Receipt</h2>
        <i className='text-[22px] text-red-600' onClick={props.onClose}>
          <MdClose />
        </i>
      </header>
      <img src={logo} className={'w-[130px] block mx-auto my-4'} />
      <div className={styles.flex_between}>
        <p className='font-bold text-blackText'>Initiator</p>
        <p>Peter Obi</p>
      </div>
      <div className={styles.flex_between}>
        <p className='font-bold text-blackText'>Description</p>
        <p>Payment for school electricity</p>
      </div>
      <div className={styles.flex_between}>
        <p className='font-bold text-blackText'>Amount</p>
        <p>₦ 30,000</p>
      </div>
      <div className={styles.flex_between}>
        <p className='font-bold text-blackText'>Date</p>
        <p>32/11/2098</p>
      </div>
      <div className={styles.flex_between}>
        <p className='font-bold text-blackText'>Time</p>
        <p>12:43 PM</p>
      </div>
    </ModalOverlay>
  );
};

interface IBanksListProps {
  banks: IBank[];
  selectBank(bank: IBank): void;
}

export const BanksList = (props: IBanksListProps) => {
  return (
    <>
      <div className='absolute max-w-[300px] top-[85%] shadow-md left-0 w-full max-h-[250px] overflow-hidden overflow-y-scroll rounded-md z-[3] bg-white'>
        {props?.banks?.map((bank, index) => {
          return (
            <p
              key={index}
              className='p-3 text-darkGray hover:text-black w-full cursor-pointer'
              onClick={() => props.selectBank(bank)}
            >
              {bank.name}
            </p>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  flex_containers:
    'flex items-center justify-center md:justify-start gap-x-6 gap-y-6 flex-wrap w-full mb-6',
  bursary_icons: 'flex items-center gap-x-3 font-bold hover:underline',
  flex_between: 'flex items-center justify-between gap-x-4 gap-y-4 mb-2 ',
};
