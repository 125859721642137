import React, { useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import AuthLayout from '../../../components/Layouts/AuthLayout';
import { useDispatch } from 'react-redux';
import PasswordInput from '../../../components/UI/PasswordInput';
import { requestPasswordResetLink } from '../../../api/thunks/auth/auth.service';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = await dispatch(requestPasswordResetLink(email));
    if (!data?.error) {
      Swal.fire({
        icon: 'success',
        text: 'A password reset link has been sent to your email',
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };

  return (
    <AuthLayout>
      <form
        onSubmit={submit}
        className="flex justify-center w-[100%] md:w-[450px] sm:mx-auto "
      >
        <div className="bg-[#FFFFFF] shadow-xl h-[500px] w-[450px] flex mx-[10px]  flex-col pl-5 md:pl-12 pr-9   ">
          <h3 className="font-extrabold mt-9">USER PASSWORD RESET </h3>
          <div className="bg-[#D9E2EC] h-[3px]  mt-2">
            <div className="h-[100%] bg-black relative max-w-[80px]"></div>
          </div>

          <div className="mt-12 ">
            <p className="text-[#636363] text-[15px]">Email</p>
            <input
              type={"email"}
              required={true}
              className={
                (email ? "border-[#000000]" : "border-[#2277c038]") +
                " input-field"
              }
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>
          <button
            disabled={email === ""}
            type="submit"
            className="bg-[#000000] text-white mt-16 py-2 rounded-lg disabled:bg-[#AAAAAA] font-semibold cursor-pointer"
          >
            Request Reset Link
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default PasswordReset;
