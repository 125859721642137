import { createAsyncThunk } from "@reduxjs/toolkit";
import { closePreloader, openErrorModal, openPreloader } from "../../../store/slices/others/handlerSlice";
import { baseHttp } from "../../../axios.config";
import { IChat, IMessage } from "../../../types/db.types";
import { IUserTypes } from "../../../utils/hooks/useFetchResult";

export const accessChat: any = createAsyncThunk("accessChat", async (recipient: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Accessing Chat" }));

    try {
        const response = await baseHttp.post<IChat>("/chat", { recipient });
        dispatch(closePreloader());
        return response?.data?._id;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const sendMessage: any = createAsyncThunk(
    "sendMessage",
    async (data: { text: string; chatId: string }, thunkApi) => {
        try {
            const response = await baseHttp.post<IMessage>(`/chat/${data.chatId}/message`, { text: data.text });
            return response?.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const sendBulkMessage: any = createAsyncThunk(
    "sendBulkMessage",
    async (data: { userType: IUserTypes; text: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Sending messages" }));
        try {
            const response = await baseHttp.post(`/chat/${data.userType}/bulk-message`, { text: data.text });
            dispatch(closePreloader());
            return response?.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);
