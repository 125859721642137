import { useQuery } from "react-query";
import { baseHttp } from "../../../axios.config";
import {
  IAllStudents,
  IAllTeacherClasses,
  IClassAttendance,
  IFetchStudentsAttendance,
  IGeneratedClasses,
  IGeneratedSubjects,
  IStudentReportInfo,
} from "../../../types/db.types";
import { IGetScores } from "./teacher.types";

export const useGetTeachings = () => {
  const { data } = useQuery<IAllTeacherClasses>({
    queryKey: "teacher/get-classes",
    queryFn: async () => {
      const { data } = await baseHttp.get("/teacher/teachings");
      return data?.classes;
    },
  });

  return {
    data,
  };
};

export const useGetTeacherClass = (url: string) => {
  const { data, isLoading } = useQuery<IGeneratedClasses>({
    queryKey: "teacher/my-class",
    queryFn: async () => {
      const { data } = await baseHttp.get(url);
      return data?.teacherClass || data?.classDetail;
    },
  });
  return {
    data,
    isLoading,
  };
};

export const useGetTeacherClasses = () => {
  const { data, isLoading } = useQuery<IGeneratedClasses[]>({
    queryKey: "teacher/all-classes",
    queryFn: async () => {
      const { data } = await baseHttp.get(`/teacher/class`);

      return data?.classes;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetDayAttendance = (
  week: number,
  day: string,
  classId: string
) => {
  const { data, isLoading } = useQuery<{
    students: IFetchStudentsAttendance[];
    isHoliday: boolean;
  }>({
    queryKey: "teacher/attendance-per-day",
    queryFn: async () => {
      const response = await baseHttp.get(
        `/teacher/attendance/${classId}/${week}/${day}`
      );

      return response?.data?.attendance;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetClassAttendance = (url: string) => {
  const { data, isLoading } = useQuery<IClassAttendance[]>({
    queryKey: "teacher/class-attendance",
    queryFn: async () => {
      const response = await baseHttp.get(url);
      return response?.data?.attendance;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetSchoolAttendance = () => {
  const { data, isLoading } = useQuery<IClassAttendance[]>({
    queryKey: "admin/teacher/get-all-attendance",
    queryFn: async () => {
      const response = await baseHttp.get("/school/attendance/all");

      return response?.data?.attendance;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetSingleSubject = (id: string) => {
  const { data } = useQuery<IGeneratedSubjects>({
    queryKey: ["teacher/get-subject"],
    queryFn: async () => {
      const response = await baseHttp.get("/teacher/subject/" + id);
      return response?.data?.subject;
    },
  });

  return {
    data,
  };
};

export const useGetScores = (subjectId: string, classId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: "teacher/get-scores&students",
    queryFn: async () => {
      const response = await baseHttp.get(
        "/teacher/student/record-score/" + subjectId
      );
      if (response?.data?.recordedScore) {
        return response?.data?.recordedScore;
      } else {
        // If score doesn't exist display all students
        const response = await baseHttp.get(
          "/teacher/student/class/" + classId
        );

        return response?.data?.students;
      }
    },
    // enabled: !!classId,
  });

  return {
    data,
    isLoading,
  };
};

export const useGetStudentsInClass = (classId: string) => {
  const { data, isLoading, refetch } = useQuery<IAllStudents[]>({
    queryKey: "teacher/get-class-students",
    queryFn: async () => {
      const response = await baseHttp.get("/teacher/student/class/" + classId);

      return response?.data?.students;
    },
    // enabled: !!classId,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetStudentReportInfo = (url: string) => {
  const { data, isLoading, refetch } = useQuery<IStudentReportInfo>({
    queryKey: `${url}`,
    queryFn: async () => {
      const response = await baseHttp.get(url);
      return response?.data?.report;
    },
  });

  return { data, isLoading, refetch };
};

export const useGetClassStudentReportInfo = (url: string) => {
  const { data, isLoading, refetch } = useQuery<IStudentReportInfo[]>({
    queryKey: "teacher/get-class-student-report-info",
    queryFn: async () => {
      const response = await baseHttp.get(url);
      return response?.data?.data;
    },
  });

  return { data, isLoading, refetch };
};
