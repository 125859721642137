import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStudentBulkSlice, SingleBulkStudent } from "../../../types/states.types";
import { studentDefaultData } from "../../../static/bulk-upload.data";

const initialState: IStudentBulkSlice = {
    students: [{ ...studentDefaultData }],
};

const studentBulkSlice = createSlice({
    name: "studentBulkSlice",
    initialState,
    reducers: {
        addNewStudent: (state) => {
            state.students = [...state.students, { ...studentDefaultData }];
        },

        removeStudent: (state, actions: PayloadAction<number>) => {
            state.students = state.students.filter((student, student_idx) => student_idx != actions.payload);
        },

        editStudent: (state, actions: PayloadAction<[number, { key: keyof SingleBulkStudent; value: any }]>) => {
            const index = actions.payload[0];
            const change = actions.payload[1];
            state.students = state.students.map((student, student_idx) => {
                if (student_idx == index) {
                    student[change.key] = change.value;
                }

                return student;
            });
        },
        resetStudents: (state) => {
            state.students = initialState.students;
        },
    },
});

const studentBulkReducer = studentBulkSlice.reducer;
export const { removeStudent, resetStudents, addNewStudent, editStudent } = studentBulkSlice.actions;
export default studentBulkReducer;
