import axios from 'axios';

export const uploadToCloud = async (image: string | File) => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('upload_preset', 'edssistance');
  const response = await axios.post(
    'https://api.cloudinary.com/v1_1/dtori4rq2/upload',
    formData
  );
  return response.data.url;
};
