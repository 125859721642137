import html2PDF from "html2pdf.js";

const exportPDF = async (container) => {
  const options = {
    jsPDF: {
      format: "a4",
      orientation: "portrait",
    },
    html2canvas: { letterRendering: true, useCORS: true, logging: true },
    margin: 1,
    image: { type: "jpeg", quality: 1 },
  };

  await html2PDF(container, options);
};

export default exportPDF;
