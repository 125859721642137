import React, { useEffect, useMemo, useCallback, useState } from "react";
import { SingleTableRowItem, TableRow } from "../Layouts/TableLayout";
import Input from "../UI/Input";
import { staffBulkUploadTable } from "../../static/bulk-upload.data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { SingleBulkStaff } from "../../types/states.types";
import { editStaff, removeStaff } from "../../store/slices/post/staffBulkUpload";
import SelectContainer from "../UI/SelectContainer";
import { classes, positions } from "../../static/admin&teacher.data";
import { months } from "moment";
import { getYearsInterval } from "../../utils/dateConverter";
import { countryList } from "../../assets/static";
import ImageContainer from "./ImageContainer";
import { BiTrash } from "react-icons/bi";
import {
    useCheckEmail,
    useCheckParentPhoneNumber,
    useCheckPhoneNumber,
    useCheckUsername,
} from "../../api/mutations/auth.mutation";
import DEFAULT_MATCHERS from "../../constants/regex.const";
import { useValidateStaffUpload } from "../../utils/hooks/bulk-upload";
import { StaffBulkChecks } from "../../types/bulk.types";

interface Props {
    index: number;
    updateErrors(errors: StaffBulkChecks, index: number): void;
}

const StaffRow = (props: Props) => {
    const staffs = useSelector((state: RootState) => state.staffBulk.staffs);
    const staff = useMemo(() => staffs[props.index], [staffs]);
    const dispatch = useDispatch();

    const changeValue = useCallback((key: keyof SingleBulkStaff, value: any) => {
        dispatch(editStaff([props.index, { key, value }]));
    }, []);

    const checks = useValidateStaffUpload(staff);

    useEffect(() => {
        props.updateErrors(checks.errors, props.index);
    }, [checks.errors]);

    return (
        <TableRow className="items-start">
            <SingleTableRowItem width={staffBulkUploadTable[0].width} fitContent={true} className="pt-6">
                <BiTrash
                    className="text-red-600"
                    size={20}
                    cursor={"pointer"}
                    title="remove staff"
                    onClick={() => {
                        dispatch(removeStaff(props.index));
                    }}
                />
            </SingleTableRowItem>
            <SingleTableRowItem width={staffBulkUploadTable[1].width} fitContent={true}>
                <ImageContainer
                    update={(file) => {
                        changeValue("imageUrl", file);
                    }}
                    value={staff.imageUrl as File}
                />
            </SingleTableRowItem>
            <SingleTableRowItem width={staffBulkUploadTable[2].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="firstName"
                    value={staff.firstName}
                    placeholder="enter firstname"
                    className="!w-full"
                    onChange={(e) => changeValue("firstName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[3].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="middleName"
                    value={staff.middleName}
                    placeholder="enter middlename"
                    className="!w-full"
                    onChange={(e) => changeValue("middleName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[4].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="lastName"
                    value={staff.lastName}
                    placeholder="enter lastname"
                    className="!w-full"
                    onChange={(e) => changeValue("lastName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[5].width} fitContent={true}>
                <Input
                    required={false}
                    type="email"
                    name="email"
                    value={staff.email}
                    placeholder="enter email"
                    className="!w-full"
                    onChange={(e) => changeValue("email", e.target.value)}
                    isLoading={checks.loading.email}
                    loadingText={"checking email"}
                    isError={checks.errors.email}
                    errorText="already exists!"
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[6].width} fitContent={true}>
                <Input
                    required={false}
                    type="number"
                    name="phoneNumber"
                    value={staff.phoneNumber}
                    placeholder="enter phone number"
                    className="!w-full"
                    minLength={10}
                    onChange={(e) => changeValue("phoneNumber", e.target.value)}
                    isLoading={checks.loading.phone}
                    loadingText={"checking  number"}
                    isError={checks.errors.phone}
                    errorText="already exists!"
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[7].width} fitContent={true}>
                <Input
                    required={true}
                    type="date"
                    name="dateOfBirth"
                    value={staff.dateOfBirth}
                    placeholder=""
                    className="!w-full"
                    onChange={(e) => changeValue("dateOfBirth", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[8].width} fitContent={true}>
                <select
                    value={staff.gender}
                    required={true}
                    className="input-field w-full !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("gender", value);
                    }}
                >
                    <option value={"male"}>M</option>
                    <option value={"female"}>F</option>
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[9].width} fitContent={true}>
                <select
                    value={staff.religion}
                    required={true}
                    className="input-field w-full !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("religion", value);
                    }}
                >
                    <option value={"christainity"}>Christain</option>
                    <option value={"islam"}>Islam</option>
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[10].width} fitContent={true}>
                <select
                    value={staff.role}
                    required={true}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("role", value);
                    }}
                >
                    {positions.map((position, index) => {
                        return (
                            <option value={position} key={index}>
                                {position}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[11].width} fitContent={true}>
                <select
                    value={staff.monthOfEmployment}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("monthOfEmployment", value);
                    }}
                >
                    {months().map((month, index) => {
                        return (
                            <option value={month} key={index}>
                                {month}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[12].width} fitContent={true}>
                <select
                    value={staff.yearOfEmployment}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("yearOfEmployment", value);
                    }}
                >
                    {getYearsInterval(1980).map((year, index) => {
                        return (
                            <option value={year} key={index}>
                                {year}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[13].width} fitContent={true}>
                <select
                    value={staff.country}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("country", value);
                    }}
                >
                    {countryList.map((country, index) => {
                        return (
                            <option value={country} key={index}>
                                {country}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[14].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="state"
                    value={staff.state}
                    placeholder="enter state"
                    className="!w-full"
                    onChange={(e) => changeValue("state", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[15].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="city"
                    value={staff.city}
                    placeholder="enter city"
                    className="!w-full"
                    onChange={(e) => changeValue("city", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[16].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="nextOfKinName"
                    value={staff.nextOfKinName}
                    placeholder="enter next of kin name"
                    className="!w-full"
                    onChange={(e) => changeValue("nextOfKinName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={staffBulkUploadTable[17].width} fitContent={true}>
                <Input
                    required={true}
                    type="number"
                    name="nextOfKinPhoneNumber"
                    value={staff.nextOfKinPhoneNumber}
                    placeholder="enter next of kin phone number"
                    className="!w-full"
                    minLength={10}
                    onChange={(e) => changeValue("nextOfKinPhoneNumber", e.target.value)}
                />
            </SingleTableRowItem>
        </TableRow>
    );
};

export default StaffRow;
