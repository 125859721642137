import { useQuery } from "react-query";
import { baseHttp } from "../../../../axios.config";
import { IAllParents, IAllStudents } from "../../../../types/db.types";
import { useLocation, useParams } from "react-router-dom";

export const useGetParents = () => {
    const { data, isLoading } = useQuery<IAllParents[]>({
        queryKey: "/admin/get-parents",
        queryFn: async () => {
            const response = await baseHttp.get("/school/parents");

            return response?.data?.parents;
        },
    });

    return {
        data,
        isLoading,
    };
};

export const useGetSingleParent = () => {
    const { id } = useParams();
    const { pathname } = useLocation();

    const endpoint = pathname.includes("admin") ? `/school/parents/${id}` : `/parents`;

    const { data, isLoading } = useQuery<IAllParents>({
        queryKey: "get-parent-profile",
        queryFn: async () => {
            const response = await baseHttp.get(endpoint);
            return response?.data?.parent;
        },
    });

    return {
        data,
        isLoading,
    };
};

export const useGetParentKids = () => {
    const { id } = useParams();
    const { pathname } = useLocation();

    const endpoint = pathname.includes("admin") ? `/school/parents/${id}/kids` : `/parents/getKids`;

    const { data, isLoading } = useQuery<IAllStudents[]>({
        queryKey: "get-parent-kids",
        queryFn: async () => {
            const response = await baseHttp.get(endpoint);
            return response?.data?.kids;
        },
    });

    return { data, isLoading };
};
