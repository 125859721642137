import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import banner from "../../assets/images/ipad.png";
import banner2 from "../../assets/images/ipad2.png";
import { BlackOutlineButton, ButtonFilled } from "../UI/Buttons";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const Banner = () => {
  // crazy and unehtical custom animation 😅🙃
  const [animate, setAnimate] = useState<boolean>(true);

  useEffect(() => {
    if (!animate) {
      setTimeout(() => {
        setAnimate(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setAnimate(false);
      }, 4000);
    }
  }, [animate]);

  return (
    <header className="h-full lg:min-h-[550px] flex flex-col">
      <Navbar />

      <div className="flex flex-col lg:flex-row gap-[2rem] flex-1">
        {/* banner text */}
        <div className={`${!animate && "hide-banner"} banner flex-1 pt-[2rem]`}>
          <p className="text-lightGray text-[1.0rem] no-animation">
            EDSSISTANCE
          </p>

          <h1 className="text-[2.5rem] mt-4 leading-[1.3] text-blackText font-bold max-w-[600px] !delay-[0ms]">
            Empower Your School Community with Seamless Student Management
          </h1>
          <p className="my-2 max-w-[600px] !delay-[300ms]">
            Streamline your school's operations and enhance communication
            between teachers, students, and parents with Edssitance. From
            attendance tracking to report cards, we've got you covered.
            Experience the future of school management today!
          </p>

          <div className="flex items-center gap-[1rem] mt-8 !transition-none no-animation">
            <Link to={"/register"}>
              <BlackOutlineButton text="Sign Up" className="!min-w-[150px]" />
            </Link>
            <Link to={"/login"}>
              <ButtonFilled text="Login" className="!min-w-[150px]" />
            </Link>
          </div>
        </div>

        {/* banner image */}
        <div
          className={`${
            !animate && "hide-banner"
          } md:items-end md:justify-end items-center justify-center flex`}
        >
          <img
            src={banner}
            loading="lazy"
            alt="banner-img"
            className="lg:h-[350px] !delay-[300ms] hidden lg:block"
          />

          <img
            src={banner2}
            loading="lazy"
            alt="banner-img"
            className="h-[200px] !delay-[300ms] lg:hidden block"
          />
        </div>
      </div>
    </header>
  );
};

export default Banner;
