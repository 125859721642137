import React from "react";
import demoSchoolLogo from "../../assets/images/demo-school.png";
import demoUser from "../../assets/images/profile-img.jpg";
import ProfileInfo from "./UI/ProfileInfo";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { IAllStudents, IStudentReportInfo, IUser } from "../../types/db.types";
import { getDateInFull } from "../../utils/getDateInFull";

interface Props {
  profile?: IAllStudents;
  termlyInfo?: IStudentReportInfo;
  term: string;
  session: string;
}

const Header = ({ profile, termlyInfo, term, session }: Props) => {
  const user = getUserFromLocalStorage() as IUser;

  return (
    <header className="">
      {/* School header */}
      <div className="flex">
        <div className="max-w-fit">
          <img
            src={user?.school?.imageUrl}
            alt={user?.school?.schoolName}
            className="w-[100px] h-[100px] rounded-full object-fit object-cover"
          />
        </div>
        <div className="flex-1 ml-2 w-full flex items-center justify-center flex-col">
          <h1 className="font-bold text-[2rem]">{user?.school?.schoolName}</h1>
          <p className="mt-3">{user?.school?.address}</p>
          <p>EMAIL: {user?.school?.schoolEmail}</p>
        </div>
      </div>

      {/* Student Header */}
      <div className="flex mt-4">
        <div className="flex-1 mr-2">
          <h2 className="uppercase font-bold text-[1.1rem] max-w-fit ml-auto mr-[14rem] underline">
            {term || user?.school?.term} TERM STUDENT'S PERFORMANCE REPORT
          </h2>

          <div className="w-full flex mt-4 flex-wrap">
            <ProfileInfo
              lineWidth={300}
              question="Name"
              answer={`${profile?.firstName} ${profile?.middleName} ${profile?.lastName}`}
            />
            <ProfileInfo
              lineWidth={150}
              question="Gender"
              answer={profile?.gender}
            />
            <ProfileInfo
              lineWidth={150}
              question="Class"
              answer={profile?.class}
            />
            <ProfileInfo
              lineWidth={150}
              question="Session"
              answer={session || user?.school?.session}
            />
            <ProfileInfo
              lineWidth={150}
              question="Admission Number"
              answer={profile?.admissionNumber}
            />
            <ProfileInfo
              lineWidth={150}
              question="D.O.B"
              answer={getDateInFull(profile?.dateOfBirth as string) || ""}
            />
            <ProfileInfo
              lineWidth={100}
              question="HEIGHT"
              answer={termlyInfo?.height ? `${termlyInfo?.height || ""}cm` : ""}
            />
            <ProfileInfo
              lineWidth={100}
              question="WEIGHT"
              answer={termlyInfo?.weight ? `${termlyInfo?.weight}kg` : ""}
            />
            <ProfileInfo
              lineWidth={100}
              question="OFFICE HELD"
              answer={termlyInfo?.officeHeld}
            />
          </div>
        </div>
        <div className="max-w-fit">
          <img
            src={profile?.imageUrl}
            alt={`${profile?.firstName} dp`}
            className="w-[100px] h-[100px] object-cover object-center"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
