import { useQuery } from 'react-query';
import { baseHttp } from '../../../axios.config';
import { IAllStudents } from '../../../types/db.types';
import { IGetClassmates, IGetTeachers } from './students.types';

export const useGetClassmates = () => {
  const { data, isLoading } = useQuery<IGetClassmates>({
    queryKey: 'student/get-classmates',
    queryFn: async () => {
      const response = await baseHttp.get('/student/classmates');
      return response?.data;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetTeachers = () => {
  const { data, isLoading } = useQuery<IGetTeachers>({
    queryKey: 'student/get-teachers',

    queryFn: async () => {
      const response = await baseHttp.get('/student/teachers');
      return response?.data;
    },
  });

  return {
    data,
  };
};
