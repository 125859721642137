import { IAllStaffs, ISchoolDetails, IUser } from "../types/db.types";

const addTokenToLocalStorage = (token: string) => {
  localStorage.setItem("token", token);
};

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const addUserToLocalStorage = (user: Object) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const getUserFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("user") as string) as IUser;
};

const deleteUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

const deleteTokenFromLocalStorage = () => {
  localStorage.removeItem("token");
};

const addStaffToLocalStorage = (staff: IAllStaffs) => {
  localStorage.setItem("staff", JSON.stringify(staff));
};

const getStaffFromLocalStorage = (): IAllStaffs => {
  return JSON.parse(localStorage.getItem("staff") as string);
};

const removeStaffFromLocalStorage = () => {
  localStorage.removeItem("staff");
};

const addSchoolToLocalStorage = (school: ISchoolDetails) => {
  // get the school from the user localstorage and replace it
  const user = getUserFromLocalStorage();

  addUserToLocalStorage({ ...user, school });
};

export {
  addTokenToLocalStorage,
  getTokenFromLocalStorage,
  addUserToLocalStorage,
  getUserFromLocalStorage,
  deleteTokenFromLocalStorage,
  deleteUserFromLocalStorage,
  addStaffToLocalStorage,
  removeStaffFromLocalStorage,
  getStaffFromLocalStorage,
  addSchoolToLocalStorage,
};
