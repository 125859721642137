import React, { HTMLAttributes } from "react";

export interface IToggleSwitchProps extends HTMLAttributes<HTMLSpanElement> {
  istoggled: boolean;
}

const ToggleSwitch: React.FC<IToggleSwitchProps> = ({
  className,
  ...props
}) => {
  return (
    <span
      className={`switch ${className} ${props.istoggled && "toggled"}`}
      {...props}
    />
  );
};

export default ToggleSwitch;
