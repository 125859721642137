import { IAddStudentSlice } from "../../../types/states.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAllStudents } from "../../../types/db.types";
import { reverseStudentDetails } from "../../../utils/dataHandling";

const initialState: IAddStudentSlice = {
    firstname: "",
    lastname: "",
    middlename: "",
    admissionNumber: "",
    dob: "",
    religion: "",
    gender: "male",
    phoneNumber: "",
    country: "",
    state: "",
    address: "",
    picture: "",
    yearOfAdmission: "",
    monthOfAdmission: "",
    resultStatus: "",
    teacherResultDecision: "",
    parent: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        address: "",
        gender: "male",
    },
    class: {
        name: "",
    },
};

const addStudentSlice = createSlice({
    name: "addStudent",
    initialState,
    reducers: {
        updateStudentDetails: (
            state: IAddStudentSlice,
            action: PayloadAction<{
                key: string;
                value:
                    | any
                    | (string &
                          ("male" | "Female") &
                          (string | File) & {
                              position: string;
                              role: string;
                              signature: string | File;
                          });
            }>
        ) => {
            (state as any)[action.payload.key as keyof IAddStudentSlice] = action.payload.value;
        },
        updateParentInfo: (
            state: IAddStudentSlice,
            action: PayloadAction<{
                key: string;
                value: (string & "male") | "Female";
            }>
        ) => {
            state.parent[action.payload.key as keyof typeof state.parent] = action.payload.value;
        },
        updateClassInfo: (
            state: IAddStudentSlice,
            action: PayloadAction<{
                key: string;
                value: (string & "male") | "Female";
            }>
        ) => {
            state.class[action.payload.key as keyof typeof state.class] = action.payload.value;
        },
        fetchStudentDetails: (state: IAddStudentSlice, action: PayloadAction<IAllStudents>) => {
            const newState = reverseStudentDetails(action.payload);

            return newState;
        },
        clearFields: () => {
            return initialState;
        },
    },
});

export const { updateStudentDetails, updateClassInfo, updateParentInfo, fetchStudentDetails, clearFields } =
    addStudentSlice.actions;
export const addStudentReducer = addStudentSlice.reducer;
