import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/get/auth.slice";
import handlers from "./slices/others/handlerSlice";
import { addStaffReducer } from "./slices/post/addStaffMemberSlice";
import { addStudentReducer } from "./slices/post/addStudent";
import { createSchoolReducer } from "./slices/post/createSchool";
import { getStudentReducer } from "./slices/get/student.slice";
import { getStaffReducer } from "./slices/get/staff.slice";
import { scoresReducer } from "./slices/get/scores.slice";
import studentBulkReducer from "./slices/post/studentBulkUpload";
import staffBulkReducer from "./slices/post/staffBulkUpload";

// the post slice folder is for making post delete and put request to the server and handling states for inputs

// the get slice is for handling data fetching and overall user and data management

export const store = configureStore({
    reducer: {
        // others
        handlers: handlers,
        // get
        user: authReducer,
        getStaff: getStaffReducer,
        getStudent: getStudentReducer,
        getStudentsScores: scoresReducer,
        // post
        addStaff: addStaffReducer,
        addStudent: addStudentReducer,
        createSchool: createSchoolReducer,
        studentBulk: studentBulkReducer,
        staffBulk: staffBulkReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type ActionDispatch = typeof store.dispatch;
