import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { Link } from 'react-router-dom';

interface IBackButtonProps {
  onClick: Function;
}

const BackButton = (props: IBackButtonProps) => {
  return (
    <button
      className={styles.back_icon}
      onClick={() => props?.onClick && props?.onClick()}
    >
      <>
        <i className='text-[1.25rem]'>
          <BiChevronLeft />
        </i>
        <p>Back</p>
      </>
    </button>
  );
};

const styles = {
  back_icon: 'flex items-center gap-x-1 text-[1rem] font-bold cursor-pointer',
};

export default BackButton;
