import React, { useMemo, ChangeEvent } from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { BiLoaderAlt, BiPaperPlane } from "react-icons/bi";
import { useGetConversationMessages, useGetSingleConversation } from "../../api/hooks/chat/chat.service";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { getChatBuddy } from "../../utils/chat.utils";
import { IChat, IMessage, IUser } from "../../types/db.types";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../api/thunks/chat/chat.service";
import { useParams } from "react-router-dom";
import { openErrorModal } from "../../store/slices/others/handlerSlice";
import io from "socket.io-client";

export default function GeneralChat() {
    const socket = useMemo(() => io(process.env.REACT_APP_API_URL as string), []);
    const [buddy, setBuddy] = React.useState<IUser | null>(null);
    const { data: chat, isLoading: isChatLoading } = useGetSingleConversation();
    const { data: messages_query, isLoading: isMessageLoading } = useGetConversationMessages();
    const [messages, setMessages] = React.useState<IMessage[]>([]);
    const [isSendingText, setIsSendingText] = React.useState<boolean>(false);
    const [text, setText] = React.useState<string>("");
    const dispatch = useDispatch();
    const { id } = useParams();
    const loggedInUser = getUserFromLocalStorage();

    const submit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const textToSend = text.trimStart().trimEnd();
        if (textToSend === "") return;
        setIsSendingText(true);
        const data = await dispatch(sendMessage({ chatId: id, text: textToSend }));
        setIsSendingText(false);
        if (!data.error) {
            setMessages([...messages, { ...data.payload, userId: loggedInUser }]);
            socket.emit("send message", data.payload);
            setText("");
        } else {
            dispatch(openErrorModal({ errorText: "Unable to send text, try again" }));
        }
    };

    React.useEffect(() => {
        if (!isChatLoading) {
            setBuddy(getChatBuddy(chat as IChat, loggedInUser));
        }
    }, [chat]);

    React.useEffect(() => {
        if (!isMessageLoading && messages_query) {
            setMessages(messages_query);
        }
    }, [messages_query]);

    React.useEffect(() => {
        socket.emit("auth user", loggedInUser);
    }, []);

    React.useEffect(() => {
        if (chat) {
            socket.emit("join chat", chat);
        }
    }, [chat]);

    React.useEffect(() => {
        socket.on("new message", (message: IMessage) => {
            if (message?.conversationId?._id?.toString() === chat?._id) {
                setMessages([...messages, message]);
            }
        });
    });

    return (
        <DashboardLayout pageTitle={"Chat"}>
            {isChatLoading ? (
                <div className="h-[80vh] flex items-center justify-center gap-2">
                    <BiLoaderAlt size={25} className="animate-spin" />
                    <p>Fetching Messages</p>
                </div>
            ) : (
                <div className="bg-white w-full h-[90vh] rounded-md mt-8 border-2 flex flex-col  justify-between overflow-hidden">
                    <header className="flex items-center gap-2 p-2 border-b-2">
                        <img
                            src={buddy?.imageUrl}
                            alt=""
                            className="w-[40px] h-[40px] rounded-full object-center object-cover"
                        />
                        <p className="font-medium">
                            {buddy?.firstName} {buddy?.lastName}
                        </p>
                    </header>

                    <div className="flex-1 flex flex-col px-2 overflow-y-scroll">
                        {messages?.map((message, index) => {
                            return (
                                <p
                                    key={index}
                                    className={`w-fit max-w-[70%] md:max-w-[300px] mt-2 px-2 py-1 rounded-md ${
                                        message.userId._id === loggedInUser._id
                                            ? "self-end bg-[#333333df] text-white"
                                            : "self-start text-blackText bg-[#eae8e8] "
                                    }`}
                                >
                                    {message.text}
                                </p>
                            );
                        })}
                    </div>

                    {/* for sending message */}
                    <form onSubmit={submit} className="border-t-2 h-[50px] flex min-h-fit">
                        <input
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            type="text"
                            className="block h-full w-full bg-[#f3f3f3] outline-none px-2"
                            placeholder="Enter your messsage here..."
                        />
                        <button
                            disabled={isSendingText}
                            className={`disabled:bg-[#444] py-2 px-2 bg-blackText  text-white h-full w-[50px] flex justify-center items-center`}
                            type="submit"
                        >
                            {isSendingText ? <BiLoaderAlt className="animate-spin" /> : <BiPaperPlane />}
                        </button>
                    </form>
                </div>
            )}
        </DashboardLayout>
    );
}
