import React, { useState } from "react";
import TableLayout, {
    SingleTableHeader,
    SingleTableRowItem,
    TableHeader,
    TableRow,
    TableRowsContainer,
} from "../../Layouts/TableLayout";
import { ITransaction } from "../../../types/db.types";
import { recentTransactionsHeaders } from "../../../static/admin&teacher.data";
import { dateConverter } from "../../../utils/dateConverter";
import { useGetKidTransactions } from "../../../api/hooks/parents/parent.service";
import BursaryReceipt from "../BursaryReceipt";

const Transactions = () => {
    const [receiptModalOpen, setReceiptModalOpen] = useState<boolean>(false);
    const [receipt, setReceipt] = useState<ITransaction | undefined>(undefined);

    const { data: transactions, isLoading } = useGetKidTransactions();

    return (
        <section className="mt-[1.4rem]">
            <h2 className="font-bold text-[1.5rem] text-blackText mb-3">Receipts</h2>
            {receiptModalOpen && <BursaryReceipt receipt={receipt} setModalOpen={setReceiptModalOpen} />}
            <TableLayout>
                <TableHeader>
                    {recentTransactionsHeaders.map((header, index) => {
                        return <SingleTableHeader {...header} key={index} />;
                    })}
                </TableHeader>
                <TableRowsContainer>
                    {transactions?.map((transaction, index) => {
                        return (
                            <TableRow key={index}>
                                <SingleTableRowItem width={recentTransactionsHeaders[0].width}>
                                    <p>{transaction?.createdAt ? dateConverter(transaction?.createdAt) : "-"}</p>
                                </SingleTableRowItem>

                                <SingleTableRowItem width={recentTransactionsHeaders[1].width}>
                                    <p>
                                        {transaction?.initiator?.firstName} {transaction?.initiator?.lastName}
                                    </p>
                                </SingleTableRowItem>
                                <SingleTableRowItem width={recentTransactionsHeaders[2].width}>
                                    <p>{"₦ " + transaction.amount.toLocaleString()}</p>
                                </SingleTableRowItem>
                                <SingleTableRowItem width={recentTransactionsHeaders[3].width}>
                                    <p
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setReceiptModalOpen(true);
                                            setReceipt(transaction as ITransaction);
                                        }}
                                    >
                                        View Details
                                    </p>
                                </SingleTableRowItem>
                            </TableRow>
                        );
                    })}
                </TableRowsContainer>
            </TableLayout>
        </section>
    );
};

export default Transactions;
