import React, { useState } from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { useLocation } from "react-router-dom";
import { useGetSingleParent } from "../../api/hooks/admin/parents/parents.service";
import { Profile } from "../../components/Admin/ParentDetailsTabs/Profile";
import { IAllParents } from "../../types/db.types";
import ChangeUserPhoneNumber from "../../components/Admin/ParentDetailsTabs/ChangeUserPhoneNumber";
import { ButtonFilled } from "../../components/UI/Buttons";
import ResetPasswordButton from "../../components/Admin/ResetPassword";

export default function SingleParent() {
    const { pathname } = useLocation();

    const { data } = useGetSingleParent();

    return (
        <>
            <DashboardLayout
                documentTitle={pathname === "/parent/profile" ? "Profile" : "Parent Profile"}
                pageTitle={
                    pathname === "/parent/profile" ? (
                        "Profile"
                    ) : (
                        <>
                            Parents / <span className="font-bold text-black">Parent Profile</span>
                        </>
                    )
                }
            >
                <section className="sm:p-6 py-6 min-h-screen">
                    <header className={styles.header}>
                        <div className="flex items-start gap-x-3">
                            <img
                                className="w-[80px] h-[80px] cursor-pointer rounded-full object-fit object-cover"
                                alt={data?.firstName}
                                src={data?.imageUrl}
                                loading="lazy"
                                onClick={() => {}}
                            />
                            <div className="flex-col gap-y-1">
                                <h2 className="text-[1.2rem] font-bold text-blackText">
                                    {data?.firstName} {data?.lastName}
                                </h2>
                            </div>
                        </div>
                        <div className=" flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap items-end justify-end">
                            {pathname.includes("/admin") && (
                                <>
                                    <ChangeUserPhoneNumber userId={data?.userId as string} />
                                    <ResetPasswordButton userId={data?.userId as string} />
                                </>
                            )}
                        </div>
                    </header>

                    <section className="w-full mt-8">
                        <Profile {...(data as IAllParents)} />
                    </section>
                </section>
            </DashboardLayout>
        </>
    );
}

const styles = {
    back_icon: "flex items-center gap-x-2 text-[1rem] font-bold",
    header: "mt-8 flex lg:items-center gap-x-4 gap-y-4 md:flex-row flex-col justify-between",
};
