import React from "react";
import stock5 from "../../assets/images/stock5.png";
import SingleService from "./SingleService";
import icon1 from "../../assets/images/download-icon.png";
import icon2 from "../../assets/images/settings-icon.png";
import icon3 from "../../assets/images/users-icon.png";
import icon4 from "../../assets/images/bell-icon.png";

const Services = () => {
  return (
    <section className="md:mt-[10rem] mt-[4rem] flex gap-y-[1.5rem] gap-x-[5rem] lg:flex-row flex-col">
      <div className="flex-1">
        <div className="flex flex-col gap-[0.5rem] mb-[1rem]">
          <h2 className="text-left text-[1.4rem] md:text-[2rem] font-bold">
            How Edssistance Works
          </h2>
          <p className="text-[0.9rem] leading-[2] max-w-[500px]  text-blackText">
            Edssistance is offering a new and improved way to manage your school
            and all the activities required. Here are the steps invloved in
            using Edssistance.
          </p>
        </div>

        <img
          src={stock5}
          alt="how it works"
          loading="lazy"
          className="w-full object-center justify-center max-h-[320px] hidden lg:block"
        />
      </div>

      <div className="flex-1 grid grid-cols-2 gap-[1rem]">
        <SingleService
          title="Sign Up"
          image={icon1}
          body="Sign up on Edssistance, pick a payment plan and complete the onboarding."
        />

        <SingleService
          title="Configuration"
          image={icon2}
          body="Complete the onboarding and configuration for your school."
        />

        <SingleService
          title="Onboard Parties"
          image={icon3}
          body="You can now onboard and add the teachers, parents, and students."
        />

        <SingleService
          title="Updates"
          image={icon4}
          body="Get access to Edssistance and its many wonderful features.."
        />
      </div>
    </section>
  );
};

export default Services;
