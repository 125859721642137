import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// General
import Landing from "./pages/Landing";
import FAQ from "./pages/Faq";
import Error from "./pages/404";
import PasswordReset from "./pages/Auth/PasswordReset";
import Preloader from "./components/UI/Preloader";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ForgotPassword/[key]";
import ReportCard from "./pages/General/ReportCard";
import GeneralChat from "./pages/General/Chat";

// Admin
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Billing from "./pages/Admin/Billing/Billing";
import Bursary from "./pages/Admin/Bursary/Bursary";
import WithdrawECash from "./pages/Admin/Bursary/WithdrawECash";
import AdminCalendar from "./pages/Admin/AdminCalendar";
import AdminSettings from "./pages/Admin/AdminSettings";
import Sickbay from "./pages/Admin/Sickbay";
import Staffs from "./pages/Admin/Staffs/Staffs";
import AddStaff from "./pages/Admin/Staffs/AddStaff";
import SingleStaff from "./pages/General/SingleStaff";
import EditStaff from "./pages/Admin/Staffs/EditStaff";
import AdminStore from "./pages/Admin/AdminStore";
import Students from "./pages/Admin/Students/Students";
import SingleStudent from "./pages/General/SingleStudent";
import AddStudent from "./pages/Admin/Students/AddStudent";
import EditStudent from "./pages/Admin/Students/EditStudent";
import Generators from "./pages/Admin/Generator/Generators";
import ClassGenerators from "./pages/Admin/Generator/Generate/ClassGenerators";
import GeneratedClasses from "./pages/Admin/Generator/Generated/GeneratedClasses";
import GeneratedPayAdvice from "./pages/Admin/Generator/Generated/GeneratedPayAdvice";
import PayadviceGenerator from "./pages/Admin/Generator/Generate/PayadviceGenerator";
import SubjectGenerator from "./pages/Admin/Generator/Generate/SubjectGenerator";
import GeneratedSubjects from "./pages/Admin/Generator/Generated/GeneratedSubjects";
import School from "./pages/Admin/School/School";
import FirstTerm from "./pages/Admin/Generator/Results/FirstTerm";
import SecondTerm from "./pages/Admin/Generator/Results/SecondTerm";
import ThirdTerm from "./pages/Admin/Generator/Results/ThirdTerm";
import EditClass from "./pages/Admin/Generator/EditGenerated/EditClass";
import EditPayAdvice from "./pages/Admin/Generator/EditGenerated/EditPayAdvice";
import EditSubject from "./pages/Admin/Generator/EditGenerated/EditSubject";
import Debtors from "./pages/Admin/Generator/Generated/Debtors";
import EditSchool from "./pages/Admin/School/EditSchool";
import AddSchoolAdmins from "./pages/Admin/School/AddSchoolAdmins";
import RemoveAdmins from "./pages/Admin/School/RemoveAdmins";
import Subscribe from "./pages/Admin/Billing/Subscribe";
import GeneratedReportCards from "./pages/Admin/Generator/Generated/GeneratedReportCards";
import ReportCardGenerators from "./pages/Admin/Generator/Generate/ReportCardGenerators";
import AdminChats from "./pages/Admin/Chats";

// Parents
import Kids from "./pages/Parent/Kids";
import ParentCalendar from "./pages/Parent/ParentCalendar";
import ParentSettings from "./pages/Parent/ParentSettings";
import ParentStore from "./pages/Parent/ParentStore";
import Payment from "./pages/Parent/Payment";
import ParentChats from "./pages/Parent/Chats";

// Student
import StudentCalendar from "./pages/Student/StudentCalendar";
import StudentSettings from "./pages/Student/StudentSettings";
import Teachers from "./pages/Student/Teachers";
import Classmates from "./pages/Student/Classmates";
import StudentChats from "./pages/Student/Chats";

// Teacher
import Class from "./pages/Teacher/Class/Class";
import TeacherCalendar from "./pages/Teacher/TeacherCalendar";
import TeacherSettings from "./pages/Teacher/TeacherSettings";
import Teachings from "./pages/Teacher/Teachings/Teachings";
import SubjectDetails from "./pages/Teacher/Teachings/SubjectDetails";
import Attendance from "./pages/Teacher/Class/Attendance/Attendance";
import SelectWeek from "./pages/Teacher/Class/Attendance/SelectWeek";
import MarkAttendance from "./pages/Teacher/Class/Attendance/MarkAttendance";
import Classes from "./pages/Teacher/Class/Classes";
import TeacherChats from "./pages/Teacher/Chats";

// Customs
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/UI/GlobalStyles";
import ProtectedLayout from "./components/Layouts/ProtectedLayout";
import Parents from "./pages/Admin/Parents/Parents";
import SingleParent from "./pages/General/SingleParent";
import QuizPage from "./pages/Student/Quiz";

function App() {
    const location = useLocation();

    const fontSize = useSelector((state: RootState) => state.handlers.fontSize);

    const theme = {
        fontSize,
    };

    const client = new QueryClient();

    return (
        <QueryClientProvider client={client}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Routes>
                    <Route path="*" element={<Error />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/forgot-password/:code" element={<ResetPassword />} />
                    <Route path="/password-reset" element={<PasswordReset />} />

                    <Route
                        path="/report-card/:studentId"
                        element={
                            <ProtectedLayout>
                                <ReportCard />
                            </ProtectedLayout>
                        }
                    />
                    <Route path="/admin/">
                        <Route path="dashboard" element={<AdminDashboard />} />
                        <Route path="billing/">
                            <Route index element={<Billing />} />
                            <Route path=":plan" element={<Subscribe />} />
                        </Route>
                        <Route path="school/">
                            <Route index element={<School />} />
                            <Route path="edit" element={<EditSchool />} />
                            <Route path="add-admin" element={<AddSchoolAdmins />} />
                            <Route path="remove-admin" element={<RemoveAdmins />} />
                        </Route>
                        <Route path="generator/">
                            <Route index element={<Generators />} />
                            <Route path="class" element={<ClassGenerators />} />
                            <Route path="pay-advice" element={<PayadviceGenerator />} />
                            <Route path="subject" element={<SubjectGenerator />} />
                            <Route path="report-card" element={<ReportCardGenerators />} />
                            <Route path="generated/">
                                <Route path="class" element={<GeneratedClasses />} />
                                <Route path="pay-advice" element={<GeneratedPayAdvice />} />
                                <Route path="subject" element={<GeneratedSubjects />} />
                                <Route path="report-card" element={<GeneratedReportCards />} />
                                <Route path="result/1st/:classId" element={<FirstTerm />} />
                                <Route path="result/2nd/:classId" element={<SecondTerm />} />
                                <Route path="result/3rd/:classId" element={<ThirdTerm />} />
                                <Route path="attendance/:classId" element={<Attendance />} />
                                <Route path="debtors/:classId" element={<Debtors />} />
                            </Route>

                            <Route path="edit/">
                                <Route path="class/:id" element={<EditClass />} />
                                <Route path="pay-advice/:id" element={<EditPayAdvice />} />
                                <Route path="subject/:id" element={<EditSubject />} />
                            </Route>
                        </Route>

                        <Route path="staffs/">
                            <Route index element={<Staffs />} />
                            <Route path=":id" element={<SingleStaff />} />
                            <Route path="new" element={<AddStaff />} />
                            <Route path="edit/:id" element={<EditStaff />} />
                        </Route>

                        <Route path="students/">
                            <Route index element={<Students />} />
                            <Route path=":id" element={<SingleStudent />} />
                            <Route path="new" element={<AddStudent />} />
                            <Route path="edit/:id" element={<EditStudent />} />
                        </Route>

                        <Route path="parents/">
                            <Route index element={<Parents />} />
                            <Route path=":id" element={<SingleParent />} />
                        </Route>
                        <Route path="calendar" element={<AdminCalendar />} />
                        <Route path="sick-bay" element={<Sickbay />} />
                        <Route path="bursary/">
                            <Route index element={<Bursary />} />
                            <Route path="withdraw-ecash" element={<WithdrawECash />} />
                        </Route>
                        <Route path="store" element={<AdminStore />} />
                        <Route path="settings" element={<AdminSettings />} />
                        <Route path="chat/">
                            <Route index element={<AdminChats />} />
                            <Route path=":id" element={<GeneralChat />} />
                        </Route>
                    </Route>

                    <Route path="/parent/">
                        <Route path="profile" element={<SingleParent />} />
                        <Route path="kids" element={<Kids />} />
                        <Route path="kid/:id" element={<SingleStudent />} />
                        <Route path="kid/:id/payment" element={<Payment />} />
                        <Route path="calendar" element={<ParentCalendar />} />
                        <Route path="settings" element={<ParentSettings />} />
                        <Route path="store" element={<ParentStore />} />
                        <Route path="chat/">
                            <Route index element={<ParentChats />} />
                            <Route path=":id" element={<GeneralChat />} />
                        </Route>
                    </Route>

                    <Route path="/teacher/">
                        <Route path="profile" element={<SingleStaff />} />
                        <Route path="add-student" element={<AddStudent />} />
                        <Route path="calendar" element={<TeacherCalendar />} />
                        <Route path="settings" element={<TeacherSettings />} />
                        <Route path="teachings/">
                            <Route index element={<Teachings />} />
                            <Route path="subject/:classId/:subjectId" element={<SubjectDetails />} />
                        </Route>
                        <Route path="class/">
                            <Route index element={<Classes />} />
                            <Route path=":classId" element={<Class />} />

                            <Route path="result/">
                                <Route path="1st/:classId" element={<FirstTerm />} />
                                <Route path="2nd/:classId" element={<SecondTerm />} />
                                <Route path="3rd/:classId" element={<ThirdTerm />} />
                            </Route>

                            <Route path="attendance/:classId/">
                                <Route index element={<Attendance />} />
                                <Route path="select-week" element={<SelectWeek />} />
                                <Route path="mark-attendance" element={<MarkAttendance />} />
                            </Route>
                        </Route>

                        <Route path="students/:id" element={<SingleStudent />} />

                        <Route path="chat/">
                            <Route index element={<TeacherChats />} />
                            <Route path=":id" element={<GeneralChat />} />
                        </Route>
                    </Route>

                    <Route path="/student/">
                        <Route path="classmates" element={<Classmates />} />
                        <Route path="teachers" element={<Teachers />} />
                        <Route path="calendar" element={<StudentCalendar />} />
                        <Route path="settings" element={<StudentSettings />} />
                        <Route path="profile" element={<SingleStudent />} />
                        <Route path="chat/">
                            <Route index element={<StudentChats />} />
                            <Route path=":id" element={<GeneralChat />} />
                        </Route>
                        <Route path="quiz" element={<QuizPage />} />
                    </Route>
                </Routes>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
