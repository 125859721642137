import React, { useEffect, useState } from "react";
import { RiMessageFill } from "react-icons/ri";
import logo from "../../assets/images/logo.png";
import NavBar from "../../components/UI/NavBar";
import Step1 from "../../components/Registration/Step1";
import Step2 from "../../components/Registration/Step2";
import Step3 from "../../components/Registration/Step3";
import Step4 from "../../components/Registration/Step4";
import VerificationModal from "../../components/Registration/VerificationModal";

export interface StepProps {
    increaseStep: () => void;
    openModal?: () => void;
}

const SubscriptionPlans = () => {
    const [step, setStep] = useState<number>(0);
    const [verificationModalOpened, setVerificationModalOpened] = useState<boolean>(false);

    const openModal = () => {
        setVerificationModalOpened(true);
    };
    const closeModal = () => {
        setVerificationModalOpened(false);
    };

    const increaseStep = () => {
        if (step < 3) {
            setStep(step + 1);
        }
    };

    useEffect(() => {
        document.title = "Edssistance | Register School";
    }, []);

    return (
        <section className="w-full min-h-screen bg-mainBg">
            {verificationModalOpened && <VerificationModal increaseStep={increaseStep} closeModal={closeModal} />}
            <div className="max-w-[1300px] mx-auto">
                <NavBar />

                <div className="flex items-center gap-x-4 justify-center w-full mt-14 py-[1rem] md:px-[3rem] sm:px-[2rem] px-[1rem]">
                    {Array(4)
                        .fill(null)
                        .map((steps, index: number) => {
                            return (
                                <span
                                    className={`w-[50px]  h-[6px] rounded-[1.25rem] cursor-pointer ${
                                        index === step ? "bg-black" : "bg-lightGray"
                                    }`}
                                    key={index}
                                    onClick={() => {
                                        index < step && setStep(index);
                                    }}
                                />
                            );
                        })}
                </div>

                <div className="mt-14 w-full min-h-[400px] sm:p-8 py-8 px-4 rounded-md bg-[rgba(235,232,232,0.2)] shadow-md border border-[rgba(175,175,175,0.6)]">
                    {step === 0 && <Step1 increaseStep={increaseStep} openModal={openModal} />}
                    {step === 1 && <Step2 increaseStep={increaseStep} />}
                    {step === 2 && <Step3 increaseStep={increaseStep} />}
                    {step === 3 && <Step4 increaseStep={increaseStep} />}
                </div>
            </div>
        </section>
    );
};

export default SubscriptionPlans;
