import { useEffect, useState } from "react";
import { SingleBulkStudent } from "../../types/states.types";
import DEFAULT_MATCHERS from "../../constants/regex.const";
import {
    useCheckEmail,
    useCheckParentPhoneNumber,
    useCheckPhoneNumber,
    useCheckUsername,
} from "../../api/mutations/auth.mutation";
import { StaffBulkChecks, StudentBulkChecks } from "../../types/bulk.types";

export const useValidateStudentUpload = (
    student?: SingleBulkStudent
): {
    errors: StudentBulkChecks;
    warnings: Partial<StudentBulkChecks>;
    loading: StudentBulkChecks;
} => {
    const [errors, setErrors] = useState<StudentBulkChecks>({
        email: false,
        phone: false,
        parentPhone: false,
        userName: false,
    });
    const [parentPhoneWarning, setParentPhoneWarning] = useState<boolean>(false);

    const emailCheck = useCheckEmail();
    const usernameCheck = useCheckUsername();
    const phoneCheck = useCheckPhoneNumber();
    const parentPhoneCheck = useCheckParentPhoneNumber();

    const updateError = (key: keyof StudentBulkChecks, value: boolean) => {
        setErrors((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        async function check() {
            if (student?.email) {
                if (DEFAULT_MATCHERS.email.test(student?.email)) {
                    const status = await emailCheck.mutateAsync(student?.email as string);
                    if (status === "error") {
                        updateError("email", true);
                    } else {
                        updateError("email", false);
                    }
                } else {
                    emailCheck.reset();
                    updateError("email", false);
                }
            } else {
                emailCheck.reset();
                updateError("email", false);
            }
        }
        check();
    }, [student?.email]);
    useEffect(() => {
        async function check() {
            if (student?.userName) {
                if (student?.userName?.length > 5) {
                    const status = await usernameCheck.mutateAsync(student?.userName as string);
                    if (status === "error") {
                        updateError("userName", true);
                    } else {
                        updateError("userName", false);
                    }
                } else {
                    usernameCheck.reset();
                    updateError("userName", false);
                }
            } else {
                usernameCheck.reset();
                updateError("userName", false);
            }
        }

        check();
    }, [student?.userName]);

    useEffect(() => {
        async function check() {
            if (student?.phoneNumber) {
                if (student.phoneNumber.length >= 10) {
                    const status = await phoneCheck.mutateAsync(student?.phoneNumber as string);
                    if (status === "error") {
                        updateError("phone", true);
                    } else {
                        updateError("phone", false);
                    }
                } else {
                    phoneCheck.reset();
                    updateError("phone", false);
                }
            } else {
                phoneCheck.reset();
                updateError("phone", false);
            }
        }

        check();
    }, [student?.phoneNumber]);

    useEffect(() => {
        async function check() {
            if (student?.parentPhoneNumber) {
                if (student.parentPhoneNumber.length >= 10) {
                    const status = await parentPhoneCheck.mutateAsync(student?.parentPhoneNumber as string);
                    if (status === "error") {
                        updateError("parentPhone", true);
                        setParentPhoneWarning(false);
                    } else if (status === "success") {
                        updateError("parentPhone", false);
                        setParentPhoneWarning(false);
                    } else {
                        updateError("parentPhone", false);
                        setParentPhoneWarning(true);
                    }
                } else {
                    phoneCheck.reset();
                    updateError("parentPhone", false);
                    setParentPhoneWarning(false);
                }
            } else {
                phoneCheck.reset();
                updateError("parentPhone", false);
                setParentPhoneWarning(false);
            }
        }

        check();
    }, [student?.parentPhoneNumber]);

    return {
        errors,
        warnings: {
            parentPhone: parentPhoneWarning,
        },
        loading: {
            parentPhone: parentPhoneCheck.isLoading,
            phone: phoneCheck.isLoading,
            email: emailCheck.isLoading,
            userName: usernameCheck.isLoading,
        },
    };
};

export const useValidateStudentBulkTable = () => {
    const validate = (errors: StudentBulkChecks[]) => {
        let valid = true;

        for (const error of errors) {
            for (const isError of Object.values(error)) {
                if (isError === true) {
                    valid = false;
                }
            }
        }

        return valid;
    };

    return { validate };
};

export const useValidateStaffUpload = (
    student?: SingleBulkStudent
): {
    errors: StaffBulkChecks;
    warnings: Partial<StaffBulkChecks>;
    loading: StaffBulkChecks;
} => {
    const [errors, setErrors] = useState<StaffBulkChecks>({
        email: false,
        phone: false,
    });

    const emailCheck = useCheckEmail();
    const phoneCheck = useCheckPhoneNumber();

    const updateError = (key: keyof StaffBulkChecks, value: boolean) => {
        setErrors((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        async function check() {
            if (student?.email) {
                if (DEFAULT_MATCHERS.email.test(student?.email)) {
                    const status = await emailCheck.mutateAsync(student?.email as string);
                    if (status === "error") {
                        updateError("email", true);
                    } else {
                        updateError("email", false);
                    }
                } else {
                    emailCheck.reset();
                    updateError("email", false);
                }
            } else {
                emailCheck.reset();
                updateError("email", false);
            }
        }
        check();
    }, [student?.email]);

    useEffect(() => {
        async function check() {
            if (student?.phoneNumber) {
                if (student.phoneNumber.length >= 10) {
                    const status = await phoneCheck.mutateAsync(student?.phoneNumber as string);
                    if (status === "error") {
                        updateError("phone", true);
                    } else {
                        updateError("phone", false);
                    }
                } else {
                    phoneCheck.reset();
                    updateError("phone", false);
                }
            } else {
                phoneCheck.reset();
                updateError("phone", false);
            }
        }

        check();
    }, [student?.phoneNumber]);

    return {
        errors,
        warnings: {},
        loading: {
            phone: phoneCheck.isLoading,
            email: emailCheck.isLoading,
        },
    };
};

export const useValidateStaffBulkTable = () => {
    const validate = (errors: StaffBulkChecks[]) => {
        let valid = true;

        for (const error of errors) {
            for (const isError of Object.values(error)) {
                if (isError === true) {
                    valid = false;
                }
            }
        }

        return valid;
    };

    return { validate };
};
