import React, { useState, useRef } from "react";
import { IFAQ } from "./FAQs";
import { BiMinus, BiPlus } from "react-icons/bi";

const FaqAccordion = ({ question, answer }: IFAQ) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const answerRef = useRef(null);

  const getRefHeight = () => {
    if (answerRef) {
      return (answerRef.current as any).getBoundingClientRect().height;
    }
  };

  return (
    <article className="w-full py-4 border-t-2">
      <header
        className="flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>{question}</p>

        <span className="text-[1.4rem] font-bold cursor-pointer">
          {isOpen ? <BiMinus /> : <BiPlus />}
        </span>
      </header>
      <div
        className={`overflow-y-hidden transition-all duration-300`}
        style={{ maxHeight: isOpen ? `${getRefHeight()}px` : "0px" }}
      >
        <p className="text-[.9rem] text-darkGray py-2" ref={answerRef}>
          {answer}
        </p>
      </div>
    </article>
  );
};

export default FaqAccordion;
