export const getDateInFull = (date: Date | string) => {
  if (!date) return null;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateFormat = new Date(date);

  return `${dateFormat.getDate()} ${
    months[dateFormat?.getMonth()]
  }, ${dateFormat.getFullYear()}`;
};
