import React from "react";
import { BlackOutlineButton, ButtonFilled } from "../UI/Buttons";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";

const CTA = () => {
  return (
    <Zoom duration={900} triggerOnce={true}>
      <section className="md:mt-[8rem] mt-[4rem] w-full min-h-[350px] bg-lightGray rounded-[56px] flex items-center justify-center flex-col md:p-[5rem] p-[2rem] text-center">
        <h2 className="md:text-[2rem] text-[1.8rem] lg:text-[3rem] font-bold leading-[1.2] mb-1">
          Join Edssistance Today
        </h2>
        <p className="font-[500]">
          Join Edssistance and get access to the many features available.
        </p>

        <div className="flex items-center justify-center gap-[0.5rem] flex-wrap mt-[2rem]">
          <Link to={"/register"}>
            <BlackOutlineButton
              text="Sign Up"
              className="!min-w-[100px] 2xs:!min-w-[150px]"
            />
          </Link>
          <Link to={"/login"}>
            <ButtonFilled
              text="Log in"
              className="!min-w-[100px] 2xs:!min-w-[150px]"
            />
          </Link>
        </div>
      </section>
    </Zoom>
  );
};

export default CTA;
