import React from "react";
import dummySignature from "../../assets/images/profile-img.jpg";
import {
  IAllStudents,
  IGeneratedClasses,
  IStudentReportInfo,
} from "../../types/db.types";

interface Props {
  termlyInfo?: IStudentReportInfo;
  studentClass?: IGeneratedClasses;
}

const Remarks = ({ termlyInfo, studentClass }: Props) => {
  return (
    <>
      {termlyInfo?.classTeacherComment && (
        <div>
          <p className="italic text-[.9rem] font-bold">Teacher's Remark:</p>
          <div className={styles.box}>
            <p>{termlyInfo?.classTeacherComment}</p>
          </div>
          <p className="text-[.9rem] mr-4 mt-2">
            Teacher's Name:{" "}
            <b>
              {studentClass?.classTeacher?.firstName}{" "}
              {studentClass?.classTeacher?.middleName}{" "}
              {studentClass?.classTeacher?.lastName}
            </b>
          </p>
        </div>
      )}

      {termlyInfo?.adminComment && (
        <div className="mt-2">
          <p className="italic text-[.9rem] font-bold">Admin's Remark:</p>
          <div className={styles.box}>
            <p>{termlyInfo?.adminComment}</p>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  box: "border-2 text-[.9rem] italic mt-2 border-blackText p-2 rounded-md text-center",
};

export default Remarks;
