import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { baseHttp } from "../../axios.config";
import { formatPhoneNumber } from "../../utils/dataHandling";
import { IUser } from "../../types/db.types";
import { AuthCheckStatus } from "../../types/states.types";

export const useCheckEmail = () => {
    const mutation = useMutation("useCheckEmail", async (email: string): Promise<AuthCheckStatus> => {
        const response = await baseHttp.post("/user/check/email", { email });

        if (response.data) {
            return "error";
        } else {
            return "success";
        }
    });
    return mutation;
};

export const useCheckPhoneNumber = () => {
    const mutation = useMutation("usecheckphonenumber", async (number: string): Promise<AuthCheckStatus> => {
        const response = await baseHttp.post("/user/check/phone-number", { phoneNumber: formatPhoneNumber(number) });

        if (response.data) {
            return "error";
        } else {
            return "success";
        }
    });
    return mutation;
};

export const useCheckParentPhoneNumber = () => {
    const mutation = useMutation("usecheckparentphonenumber", async (number: string): Promise<AuthCheckStatus> => {
        const response = await baseHttp.post<IUser>("/user/check/phone-number", {
            phoneNumber: formatPhoneNumber(number),
        });

        if (response.data) {
            if (response.data.role != "parent") {
                return "error";
            } else {
                return "warning";
            }
        } else {
            return "success";
        }
    });
    return mutation;
};

export const useCheckUsername = () => {
    const mutation = useMutation("usecheckusername", async (userName: string): Promise<AuthCheckStatus> => {
        const response = await baseHttp.post("/user/check/username", { userName });

        if (response.data) {
            return "error";
        } else {
            return "success";
        }
    });
    return mutation;
};
