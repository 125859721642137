import React, { ReactElement, useEffect, useState } from "react";
import {
  BiBook,
  BiCheckCircle,
  BiDotsVerticalRounded,
  BiTransfer,
  BiUser,
  BiPlus,
  BiMinus,
} from "react-icons/bi";
import { AiOutlineRotateLeft } from "react-icons/ai";
import { FaEquals, FaExchangeAlt } from "react-icons/fa";
import { GiBank, GiCancel } from "react-icons/gi";
import { RiArrowRightDownLine, RiArrowRightUpLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import BursaryReceipt, {
  WithdrawalReceipt,
} from "../../../components/Admin/BursaryReceipt";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import {
  BlackOutlineButton,
  ButtonFilled,
  OutlineButton,
} from "../../../components/UI/Buttons";
import {
  ContentBox,
  SmallContentBoxWIthAvatar,
} from "../../../components/UI/ContentBox";
import ModalOverlay from "../../../components/UI/ModalOverlay";
import {
  bursaryTransactions,
  recentTransactionsHeaders,
} from "../../../static/admin&teacher.data";
import { MdClose, MdOutlineCancel } from "react-icons/md";
import {
  useGetBursaryInfo,
  useGetTransactionHistory,
} from "../../../api/hooks/admin/school/school.service";
import {
  CashReceiptModal,
  WithdrawCash,
} from "../../../components/Admin/BursaryModals";
import {
  ITransaction,
  ITransactionHistory,
  IWalletTransaction,
} from "../../../types/db.types";
import { dateConverter } from "../../../utils/dateConverter";

type transactionTypes = "inflow" | "outflow";

const Bursary = () => {
  const [transactionFilter, setTransactionFilter] =
    useState<transactionTypes>("inflow");
  const [receiptModalOpen, setReceiptModalOpen] = useState<boolean>(false);
  const [transactionReceipt, setTransactionReceipt] = useState<
    ITransaction | undefined
  >(undefined);

  const [withdrawalReceiptModalOpen, setWithdrawalReceiptModalOpen] =
    useState<boolean>(false);
  const [withdrawalReceipt, setWithdrawalReceipt] = useState<
    IWalletTransaction | undefined
  >(undefined);

  const [width, setWidth] = useState(window.innerWidth);

  const { data: transactionHistory, isLoading } = useGetTransactionHistory();
  const { data: info } = useGetBursaryInfo();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <DashboardLayout pageTitle="Bursary">
      <>
        {/* Receipt for all transaction /** pass the data as props oooo */}

        {receiptModalOpen && (
          <BursaryReceipt
            receipt={transactionReceipt}
            setModalOpen={setReceiptModalOpen}
          />
        )}

        {withdrawalReceiptModalOpen && (
          <WithdrawalReceipt
            setModalOpen={setWithdrawalReceiptModalOpen}
            receipt={withdrawalReceipt}
          />
        )}
      </>
      <>
        <section className="sm:p-6 py-6 min-h-screen">
          <p className="mb-3 sm:hidden ">
            {" "}
            <span className="text-[24px] text-white bg-blackText w-[34px] h-[34px] mr-2 align-middle inline-flex items-center justify-center rounded-full">
              <AiOutlineRotateLeft />
            </span>{" "}
            Kindly tilt your device or use a larger screen for a better view
          </p>
          <div className="mb-3">
            <TableLayout>
              <div className="flex flex-col gap-x-2 gap-y-2 items-center justify-start min-w-[800px] mx-auto p-4">
                <div className="w-full flex items-center justify-center gap-x-4 flex-nowrap min-w-fit">
                  <div className="mb-2 min-w-fit">
                    <SmallContentBoxWIthAvatar
                      maxWidth={300}
                      fontSize={12}
                      avatar={"₦"}
                      title={"Expected Amount"}
                      subtitle={info?.totalExpected as number}
                      iconBg={"#5151f536"}
                      iconColor={"royalblue"}
                      borderColor={"royalblue"}
                      fullFlex={true}
                    />
                  </div>
                </div>
                <div className="w-full min-w-fit flex items-start justify-center gap-x-4 flex-nowrap">
                  <div className="min-w-fit flex-1">
                    <SmallContentBoxWIthAvatar
                      maxWidth={300}
                      fontSize={12}
                      avatar={"₦"}
                      title={"Cash In-flow"}
                      subtitle={info?.cash as number}
                      iconBg={"#51f55f36"}
                      iconColor={"#136837"}
                      borderColor={"#136837"}
                      fullFlex={true}
                    />
                  </div>
                  <BiPlus className="text-[1.25rem] text-blackText font-bold self-center min-w-fit mb-3" />
                  <div className="min-w-fit flex-1">
                    <SmallContentBoxWIthAvatar
                      maxWidth={300}
                      fontSize={12}
                      avatar={"₦"}
                      title={"E-Cash In-flow"}
                      subtitle={info?.paystack as number}
                      iconBg={"#51f55f36"}
                      iconColor={"#136837"}
                      borderColor={"#136837"}
                      fullFlex={true}
                    />
                  </div>
                  <FaEquals className="min-w-fit self-center mb-3" />
                  <div className="min-w-fit flex-1">
                    <SmallContentBoxWIthAvatar
                      maxWidth={300}
                      fontSize={12}
                      avatar={"₦"}
                      title={"Total In-flow"}
                      subtitle={
                        (info?.cash as number) + (info?.paystack as number)
                      }
                      iconBg={"#51f55f36"}
                      iconColor={"#136837"}
                      borderColor={"#136837"}
                      fullFlex={true}
                    />
                  </div>
                </div>
              </div>
            </TableLayout>
          </div>

          <ContentBox defaultPadding={false}>
            <header className="sm:px-6 px-4 pb-4 border-b-[1.5px] text-blackText font-bold">
              Transactions
            </header>
            <div className="py-6 sm:px-6 px-4 flex  flex-wrap gap-y-4 gap-x-6">
              {/* <div className={styles.bursary_icons}>
              <BiUser />
              Staff Salary
            </div> */}
              {/* <div className={styles.bursary_icons}>
              <FaExchangeAlt />
              Petty Cash
            </div> */}
              {/* <div className={styles.bursary_icons}>
              <GiBank />
              In-Flow
            </div> */}

              <div
                className={
                  styles.bursary_icons +
                  " flex-col justify-start !items-start text-white bg-blackText px-3 py-2 rounded-[1.25rem]"
                }
              >
                <h4>Available Balance</h4>
                <p>₦{info?.balance?.toLocaleString()}</p>
              </div>

              <Link
                to={"/admin/bursary/withdraw-ecash"}
                className={
                  styles.bursary_icons +
                  "  justify-start !items-start text-white bg-blackText px-3 py-2 rounded-[1.25rem]"
                }
              >
                <h4>
                  <FaExchangeAlt className="align-middle inline-block mr-2" />
                  Withdraw E-Cash
                </h4>
              </Link>
            </div>
          </ContentBox>

          <article>
            <header className="mt-7 text-[1.125rem] md:text-[24px] font-bold justify-between items-center text-darkerGray flex flex-wrap">
              <h1>Recent Transactions</h1>
            </header>
            <div className="my-4 overflow-x-scroll">
              <div className="flex items-center min-w-fit justify-center gap-x-3">
                {(
                  [
                    { text: "inflow", icon: <GiBank /> },
                    { text: "outflow", icon: <BiTransfer /> },
                  ] as { text: transactionTypes; icon?: ReactElement }[]
                ).map((transactionType, index: number) => {
                  return (
                    <div
                      key={index}
                      className="min-w-fit"
                      onClick={() => setTransactionFilter(transactionType.text)}
                    >
                      {transactionFilter === transactionType.text ? (
                        <ButtonFilled
                          icon={transactionType?.icon}
                          text={
                            transactionType?.text === "inflow"
                              ? "in-flow"
                              : "out-flow"
                          }
                        />
                      ) : (
                        <OutlineButton
                          icon={transactionType?.icon}
                          text={
                            transactionType?.text === "inflow"
                              ? "in-flow"
                              : "out-flow"
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full sm:px-0 px-2">
              <TableLayout>
                <TableHeader>
                  {recentTransactionsHeaders.map((header, index) => {
                    return <SingleTableHeader {...header} key={index} />;
                  })}
                </TableHeader>
                <TableRowsContainer>
                  {transactionHistory?.[
                    transactionFilter as keyof ITransactionHistory
                  ]?.map((transaction, index) => {
                    return (
                      <TableRow key={index}>
                        <SingleTableRowItem
                          width={recentTransactionsHeaders[0].width}
                        >
                          <p>
                            {transaction?.createdAt
                              ? dateConverter(transaction?.createdAt)
                              : "-"}
                          </p>
                        </SingleTableRowItem>

                        <SingleTableRowItem
                          width={recentTransactionsHeaders[1].width}
                        >
                          <p>
                            {transaction?.initiator?.firstName}{" "}
                            {transaction?.initiator?.lastName}
                          </p>
                        </SingleTableRowItem>
                        <SingleTableRowItem
                          width={recentTransactionsHeaders[2].width}
                        >
                          <p>{"₦ " + transaction.amount.toLocaleString()}</p>
                        </SingleTableRowItem>
                        <SingleTableRowItem
                          width={recentTransactionsHeaders[3].width}
                        >
                          <p
                            className="cursor-pointer"
                            onClick={() => {
                              if ((transaction as ITransaction)?.studentId) {
                                setReceiptModalOpen(true);
                                setTransactionReceipt(
                                  transaction as ITransaction
                                );
                              } else {
                                setWithdrawalReceiptModalOpen(true);
                                setWithdrawalReceipt(
                                  transaction as IWalletTransaction
                                );
                              }
                            }}
                          >
                            View Details
                          </p>
                        </SingleTableRowItem>
                      </TableRow>
                    );
                  })}
                </TableRowsContainer>
              </TableLayout>
            </div>
          </article>
        </section>
      </>
    </DashboardLayout>
  );
};

const styles = {
  flex_containers:
    "flex items-center justify-center md:justify-start gap-x-6 gap-y-6 flex-wrap w-full mb-6",
  bursary_icons: "flex items-center gap-x-3 font-bold hover:underline",
  flex_between: "flex items-center justify-between gap-x-4 gap-y-4 mb-2 ",
};

export default Bursary;
