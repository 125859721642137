import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  useGetClassScores,
  useGetStudentsAverage,
} from "../../api/hooks/admin/generators/generators.service";
import { IGeneratedSubjects } from "../../types/db.types";
import { getAllSubjects } from "../result-calculator";
import { approveClassResult } from "../../api/thunks/teacher/teacher.service";
import { useDispatch } from "react-redux";

const useStoreResultAverage = (classId: string, isAdminPage: boolean) => {
  const dispatch = useDispatch();

  const resultEndpoint = isAdminPage
    ? "/school/class-scores"
    : "/teacher/class-result";

  const [subjects, setSubjects] = useState<IGeneratedSubjects[]>([]);

  const { data: classScores, isLoading } = useGetClassScores(
    resultEndpoint,
    classId as string
  );

  const approveResult = async () => {
    const data = await dispatch(
      approveClassResult({
        classId,
        subjectsLength: subjects?.length,
        scores: classScores,
      })
    );
  };

  useEffect(() => {
    // get all the subjects from the score of the first student
    if (classScores && classScores?.[0] && !isLoading) {
      setSubjects(getAllSubjects(classScores?.[0]));
    }
  }, [isLoading, classScores]);

  return {
    classScores,
    subjects,
    approveResult,
  };
};

export default useStoreResultAverage;
