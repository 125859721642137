import { BiUser } from "react-icons/bi";
import { getDateInFull } from "../../../utils/getDateInFull";
import { SingleProfileDetail } from "../StaffDetailsTabs";
import { ContentBoxWithAvatar, ContentBoxWithAvatarHeader } from "../../UI/ContentBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IAllParents, IAllStudents } from "../../../types/db.types";
import { useGetParentKids } from "../../../api/hooks/admin/parents/parents.service";
import { FiUsers } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

interface Props extends IAllParents {}

export const Profile = (props: Props) => {
    const { data: kids } = useGetParentKids();
    const location = useLocation();

    return (
        <>
            <div className="w-full flex gap-x-4 gap-y-4 xl:flex-row flex-col">
                <div className="flex-[60%]">
                    <ContentBoxWithAvatar avatar={<BiUser />} header={"General Information"} hasButton={false}>
                        <section className={styles.content_rows}>
                            <SingleProfileDetail title={"Name"} subtitle={`${props?.firstName} ${props?.lastName}`} />
                            <SingleProfileDetail title={"Email"} subtitle={`${props?.email}`} />
                            <SingleProfileDetail title={"Phone Number"} subtitle={props?.phoneNumber?.toString()} />
                        </section>
                    </ContentBoxWithAvatar>
                </div>
                <div className="flex-[40%] gap-y-4">
                    {location.pathname.includes("admin") && (
                        <ContentBoxWithAvatar avatar={<FiUsers />} header={"Kids"} hasButton={false}>
                            <div className="flex items-center flex-col">
                                {kids?.map((kid, index) => {
                                    return (
                                        <Link
                                            to={`/admin/students/${kid._id}`}
                                            key={index}
                                            className="flex items-center gap-2 justify-betweeen p-2 cursor-pointer hover:bg-[#f2f2f2] w-full border-b"
                                        >
                                            <div className="flex items-center gap-2">
                                                <img
                                                    src={kid?.imageUrl}
                                                    alt={kid?.firstName}
                                                    className="w-[30px] h-[30px] object-center rounded-full object-fit"
                                                />
                                                <p className="truncate text-[.8rem]">
                                                    {kid?.firstName} {kid?.lastName}
                                                </p>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </ContentBoxWithAvatar>
                    )}
                </div>
            </div>
        </>
    );
};

const styles = {
    content_rows: "grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 p-4 gap-x-4 gap-y-5",
    content: "flex flex-col gap-y-[5px] block",
    content_title: "text-[0.94rem] text-darkGray capitalize font-bold",
    content_text: "text-blackText text-[1rem] capitalize",
    bold_font: "font-bold text-[1rem] text-darkerGray",
    buttons:
        "px-4 py-2 flex items-center border-[1.5px] border-blackText rounded-md font-bold gap-x-4 flex-nowrap sm:min-w-[150px] text-center justify-center w-full sm:w-fit",
};
