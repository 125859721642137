import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useGetStudentAttendance } from "../../../api/hooks/admin/students/students.service";
import { ISingleStudentAttendance } from "../../../types/db.types";
import { getStudentDayAttendance } from "../../../utils/attendance";
import { RootState } from "../../../store/store";

export const Attendance = () => {
  const { id } = useParams();
  const location = useLocation();

  const student = useSelector((state: RootState) => state.getStudent);

  const endpoint = location.pathname.includes("admin")
    ? `/school/attendance/student/${id}`
    : location.pathname.includes("teacher")
    ? `/teacher/attendance/student/${id}`
    : location.pathname.includes("parent")
    ? `/parents/kid/attendance/${id}`
    : `/student/attendance`;

  const { data, isLoading: attendanceLoading } = useGetStudentAttendance(
    id as string,
    endpoint
  );

  return (
    <>
      <div className="flex bg-white flex-wrap h-[140px] border-[1.8px] border-blackText">
        <div className="flex-[30%] min-w-fit h-full flex flex-col justify-between p-[1rem] border-r-[1.8px] border-r-blackText">
          <h3 className="font-bold">Full Name</h3>
          <div>
            <img
              src={student?.imageUrl}
              className="w-[30px] h-[30px] object-cover object-center rounded-full inline-block align-middle mr-2"
              alt=""
              loading="lazy"
            />
            <h4 className="inline-block">
              {student?.firstName} {student?.lastName}
            </h4>
          </div>
        </div>
        <div className="flex-[70%] h-full overflow-x-scroll attendance-scrollbar">
          <div className=" h-full flex min-w-fit">
            {Array(14)
              .fill(null)
              .map((week, index) => {
                return (
                  <div
                    key={index}
                    className="h-full min-w-[250px] max-w-[250px] flex flex-col border-r-[1.5px] border-r-blackText"
                  >
                    <>
                      <h3 className="p-3 bg-blackText text-white text-center font-bold h-[33%]">
                        Week {index + 1}
                      </h3>
                      <div className="flex flex-1">
                        {[
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                        ].map((day, dayIndex) => {
                          return (
                            <div
                              className={`h-full flex-1 flex flex-col border-r-[2px] border-r-blackText`}
                              key={dayIndex}
                            >
                              <p className="h-[50%] flex justify-center items-center cursor-pointer border-b-[1.5px] border-b-blackText">
                                {day.slice(0, 3)}
                              </p>
                              <p className="h-[50%] flex justify-center items-center cursor-pointer ">
                                <span className="border-r-[1.5px] flex items-center justify-center border-r-[#969696] w-[20px] h-full">
                                  {getStudentDayAttendance(
                                    data as ISingleStudentAttendance[],
                                    index + 1,
                                    day,
                                    "morning"
                                  )}
                                </span>
                                <span className="w-[20px] flex items-center justify-center h-full">
                                  {getStudentDayAttendance(
                                    data as ISingleStudentAttendance[],
                                    index + 1,
                                    day,
                                    "afternoon"
                                  )}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
