import React from "react";
import { BiBell, BiEnvelope } from "react-icons/bi";
import { useGetUnreadMessages } from "../../api/hooks/chat/chat.service";

export default function BellIcon() {
    const { data: count, isLoading } = useGetUnreadMessages();

    return (
        <div className="relative">
            <BiEnvelope size={32} />
            {count && count > 0 ? (
                <span className="w-[20px] h-[20px]  text-[.8rem] flex items-center justify-center text-white bg-blackText rounded-full absolute top-[-7px] left-[50%] ">
                    {count}
                </span>
            ) : (
                <></>
            )}
        </div>
    );
}
