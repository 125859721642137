import React, { FC, useState } from "react";
import { StepProps } from "../../pages/Auth/Register";
import { adminDetailsProps } from "../../types/states.types";
import { InputFields } from "../../types/componentsProps.types";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { registerAsSuperAdmin } from "../../api/thunks/auth/auth.service";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import PasswordInput from "../UI/PasswordInput";

const Step1: FC<StepProps> = ({ increaseStep, openModal }) => {
  const [adminDetails, setAdminsDetails] = useState<adminDetailsProps>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateAdminDetails = (key: string, value: string) => {
    setAdminsDetails({ ...adminDetails, [key]: value });
  };

  const inputFields: InputFields[] = [
    {
      placeholder: "Enter First Name",
      name: "firstName",
      type: "text",
      value: adminDetails.firstName,
      label: "Create Firstname",
    },
    {
      placeholder: "Enter Last Name",
      name: "lastName",
      type: "text",
      value: adminDetails.lastName,
      label: "Create Last Name",
    },
    {
      placeholder: "Enter your email",
      name: "email",
      type: "email",
      value: adminDetails.email,
      label: "Email",
    },
    {
      placeholder: "Enter your password",
      name: "password",
      type: "password",
      value: adminDetails.password,
      label: "Password",
    },
    {
      placeholder: "Confirm your password",
      name: "confirm_password",
      type: "password",
      value: adminDetails.confirm_password,
      label: "Confirm password",
    },
  ];

  const registerSuperAdmin = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = await dispatch(
      registerAsSuperAdmin({
        firstName: adminDetails.firstName,
        lastName: adminDetails.lastName,
        email: adminDetails.email,
        password: adminDetails.password,
        confirmPassword: adminDetails.confirm_password,
      })
    );
    // if there's no error, open the modal and add the email to the route, from the modal, open the next step
    if (!data?.error) {
      setSearchParams({
        email: adminDetails.email,
        userId: data?.payload?.userId,
      });
      openModal && openModal();
    }
  };
  return (
    <>
      <header className="text-darkGray text-center">
        <h1 className="text-[27px] font-bold mb-2">
          Super Administrator Details
        </h1>
        <p className="text-[1rem] font-[500]">
          Provide your details as the super admin of this school
        </p>
      </header>
      <form className="" onSubmit={registerSuperAdmin}>
        <div className="mt-8 sm:px-3 mb-6 w-full max-w-[600px] mx-auto">
          <div className="w-full p-6 rounded-md bg-white shadow-md">
            {inputFields.map((field, index: number) => {
              return (
                <div key={index} className="mb-4">
                  <label
                    htmlFor={field.name}
                    className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                  >
                    {field.label}
                  </label>
                  {field.type !== "password" ? (
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={field.value}
                      minLength={4}
                      className="input-field"
                      onChange={(e) => {
                        updateAdminDetails(field.name, e.target.value);
                      }}
                      required
                    />
                  ) : (
                    <PasswordInput
                      placeholder={field.placeholder}
                      name={field.name}
                      value={field.value as string}
                      onChange={(e) => {
                        updateAdminDetails(field.name, e.target.value);
                      }}
                    />
                  )}
                  {field.name === "confirm_password" &&
                    adminDetails?.confirm_password.length > 0 &&
                    adminDetails.password !== adminDetails.confirm_password && (
                      <p className="font-bold text-[15px] text-red-600 mt-1">
                        Passwords do not match
                      </p>
                    )}
                </div>
              );
            })}
          </div>
        </div>
        <button
          className="max-w-full min-w-full sm:min-w-fit sm:max-w-fit ml-auto py-3 px-6 bg-black text-mainBg rounded-md block mt-6"
          type={"submit"}
        >
          Proceed{" "}
          <span className="inline-block align-middle ml-2">
            <BsArrowRight />
          </span>
        </button>
      </form>
    </>
  );
};

export default Step1;
