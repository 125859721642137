/**
 * Contains the lesson materials and class results tab for the subject details page
 */

import React, { useEffect } from "react";
import { subjectScoresHeaders } from "../../static/admin&teacher.data";
import TableLayout, {
  TableHeader,
  SingleTableHeader,
  TableRowsContainer,
  TableRow,
  SingleTableRowItem,
} from "../Layouts/TableLayout";
import { ScoreState, subjectsScoresProps } from "../../types/static.types";
import { ButtonFilled } from "../UI/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { updateSingleStudentScore } from "../../store/slices/get/scores.slice";
import { recordScore } from "../../api/thunks/teacher/teacher.service";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import {
  ISchoolDetails,
  ISchoolResultGradingFormat,
} from "../../types/db.types";

export const ClassResult = () => {
  const studentsScores = useSelector(
    (state: RootState) => state.getStudentsScores.scores
  );

  const dispatch = useDispatch();

  const { subjectId } = useParams();

  const school = getUserFromLocalStorage().school as ISchoolDetails;

  const getStudentScore = (studentId: string): ScoreState => {
    return studentsScores?.find?.(
      (score) => score?.student?._id === studentId
    ) as ScoreState;
  };

  const saveScore = async () => {
    await dispatch(recordScore(subjectId as string));
    Swal.fire({
      icon: "success",
      title: "Scores Recorded",
      showConfirmButton: false,
      text: "The scores have been updated successfully",
      footer: `<div> <a href="/teacher/teachings">Go back to your classes</a></div>`,
    });
  };

  return (
    <>
      <TableLayout>
        <TableHeader>
          {subjectScoresHeaders.map((header, index) => {
            return <SingleTableHeader {...header} key={index} />;
          })}
        </TableHeader>
        <TableRowsContainer>
          {studentsScores?.map((student, index) => {
            const formatScore = (score: number | string) =>
              score === "" ? 0 : parseFloat(score as string);
            return (
              <TableRow key={index}>
                <SingleTableRowItem width={subjectScoresHeaders[0].width}>
                  <div className="flex items-center gap-x-2 cursor-pointer">
                    <img
                      className="w-[25px] h-[25px] rounded-full"
                      alt={student?.student?.firstName}
                      loading="lazy"
                      src={student?.student?.imageUrl}
                    />
                    <p className="font-bold text-[#222] cursor-pointer hover:underline">
                      {student?.student?.firstName} {student?.student?.lastName}
                    </p>
                  </div>
                </SingleTableRowItem>
                <SingleTableRowItem width={subjectScoresHeaders[1].width}>
                  <input
                    type="number"
                    min={0}
                    className={styles.small_input}
                    value={getStudentScore(student.student?._id).firstCA}
                    onChange={(e) => {
                      dispatch(
                        updateSingleStudentScore({
                          studentId: student.student?._id,
                          key: "firstCA",
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                </SingleTableRowItem>
                <SingleTableRowItem width={subjectScoresHeaders[2].width}>
                  <input
                    type="number"
                    min={0}
                    className={styles.small_input}
                    value={getStudentScore(student.student?._id).secondCA}
                    onChange={(e) => {
                      dispatch(
                        updateSingleStudentScore({
                          studentId: student.student?._id,
                          key: "secondCA",
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                </SingleTableRowItem>
                <>
                  {school?.result_grading_format ===
                    ISchoolResultGradingFormat._70_30 && (
                    <SingleTableRowItem width={subjectScoresHeaders[3].width}>
                      <input
                        type="number"
                        min={0}
                        className={styles.small_input}
                        value={getStudentScore(student.student?._id).thirdCA}
                        onChange={(e) => {
                          dispatch(
                            updateSingleStudentScore({
                              studentId: student.student?._id,
                              key: "thirdCA",
                              value: e.target.value,
                            })
                          );
                        }}
                      />
                    </SingleTableRowItem>
                  )}
                </>
                <SingleTableRowItem width={subjectScoresHeaders[4].width}>
                  <input
                    type="number"
                    min={0}
                    max={100}
                    className={styles.small_input}
                    value={getStudentScore(student.student?._id).examScore}
                    onChange={(e) => {
                      dispatch(
                        updateSingleStudentScore({
                          studentId: student.student?._id,
                          key: "examScore",
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                </SingleTableRowItem>
                <SingleTableRowItem width={subjectScoresHeaders[5].width}>
                  <p>
                    {formatScore(student.firstCA) +
                      formatScore(student.secondCA) +
                      formatScore(student.thirdCA) +
                      formatScore(student.examScore)}
                  </p>
                </SingleTableRowItem>
              </TableRow>
            );
          })}
        </TableRowsContainer>
      </TableLayout>

      <div
        onClick={saveScore}
        className="flex items-center sm:justify-end justify-start mt-2"
      >
        <ButtonFilled text="Save Scores" />
      </div>
    </>
  );
};

export const LessonMaterials = () => {
  return <></>;
};

const styles = {
  small_input:
    "w-[70px] p-2 text-[1rem] rounded-md border-[1.5px] border-transparent focus:border-blackText",
};
