export const dateConverter = (date: Date) => {
    const dateInJs = new Date(date);
    const month = dateInJs.getMonth() + 1;
    const day = dateInJs.getDate();
    const year = dateInJs.getFullYear();

    return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
};

export const dateInputFormatter = (date: Date) => {
    const dateInJs = new Date(date);
    const month = dateInJs.getMonth() + 1;
    const day = dateInJs.getDate();
    const year = dateInJs.getFullYear();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const getYearsInterval = (startYear: number, endYear: number = new Date().getFullYear()) => {
    const yearsArr = [];
    for (let i = startYear; i <= endYear + 1; i++) {
        yearsArr.push(i);
    }

    return yearsArr;
};
