import React, { FC, useEffect, useRef, useState } from "react";
import { BiPlus, BiSearch } from "react-icons/bi";
import { RiMenu2Line } from "react-icons/ri";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import TableLayout, {
    SingleTableHeader,
    SingleTableRowItem,
    TableHeader,
    TableRow,
    TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import { Link } from "react-router-dom";
import { staffsHeader } from "../../../static/admin&teacher.data";
import { ButtonFilled, OutlineButton } from "../../../components/UI/Buttons";
import { useGetStaffs } from "../../../api/hooks/admin/staffs/staffs.service";
import { IAllStaffs } from "../../../types/db.types";
import NoItem from "../../../components/UI/NoItem";
import { useSearch } from "../../../utils/hooks/useSearch";
import { StaffFilter } from "../../../components/UI/FilterModals";

const Staffs = () => {
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const [gender, setGender] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState<string | undefined>(undefined);

    const { data, refetch, isLoading, isError } = useGetStaffs(gender, position);

    const { list, setList, searchValue, setSearchValue } = useSearch(data?.staffs);

    useEffect(() => {
        refetch({});
    }, [gender, position]);

    return (
        <DashboardLayout pageTitle="Staff Members">
            <section className="sm:p-6 py-6 min-h-screen">
                {filterModalOpen && (
                    <StaffFilter
                        gender={gender}
                        setGender={setGender}
                        position={position}
                        setPosition={setPosition}
                        closeModal={() => {
                            setFilterModalOpen(false);
                        }}
                    />
                )}

                <header className={styles.header_container}>
                    {/* search container */}
                    <div className={styles.search_box}>
                        <div className="flex-1">
                            <input
                                type="text"
                                placeholder='Try "Ademola Osanyin"'
                                className={styles.search_input}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                        <span className={styles.search_icon}>
                            <BiSearch />
                        </span>
                    </div>
                    <div className="flex-[33%] flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap">
                        {/* filter button */}
                        <OutlineButton
                            icon={<RiMenu2Line />}
                            text={"Filter"}
                            onClick={() => setFilterModalOpen(true)}
                        />

                        <Link to={"/admin/staffs/new"} className="min-w-fit">
                            <ButtonFilled icon={<BiPlus />} text={"Add Staff Members"} />
                        </Link>
                    </div>
                </header>
                {list?.length > 0 && (
                    <div className="w-full sm:px-0 px-2">
                        <TableLayout>
                            <TableHeader>
                                {staffsHeader.map((header, index) => {
                                    return <SingleTableHeader key={index} text={header.text} width={header.width} />;
                                })}
                            </TableHeader>

                            <TableRowsContainer>
                                {(list as IAllStaffs[])?.map((rowItem, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <SingleTableRowItem width={staffsHeader[0].width}>
                                                <Link
                                                    to={`/admin/staffs/${rowItem._id}`}
                                                    className="flex items-center gap-x-2 cursor-pointer"
                                                >
                                                    <img
                                                        className="w-[25px] h-[25px] rounded-full"
                                                        alt={rowItem.firstName}
                                                        loading="lazy"
                                                        src={rowItem.imageUrl}
                                                    />
                                                    <p className="font-bold text-[#222] cursor-pointer hover:underline">
                                                        {rowItem.firstName} {rowItem.lastName}
                                                    </p>
                                                </Link>
                                            </SingleTableRowItem>

                                            <SingleTableRowItem width={staffsHeader[1].width}>
                                                <p className="capitalize">{rowItem.gender}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={staffsHeader[2].width}>
                                                <p className="capitalize">{rowItem.role}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={staffsHeader[3].width}>
                                                <p>{rowItem.phoneNumber}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={staffsHeader[4].width}>
                                                <p>
                                                    {rowItem.city && rowItem.state
                                                        ? `${rowItem.city}, ${rowItem.state}`
                                                        : ""}
                                                </p>
                                            </SingleTableRowItem>
                                        </TableRow>
                                    );
                                })}
                            </TableRowsContainer>
                        </TableLayout>
                    </div>
                )}
                {/* No Staff member */}
                {(data?.staffs?.length === 0 || isLoading) && !gender && !position && data?.staffs?.length === 0 && (
                    <NoItem title="No Staff Yet" subtitle="Kindly Add A Staff Member" />
                )}
                {(data?.staffs?.length !== 0 && list?.length === 0) ||
                    (data?.staffs?.length === 0 && (gender || position) && (
                        <div className="w-full h-[80vh] flex justify-center items-center flex-col gap-y-3">
                            <h2 className="text-darkGray text-[1.7rem] font-bold">No staff matches your search!</h2>
                        </div>
                    ))}
            </section>
        </DashboardLayout>
    );
};

export default Staffs;

const styles = {
    header_container: "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
    search_box: "flex-[67%] flex w-full gap-[1rem] gap-y-[1rem] border-[1.5px] rounded-md hover:border-black bg-white",
    search_input: "w-full outline-none border-none h-[35px] px-4 placeholder:text-darkGray",
    search_icon: "flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray",
    buttons: "px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit",
};
