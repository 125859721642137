import React from "react";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";
import { IStudentAttendance } from "../../pages/Teacher/Class/Attendance/MarkAttendance";
import {
  IAllStudents,
  IClassAttendance,
  ISingleStudentAttendance,
} from "../../types/db.types";
import {
  getStudentTotalAttendance,
  getStudentTotalDayAttendance,
  getTotalDaysSchoolOpened,
} from "../../utils/attendance";

const fullWidth = 296;

interface Props {
  studentAttendance?: ISingleStudentAttendance[];
  schoolAttendance?: IClassAttendance[];
  profile?: IAllStudents;
}

const Attendance = ({
  schoolAttendance,
  studentAttendance,
  profile,
}: Props) => {
  const schoolTotal =
    (getTotalDaysSchoolOpened(schoolAttendance as IClassAttendance[])?.days ||
      0) * 2;
  const studentTotal =
    getStudentTotalAttendance(
      schoolAttendance as IClassAttendance[],
      profile?._id as string
    ) || 0;

  return (
    <div className="max-h-fit">
      <TableLayout>
        <TableHeader>
          <SingleTableHeader
            bg="#181619"
            color="white"
            width={fullWidth}
            text={<p className="text-[.8rem]">ATTENDANCE SUMMARY</p>}
          />
        </TableHeader>

        <TableRowsContainer>
          <TableRow>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.7}>
              <p className={styles.p}>No. of times school opened</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.3}>
              <p className={styles.p}>{schoolTotal}</p>
            </SingleTableRowItem>
          </TableRow>

          <TableRow>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.7}>
              <p className={styles.p}>No. of times present</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.3}>
              <p className={styles.p}>{studentTotal}</p>
            </SingleTableRowItem>
          </TableRow>

          <TableRow>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.7}>
              <p className={styles.p}>No. of times absent</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.3}>
              <p className={styles.p}>{schoolTotal - studentTotal}</p>
            </SingleTableRowItem>
          </TableRow>
        </TableRowsContainer>
      </TableLayout>
    </div>
  );
};

const styles = {
  p: "font-bold text-[0.75rem] p-2",
};

export default Attendance;
