import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleStudent } from "../../../api/hooks/admin/students/students.service";
import { editStudent } from "../../../api/thunks/admin/admin.service";
import StudentForm from "../../../components/Admin/StudentForm";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { clearFields, fetchStudentDetails } from "../../../store/slices/post/addStudent";

const EditStudent = () => {
    const navigate = useNavigate();

    const { id } = useParams();

    const dispatch = useDispatch();

    const { data, isLoading } = useGetSingleStudent(id as string, "/school/student/" + id);

    const onSubmit = async () => {
        const data = await dispatch(editStudent(id));

        if (!data?.error) {
            navigate("/admin/students");
            dispatch(clearFields());
        }
    };

    useEffect(() => {
        if (data && !isLoading) {
            dispatch(fetchStudentDetails(data));
        }
    }, [data]);

    return (
        <DashboardLayout
            pageTitle={
                <>
                    Students/
                    <span className="text-blackText font-bold">Edit Student</span>
                </>
            }
            documentTitle={"Edit Student"}
        >
            <section>
                <StudentForm onSubmit={onSubmit} />
            </section>
        </DashboardLayout>
    );
};

export default EditStudent;
