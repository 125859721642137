import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAllStaffs } from "../../../types/db.types";

const initialState: IAllStaffs = {
  address: "",
  city: "",
  country: "",
  createdAt: "",
  religion: "",
  dateOfBirth: "",
  documents: [],
  email: "",
  firstName: "",
  gender: "",
  imageUrl: "",
  lastName: "",
  middleName: "",
  monthOfEmployment: "",
  nextOfKinAddress: "",
  nextOfKinName: "",
  nextOfKinPhoneNumber: "",
  phoneNumber: "",
  role: "",
  schoolId: "",
  signature: "",
  state: "",
  updatedAt: "",
  userId: "",
  yearOfEmployment: "",
  __v: 0,
  _id: "",
  adminAccess: false,
};

const getStaffSlice = createSlice({
  name: "staff/get-staff",
  initialState,
  reducers: {
    updateSingleStaff: (
      state: IAllStaffs,
      action: PayloadAction<IAllStaffs>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const getStaffReducer = getStaffSlice.reducer;
export const { updateSingleStaff } = getStaffSlice.actions;
