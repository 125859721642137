import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reverseStaffDetails } from "../../../utils/dataHandling";
import { countryList } from "../../../assets/static";
import { IAllStaffs } from "../../../types/db.types";
import { IAddStaffSlice } from "../../../types/states.types";

const initialState: IAddStaffSlice = {
    firstname: "",
    lastname: "",
    middlename: "",
    religion: "",
    dob: "",
    gender: "male",
    email: "",
    phoneNumber: "",
    country: "",
    state: "",
    city: "",
    address: "",
    picture: "",
    monthOfEmployment: "",
    yearOfEmployment: "",
    role: {
        position: "",
        otherPosition: "",
        signature: "",
    },
    nextOfKin: {
        name: "",
        phoneNumber: "",
        address: "",
    },
    documents: [{ id: 0, name: "", file: "" }],
};

const addStaffSlice = createSlice({
    name: "postStaff",
    initialState,
    reducers: {
        updateStaffDetails: (
            state: IAddStaffSlice,
            action: PayloadAction<{
                key: string;
                value:
                    | any
                    | (string &
                          ("male" | "Female") &
                          ("christianity" | "islam") &
                          (string | File) & {
                              position: string;
                              role: string;
                              signature: string | File;
                          });
            }>
        ) => {
            state[action.payload.key as keyof IAddStaffSlice] = action.payload.value as never;
        },
        updateRoleDetails: (state: IAddStaffSlice, action: PayloadAction<{ key: string; value: any | string }>) => {
            state.role[action.payload.key as keyof typeof state.role] = action.payload.value;
        },
        updateNextOfKinDetails: (state: IAddStaffSlice, action: PayloadAction<{ key: string; value: string }>) => {
            state.nextOfKin[action.payload.key as keyof typeof state.nextOfKin] = action.payload.value;
        },
        addNewDocument: (state: IAddStaffSlice) => {
            state.documents = [...state.documents, { id: new Date().getTime(), name: "", file: "" }];
        },
        removeDocument: (state: IAddStaffSlice, action: PayloadAction<{ id: number }>) => {
            state.documents = state.documents.filter((doc) => doc.id !== action.payload.id);
        },
        updateDocumentDetails: (
            state: IAddStaffSlice,
            action: PayloadAction<{ id: number; key: string; value: string | File }>
        ) => {
            state.documents = state.documents.map((doc: any) => {
                if (doc.id === action.payload.id) {
                    doc[action.payload.key] = action.payload.value;
                }
                return doc;
            });
        },

        fetchStaffDetails: (state: IAddStaffSlice, action: PayloadAction<IAllStaffs>) => {
            const newData = reverseStaffDetails(action.payload);
            return newData;
        },
        resetStaffFields: () => {
            return initialState;
        },
    },
});

export const {
    updateStaffDetails,
    updateRoleDetails,
    updateNextOfKinDetails,
    addNewDocument,
    removeDocument,
    updateDocumentDetails,
    fetchStaffDetails,
    resetStaffFields,
} = addStaffSlice.actions;
export const addStaffReducer = addStaffSlice.reducer;
