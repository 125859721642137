import { useEffect, useState } from 'react';
import { MdCancel, MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { closeCreateSchoolModal } from '../../store/slices/others/handlerSlice';
import { RootState } from '../../store/store';
import { useGetParams } from '../../utils/getLocationParams';
import { getTokenFromLocalStorage } from '../../utils/localStorage';
import Step2 from '../Registration/Step2';
import Step3 from '../Registration/Step3';
import ModalOverlay from './ModalOverlay';

export const CreateSchoolModal = () => {
  const [step, setStep] = useState<number>(0);
  let location = useLocation();
  const dispatch = useDispatch();

  const { createModalOpen } = useSelector((state: RootState) => state.handlers);

  const closeModal = () => dispatch(closeCreateSchoolModal());

  if (!createModalOpen) {
    return <></>;
  }

  return (
    <ModalOverlay modalWidth={1000}>
      <>
        {/* To close the modal */}

        <i
          onClick={closeModal}
          className='absolute top-[30px] right-[30px] z-[5] font-bold text-[24px] text-red-500 cursor-pointer'
        >
          <MdClose />
        </i>
      </>
      <>
        {step === 0 && <Step2 increaseStep={() => setStep(step + 1)} />}
        {step === 1 && <Step3 increaseStep={() => {}} />}
      </>
    </ModalOverlay>
  );
};
