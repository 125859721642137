import React from 'react';
import { BiPaperPlane, BiPlus, BiSearch } from 'react-icons/bi';
import { GiGraduateCap } from 'react-icons/gi';
import { RiMenu2Line } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import { useGetTeacherClass } from '../../../api/hooks/teachers/teachers.service';
import DashboardLayout from '../../../components/Layouts/DashboardLayout';
import TableLayout, {
  SingleTableHeader,
  TableHeader,
  SingleTableRowItem,
  TableRow,
  TableRowsContainer,
} from '../../../components/Layouts/TableLayout';
import { ButtonFilled, OutlineButton } from '../../../components/UI/Buttons';
import { ContentBoxWithAvatar } from '../../../components/UI/ContentBox';
import NoItem from '../../../components/UI/NoItem';
import {
  studentsHeader,
  studentsList,
} from '../../../static/admin&teacher.data';
import { IAllStudents, IUser } from '../../../types/db.types';
import { useSearch } from '../../../utils/hooks/useSearch';
import { getUserFromLocalStorage } from '../../../utils/localStorage';

const Class = () => {
  const { classId } = useParams();

  const { data: teacherClass } = useGetTeacherClass(
    `/teacher/class/${classId}`
  );

  const { list, searchValue, setSearchValue } = useSearch(
    teacherClass?.students as IAllStudents[]
  );

  const user: IUser = getUserFromLocalStorage();

  return (
    <DashboardLayout pageTitle='Class'>
      <section className='py-6 sm:p-6 min-h-screen w-full'>
        <header className='flex items-center justify-between flex-wrap gap-x-4 gap-y-4'>
          <div className='w-full sm:max-w-[360px]'>
            <ContentBoxWithAvatar
              avatar={<GiGraduateCap />}
              header={teacherClass?.className + ' ' + teacherClass?.classArm}
              hasButton={false}
            >
              <div className='flex flex-wrap gap-x-4 gap-y-4 items-center py-2 px-3'>
                <div>
                  <h4 className='text-[1.1rem] text-darkGray mb-1'>Session</h4>
                  <h2 className='text-[1.1rem] text-blackText leading-[1.1]'>
                    {user?.school?.session}
                  </h2>
                </div>
                <div>
                  <h4 className='text-[1.1rem] text-darkGray mb-1'>Term</h4>
                  <h2 className='text-[1.1rem] text-blackText leading-[1.1]'>
                    {user?.school?.term}
                  </h2>
                </div>
                <div>
                  <h4 className='text-[1.1rem] text-darkGray mb-1'>
                    Population
                  </h4>
                  <h2 className='text-[1.1rem] text-blackText leading-[1.1]'>
                    {teacherClass?.students?.length || 'N/A'}
                  </h2>
                </div>
              </div>
            </ContentBoxWithAvatar>
          </div>
          <div className='flex items-center gap-x-4 gap-y-4 ml-auto'>
            {/* MVP */}
            {/* <OutlineButton icon={<BiPaperPlane />} text={"Send Notification"} /> */}
            <Link to={`/teacher/class/attendance/${classId}`}>
              <ButtonFilled text={'Attendance'} />
            </Link>

            <Link to={`/teacher/class/result/${user?.school?.term}/${classId}`}>
              <ButtonFilled text={'Class Result'} />
            </Link>
          </div>
        </header>
        <div className={styles.header_container}>
          {/* search container */}
          <div className={styles.search_box}>
            <div className='flex-1'>
              <input
                type='text'
                placeholder='Try "Ademola Osanyin"'
                className={styles.search_input}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <span className={styles.search_icon}>
              <BiSearch />
            </span>
          </div>
          {/* <div className='flex-[33%] flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap'>
            
          </div> */}
        </div>
        <div className='px-2 md:px-0'>
          <TableLayout>
            <TableHeader>
              {/* Get only the fullname, and gender*/}
              {studentsHeader.slice(0, 2).map((header, index) => {
                return <SingleTableHeader key={index} {...header} />;
              })}
            </TableHeader>
            <TableRowsContainer>
              {(list as IAllStudents[])?.map((rowItem, index) => {
                return (
                  <TableRow key={index}>
                    <SingleTableRowItem width={studentsHeader[0].width}>
                      <Link
                        to={'/teacher/students/' + rowItem._id}
                        className='flex items-center gap-x-2 cursor-pointer'
                      >
                        <img
                          className='w-[25px] h-[25px] rounded-full'
                          alt={rowItem.firstName}
                          loading='lazy'
                          src={rowItem.imageUrl}
                        />
                        <p className='font-bold text-[#222] cursor-pointer hover:underline'>
                          {rowItem.firstName} {rowItem.lastName}
                        </p>
                      </Link>
                    </SingleTableRowItem>

                    <SingleTableRowItem width={studentsHeader[1].width}>
                      <p className='capitalize'>{rowItem.gender}</p>
                    </SingleTableRowItem>
                  </TableRow>
                );
              })}
            </TableRowsContainer>
          </TableLayout>
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    'flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center mt-8',
  search_box:
    'flex-[67%] flex w-full gap-[1rem] border-[1.5px] rounded-md hover:border-black bg-white',
  search_input:
    'w-full outline-none border-none h-[35px] px-4 placeholder:text-darkGray',
  search_icon:
    'flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray',
  buttons:
    'px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit',
};

export default Class;
