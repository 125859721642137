import React, { useState } from "react";
import ConfirmationModal from "../UI/ConfirmationModal";
import { ButtonFilled } from "../UI/Buttons";
import { useDispatch } from "react-redux";
import { resetUserPassword } from "../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";

interface Props {
    userId: string;
}

export default function ResetPasswordButton({ userId }: Props) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onClick = async () => {
        const data = await dispatch(resetUserPassword(userId));

        if (!data.error) {
            await Swal.fire({
                title: "Reset Password",
                text: `You have successfully reset this user password to "password"`,
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });
            setModalOpen(false);
        }
    };

    return (
        <>
            {modalOpen && (
                <ConfirmationModal
                    text="Are you sure you want to reset this user's password to default?"
                    onCancel={() => setModalOpen(false)}
                    onConfirm={onClick}
                />
            )}

            <ButtonFilled text={"Reset Password"} onClick={() => setModalOpen(true)} />
        </>
    );
}
