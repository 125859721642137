import React, { useState } from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import image from "../../assets/images/profile-img.jpg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BiChevronLeft, BiPencil, BiPlus, BiTrash } from "react-icons/bi";
import { ButtonFilled } from "../../components/UI/Buttons";
import { TabsProps } from "../../types/static.types";
import { Attendance } from "../../components/Admin/StudentDetailsTabs/Attendance";
import EducationalHistory from "../../components/Admin/StudentDetailsTabs/EducationalHistory";
import { Profile } from "../../components/Admin/StudentDetailsTabs/Profile";
import { Scores } from "../../components/Admin/StudentDetailsTabs/Scores";
import { TuitionDetails } from "../../components/Admin/StudentDetailsTabs/TuitionDetails";
import { useGetSingleStudent, useGetStudentAttendance } from "../../api/hooks/admin/students/students.service";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleStudent } from "../../store/slices/get/student.slice";
import { RootState } from "../../store/store";
import UserImageModal from "../../components/UI/UserImageModal";
import { baseHttp } from "../../axios.config";
import BackButton from "../../components/UI/BackButton";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import { deleteStudent } from "../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import ReportCard from "../../components/Admin/StudentDetailsTabs/ReportCard";
import ResetPasswordButton from "../../components/Admin/ResetPassword";

const tabs: Array<TabsProps | {}> = [
    { title: "Profile", component: <Profile /> },
    // { title: "Educational History", component: <EducationalHistory /> },
    { title: "Attendance", component: <Attendance /> },
    { title: "Scores", component: <Scores /> },
    // Only the admin should see this
    {
        ...(window.location.pathname.includes("admin") || window.location.pathname.includes("parent")
            ? {
                  title: "Tuition Details",
                  component: <TuitionDetails />,
              }
            : {}),
    },
    {
        title: "Report Card",
        component: <ReportCard />,
    },
];

const SingleStudent = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const location = useLocation();

    const navigate = useNavigate();

    const [openedTab, setOpenedTab] = React.useState<TabsProps>({
        title: "Profile",
        component: <Profile />,
    });
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const { pathname } = useLocation();

    const switchTab = (tab: TabsProps) => {
        setOpenedTab(tab);
    };

    // admin endpoint is different from teacher endpoint
    const endpoint = location.pathname.includes("admin")
        ? `/school/student/${id}`
        : location.pathname.includes("teacher")
        ? `/teacher/student/${id}`
        : location.pathname.includes("parent")
        ? `/parents/kid/profile/${id}`
        : "/student/profile";

    const { data, isLoading, isError } = useGetSingleStudent(id as string, endpoint);

    const student = useSelector((state: RootState) => state.getStudent);

    const deleteAccount = async () => {
        setDeleteModal(false);
        const data = await dispatch(deleteStudent(id));

        if (!data?.error) {
            Swal.fire({
                icon: "success",
                title: "Delete Student's Account",
                text: "Student's account deleted successfully",
                timer: 2000,
                showConfirmButton: false,
            });
            setTimeout(() => {
                navigate("/admin/students");
            }, 2000);
        }
    };

    React.useEffect(() => {
        if (data && !isLoading) {
            dispatch(updateSingleStudent(data));
        }
    }, [data]);

    // if it is the student profile page display a different title
    return (
        <DashboardLayout
            documentTitle={pathname === "/student/profile" ? "Profile" : "Student Profile"}
            pageTitle={
                pathname === "/student/profile" ? (
                    "Profile"
                ) : (
                    <>
                        Students /<span className="font-bold text-black">Student Profile</span>
                    </>
                )
            }
        >
            <>
                {imageModalOpen && (
                    <UserImageModal
                        imageUrl={student?.imageUrl}
                        firstName={student?.firstName}
                        lastName={student?.lastName}
                        username={student?.userName}
                        closeModal={() => {
                            setImageModalOpen(false);
                        }}
                    />
                )}
                {data && deleteModal && (
                    <ConfirmationModal
                        onCancel={() => setDeleteModal(false)}
                        onConfirm={deleteAccount}
                        text="Are you sure you want to delete this student's account?"
                    />
                )}
            </>

            <section className="sm:p-6 py-6 min-h-screen">
                <header className={styles.header}>
                    <div className="flex items-center gap-x-3">
                        <img
                            className="w-[80px] h-[80px] cursor-pointer rounded-full object-fit object-cover"
                            alt={student?.firstName}
                            src={student?.imageUrl}
                            loading="lazy"
                            onClick={() => {
                                setImageModalOpen(true);
                            }}
                        />
                        <div className="flex-col gap-y-1">
                            <h2 className="text-[1.2rem] font-bold text-blackText">
                                {student?.firstName} {student?.lastName}
                            </h2>
                            <p className="text-darkGray text-[1rem]">Class - {student?.class}</p>
                            {student.monthOfAdmission && (
                                <p className="text-[0.8rem] mt-1">
                                    <i>Started</i>
                                    <span className="font-bold inline-block ml-[6px]">
                                        {student?.monthOfAdmission}, {student?.yearOfAdmission}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className=" flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap items-end justify-end">
                        {/* <OutlineButton icon={<BiPaperPlane />} text={"Send Notification"} /> */}
                        {/* <ButtonFilled text={"Approve Report Card"} /> */}
                        {pathname.includes("/admin") && (
                            <>
                                <ButtonFilled
                                    icon={<BiTrash />}
                                    text={"Delete Student"}
                                    onClick={() => setDeleteModal(true)}
                                />
                                <Link to={`/admin/students/edit/${student?._id}`}>
                                    <ButtonFilled icon={<BiPencil />} text="Edit Student Profile" />
                                </Link>
                                <ResetPasswordButton userId={student?.userId} />
                            </>
                        )}
                    </div>
                </header>

                {/* tabs */}
                <div className="mt-8 sm:px-0 px-4">
                    <div className="w-full max-w-full overflow-x-scroll border-b-[1.5px] flex items-center gap-x-4 pb-2">
                        {(tabs as TabsProps[])?.map((tab, index) => {
                            return (
                                tab?.title && (
                                    <h3
                                        key={index}
                                        className={`pb-1 mb-0  leading-[1] border-b-[1.5px] cursor-pointer ${
                                            index === 0 ? "min-w-[11.25rem]" : "min-w-fit"
                                        } font-bold text-center ${
                                            openedTab.title === tab.title
                                                ? "border-black text-blackText"
                                                : "border-transparent text-darkerGray"
                                        }`}
                                        onClick={() => {
                                            switchTab(tab);
                                        }}
                                    >
                                        {tab.title}
                                    </h3>
                                )
                            );
                        })}
                    </div>
                </div>
                <section className="w-full mt-8">{openedTab.component}</section>
            </section>
        </DashboardLayout>
    );
};

const styles = {
    back_icon: "flex items-center gap-x-2 text-[1rem] font-bold",
    header: "mt-8 flex lg:items-center gap-x-4 gap-y-4 md:flex-row flex-col justify-between",
};

export default SingleStudent;
