import { useQuery } from "react-query";
import { baseHttp } from "../../../axios.config";
import {
  IAllStudents,
  IBillsPaid,
  ITransaction,
} from "../../../types/db.types";
import { useParams } from "react-router-dom";

export const useGetKids = (gender?: string, classDetail?: string) => {
  const { data, isLoading, refetch } = useQuery<IAllStudents[]>({
    queryKey: "parent/get-kids",
    queryFn: async () => {
      const response = await baseHttp.get(
        `/parents/getKids?${gender ? `gender=${gender}&` : ""}${
          classDetail ? `className=${classDetail}` : ""
        }`
      );
      return response.data?.kids;
    },
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetBillsPaid = (studentId: string) => {
  const { data, isLoading } = useQuery<IBillsPaid[]>({
    queryKey: "parent/get-bill",
    queryFn: async () => {
      const response = await baseHttp.get(
        `/parents/kid/bills-paid/${studentId}`
      );
      return response?.data?.fees;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetKidTransactions = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery<ITransaction[]>({
    queryKey: "useGetKidTransactions",
    queryFn: async () => {
      const response = await baseHttp.get(`/parents/kid/${id}/transactions`);

      return response?.data?.transactions;
    },
  });

  return {
    data,
    isLoading,
  };
};
