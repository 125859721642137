import React from "react";
import { BiMinus, BiPlus, BiUser } from "react-icons/bi";

interface Props {
    update(file?: File): void;
    value?: File;
}

const ImageContainer = (props: Props) => {
    const id = (Math.random() * 9999999).toString();

    return (
        <>
            <label
                htmlFor={id}
                className="rounded-full border-2 relative hover:border-black w-[40px] h-[40px] cursor-pointer flex items-center justify-center"
            >
                {props.value ? (
                    <img src={URL.createObjectURL(props.value)} className="w-full h-full rounded-full" />
                ) : (
                    <BiUser size={20} />
                )}
                <span className="absolute bottom-[4px] -right-[8px] z-[3]">
                    {props.value ? (
                        <BiMinus
                            size={18}
                            strokeWidth={2}
                            className="bg-black text-white rounded-full"
                            onClick={() => {
                                props.update(undefined);
                            }}
                        />
                    ) : (
                        <BiPlus size={18} strokeWidth={2} className="bg-black text-white rounded-full" />
                    )}
                </span>
            </label>

            {typeof props.value == "undefined" && (
                <input
                    type="file"
                    accept="image/*"
                    id={id}
                    className="hidden"
                    onChange={(e) => props.update(e.target.files?.[0] as File)}
                />
            )}
        </>
    );
};

export default ImageContainer;
