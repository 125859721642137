import React, { useState } from "react";
import { IUserTypes } from "../../utils/hooks/useFetchResult";
import ModalOverlay from "../UI/ModalOverlay";
import { MdClose } from "react-icons/md";
import { ButtonFilled } from "../UI/Buttons";
import { BiPaperPlane } from "react-icons/bi";
import ConfirmationModal from "../UI/ConfirmationModal";
import { useDispatch } from "react-redux";
import { sendBulkMessage } from "../../api/thunks/chat/chat.service";
import Swal from "sweetalert2";

interface Props {
    userType: IUserTypes;
    closeModal(): void;
}

export default function BulkMessageModal({ userType, closeModal }: Props) {
    const [message, setMessage] = useState<string>("");
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const submit = async () => {
        const data = await dispatch(sendBulkMessage({ userType, text: message }));

        if (!data?.error) {
            setModalOpen(false);
            closeModal();
            await Swal.fire({
                title: "Bulk Message",
                text: "Messages have been sent successfully",
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });
            // reload to fetch messages
            window.location.reload();
        }
    };

    return (
        <>
            <ModalOverlay modalWidth={600}>
                <header className="flex items-center gap-2 justify-between">
                    <h2 className="text-[1.1rem] font-bold capitalize">Message {userType}</h2>
                    <MdClose color="darkred" size={25} onClick={closeModal} />
                </header>

                <textarea
                    className="resize-none w-[100%] h-[150px] border-2 rounded-md my-3 p-2"
                    placeholder="Enter Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                <ButtonFilled
                    fullWidth={true}
                    text={"Send Message"}
                    icon={<BiPaperPlane />}
                    type="submit"
                    onClick={() => {
                        {
                            if (message.length !== 0) {
                                setModalOpen(true);
                            }
                        }
                    }}
                />
            </ModalOverlay>

            {modalOpen && (
                <ConfirmationModal
                    text={`Are you sure you want to send message to all ${userType}${
                        userType.endsWith("s") ? "" : "s"
                    }`}
                    onCancel={() => setModalOpen(false)}
                    onConfirm={submit}
                />
            )}
        </>
    );
}
