import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStudentsFetch } from "../../../api/hooks/admin/students/students.types";
import { IAllStudents } from "../../../types/db.types";
import { IAddStudent } from "../../../api/thunks/admin/admin.types";

const initialState: IAllStudents = {
  address: "",
  class: "",
  country: "",
  createdAt: "",
  dateOfBirth: "",
  firstName: "",
  middleName: "",
  religion: "",
  gender: "",
  userName: "",
  imageUrl: "",
  admissionNumber: "",
  lastName: "",
  phoneNumber: "",
  schoolId: "",
  state: "",
  updatedAt: "",
  userId: "",
  monthOfAdmission: "",
  yearOfAdmission: "",
  resultStatus: "",
  teacherResultDecision: "",
  __v: 0,
  _id: "",
  parent: {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: "",
  },
};

const studentSlice = createSlice({
  name: "get/student",
  initialState,
  reducers: {
    updateSingleStudent: (
      state: IAllStudents,
      action: PayloadAction<IAllStudents>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateSingleStudent } = studentSlice.actions;

export const getStudentReducer = studentSlice.reducer;
