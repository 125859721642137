import React, { useEffect, useState } from "react";
import { BiPlus, BiSearch } from "react-icons/bi";
import { RiMenu2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useGetKids } from "../../api/hooks/parents/parent.service";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import TableLayout, {
  SingleTableHeader,
  TableHeader,
  TableRowsContainer,
  TableRow,
  SingleTableRowItem,
} from "../../components/Layouts/TableLayout";
import { ButtonFilled, OutlineButton } from "../../components/UI/Buttons";
import { StudentFilter } from "../../components/UI/FilterModals";
import NoItem from "../../components/UI/NoItem";
import { kids, kidsHeaders } from "../../static/parents.data";
import { IAllStudents } from "../../types/db.types";
import { useSearch } from "../../utils/hooks/useSearch";

const Kids = () => {
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

  const [gender, setGender] = useState<string | undefined>(undefined);
  const [classDetail, setClass] = useState<string | undefined>(undefined);

  const {
    data: kids,
    isLoading,
    refetch,
  } = useGetKids(gender as string, classDetail as string);

  const { list, setList, searchValue, setSearchValue } = useSearch(
    kids as IAllStudents[]
  );

  useEffect(() => {
    refetch({});
  }, [gender, classDetail]);

  return (
    <DashboardLayout pageTitle="Child(ren)/Ward(s)">
      <>
        {filterModalOpen && (
          <StudentFilter
            class={classDetail}
            setClass={setClass}
            gender={gender}
            setGender={setGender}
            closeModal={() => setFilterModalOpen(false)}
          />
        )}
      </>

      <section className="min-h-screen sm:p-6 py-6">
        <header className={styles.header_container}>
          {/* search container */}
          <div className={styles.search_box}>
            <div className="flex-1">
              <input
                type="text"
                placeholder='Try "Ademola Osanyin"'
                className={styles.search_input}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <span className={styles.search_icon}>
              <BiSearch />
            </span>
          </div>
          <div className="flex-[33%] flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap">
            {/* filter button */}
            <OutlineButton
              icon={<RiMenu2Line />}
              text={"Filter"}
              onClick={() => setFilterModalOpen(true)}
            />
          </div>
        </header>
        <TableLayout>
          <TableHeader>
            {kidsHeaders.map((header, index) => {
              return <SingleTableHeader {...header} key={index} />;
            })}
          </TableHeader>
          <TableRowsContainer>
            {(list as IAllStudents[])?.map((kid, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={kidsHeaders[0].width}>
                    <Link
                      to={`/parent/kid/${kid._id}`}
                      className="flex items-center gap-x-2 cursor-pointer"
                    >
                      <img
                        className="w-[25px] h-[25px] rounded-full"
                        alt={kid._id}
                        loading="lazy"
                        src={kid.imageUrl}
                      />
                      <p className="font-bold text-[#222] cursor-pointer hover:underline">
                        {kid.firstName} {kid.lastName}
                      </p>
                    </Link>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={kidsHeaders[1].width}>
                    <p>{kid.class}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={kidsHeaders[2].width}>
                    <p>{kid.state}</p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
        <>
          {(kids?.length === 0 || isLoading) &&
            !gender &&
            !classDetail &&
            kids?.length === 0 && (
              <NoItem title="No Student Yet" subtitle="Kindly Add Students" />
            )}
          {(kids?.length !== 0 && list?.length === 0) ||
            (kids?.length === 0 && (gender || classDetail) && (
              <div className="w-full h-[80vh] flex justify-center items-center flex-col gap-y-3">
                <h2 className="text-darkGray text-[1.7rem] font-bold">
                  No student matches your search!
                </h2>
              </div>
            ))}
        </>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  search_box:
    "flex-[67%] flex w-full gap-[1rem] gap-y-[1rem] border-[1.5px] rounded-md hover:border-black bg-white",
  search_input:
    "w-full outline-none border-none h-[35px] px-4 placeholder:text-darkGray",
  search_icon:
    "flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray",
  buttons:
    "px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit",
};

export default Kids;
