import React, { useEffect, useState } from 'react';
import { MdOutlineClass } from 'react-icons/md';
import TableLayout, {
  TableHeader,
  SingleTableHeader,
  TableRowsContainer,
  TableRow,
  SingleTableRowItem,
} from '../../../../components/Layouts/TableLayout';
import DashboardLayout from '../../../../components/Layouts/DashboardLayout';
import {
  useGetClasses,
  useGetPayAdivce,
} from '../../../../api/hooks/admin/generators/generators.service';
import {
  IGeneratedClasses,
  IGeneratedPayAdvice,
} from '../../../../types/db.types';
import NoItem from '../../../../components/UI/NoItem';
import { Link } from 'react-router-dom';
import { ButtonFilled } from '../../../../components/UI/Buttons';
import BackButton from '../../../../components/UI/BackButton';

const GeneratedPayAdvice = () => {
  const [selectedClass, setSelectedClass] = useState<IGeneratedClasses | null>(
    null
  );

  const [classPayAdvice, setClassPayAdvice] = useState<
    IGeneratedPayAdvice[] | null
  >();

  const selectClass = (classDetails: IGeneratedClasses) => {
    setSelectedClass(classDetails);
  };

  const { data: classes, isLoading } = useGetClasses();

  const {
    data: payAdvice,
    isLoading: payAdviceLoading,
    refetch,
  } = useGetPayAdivce(selectedClass?._id as string);

  useEffect(() => {
    // fetch the Pay Adivce whenever a new class is chosen
    refetch();
  }, [selectedClass]);

  useEffect(() => {
    if (!isLoading && !payAdviceLoading && payAdvice) {
      setClassPayAdvice(payAdvice);
    }
  }, [payAdvice]);

  if (!classes || classes?.length == 0) {
    return (
      <DashboardLayout pageTitle={'Generated Pay Advice'}>
        <section className='w-full sm:p-6 py-6 min-h-screen'>
          <NoItem title='No class yet' subtitle='Kindly add class(es)' />
        </section>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle={'Generated Pay Advice'}>
      <section className='w-full sm:p-6 py-6 min-h-screen'>
        <div className='w-full mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4'>
          {classes?.map((singleClass, index: number) => {
            return (
              <article
                className={
                  styles.content_box +
                  ` ${
                    (selectedClass as IGeneratedClasses)?._id ===
                      singleClass._id && 'border-[#242424]'
                  }`
                }
                key={index}
                onClick={() => {
                  // make it the selected class
                  selectClass(singleClass);
                }}
              >
                <span
                  className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                >
                  <MdOutlineClass />
                </span>
                <h2 className={styles.content_box_title}>
                  {singleClass?.className} {singleClass?.classArm}
                </h2>
              </article>
            );
          })}
        </div>

        {/* {selectedClass?.className && (
          <div className='mt-8 w-full flex justify-end'>
            <Link to={`/admin/generator/edit/pay-advice/${selectedClass?._id}`}>
              <ButtonFilled
                text={`Edit ${selectedClass?.className} ${selectedClass?.classArm} Pay Advice`}
              />
            </Link>
          </div>
        )} */}

        {selectedClass && (
          <section className='w-full mt-8'>
            <TableLayout>
              <TableHeader>
                {['Item', 'Charges'].map((item, index) => {
                  return (
                    <SingleTableHeader text={item} key={index} width={120} />
                  );
                })}
              </TableHeader>
              <TableRowsContainer>
                {classPayAdvice?.map(
                  (item: IGeneratedPayAdvice, index: number) => {
                    return (
                      <TableRow key={index}>
                        <SingleTableRowItem width={120}>
                          <p>{item.item}</p>
                        </SingleTableRowItem>
                        <SingleTableRowItem width={120}>
                          <p>₦ {item.charge}</p>
                        </SingleTableRowItem>
                      </TableRow>
                    );
                  }
                )}
              </TableRowsContainer>
            </TableLayout>
          </section>
        )}
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    'flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center',
  content_box:
    'p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer',
  content_box_icon:
    'w-[30px] h-[30px] rounded-full flex justify-center items-center',
  content_box_subtitle: 'text-[15px] underline text-darkGray font-[300]',
  content_box_title: 'text-[1rem] font-bold text-blackText flex flex-col',
};

export default GeneratedPayAdvice;
