/**
 * A modal that displays the user image whenever it's clicked from their profile
 */

import React from "react";

interface UserImageModalProps {
  closeModal: () => void;
  imageUrl: string;
  firstName: string;
  lastName: string;
  username: string;
}

const UserImageModal = (props: UserImageModalProps) => {
  return (
    <section className="fixed top-0 left-0 min-h-screen w-screen z-[5]">
      <div
        className="w-full min-h-screen bg-[rgba(0,0,0,.5)]"
        onClick={props.closeModal}
      ></div>
      {/* Modal */}
      <div className="w-[92vw] max-w-[500px] bg-white shadow-md rounded-md absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer py-2">
        <h2 className="text-[24px] text-center font-500">
          {props.firstName} {props.lastName}
        </h2>
        <p className="text-[1.1rem] text-darkerGray text-center">
          {props.username}
        </p>

        <img
          src={props.imageUrl}
          loading="lazy"
          alt={props.username}
          className="w-[200px] h-[200px] border-[8px] border-slate-200 rounded-full block mx-auto my-6 object-center object-cover shadow-md"
        />
      </div>
    </section>
  );
};

export default UserImageModal;
