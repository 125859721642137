import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

import { FaHandPointRight } from "react-icons/fa";
import NavBar from "../components/UI/NavBar";
import { Fade, Zoom, Bounce } from "react-awesome-reveal";
import Banner from "../components/Home/Banner";
import FeaturesContainer from "../components/Home/FeaturesContainer";
import Services from "../components/Home/Services";
import CTA from "../components/Home/CTA";
import FAQs from "../components/Home/FAQs";
import Footer from "../components/Home/Footer";

const Landing = () => {
  return (
    <>
      <section className="max-w-[1200px] bg-white p-[1rem] lg:px-[3rem] mx-auto">
        <Banner />
        <FeaturesContainer />
        <Services />
        <CTA />
        <FAQs />
      </section>
      <Footer />
    </>
  );
};

export default Landing;
