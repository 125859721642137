import React, { useEffect, useState } from "react";
import { tableHeaders } from "../../../types/static.types";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../Layouts/TableLayout";
import { IGeneratedSubjects, IStudentScores } from "../../../types/db.types";
import { fuseSubjectAndScores } from "../../../utils/dataHandling";
import { getGrade, getRemark } from "../../../utils/report-card";

interface Props {
  scores?: IStudentScores[];
}

const defaultTableHeader: tableHeaders[] = [
  { text: "SUBJECTS", width: 200 },
  { text: "CA", width: 30 },
  { text: "EXAM", width: 30 },
  { text: "TOTAL", subtext: "100", width: 70.0 },
  {
    text: "Grade",
    width: 50,
  },
  {
    text: "Remarks",
    width: 100,
  },
];

const FirstTerm = ({ scores }: Props) => {
  return (
    <article className={`flex-1 h-full`}>
      <TableLayout className="flex flex-col h-full">
        <TableHeader>
          {defaultTableHeader?.map((header, index) => {
            return (
              <SingleTableHeader
                bg="#181619"
                color="white"
                key={index}
                noPadding={true}
                text={
                  <p className="text-[.75rem] h-full px-[8px] py-[12px]">
                    {header.text} {header.subtext ? `(${header.subtext})` : ""}
                  </p>
                }
                width={header.width}
              />
            );
          })}
        </TableHeader>

        <TableRowsContainer className="flex-1 justify-between">
          {scores?.map((score, index) => {
            const total =
              score?.firstCA +
              score?.secondCA +
              score?.thirdCA +
              score?.examScore;
            return (
              <TableRow
                key={index}
                className="items-center justify-center flex-1"
              >
                <SingleTableRowItem
                  width={defaultTableHeader[0].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{score?.subjectId?.title}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[1].width}
                  noPadding={true}
                >
                  <p className={styles.p}>
                    {score?.firstCA + score?.secondCA + score?.thirdCA}
                  </p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[2].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{score?.examScore}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[3].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{total?.toFixed(1)}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[4].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{getGrade(total)}</p>
                </SingleTableRowItem>
                <SingleTableRowItem
                  width={defaultTableHeader[5].width}
                  noPadding={true}
                >
                  <p className={styles.p}>{getRemark(total)}</p>
                </SingleTableRowItem>
              </TableRow>
            );
          })}
        </TableRowsContainer>
      </TableLayout>
    </article>
  );
};

const styles = {
  p: "p-2 text-[.8rem] font-bold",
};

export default FirstTerm;
