import React, { useEffect, useState, ChangeEvent } from "react";
import { MdOutlineClass } from "react-icons/md";
import TableLayout, {
  TableHeader,
  SingleTableHeader,
  TableRowsContainer,
  TableRow,
  SingleTableRowItem,
} from "../../../../components/Layouts/TableLayout";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import {
  IAllStudents,
  IGeneratedClasses,
  IGeneratedSubjects,
  IStudentReportInfo,
} from "../../../../types/db.types";
import {
  useGetClasses,
  useGetClassesWithApprovedResults,
  useGetClassesWithoutApprovedResults,
  useGetSingleClass,
  useGetSubjects,
} from "../../../../api/hooks/admin/generators/generators.service";
import { Link } from "react-router-dom";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import NoItem from "../../../../components/UI/NoItem";
import BackButton from "../../../../components/UI/BackButton";
import { tableHeaders } from "../../../../types/static.types";
import { BiEdit } from "react-icons/bi";
import Input from "../../../../components/UI/Input";
import {
  useGetClassStudentReportInfo,
  useGetStudentReportInfo,
} from "../../../../api/hooks/teachers/teachers.service";
import { useDispatch } from "react-redux";
import {
  addNextResumptionDate,
  commentOnStudentResult,
} from "../../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import ConfirmationModal from "../../../../components/UI/ConfirmationModal";
import useStoreResultAverage from "../../../../utils/hooks/useStoreResultAverage";
import { openErrorModal } from "../../../../store/slices/others/handlerSlice";

const tableHeader: tableHeaders[] = [
  { text: "Student", width: 200 },
  { text: "Add Comments", width: 350 },
  { text: "", width: 150 },
];

const ReportCardGenerator = () => {
  const [selectedClass, setSelectedClass] =
    useState<IGeneratedClasses | null>();
  const [nextResumptionDate, setDate] = useState<Date | string>("");
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const {
    data: classes,
    isLoading,
    refetch,
  } = useGetClassesWithoutApprovedResults();

  const dispatch = useDispatch();

  const { approveResult } = useStoreResultAverage(
    selectedClass?._id as string,
    true
  );

  const submit = async () => {
    if (!nextResumptionDate) {
      dispatch(openErrorModal({ errorText: "Provide next resumption date" }));
    } else {
      const data1 = await dispatch(
        addNextResumptionDate({
          classId: selectedClass?._id,
          nextResumptionDate,
        })
      );

      if (!data1.error) {
        await approveResult();

        await Swal.fire({
          title: "Approve results",
          text: "Class Results Approved Successfully",
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });

        setConfirmationModalOpen(false);
        setSelectedClass(null);
        setDate("");
        refetch();
      }
    }
  };

  const selectClass = (classDetails: IGeneratedClasses) => {
    setSelectedClass(classDetails);
  };

  if ((!classes || classes?.length == 0) && !isLoading) {
    return (
      <DashboardLayout pageTitle={"Report Card Generator"}>
        <section className="w-full sm:p-6 py-6 min-h-screen">
          <NoItem title="All Results have been approved✅" subtitle="" />
        </section>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle={"Generate Report Card"}>
      <section className="w-full sm:p-6 py-6 min-h-screen">
        <>
          {confirmationModalOpen && (
            <ConfirmationModal
              text="Are you sure you want to approve this class result? "
              onConfirm={submit}
              onCancel={() => setConfirmationModalOpen(false)}
            />
          )}
        </>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 mt-8 lg:grid-cols-3 gap-x-4 gap-y-4">
          {classes?.map((singleClass, index: number) => {
            return (
              <article
                className={
                  styles.content_box +
                  ` ${
                    (((selectedClass?.className as string) +
                      selectedClass?.classArm) as string) ===
                      singleClass.className + singleClass?.classArm &&
                    "border-[#242424]"
                  }`
                }
                key={index}
                onClick={() => {
                  // make it the selected class
                  selectClass(singleClass);
                }}
              >
                <span
                  className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                >
                  <MdOutlineClass />
                </span>
                <h2 className={styles.content_box_title}>
                  {singleClass.className} {singleClass.classArm}
                </h2>
              </article>
            );
          })}
        </div>

        {selectedClass?.className && (
          <section className="w-full">
            <div className="flex items-center justify-between gap-[1rem] mt-8 mb-8 flex-wrap">
              <div className="flex flex-col gap-y-[1rem]">
                <label
                  htmlFor="nextResumptionDate"
                  className="text-[.8rem] font-bold block"
                >
                  Select Next Resumption Date
                </label>
                <Input
                  type="date"
                  value={nextResumptionDate}
                  placeholder="Next Resumption Date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDate(e.target.value);
                  }}
                  name="nextResumptionDate"
                  className="max-w-[160px] !text-[.8rem] cursor-pointer"
                />
              </div>

              <ButtonFilled
                text="Approve Report Cards"
                onClick={() => setConfirmationModalOpen(true)}
              />
            </div>

            <TableLayout>
              <TableHeader>
                {tableHeader.map((header, index) => {
                  return (
                    <SingleTableHeader
                      text={header.text}
                      key={index}
                      width={header.width}
                    />
                  );
                })}
              </TableHeader>
              <TableRowsContainer>
                {selectedClass?.students?.map((student, index: number) => {
                  return (
                    <StudentRow
                      key={index}
                      tableHeader={tableHeader}
                      student={student}
                    />
                  );
                })}
              </TableRowsContainer>
            </TableLayout>
          </section>
        )}
      </section>
    </DashboardLayout>
  );
};

// i had to seperate this component because i'll need to use useState for the rows
interface Props {
  student: IAllStudents;
  tableHeader: tableHeaders[];
}
const StudentRow = ({ student, tableHeader }: Props) => {
  const [comment, setComment] = useState<string>("");

  const dispatch = useDispatch();

  const { data: studentReport, refetch } = useGetStudentReportInfo(
    `/school/student/${student?._id}/report-card-information`
  );

  const submitComment = async () => {
    const data = await dispatch(
      commentOnStudentResult({ adminComment: comment, studentId: student?._id })
    );

    if (!data?.error) {
      await Swal.fire({
        title: "Student Result Comment",
        text: "You have successfully commented on this student's result",
        timer: 1500,
        showConfirmButton: false,
        icon: "success",
      });
      refetch();
    }
  };

  useEffect(() => {
    if (studentReport) setComment(studentReport?.adminComment);
  }, [studentReport]);

  return (
    <TableRow>
      <SingleTableRowItem width={tableHeader[0]?.width}>
        <Link
          to={`/admin/students/${student?._id}`}
          className="flex items-center gap-x-2 cursor-pointer"
        >
          <img
            className="w-[25px] h-[25px] rounded-full"
            alt={student?.firstName}
            loading="lazy"
            src={student?.imageUrl}
          />
          <p className="font-bold text-[#222] cursor-pointer hover:underline">
            {student?.firstName} {student?.lastName}
          </p>
        </Link>
      </SingleTableRowItem>

      <SingleTableRowItem width={tableHeader[1]?.width}>
        <div className="flex gap-[.8rem]">
          <Input
            type="text"
            placeholder="Enter Comment"
            value={comment}
            name="comment"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setComment(e.target.value)
            }
          />
          <ButtonFilled text={"Submit"} onClick={submitComment} />
        </div>
      </SingleTableRowItem>

      <SingleTableRowItem width={tableHeader[2]?.width}>
        <Link to={`/report-card/${student._id}`}>
          <ButtonFilled
            className="!min-w-[120px] !text-[0.8rem]"
            text={"Preview Result"}
          />
        </Link>
      </SingleTableRowItem>
    </TableRow>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  content_box:
    "p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer",
  content_box_icon:
    "w-[30px] h-[30px] rounded-full flex justify-center items-center",
  content_box_subtitle: "text-[15px] underline text-darkGray font-[300]",
  content_box_title: "text-[1rem] font-bold text-blackText flex flex-col",
};

export default ReportCardGenerator;
