import React from "react";

interface ProfileInfoProps {
  lineWidth: number;
  question?: string;
  answer?: string;
}

const ProfileInfo = ({ lineWidth, question, answer }: ProfileInfoProps) => {
  return (
    <div className="flex items-end min-w-fit mr-4 mt-3 text-[14px]">
      <p className="uppercase">{question}: </p>
      <div className="font-bold uppercase flex items-center flex-col ml-1">
        <p>{answer}</p>
        <hr
          className="h-[2px] bg-blackText block mt-1"
          style={{ width: lineWidth }}
        />
      </div>
    </div>
  );
};

export default ProfileInfo;
