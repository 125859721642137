import React from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import Input from "../../../components/UI/Input";
import { ButtonFilled } from "../../../components/UI/Buttons";

const range = [
  {
    id: 1,
    range: "0 - 50",
    price: 7500,
  },
  {
    id: 2,
    range: "51 - 100",
    price: 15000,
  },
  {
    id: 3,
    range: "101 - 150",
    price: 22500,
  },
  {
    id: 4,
    range: "151 - 200",
    price: 30000,
  },
  {
    id: 5,
    range: "201 - 250",
    price: 37500,
  },
  {
    id: 6,
    range: "251 - 300",
    price: 45000,
  },
];

const Subscribe = () => {
  const { plan } = useParams();

  return (
    <DashboardLayout pageTitle={"Subscribe"}>
      <section className="min-h-screen w-full py-6 sm:p-6">
        <h2 className="font-bold text-[1.2rem] capitalize mb-4">{plan} Plan</h2>
        {range.map((item, index) => {
          return (
            <div className="flex items-center gap-[1rem]">
              <input type="radio" className="accent-blackText" name="index" />
              <label
                htmlFor={index + " index"}
                className="inline-block min-w-[150px]"
              >
                {item.range} users
              </label>
              <p>
                ₦ {item.price} <b>/month</b>
              </p>
            </div>
          );
        })}

        <h2 className="mt-6 font-bold">Enter Card Info</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-[1rem] mt-4">
          <div>
            <label htmlFor="" className="block mb-1">
              Account Number
            </label>
            <Input
              type="number"
              value={""}
              name="accountNumber"
              onChange={() => {}}
              placeholder="Enter account number"
            />
          </div>

          <div>
            <label htmlFor="" className="block mb-1">
              Bank Name
            </label>
            <Input
              type="number"
              value={""}
              name="bankName"
              onChange={() => {}}
              placeholder="Enter Bank Name"
            />
          </div>

          <div>
            <label htmlFor="" className="block mb-1">
              Account Name
            </label>
            <Input
              type="number"
              value={""}
              name="accountName"
              onChange={() => {}}
              placeholder="Enter Account Name"
            />
          </div>
        </div>

        <div className="mt-5">
          <ButtonFilled text="Submit" className="min-w-[180px]" />
        </div>
      </section>
    </DashboardLayout>
  );
};

export default Subscribe;
