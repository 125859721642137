import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addAdmin } from "../../api/thunks/admin/admin.service";
import { IAddAdmin } from "../../api/thunks/admin/admin.types";
import { StepProps } from "../../pages/Auth/Register";
import { InputFields } from "../../types/componentsProps.types";
import { adminDetailsProps } from "../../types/states.types";
import { useGetParams } from "../../utils/getLocationParams";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Step4: FC<StepProps> = ({ increaseStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const school = useGetParams("school");
  const token = useGetParams("token");

  const [searchParams, setSearchParams] = useSearchParams();
  const [numberOfAdmins, setNumberOfAdmins] = useState<number>(1);
  const [adminsDetails, setAdminsDetails] = useState([
    {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
    },
  ]);
  const addNewAdmin = () => {
    setAdminsDetails([
      ...adminsDetails,
      {
        id: new Date().getTime(),
        firstName: "",
        lastName: "",
        email: "",
      },
    ]);
    setNumberOfAdmins(numberOfAdmins + 1);
  };
  const removeAdmin = (id: number) => {
    setAdminsDetails((prev) => prev.filter((admin) => admin.id !== id));
  };

  const updateAdminDetails = (id: number, key: string, value: string) => {
    setAdminsDetails(() => {
      return adminsDetails.map((detail) => {
        if (detail.id === id) {
          return { ...detail, [key]: value };
        }
        return detail;
      });
    });
  };

  const addAdmins = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    // map to get the server desired result
    const admins: IAddAdmin[] = adminsDetails.map((detail) => ({
      firstName: detail.firstName,
      lastName: detail.lastName,
      email: detail.email,
      role: "admin",
    }));
    const data = await dispatch(addAdmin({ data: admins, token }));
    if (!data?.error) {
      navigate("/login");
    }
  };

  return (
    <>
      <header className="text-darkGray text-center">
        <h1 className="text-[27px] font-bold mb-2">School Preference</h1>
        <p className="text-[1rem] font-[500]">
          We recommend that the admin's priviledge should be split between at
          least two receipients.
        </p>
      </header>
      <form onSubmit={addAdmins}>
        <div className="mt-8 sm:px-3 mb-6 w-full max-w-[600px] mx-auto max-h-[500px] overflow-hidden overflow-y-scroll">
          {adminsDetails.map((singleAdmin, index: number) => {
            const inputFields: InputFields[] = [
              {
                placeholder: "Enter First Name",
                name: "firstName",
                type: "text",
                value: singleAdmin.firstName,
                label: "Create Firstname",
              },
              {
                placeholder: "Enter Last Name",
                name: "lastName",
                type: "text",
                value: singleAdmin.lastName,
                label: "Create Last Name",
              },
              {
                placeholder: "Enter your email",
                name: "email",
                type: "email",
                value: singleAdmin.email,
                label: "Email",
              },
            ];
            return (
              <article key={index} className="mb-4">
                <header className="w-full flex items-center justify-between gap-x-4 mb-3">
                  <p className=" font-bold">Administrator {index + 1}</p>
                  {adminsDetails.length > 1 && (
                    <button
                      type="button"
                      className="py-3 px-4 bg-black text-white cursor-pointer rounded-md"
                      onClick={() => {
                        removeAdmin(singleAdmin.id as number);
                      }}
                    >
                      Remove
                    </button>
                  )}
                </header>{" "}
                <div className="w-full p-6 rounded-md bg-white shadow-md">
                  {inputFields.map((field, index: number) => {
                    return (
                      <div key={index} className="mb-4">
                        <label
                          htmlFor={field.name}
                          className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                        >
                          {field.label}
                        </label>
                        <input
                          type={field.type}
                          name={field.name}
                          placeholder={field.placeholder}
                          value={field.value}
                          className="input-field"
                          onChange={(e) => {
                            updateAdminDetails(
                              singleAdmin.id as number,
                              field.name,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </article>
            );
          })}
        </div>

        <div className="flex items-center justify-center w-full gap-x-4 gap-y-4 sm:flex-row flex-col max-w-[600px] mx-auto">
          <button
            className="w-full border-[1.5px] rounded-md text-[1rem] text-black border-black hover:text-white hover:bg-black cursor-pointer p-3 transition"
            onClick={addNewAdmin}
            type="button"
          >
            Add New Admin
          </button>
          <button
            className="w-full border-[1.5px] rounded-md text-[1rem] border-black text-white bg-black cursor-pointer p-3"
            type="submit"
          >
            Proceed
          </button>
        </div>

        <div className="flex items-end justify-end max-w-[600px] mx-auto mt-3">
          <Link
            to={"/login"}
            className="flex items-center gap-[.5rem] text-[1.1rem]"
          >
            Skip{" "}
            <span>
              <BsArrowRight />
            </span>
          </Link>
        </div>
      </form>
    </>
  );
};

export default Step4;
