import {
    bursaryTransactionsProps,
    ClassAttendanceProps,
    plansProps,
    StaffEmploymentRecordProps,
    StaffLessonNotesProps,
    staffsListProps,
    studentPayAdviceProps,
    studentScoresProps,
    studentsListProps,
    subjectsScoresProps,
    tableHeaders,
} from "../types/static.types";
import { ISubscriptionPlanName } from "../types/db.types";
import profileImage from "../assets/images/profile-img.jpg";
import { IGeneratedSubjects, ISchoolDetails, ISchoolResultGradingFormat } from "../types/db.types";
import { IGetScores } from "../api/hooks/teachers/teacher.types";
import { getUserFromLocalStorage } from "../utils/localStorage";

const school = getUserFromLocalStorage()?.school as ISchoolDetails;
const is70_30Grading = school?.result_grading_format === ISchoolResultGradingFormat?._70_30;

export const classes: string[] = [
    "Creche",
    "Kindergaten",
    "Basic 1",
    "Basic 2",
    "Basic 3",
    "Basic 4",
    "Basic 5",
    "JSS 1",
    "JSS 2",
    "JSS 3",
    "SSS 1",
    "SSS 2",
    "SSS 3",
];

export const positions: string[] = [
    "Teacher",
    "Cleaner",
    "Bus Driver",
    "Security",
    "Bursar",
    "Nurse",
    "Chef",
    "Bus Assistant",
    "Nanny",
    "Gardener",
];

export const plans: plansProps[] = [
    {
        duration: "Month",
        plan: [
            {
                name: ISubscriptionPlanName.BASIC,
                description: "Ideal for schools who need access to the following features",
                price: 5000,
                features: [
                    {
                        name: "Payment receipts can be viewed on both admin's and parents' dashboards.",
                        available: true,
                    },
                    {
                        name: "All debtors of the school can be fetched per class.",
                        available: true,
                    },
                    {
                        name: "Report cards are automatically generated at the end of the term.",
                        available: true,
                    },
                    {
                        name: "Messages can be sent and replied to by all users of this application.",
                        available: true,
                    },
                    { name: "Access all classes from your device", available: true },
                    {
                        name: "Updated bursary for all kinds of transactions",
                        available: true,
                    },
                    {
                        name: "Create a class, class payadvice and class teacher",
                        available: true,
                    },
                    {
                        name: "Parents have access to child's profile and can pay from their profile",
                        available: true,
                    },
                    {
                        name: "Teachers can record score and attendance on the go",
                        available: true,
                    },
                    {
                        name: "Automated Staff Salary",
                        available: true,
                    },
                ],
            },
            // {
            //     name: ISubscriptionPlanName.STANDARD,
            //     isComingSoon: true,
            //     description: "Ideal for schools who need personalized services and security for large teams",
            //     price: 15000,
            //     features: [
            //         { name: "Access all classes from your device", available: true },
            //         {
            //             name: "Updated bursary for all kind of transactions",
            //             available: true,
            //         },
            //         {
            //             name: "Create a class, class payadvice and class teacher",
            //             available: true,
            //         },
            //         {
            //             name: "Parents have access to child's profile and can pay from their profile",
            //             available: true,
            //         },
            //         {
            //             name: "Teachers can record score and attendance on the go",
            //             available: true,
            //         },
            //         {
            //             name: "Report cards are generated at the end of the term",
            //             available: true,
            //         },
            //         {
            //             name: "Automated Staff Salary",
            //             available: true,
            //         },
            //         {
            //             name: "School clinic records are taken care of",
            //             available: true,
            //         },

            //         {
            //             name: "Store management is handled automatically",
            //             available: true,
            //         },
            //     ],
            // },
        ],
    },
];
export const staffsHeader: tableHeaders[] = [
    { text: "Full Name", width: 180 },
    { text: "Gender", width: 80 },
    { text: "Position", width: 120 },
    { text: "Phone number", width: 180 },
    { text: "Location", width: 100 },
];

export const studentsHeader: tableHeaders[] = [
    { text: "Full Name", width: 180 },
    { text: "Gender", width: 80 },
    { text: "Class", width: 120 },
    { text: "Phone number", width: 180 },
    { text: "Location", width: 100 },
];

export const parentsHeader: tableHeaders[] = [
    { text: "Full Name", width: 180 },
    { text: "Phone number", width: 180 },
    { text: "Email", width: 180 },
];

export const staffsList: staffsListProps[] = [
    {
        id: 1,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 2,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 3,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 4,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 5,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 6,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 7,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 8,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 9,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 10,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 11,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 12,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 13,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 14,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 15,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 16,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
    {
        id: 17,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        subject: "English",
        location: "Lagos",
    },
];

export const studentsList: studentsListProps[] = [
    {
        id: 1,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 2,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 3,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 4,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 5,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 6,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 7,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 8,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 9,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 10,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 11,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 12,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 13,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 14,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 15,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 16,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
    {
        id: 17,
        name: "Adedamola James",
        image: profileImage,
        gender: "male",
        phoneNumber: "08075258288",
        class: "JSS 1",
        location: "Lagos",
    },
];

export const singleStaffDetails = {
    user: {
        name: "Adedamola Alegbe-James",
        gender: "male",
        dob: "23/09/1998",
        telephone: "08098309887",
        email: "Jaiyeolaosanyin@gmail.com",
        address: "5, Alade Crescent, Sabo, Kampala, Uganda",
    },
    nextOfKin: {
        name: "Alage-James",
        gender: "male",
        dob: "23/09/1998",
        telephone: "08098309887",
        address: "5, Alade Crescent, Sabo, Kampala, Uganda",
    },
    role: {
        position: "Teacher",
        class: "JSS 2",
        subject: ["Literature in English", "Yoruba"],
    },
    accountInfo: {
        bank: "Union Bank",
        account_name: "Adewale Ayuba",
        account_number: "0987654321",
    },
};

export const staffEmploymentRecordHeaders: tableHeaders[] = [
    { text: "Title", width: 300 },
    { text: "Status", width: 300 },
];

export const staffEmploymentRecord: StaffEmploymentRecordProps[] = [
    { title: "Birth Certificate", link: "" },
    { title: "WAEC Certificate", link: "" },
    { title: "University Degree", link: "" },
    { title: "Change of name", link: "" },
];

export const staffLessonNoteHeaders: tableHeaders[] = [
    { text: "Session", width: 180 },
    { text: "Term", width: 120 },
    { text: "Subject", width: 200 },
    { text: "Status", width: 120 },
];

export const staffLessonNotes: StaffLessonNotesProps[] = [
    {
        session: "2022/2023",
        term: "First",
        subject: "English Language",
        completed: false,
    },
    {
        session: "2022/2023",
        term: "Second",
        subject: "Mathematics",
        completed: true,
    },
    {
        session: "2022/2023",
        term: "First",
        subject: "Biology",
        completed: true,
    },
    {
        session: "2022/2023",
        term: "Second",
        subject: "French",
        completed: false,
    },
    {
        session: "2022/2023",
        term: "First",
        subject: "Yoruba Language",
        completed: true,
    },
    {
        session: "2022/2023",
        term: "Second",
        subject: "Chemistry",
        completed: true,
    },
    {
        session: "2022/2023",
        term: "First",
        subject: "Physics",
        completed: false,
    },
];

export const singleStudentDetails = {
    user: {
        name: "Adedamola Alegbe-James",
        gender: "male",
        dob: "23/09/1998",
        telephone: "08098309887",
        email: "Jaiyeolaosanyin@gmail.com",
        address: "5, Alade Crescent, Sabo, Kampala, Uganda",
        class: "JSS 2",
    },
    parent: {
        name: "Alage-James",
        gender: "male",
        dob: "23/09/1998",
        telephone: "08098309887",
        email: "Jaiyeolaosanyin@gmail.com",
        address: "5, Alade Crescent, Sabo, Kampala, Uganda",
    },
    tuition: {
        total: 30000,
        paid: 25000,
        balance: 5000,
    },
    sickbayHistory: {
        visits: 15,
        lastVisited: "21/02/2023",
        issueTreated: "Sport Injury",
        nurse: "Jaiye Cynthia",
    },
};

export const studentPayAdviceHeaders = [
    { text: "Item", width: 150 },
    { text: "Charge", width: 100 },
    { text: "Amount Paid", width: 200, subtitles: ["Cash", "Card"] },
    { text: "Balance", width: 80 },
    { text: "Status", width: 90 },
];

export const bill: studentPayAdviceProps[] = [
    {
        item: "Tuition",
        charge: 65000,
        withCard: { amount: 10000, date: "22/02/22" },
        withCash: { amount: 10000, date: "22/02/22" },
        paid: 20000,
    },
    {
        item: "Graduation",
        charge: 0,
        withCard: { amount: 0, date: "22/02/22" },
        withCash: { amount: 0, date: "22/02/22" },
        paid: 0,
    },
    {
        item: "Language Studio",
        charge: 0,
        withCard: { amount: 0, date: "22/02/22" },
        withCash: { amount: 0, date: "22/02/22" },
        paid: 0,
    },
    {
        item: "Summer Coaching",
        charge: 3000,
        withCard: { amount: 0, date: "22/02/22" },
        withCash: { amount: 3000, date: "22/02/22" },
        paid: 3000,
    },
    {
        item: "Excursion",
        charge: 0,
        withCard: { amount: 0, date: "22/02/22" },
        withCash: { amount: 0, date: "22/02/22" },
        paid: 0,
    },
    {
        item: "Uniform/P.E",
        charge: 0,
        withCard: { amount: 0, date: "22/02/22" },
        withCash: { amount: 0, date: "22/02/22" },
        paid: 0,
    },
    {
        item: "Exam Fee",
        charge: 5000,
        withCard: { amount: 0, date: "22/02/22" },
        withCash: { amount: 0, date: "22/02/22" },
        paid: 0,
    },
    {
        item: "Total",
        charge: 73000,
        withCard: { amount: 10000, date: "22/02/22" },
        withCash: { amount: 13000, date: "22/02/22" },
        paid: 23000,
    },
];

export const studentAttendanceHeaders: tableHeaders[] = [
    { text: "", width: 100 },
    { text: "Mon", width: 70 },
    { text: "Tue", width: 70 },
    { text: "Wed", width: 70 },
    { text: "Thur", width: 70 },
    { text: "Fri", width: 70 },
];

export const studentAttendance = [
    {
        week: 1,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 2,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 3,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 4,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 5,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 6,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 7,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
    {
        week: 8,
        days: [
            { day: "Mon", present: true },
            { day: "Tue", present: false },
            { day: "Wed", present: true },
            { day: "Thur", present: true },
            { day: "Fri", present: false },
        ],
    },
];

export const studentScoresHeaders: tableHeaders[] = [
    { text: "Subject", width: 200 },
    {
        text: (
            <>
                1<sup>st</sup>CA
            </>
        ),
        width: 90,
    },
    {
        text: (
            <>
                2<sup>nd</sup>CA
            </>
        ),
        width: 80,
    },
    is70_30Grading
        ? {
              text: (
                  <>
                      3<sup>rd</sup>CA
                  </>
              ),
              width: 80,
          }
        : {},
    { text: "Exam", width: 120 },
    { text: "Total Score", width: 120 },
    // { text: 'Status', width: 90 },
];

export const studentScores: studentScoresProps[] = [
    {
        subject: "English Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 40,
    },
    {
        subject: "Mathematics",
        firstCA: 10,
        secondCA: 1,
        thirdCA: 9,
        examScore: 19,
    },
    {
        subject: "Yoruba Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 30,
    },
    {
        subject: "English Language",
        firstCA: 14,
        secondCA: 11,
        thirdCA: 11,
        examScore: 40,
    },
    {
        subject: "English Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 40,
    },
    {
        subject: "English Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 40,
    },
    {
        subject: "English Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 40,
    },
    {
        subject: "English Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 40,
    },
    {
        subject: "English Language",
        firstCA: 10,
        secondCA: 11,
        thirdCA: 19,
        examScore: 40,
    },
];

export const recentTransactionsHeaders: tableHeaders[] = [
    { text: "Date", width: 80 },
    { text: "Name", width: 200 },
    { text: "Amount", width: 80 },
    { text: "", width: 100 },
];

export const bursaryTransactions: bursaryTransactionsProps[] = [
    {
        date: "14-08-23",
        name: "Alpha-Zeus Pharmacy",
        amount: 10000,
        transactionType: "in-flow",
    },
    {
        date: "14-08-23",
        name: "Alpha-Zeus Pharmacy",
        amount: 10000,
        transactionType: "in-flow",
    },
    {
        date: "14-08-23",
        name: "Alpha-Zeus Pharmacy",
        amount: 10000,
        transactionType: "out-flow",
    },
    {
        date: "14-08-23",
        name: "Alpha-Zeus Pharmacy",
        amount: 10000,
        transactionType: "in-flow",
    },
    {
        date: "14-08-23",
        name: "Alpha-Zeus Pharmacy",
        amount: 10000,
        transactionType: "out-flow",
    },
];

export const classAttendance: ClassAttendanceProps[] = [
    {
        studentName: "Peter Obi",
        studentId: 1,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },

    {
        studentName: "Peter Obi",
        studentId: 2,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 3,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 4,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 5,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 6,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 7,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 8,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 9,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 10,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
    {
        studentName: "Peter Obi",
        studentId: 11,
        attendance: [
            {
                week: 1,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 2,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 3,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 4,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 5,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 6,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 7,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 8,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
            {
                week: 9,
                days: [
                    {
                        day: "Mon",
                        present: true,
                    },
                    {
                        day: "Tue",
                        present: false,
                    },
                    {
                        day: "Wed",
                        present: false,
                    },
                    {
                        day: "Thur",
                        present: true,
                    },
                    {
                        day: "Fri",
                        present: true,
                    },
                ],
            },
        ],
    },
];

export const teachersSubjects = [
    {
        class: "SSS 1 Olive",
        subject: "Biology",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "JSS 3 Mango",
        subject: "Literature",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
    {
        class: "SSS1 Grapes",
        subject: "English",
    },
];

export const subjectScoresHeaders: tableHeaders[] = [
    {
        text: "Student Name",
        width: 200,
    },
    {
        text: (
            <>
                1<sup>st</sup>CA
            </>
        ),
        width: 100,
    },
    {
        text: (
            <>
                2<sup>nd</sup>CA
            </>
        ),
        width: 100,
    },
    is70_30Grading
        ? {
              text: (
                  <>
                      3<sup>rd</sup>CA
                  </>
              ),
              width: 100,
          }
        : {},
    {
        text: "Exam",
        width: 100,
    },
    {
        text: "Total Score",
        width: 100,
    },
    // NOT AVAILABLE IN FREE VERSION
    // {
    //   text: "Status",
    //   width: 200,
    // },
];

export const teacherClassesHeader: tableHeaders[] = [
    { text: "Class", width: 200 },
    { text: "Students", width: 200 },
];
