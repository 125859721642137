import axios, { AxiosError } from "axios";
import { getTokenFromLocalStorage, deleteTokenFromLocalStorage } from "./utils/localStorage";
import Swal from "sweetalert2";

const baseHttp = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
});

const authHttp = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/auth`,
});

baseHttp.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
        if (error.status === 403) {
            Swal.fire({
                title: "Session Expired!",
                text: "Login again to continue",
                timer: 2000,
                icon: "warning",
                showConfirmButton: false,
            }).then(() => {
                window.location.href = "/login";
                deleteTokenFromLocalStorage();
            });
        }

        return Promise.reject(error);
    }
);

export { authHttp, baseHttp };
