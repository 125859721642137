import React, { useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFilled } from '../../components/UI/Buttons';
import { ContentBoxWithAvatar } from '../../components/UI/ContentBox';
import { changeFontSize } from '../../store/slices/others/handlerSlice';
import { RootState } from '../../store/store';

const FontEditor = () => {
  const size = localStorage.getItem('font-size');

  const [fontSize, setFontSize] = useState(size || 100);

  const dispatch = useDispatch();

  return (
    <div className='w-full max-w-[300px]'>
      <ContentBoxWithAvatar
        avatar={<BsPencil />}
        hasButton={false}
        header='Font Size'
      >
        <div className='px-6 py-3'>
          <div className='flex space-x-2 items-center'>
            <p className='font-bold max-w-fit pr-2'>{fontSize}%</p>

            <input
              value={fontSize}
              min={80}
              max={110}
              onChange={(e) => setFontSize(Number(e.target.value))}
              type='range'
              className='accent-blackText w-full flex-1'
            />
          </div>
          <div className='max-w-fit ml-auto mt-3'>
            <ButtonFilled
              icon={<BiSave />}
              text='Edit font size'
              onClick={() => {
                dispatch(changeFontSize(fontSize as number));
              }}
            />
          </div>{' '}
        </div>
      </ContentBoxWithAvatar>
    </div>
  );
};

export default FontEditor;
