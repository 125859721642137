import React from "react";
import { ButtonFilled } from "../UI/Buttons";
import FaqAccordion from "./FaqAccordion";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

export interface IFAQ {
  question: string;
  answer: string;
}

const faqs: IFAQ[] = [
  {
    question: "What is edssistance?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, dolore officiis! Error expedita, ab atque inventore beatae corporis consequuntur vero voluptatem tenetur officia neque odit delectus nostrum dicta eligendi. Magni?",
  },

  {
    question: "Is there a trial period on joining Edssistance?",
    answer: "Yes, there is",
  },

  {
    question: "How many people can I onboard on edssistance?",
    answer: "One million uncountable people",
  },
];

const FAQs = () => {
  return (
    <section className="md:mt-[10rem] mt-[4rem] flex gap-y-[1.5rem] gap-x-[5rem] lg:flex-row flex-col-reverse">
      <div className="flex-1">
        <div className=" border-b-2">
          {faqs?.map((faq, index) => {
            return <FaqAccordion {...faq} key={index} />;
          })}
        </div>

        <Link to={"/faq"} className="underline mt-2 font-[600] block">
          More FAQs
          <span className="inline-block align-middle ml-2">
            <BsArrowRight />
          </span>
        </Link>
      </div>

      <div className="flex-1">
        <h2 className="text-left text-[1.4rem] md:text-[2rem] font-bold">
          How We Can Help You?
        </h2>
        <p className="text-[0.9rem] leading-[2] max-w-[350px] text-darkGray">
          Follow our newsletter. We will regularly update you on your latest
          project and features.
        </p>

        <form className="mt-4 flex items-center gap-[15px]">
          <input
            className="bg-[#ededed] border-none outline-none text-blackText text-[.9rem] p-[10px] px-[1rem] rounded-md"
            placeholder="Enter Your Email"
            type="email"
          ></input>
          <ButtonFilled
            type="submit"
            text="Lets talk"
            className="!min-w-[90px]"
          />
        </form>
      </div>
    </section>
  );
};

export default FAQs;
