import React, { ChangeEvent, useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

interface PasswordInputProps {
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  className?: string;
}

const PasswordInput = (props: PasswordInputProps) => {
  const [type, setType] = useState<'password' | 'text'>('password');

  return (
    <div className='relative'>
      <input
        type={type}
        {...props}
        required
        className={`input-field ${props.className}`}
        minLength={8}
      />
      {props.value.length > 0 && (
        <span
          className='absolute top-[50%] right-[1.25rem] -translate-y-[50%] text-[1.3rem] z-[3] cursor-pointer'
          onClick={() => {
            setType((prev) => (prev === 'text' ? 'password' : 'text'));
          }}
        >
          {type === 'text' ? (
            <BsEye className='text-green-500' />
          ) : (
            <BsEyeSlash className='text-red-500' />
          )}
        </span>
      )}
    </div>
  );
};

export default PasswordInput;
