import React, { ReactElement } from 'react';
import { BlackOutlineButton, ButtonFilled } from './Buttons';
import ModalOverlay from './ModalOverlay';

interface ConfirmationModalProps {
  children?: ReactElement | ReactElement[];
  text?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <ModalOverlay modalWidth={600}>
      <>
        {props?.text && (
          <h2 className='text-[2rem] mb-4 text-center'>{props?.text}</h2>
        )}
      </>

      <>{props?.children && props?.children}</>

      <div className='w-full flex items-center gap-x-4 gap-y-4 flex-col-reverse sm:flex-row mt-6'>
        <div className='flex-1 w-full'>
          <BlackOutlineButton
            text='Cancel'
            fullWidth={true}
            onClick={props?.onCancel}
          />
        </div>
        <div className='flex-1 w-full'>
          <ButtonFilled
            text='Confirm'
            fullWidth={true}
            onClick={props?.onConfirm}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
