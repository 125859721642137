import React, { useState } from "react";
import ConfirmationModal from "../UI/ConfirmationModal";
import { subscribe } from "../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { ISubscriptionPlanName } from "../../types/db.types";
import { dateConverter } from "../../utils/dateConverter";
import PlanInfo from "./PlanInfo";
import { plans } from "../../static/admin&teacher.data";

interface Props {
  endDate: Date;
}

const StandardPlan = ({ endDate }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const submit = async () => {
    const data = await dispatch(subscribe(ISubscriptionPlanName.STANDARD));

    if (!data?.error) {
      setModalOpen(false);
      window.open(data?.payload?.authorization_url, "_blank");
    }
  };

  return (
    <>
      {modalOpen && (
        <ConfirmationModal
          onCancel={() => setModalOpen(false)}
          onConfirm={submit}
          text="Are you sure you want to upgrade to standard?"
        />
      )}

      <header className=" ">
        <h2 className="text-[1.5rem] font-bold">School Subscription</h2>
        <p>
          Current Plan: <b>Standard</b>
        </p>
        <p>
          Expires: <b>{dateConverter(endDate as Date)}</b>
        </p>
      </header>

      <div className="mt-6">
        <h2 className="mb-4 text-[1.5rem] font-bold">
          Subscribe to Basic Plan
        </h2>
        <PlanInfo {...plans[0]?.plan[0]} setModalOpen={setModalOpen} />
      </div>
    </>
  );
};

export default StandardPlan;
