/**
 * Utils for the functions used to filter the inputs in subject generator and edit subjects
 */
import React from 'react';

export type PayAdviceItemProps = {
  id: string | number;
  charge: number | string;
  item: string;
};

const getItemValues = (
  items: PayAdviceItemProps[],
  id: string | number
): { charge: string; item: string } => {
  const item = items.find((item) => item.id === id);
  return { charge: item?.charge as string, item: item?.item as string };
};

const addNewItem = (items: PayAdviceItemProps[]) => {
  return [...items, { charge: '', item: '', id: new Date().getTime() }];
};

const removeItem = (items: PayAdviceItemProps[], id: string | number) => {
  return items.filter((item) => item.id !== id);
};

const updateItemChargeAndName = (
  items: PayAdviceItemProps[],
  id: string | number,
  key: string,
  value: string | number
) => {
  return items.map((item) => {
    if (item.id === id) {
      item[key as keyof typeof item] = value as string;
    }
    return item;
  });
};

export { getItemValues, removeItem, addNewItem, updateItemChargeAndName };
