import React, { useEffect, useState } from "react";
import { MdOutlineClass } from "react-icons/md";
import TableLayout, {
  TableHeader,
  SingleTableHeader,
  TableRowsContainer,
  TableRow,
  SingleTableRowItem,
} from "../../../../components/Layouts/TableLayout";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import {
  IGeneratedClasses,
  IGeneratedSubjects,
} from "../../../../types/db.types";
import {
  useGetClasses,
  useGetSubjects,
} from "../../../../api/hooks/admin/generators/generators.service";
import { Link } from "react-router-dom";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import NoItem from "../../../../components/UI/NoItem";
import BackButton from "../../../../components/UI/BackButton";

const GeneratedSubjects = () => {
  const [selectedClass, setSelectedClass] =
    useState<IGeneratedClasses | null>();

  const [classSubjects, setClassSubjects] = useState<
    IGeneratedSubjects[] | null
  >();

  const selectClass = (classDetails: IGeneratedClasses) => {
    setSelectedClass(classDetails);
  };

  const { data: classes, isLoading } = useGetClasses();

  const {
    data: subjects,
    isLoading: subjectsLoading,
    refetch,
  } = useGetSubjects(selectedClass?._id as string);

  useEffect(() => {
    // fetch the subjects whenever a new class is chosen
    refetch();
  }, [selectedClass]);

  useEffect(() => {
    if (!isLoading && !subjectsLoading && subjects) {
      setClassSubjects(subjects);
    }
  }, [subjects]);

  if (!classes || classes?.length == 0) {
    return (
      <DashboardLayout pageTitle={"Generated Subjects"}>
        <section className="w-full sm:p-6 py-6 min-h-screen">
          <NoItem title="No class yet" subtitle="Kindly add class(es)" />
        </section>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle={"Generated Subjects"}>
      <section className="w-full sm:p-6 py-6 min-h-screen">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 mt-8 lg:grid-cols-3 gap-x-4 gap-y-4">
          {classes?.map((singleClass, index: number) => {
            return (
              <article
                className={
                  styles.content_box +
                  ` ${
                    (((selectedClass?.className as string) +
                      selectedClass?.classArm) as string) ===
                      singleClass.className + singleClass?.classArm &&
                    "border-[#242424]"
                  }`
                }
                key={index}
                onClick={() => {
                  // make it the selected class
                  selectClass(singleClass);
                }}
              >
                <span
                  className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                >
                  <MdOutlineClass />
                </span>
                <h2 className={styles.content_box_title}>
                  {singleClass.className} {singleClass.classArm}
                </h2>
              </article>
            );
          })}
        </div>

        {selectedClass?.className && (
          <div className="mt-8 w-full flex justify-end">
            <Link to={`/admin/generator/edit/subject/${selectedClass?._id}`}>
              <ButtonFilled
                text={`Edit ${selectedClass?.className} ${selectedClass?.classArm} Subjects`}
              />
            </Link>
          </div>
        )}

        {selectedClass?.className && (
          <section className="w-full">
            <TableLayout>
              <TableHeader>
                {["Subject", "Teacher"].map((item, index) => {
                  return (
                    <SingleTableHeader text={item} key={index} width={120} />
                  );
                })}
              </TableHeader>
              <TableRowsContainer>
                {classSubjects?.map((subject, index: number) => {
                  return (
                    <TableRow key={index}>
                      <SingleTableRowItem width={120}>
                        <p>{subject.title}</p>
                      </SingleTableRowItem>
                      <SingleTableRowItem width={120}>
                        {subject?.teacher ? (
                          <Link
                            to={`/admin/staffs/${subject?.teacher?._id}`}
                            className="flex items-center gap-x-2 cursor-pointer"
                          >
                            <img
                              className="w-[25px] h-[25px] rounded-full"
                              alt={subject?.teacher?.firstName}
                              loading="lazy"
                              src={subject?.teacher?.imageUrl}
                            />
                            <p className="font-bold text-[#222] cursor-pointer hover:underline">
                              {subject?.teacher?.firstName}{" "}
                              {subject?.teacher?.lastName}
                            </p>
                          </Link>
                        ) : (
                          <p className="font-500">
                            No teacher has been assigned
                          </p>
                        )}
                      </SingleTableRowItem>
                    </TableRow>
                  );
                })}
              </TableRowsContainer>
            </TableLayout>
          </section>
        )}
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  content_box:
    "p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer",
  content_box_icon:
    "w-[30px] h-[30px] rounded-full flex justify-center items-center",
  content_box_subtitle: "text-[15px] underline text-darkGray font-[300]",
  content_box_title: "text-[1rem] font-bold text-blackText flex flex-col",
};

export default GeneratedSubjects;
