import React, { useState } from "react";
import { MdOutlineClass } from "react-icons/md";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../../../components/Layouts/TableLayout";
import profileImg from "../../../../assets/images/profile-img.jpg";
import { IGenerateClass } from "../../../../api/thunks/admin/admin.types";
import {
  IAllStudents,
  IGeneratedClasses,
  IUser,
} from "../../../../types/db.types";
import { useGetClasses } from "../../../../api/hooks/admin/generators/generators.service";
import { Link } from "react-router-dom";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import { useDispatch } from "react-redux";
import { deleteClass } from "../../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import ConfirmationModal from "../../../../components/UI/ConfirmationModal";
import NoItem from "../../../../components/UI/NoItem";
import BackButton from "../../../../components/UI/BackButton";
import { baseHttp } from "../../../../axios.config";
import { getUserFromLocalStorage } from "../../../../utils/localStorage";

const GeneratedClasses = () => {
  const [selectedClass, setSelectedClass] = useState<IGeneratedClasses | null>({
    className: "",
    classArm: "",
    students: [],
  });
  const [openedTab, setOpenedTab] = useState<"teachers" | "students">(
    "teachers"
  );

  // Confirmation Modal
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const selectClass = async (classDetails: IGeneratedClasses) => {
    setSelectedClass(classDetails);

    const response = await baseHttp.get(
      `/school/class-scores/${classDetails._id}`
    );
  };

  const { data: classes, refetch } = useGetClasses();

  // Delete class function
  const del = async () => {
    const data = await dispatch(deleteClass(selectedClass?._id));

    if (!data?.error) {
      setModalOpen(false);
      setSelectedClass(null);

      Swal.fire({
        icon: "success",
        text: "Class deleted successfully",
        showConfirmButton: false,
        timer: 1000,
      });

      // Fetch all classes again
      refetch();
    }
  };

  if (!classes || classes?.length == 0) {
    return (
      <DashboardLayout pageTitle={"Generated Classes"}>
        <section className="w-full sm:p-6 py-6 min-h-screen">
          <NoItem title="No class yet" subtitle="Kindly add class(es)" />
        </section>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle={"Generated Classes"}>
      <section className="w-full sm:p-6 py-6 min-h-screen">
        {/* Confirmation Modal for deleting classes */}
        {modalOpen && (
          <ConfirmationModal
            onCancel={() => {
              setModalOpen(false);
            }}
            onConfirm={del}
            text={`Are you sure you want to delete ${selectedClass?.className} ${selectedClass?.classArm}?`}
          />
        )}

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 mt-8">
          {classes?.map((singleClass, index: number) => {
            return (
              <article
                className={
                  styles.content_box +
                  ` ${
                    (selectedClass as IGeneratedClasses)?.className +
                      selectedClass?.classArm ===
                      singleClass.className + singleClass.classArm &&
                    "border-[#242424]"
                  }`
                }
                key={index}
                onClick={() => {
                  // make it the selected class
                  selectClass(singleClass);
                }}
              >
                <span
                  className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                >
                  <MdOutlineClass />
                </span>
                <h2 className={styles.content_box_title}>
                  {singleClass.className} {singleClass.classArm}
                </h2>
              </article>
            );
          })}
        </div>

        {selectedClass?.className && (
          <div className="mt-8 w-full flex flex-wrap gap-x-4 gap-y-4 sm:justify-end">
            <Link to={`/admin/generator/edit/class/${selectedClass?._id}`}>
              <ButtonFilled
                text={`Edit ${selectedClass?.className} ${selectedClass?.classArm}`}
              />
            </Link>

            <ButtonFilled
              onClick={() => {
                setModalOpen(true);
              }}
              text={`Delete ${selectedClass?.className} ${selectedClass?.classArm}`}
            />

            <Link
              to={`/admin/generator/generated/result/${selectedClass?.schoolId?.term}/${selectedClass._id}`}
            >
              <ButtonFilled text="View Results" />
            </Link>

            <Link
              to={`/admin/generator/generated/attendance/${selectedClass?._id}`}
            >
              <ButtonFilled text="View Attendance" />
            </Link>

            <Link
              to={`/admin/generator/generated/debtors/${selectedClass._id}`}
            >
              <ButtonFilled text="View Debtors" />
            </Link>
          </div>
        )}

        {/* ========= TABS ============ */}

        <div className="mt-8 sm:px-0 px-4">
          <div className="w-full max-w-full overflow-x-scroll border-b-[1.5px] flex items-center gap-x-4 pb-2">
            {(["teachers", "students"] as Array<"teachers" | "students">).map(
              (tab, index: number) => {
                return (
                  <h3
                    key={index}
                    className={`pb-1 mb-0  leading-[1] border-b-[1.5px] cursor-pointer ${
                      index === 0 ? "min-w-[11.25rem]" : "min-w-fit"
                    } font-bold text-center ${
                      openedTab === tab
                        ? "border-black text-blackText"
                        : "border-transparent text-darkerGray"
                    }`}
                    onClick={() => {
                      setOpenedTab(tab);
                    }}
                  >
                    {tab === "teachers"
                      ? "Class Teacher"
                      : selectedClass?.students
                      ? `Students (${selectedClass?.students?.length})`
                      : "Students"}
                  </h3>
                );
              }
            )}
          </div>
        </div>
        {selectedClass?.className && (
          <>
            <section className="w-full mt-8">
              <TableLayout>
                <TableHeader>
                  {(openedTab === "teachers"
                    ? ["Name", "Gender"]
                    : ["Name", "Gender"]
                  ).map((item, index) => {
                    return (
                      <SingleTableHeader
                        text={item}
                        key={index}
                        width={item === "Name" ? 200 : 120}
                      />
                    );
                  })}
                </TableHeader>
                <TableRowsContainer>
                  {openedTab === "teachers" ? (
                    selectedClass?.classTeacher ? (
                      <TableRow>
                        <SingleTableRowItem width={200}>
                          <Link
                            to={`/admin/staffs/${selectedClass?.classTeacher?._id}`}
                            className=""
                          >
                            <span className="inline-block mr-2 align-middle">
                              <img
                                src={selectedClass?.classTeacher?.imageUrl}
                                className="w-[25px] h-[25px] rounded-full object-center items-center"
                              />
                            </span>
                            <p className="inline-block">
                              {selectedClass?.classTeacher?.firstName}{" "}
                              {selectedClass?.classTeacher?.lastName}
                            </p>
                          </Link>
                        </SingleTableRowItem>
                        <SingleTableRowItem width={120}>
                          <p className="capitalize">
                            {selectedClass?.classTeacher?.gender}
                          </p>
                        </SingleTableRowItem>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <div className="min-w-full py-5 text-center px-3 font-bold text-darkerGray">
                          No teacher has been assigned to this class
                        </div>
                      </TableRow>
                    )
                  ) : (
                    selectedClass?.students?.map(
                      (user: IAllStudents, index: number) => {
                        return (
                          <TableRow key={index}>
                            <SingleTableRowItem width={200}>
                              <Link
                                to={`/admin/students/${user?._id}`}
                                className=""
                              >
                                <span className="inline-block mr-2 align-middle">
                                  <img
                                    src={user?.imageUrl}
                                    loading="lazy"
                                    className="w-[25px] h-[25px] rounded-full object-center items-center"
                                  />
                                </span>
                                <p className="inline-block">
                                  {user?.firstName} {user?.lastName}
                                </p>
                              </Link>
                            </SingleTableRowItem>
                            <SingleTableRowItem width={120}>
                              <p>{user?.gender}</p>
                            </SingleTableRowItem>
                          </TableRow>
                        );
                      }
                    )
                  )}
                </TableRowsContainer>
              </TableLayout>
            </section>
          </>
        )}
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  content_box:
    "p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer",
  content_box_icon:
    "w-[30px] h-[30px] rounded-full flex justify-center items-center",
  content_box_subtitle: "text-[15px] underline text-darkGray font-[300]",
  content_box_title: "text-[1rem] font-bold text-blackText flex flex-col",
};

export default GeneratedClasses;
