import React from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../components/UI/Buttons";
import { GiPaperPlane } from "react-icons/gi";
import UserSearch from "../../components/Chat/UserSearch";
import Conversations from "../../components/Chat/Conversations";

const TeacherChats = () => {
  return (
    <DashboardLayout pageTitle="Chat">
      <section className="w-full min-h-screen py-6 md:p-6">
        <div className="flex flex-wrap gap-4">
          <ButtonFilled text={"Message All Students"} icon={<GiPaperPlane />} />
        </div>

        <UserSearch />
        <Conversations />
      </section>
    </DashboardLayout>
  );
};

export default TeacherChats;
