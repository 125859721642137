import React from "react";
import { useLocation, useParams } from "react-router-dom";
import ReportCardInformation from "./ReportCardInformation";
import { ButtonFilled } from "../../UI/Buttons";
import { Link } from "react-router-dom";
import { useGetStudentApprovedResults } from "../../../api/hooks/admin/students/students.service";
import { ContentBox } from "../../UI/ContentBox";

const ReportCard = () => {
  const location = useLocation();

  const { id } = useParams();

  const { data: results, isLoading } = useGetStudentApprovedResults(id);

  return (
    <>
      {location.pathname.includes("teacher") && <ReportCardInformation />}

      <div className="items-center gap-[1rem] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {results?.map((result, index) => {
          const sessions = result?.session?.split("/");
          return (
            <ContentBox defaultPadding={true}>
              <h3 className="font-medium text-center text-[1.2rem]">
                {result?.classId?.className + " " + result?.classId?.classArm}
              </h3>

              <p className="mt-2 text-center">
                {result?.term} term, {result?.session} session.
              </p>

              <Link
                to={
                  location.pathname.includes("/student/profile")
                    ? `/report-card/student?term=${
                        result?.term
                      }&session=${`${sessions[0]}%2F${sessions[1]}`}`
                    : `/report-card/${id}?term=${
                        result?.term
                      }&session=${`${sessions[0]}%2F${sessions[1]}`}`
                }
              >
                <ButtonFilled
                  className="!min-w-[150px] mx-auto mt-4"
                  text="View Report Card"
                />
              </Link>
            </ContentBox>
          );
        })}
      </div>
    </>
  );
};

export default ReportCard;
