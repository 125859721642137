import React, { useState } from "react";
import TableLayout, {
    SingleTableHeader,
    TableHeader,
    TableRow,
    TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import { studentBulkUploadTable } from "../../../static/bulk-upload.data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import StudentRow from "../../../components/BulkUpload/StudentRow";
import { BiPlus, BiPlusCircle } from "react-icons/bi";
import { addNewStudent, resetStudents } from "../../../store/slices/post/studentBulkUpload";
import { ButtonFilled } from "../../../components/UI/Buttons";
import { StudentBulkChecks } from "../../../types/bulk.types";
import { useValidateStudentBulkTable } from "../../../utils/hooks/bulk-upload";
import { openErrorModal } from "../../../store/slices/others/handlerSlice";
import { addBulkStudents } from "../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

const StudentBulkUpload = () => {
    const students = useSelector((state: RootState) => state.studentBulk.students);
    const [errors, setErrors] = useState<StudentBulkChecks[]>([]);
    const { validate } = useValidateStudentBulkTable();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const updateErrors = (errors: StudentBulkChecks, index: number) => {
        setErrors((prev) => {
            prev[index] = errors;

            return prev;
        });
    };

    const submit = async (e: React.FormEvent) => {
        e.preventDefault();

        const valid = validate(errors);

        if (!valid) {
            dispatch(openErrorModal({ errorText: "Clear all errors before upload." }));
            return;
        }

        const data = await dispatch(addBulkStudents());

        if (!data.error) {
            await Swal.fire({
                title: "Students bulk upload",
                text: "Students uploaded successfully",
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });

            if (location.pathname.includes("admin")) {
                navigate("/admin/students");
            } else {
                navigate("/teacher/profile");
            }

            dispatch(resetStudents());
        }
    };

    return (
        <form className="" onSubmit={submit}>
            <div className="relative">
                <BiPlusCircle
                    size={30}
                    title="add new student"
                    className="absolute right-0 -bottom-[10px] z-[10]"
                    cursor={"pointer"}
                    onClick={() => dispatch(addNewStudent())}
                />

                <TableLayout>
                    <TableHeader>
                        {studentBulkUploadTable.map((header, index) => {
                            return <SingleTableHeader {...header} key={index} center={false} />;
                        })}
                    </TableHeader>

                    <TableRowsContainer>
                        {students.map((student, index) => {
                            return <StudentRow updateErrors={updateErrors} key={index} index={index} />;
                        })}
                    </TableRowsContainer>
                </TableLayout>
            </div>

            <ButtonFilled text={"Upload Students"} type="submit" className="mt-6 !min-w-[150px]" />
        </form>
    );
};

export default StudentBulkUpload;
