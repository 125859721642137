import React, { Fragment, useState } from 'react';
import { IAllStaffs, IGeneratedSubjects } from '../../types/db.types';
import UserSelectContainer from '../UI/UserSelectContainer';
import { BiTrash, BiSave } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import {
  deleteSubject,
  editSubject,
} from '../../api/thunks/admin/admin.service';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ConfirmationModal from '../UI/ConfirmationModal';

type ContainerProps = {
  subject: IGeneratedSubjects;
  staffs: IAllStaffs[];
  refetch: () => void;
};

const EditSubjectContainers = (props: ContainerProps) => {
  const [title, setTitle] = useState<string>(props?.subject?.title || '');
  const [teacher, setTeacher] = useState<IAllStaffs>(props?.subject?.teacher);
  // For confirmation
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const edit = async () => {
    const data = await dispatch(
      editSubject({
        subject: { title, teacher: teacher?._id },
        id: props?.subject?._id,
      })
    );
    if (!data?.error) {
      Swal.fire({
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
        title: 'Subject Edited Successfully',
      });
      props?.refetch();
    }
  };

  const del = async () => {
    await dispatch(deleteSubject(props?.subject?._id));
    props?.refetch();
    setModalOpen(false);
  };

  return (
    <div className='md:grid-cols-2 grid-cols-1 grid gap-x-4 gap-y-4 max-w-[1000px]'>
      {modalOpen && (
        <ConfirmationModal
          onConfirm={del}
          onCancel={() => {
            setModalOpen(false);
          }}
          text={`Are you sure you want to delete ${props?.subject?.title}?`}
        />
      )}
      <div className='w-full relative'>
        <input
          type='text'
          className='input-field'
          placeholder='Enter Subject'
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className='flex items-center gap-x-4 gap-y-4'>
        <div className='flex-1'>
          <UserSelectContainer
            list={props?.staffs}
            currentItem={teacher || 'Select Subject Teacher'}
            fitContent={false}
            updateWithId={(id) => {
              const teacher = props?.staffs?.find((staff) => staff?._id === id);
              setTeacher(teacher as IAllStaffs);
            }}
          />
        </div>

        <div className='flex items-center flex-1 max-w-fit min-w-fit gap-x-4 gap-y-4'>
          {/* Should be true only when it has been edited */}

          <span
            className='text-green-500 cursor-pointer text-[1.25rem]'
            title='Save'
            onClick={edit}
          >
            <BiSave />
          </span>

          <span
            className='text-red-500 cursor-pointer text-[1.25rem]'
            title='Delete'
            onClick={() => setModalOpen(true)}
          >
            <BiTrash />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditSubjectContainers;
