import React from "react";

import TableLayout, {
    SingleTableHeader,
    SingleTableRowItem,
    TableHeader,
    TableRow,
    TableRowsContainer,
} from "../Layouts/TableLayout";
import { useGetUserConversations } from "../../api/hooks/chat/chat.service";
import { BiLoaderAlt } from "react-icons/bi";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { Link, useLocation } from "react-router-dom";
import { IUser } from "../../types/db.types";
import { getChatBuddy } from "../../utils/chat.utils";
import moment from "moment";
import getAccountType from "../../utils/getAccountType";

const Conversations = () => {
    const { data, isLoading } = useGetUserConversations();
    const location = useLocation();
    const loggedInUser = getUserFromLocalStorage();
    const accountType = getAccountType(location);

    if (!isLoading && data?.length === 0) {
        return (
            <div className="w-full h-[200px] flex items-center justify-center">
                <p>Empty chat. Search users to start sending messages</p>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="w-full h-[200px] flex items-center justify-center gap-2 text-blackText">
                <BiLoaderAlt className="animate-spin" cursor={"pointer"} size={25} />
                <p>Loading messages</p>
            </div>
        );
    }

    return (
        <div className="w-full">
            <TableLayout>
                <TableHeader>
                    <SingleTableHeader text={"Full Name"} width={200} />
                    <SingleTableHeader text={"Role"} width={200} />
                    <SingleTableHeader text={""} width={200} />
                </TableHeader>

                <TableRowsContainer>
                    {data?.map((chat, index) => {
                        const user = getChatBuddy(chat, loggedInUser);
                        /**
                         * if the there's no last message, make it true
                         * if there is check if it belongs to the user
                         * if it does, check whether it is read or not
                         * else return true
                         */
                        const isRead = chat.latestMessage
                            ? chat.latestMessage.isRead.user?.toString() === loggedInUser._id?.toString()
                                ? chat.latestMessage.isRead.read
                                : true
                            : true;
                        return (
                            <Link
                                to={`/${accountType}/chat/${chat._id}`}
                                className={`border-b-[1.5px] ${isRead ? "white" : "bg-gray-100"}`}
                            >
                                <TableRow className="!border-b-0 !bg-transparent" key={index}>
                                    <SingleTableRowItem width={200}>
                                        <div className="flex items-center gap-2">
                                            <img
                                                src={user?.imageUrl}
                                                className="w-[30px] h-[30px] object-center object-cover rounded-full cursor-pointer"
                                                alt=""
                                            />
                                            <p className="font-bold cursor-pointer">
                                                {user?.firstName} {user?.lastName}
                                            </p>
                                        </div>
                                    </SingleTableRowItem>

                                    <SingleTableRowItem width={200}>
                                        <p className="capitalize"> {user?.role}</p>
                                    </SingleTableRowItem>

                                    <SingleTableRowItem width={200}>
                                        <p className="truncate">{moment(chat.updatedAt).fromNow()}</p>
                                    </SingleTableRowItem>
                                </TableRow>
                                {chat.latestMessage && (
                                    <p className="px-2 py-0">
                                        <b>
                                            {chat.latestMessage?.userId?._id?.toString() ===
                                            loggedInUser._id?.toString()
                                                ? "You: "
                                                : `${user?.firstName} ${user.lastName}: `}
                                        </b>

                                        {chat?.latestMessage?.text?.length > 50
                                            ? `${chat?.latestMessage?.text?.slice(0, 50)}...`
                                            : chat?.latestMessage?.text}
                                    </p>
                                )}
                            </Link>
                        );
                    })}
                </TableRowsContainer>
            </TableLayout>
        </div>
    );
};

export default Conversations;
