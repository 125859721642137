import React, { ChangeEvent, useEffect, useState } from "react";
import { MdOutlineClass } from "react-icons/md";
import TableLayout, {
  TableHeader,
  SingleTableHeader,
  TableRowsContainer,
  TableRow,
  SingleTableRowItem,
} from "../../../../components/Layouts/TableLayout";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import {
  IGeneratedClasses,
  IGeneratedSubjects,
} from "../../../../types/db.types";
import {
  useGetClasses,
  useGetClassesWithApprovedResults,
  useGetSingleClass,
  useGetSubjects,
} from "../../../../api/hooks/admin/generators/generators.service";
import { Link } from "react-router-dom";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import NoItem from "../../../../components/UI/NoItem";
import BackButton from "../../../../components/UI/BackButton";
import { tableHeaders } from "../../../../types/static.types";
import Input from "../../../../components/UI/Input";

const tableHeader: tableHeaders[] = [
  { text: "Student", width: 200 },
  { text: "", width: 200 },
];

const GeneratedReportCards = () => {
  const [selectedClass, setSelectedClass] =
    useState<IGeneratedClasses | null>();

  const [nextResumptionDate, setNextResumptionDate] = useState<Date | string>(
    ""
  );

  const selectClass = (classDetails: IGeneratedClasses) => {
    setSelectedClass(classDetails);
  };

  const { data: classes, isLoading } = useGetClassesWithApprovedResults();

  if ((!classes || classes?.length == 0) && !isLoading) {
    return (
      <DashboardLayout pageTitle={"Generated Subjects"}>
        <section className="w-full sm:p-6 py-6 min-h-screen">
          <NoItem title="No approved result yet" subtitle="" />
        </section>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle={"Generated Report Cards"}>
      <section className="w-full sm:p-6 py-6 min-h-screen">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 mt-8 lg:grid-cols-3 gap-x-4 gap-y-4">
          {classes?.map((singleClass, index: number) => {
            return (
              <article
                className={
                  styles.content_box +
                  ` ${
                    (((selectedClass?.className as string) +
                      selectedClass?.classArm) as string) ===
                      singleClass.className + singleClass?.classArm &&
                    "border-[#242424]"
                  }`
                }
                key={index}
                onClick={() => {
                  // make it the selected class
                  selectClass(singleClass);
                }}
              >
                <span
                  className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                >
                  <MdOutlineClass />
                </span>
                <h2 className={styles.content_box_title}>
                  {singleClass.className} {singleClass.classArm}
                </h2>
              </article>
            );
          })}
        </div>

        {selectedClass?.className && (
          <section className="w-full">
            <TableLayout>
              <TableHeader>
                {tableHeader.map((header, index) => {
                  return (
                    <SingleTableHeader
                      text={header.text}
                      key={index}
                      width={header.width}
                    />
                  );
                })}
              </TableHeader>
              <TableRowsContainer>
                {selectedClass?.students?.map((student, index: number) => {
                  return (
                    <TableRow key={index}>
                      <SingleTableRowItem width={tableHeader[0]?.width}>
                        <Link
                          to={`/admin/students/${student?._id}`}
                          className="flex items-center gap-x-2 cursor-pointer"
                        >
                          <img
                            className="w-[25px] h-[25px] rounded-full"
                            alt={student?.firstName}
                            loading="lazy"
                            src={student?.imageUrl}
                          />
                          <p className="font-bold text-[#222] cursor-pointer hover:underline">
                            {student?.firstName} {student?.lastName}
                          </p>
                        </Link>
                      </SingleTableRowItem>

                      <SingleTableRowItem width={tableHeader[1]?.width}>
                        <Link to={`/report-card/${student?._id}`}>
                          <ButtonFilled
                            text={"View Result"}
                            className="!min-w-[140px] max-w-fit ml-auto block"
                          />
                        </Link>
                      </SingleTableRowItem>
                    </TableRow>
                  );
                })}
              </TableRowsContainer>
            </TableLayout>
          </section>
        )}
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  content_box:
    "p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer",
  content_box_icon:
    "w-[30px] h-[30px] rounded-full flex justify-center items-center",
  content_box_subtitle: "text-[15px] underline text-darkGray font-[300]",
  content_box_title: "text-[1rem] font-bold text-blackText flex flex-col",
};

export default GeneratedReportCards;
