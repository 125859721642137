import React from "react";
import Feature from "./Feature";
import stock1 from "../../assets/images/stock1.png";
import stock2 from "../../assets/images/stock2.png";
import stock3 from "../../assets/images/stock3.png";
import stock4 from "../../assets/images/stock4.png";

const FeaturesContainer = () => {
  return (
    <section className="mt-[4rem] lg:mt-[6rem] max-w-[1000px] mx-auto">
      <div className="flex items-center justify-center flex-col gap-[0.5rem] mb-[3rem]">
        <h2 className="text-center text-[1.4rem] md:text-[2rem] font-bold">
          Our Offerings To You
        </h2>
        <p className="text-[1rem] max-w-[500px] text-center text-blackText">
          Here are the various benefits of Edssistance to the school and other
          parties
        </p>
      </div>

      <Feature
        order={1}
        image={stock1}
        title="School Management"
        body="Simplify administrative tasks such as attendance management, student records and transportation logistics, reducing paperword and saving time. Also, ensure accurate and up-to-date student records, helping in better decision making and compliance."
      />

      <Feature
        order={2}
        image={stock2}
        title="Teachers"
        body="Automate routine tasks like marking attendance and generating report cards, freeing up teachers to concentrate on teaching. Get access to student information and lesson notes from anywhere, facilitating lesson planning while also communicating with parent and management with ease"
      />

      <Feature
        order={1}
        image={stock3}
        title="Parents/Guardians"
        body="Experience peace of mind knowing your child's well-being and educational progress are being monitored and communicated effectively as you get real-time updates, timely notifications and report card access"
      />

      <Feature
        order={2}
        image={stock4}
        title="Students"
        body="Gain access to lesson notes, assignments and resources online, enabling self-paced learning, important documents like report cards and school announcements conviniently"
      />
    </section>
  );
};

export default FeaturesContainer;
