import React, { ReactElement } from "react";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { Navigate } from "react-router-dom";
import { openErrorModal } from "../../store/slices/others/handlerSlice";
import { useDispatch } from "react-redux";

interface Props {
  children: ReactElement | ReactElement[];
}

const ProtectedLayout = ({ children }: Props) => {
  const user = getUserFromLocalStorage();
  const dispatch = useDispatch();

  if (!user) {
    dispatch(openErrorModal({ errorText: "Login to access this route" }));
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedLayout;
