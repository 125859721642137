import React, { Dispatch, SetStateAction, useState } from "react";
import { IAllStaffs, IAllStudents } from "../../types/db.types";
import { IGenerateClass } from "../../api/thunks/admin/admin.types";
import { BiSearch, BiPlus } from "react-icons/bi";
import { GiPoloShirt } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import SelectContainer from "../../components/UI/SelectContainer";
import { Input } from "postcss";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../components/Layouts/TableLayout";
import {
  studentsHeader,
  staffsList,
  staffsHeader,
  classes,
} from "../../static/admin&teacher.data";
import { Link, useLocation } from "react-router-dom";
import { BlackOutlineButton, ButtonFilled } from "../UI/Buttons";

export type ModalProps = {
  setNewClass: Dispatch<SetStateAction<IGenerateClass>>;
  closeModal: () => void;
  newClass: IGenerateClass;
  data?: IAllStaffs[] | IAllStudents[];
  setSteps?: Dispatch<SetStateAction<number>>;
  //   For edits only
  selectedClass?: string;
  classBranch?: string;
  studentsInClass?: string[]; // pass the students id as props
  classTeacher?: string; //pass the class teacher id as props
};

const AddClassModal = (props: ModalProps) => {
  const [selectedClass, setSelectedClass] = useState<string>(
    props?.selectedClass || "Creche"
  );
  const [classBranch, setClassBranch] = useState<string>(
    props?.classBranch || ""
  );

  const location = useLocation();

  return (
    <section className="w-full min-h-screen overflow-y-scroll flex justify-center items-center fixed top-0 left-0 z-[4] bg-[rgba(0,0,0,0.5)]">
      <main className="w-[92vw] max-w-[500px] bg-white mx-auto shadow-md p-6">
        <header className="flex items-center gap-x-3 justify-between">
          <h1 className="font-[500] text-darkerGray text-[1.25rem]">
            Add Class
          </h1>
          <span
            className="text-[24px] text-red-600 cursor-pointer"
            onClick={() => {
              props.closeModal();
            }}
          >
            <MdClose />
          </span>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 mt-8">
          <div>
            <label className="text-[1rem] text-darkerGray font-light mb-2 block cursor-pointer">
              Select Class
            </label>
            <SelectContainer
              list={classes}
              currentItem={selectedClass}
              updateItem={(value: string) => {
                setSelectedClass(value);
              }}
              fitContent={false}
            />
          </div>
          <div>
            <label className="text-[1rem] text-darkerGray font-light mb-2 block cursor-pointer">
              Class Branch e.g {`("${selectedClass} Gold")`}
            </label>
            <input
              type="text"
              placeholder="Class Name"
              value={selectedClass + " " + classBranch}
              className="input-field"
              onChange={(e) => {
                setClassBranch(() => {
                  // make sure its only the value after the selected class that is changing!!!!
                  return e.target.value
                    .split(" ")
                    .filter((item, index) => index !== 0 && index !== 1)
                    .join(" ");
                });
              }}
            />
          </div>
        </div>
        <button
          className="w-full bg-blackText rounded-md text-white px-3 py-2 mt-4"
          onClick={() => {
            props.setNewClass({
              ...props.newClass,
              className: selectedClass,
              classArm: classBranch,
              // Reset students to empty array whenever class name  (not classArm) is changed
              students:
                props?.newClass?.className === selectedClass
                  ? props?.newClass?.students
                  : [],
            });
            props.closeModal();
            props?.setSteps && props.setSteps(1);
          }}
        >
          {location.pathname.includes("edit") ? "Edit Class" : "Create Class"}
        </button>
      </main>
    </section>
  );
};

const StudentsList = (props: ModalProps) => {
  const [studentAdded, setStudentAdded] = useState<string[]>(
    props?.studentsInClass || []
  );
  const addStudentToList = (id: string) => {
    setStudentAdded([...studentAdded, id]);
  };
  const removeStudent = (id: string) => {
    setStudentAdded(() => studentAdded.filter((student) => student !== id));
  };

  return (
    <>
      <div className="w-full sm:px-0 px-2">
        <TableLayout>
          <TableHeader>
            {[{ text: "", width: 50 }, ...studentsHeader].map(
              (header, index) => {
                return (
                  <h2
                    className={`font-bold p-0 m-0 py-3 px-2 flex-1 text-darkGray`}
                    style={{
                      minWidth: `${header.width}px`,
                    }}
                  >
                    {header.text}
                  </h2>
                );
              }
            )}
          </TableHeader>
          <TableRowsContainer>
            {(props?.data as IAllStudents[])
              ?.filter(
                (student) => student?.class === props?.newClass?.className
              )
              ?.map((student, index) => {
                return (
                  <TableRow key={index}>
                    <SingleTableRowItem width={50}>
                      <input
                        type={"checkbox"}
                        className="accent-blackText"
                        checked={studentAdded.includes(student._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addStudentToList(student._id);
                          } else {
                            removeStudent(student._id);
                          }
                        }}
                      />
                    </SingleTableRowItem>
                    <SingleTableRowItem width={studentsHeader[0].width}>
                      <Link
                        to={`/admin/students/${student?._id}`}
                        className="flex items-center gap-x-2 cursor-pointer"
                      >
                        <img
                          className="w-[25px] h-[25px] rounded-full"
                          alt={student?.imageUrl}
                          loading="lazy"
                          src={student?.imageUrl}
                        />
                        <p className="font-bold text-[#222] cursor-pointer hover:underline">
                          {student?.firstName} {student?.lastName}
                        </p>
                      </Link>
                    </SingleTableRowItem>
                    <SingleTableRowItem width={studentsHeader[1].width}>
                      <p>{student?.gender}</p>
                    </SingleTableRowItem>
                    <SingleTableRowItem width={studentsHeader[2].width}>
                      <p>{student?.class}</p>
                    </SingleTableRowItem>

                    <SingleTableRowItem width={studentsHeader[3].width}>
                      <p>{student?.phoneNumber}</p>
                    </SingleTableRowItem>
                    <SingleTableRowItem width={studentsHeader[4].width}>
                      <p>{student?.state}</p>
                    </SingleTableRowItem>
                  </TableRow>
                );
              })}
          </TableRowsContainer>
        </TableLayout>
      </div>
      <div className="mt-4 w-full pb-4 flex justify-between flex-wrap gap-x-2 gap-y-2">
        <BlackOutlineButton
          text="Close"
          onClick={() => {
            props.closeModal();
          }}
        />
        <ButtonFilled
          text="Add Selected Students"
          onClick={() => {
            studentAdded.length > 0 &&
              props.setNewClass({ ...props.newClass, students: studentAdded });
            props.closeModal();
            studentAdded.length > 0 && props?.setSteps && props.setSteps(2);
          }}
        />
      </div>
    </>
  );
};

const TeachersList = (props: ModalProps) => {
  const [staffAdded, setStaffAdded] = useState<string>(
    props?.classTeacher || ""
  );

  return (
    <>
      <div className="w-full sm:px-0 px-2">
        <TableLayout>
          <TableHeader>
            {[{ text: "", width: 50 }, ...staffsHeader].map((header, index) => {
              return (
                <h2
                  className={`font-bold p-0 m-0 py-3 px-2 flex-1 text-darkGray`}
                  style={{
                    minWidth: `${header.width}px`,
                  }}
                >
                  {header.text}
                </h2>
              );
            })}
          </TableHeader>
          <TableRowsContainer>
            {(props?.data as IAllStaffs[])?.map((staff, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={50}>
                    <input
                      type={"checkbox"}
                      className="accent-blackText"
                      checked={staffAdded === staff._id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setStaffAdded(staff._id);
                        } else {
                          setStaffAdded("");
                        }
                      }}
                    />
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[0].width}>
                    <Link
                      to={`/admin/staffs/${staff?._id}`}
                      className="flex items-center gap-x-2 cursor-pointer"
                    >
                      <img
                        className="w-[25px] h-[25px] rounded-full"
                        alt={staff.firstName}
                        loading="lazy"
                        src={staff.imageUrl}
                      />
                      <p className="font-bold text-[#222] cursor-pointer hover:underline">
                        {staff.firstName} {staff.lastName}
                      </p>
                    </Link>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={staffsHeader[1].width}>
                    <p>{staff.gender}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[2].width}>
                    <p>{staff?.role}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[3].width}>
                    <p>{staff.phoneNumber}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[4].width}>
                    <p>
                      {staff.city}, {staff?.state}
                    </p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </div>
      <div className="mt-4 w-full pb-4 flex justify-between flex-wrap gap-x-2 gap-y-2">
        <BlackOutlineButton
          text="Close"
          onClick={() => {
            props.closeModal();
          }}
        />
        <ButtonFilled
          text="Add Selected Teachers"
          onClick={() => {
            staffAdded &&
              props.setNewClass({
                ...props.newClass,
                classTeacher: staffAdded,
              });
            props.closeModal();
            staffAdded && props?.setSteps && props.setSteps(3);
          }}
        />
      </div>
    </>
  );
};

export { StudentsList, TeachersList, AddClassModal };
