import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../../components/UI/Buttons";
import { SingleProfileDetail } from "../../../components/Admin/StaffDetailsTabs";
import { ContentBox, ContentBoxWithAvatar } from "../../../components/UI/ContentBox";
import { MdSchool } from "react-icons/md";
import { useGetSchool } from "../../../api/hooks/admin/school/school.service";
import { GiClick } from "react-icons/gi";
import ConfirmationModal from "../../../components/UI/ConfirmationModal";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { moveToNextTerm } from "../../../api/thunks/admin/admin.service";
import { Link } from "react-router-dom";
import { addSchoolToLocalStorage, getUserFromLocalStorage } from "../../../utils/localStorage";
import { IUser } from "../../../types/db.types";

const School = () => {
    const { data, isLoading, refetch } = useGetSchool();
    const dispatch = useDispatch();

    const user = getUserFromLocalStorage() as IUser;

    // For confirmation modal
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const advance = async () => {
        const data = await dispatch(moveToNextTerm());

        if (!data?.error) {
            const payload = data?.payload?.school;
            setModalOpen(false);
            await Swal.fire({
                title: "Congratulations 🥳",
                text: `Welcome to ${payload?.term} term ${payload?.session} session, we wish you success in this new term 💪`,
                showConfirmButton: false,
                timer: 2000,
            });
            refetch();
        }
    };

    useEffect(() => {
        if (data && !isLoading) {
            addSchoolToLocalStorage(data);
        }
    }, [data]);

    return (
        <DashboardLayout pageTitle={"School"}>
            <>
                {modalOpen && (
                    <ConfirmationModal
                        onCancel={() => {
                            setModalOpen(false);
                        }}
                        onConfirm={advance}
                    >
                        <h2 className="text-[2rem] mb-4 text-center">
                            Are you sure you want to move to the next term?
                        </h2>
                        <p className="text-center italic text-blackText text-[1rem]">
                            Kindly ensure all scores and attendance have been recorded
                        </p>
                    </ConfirmationModal>
                )}
            </>
            <section className="py-6 sm:p-6 min-h-screen">
                <header className={styles.header}>
                    <div className="flex items-center gap-x-3">
                        <img
                            className="w-[150px] h-[150px] cursor-pointer rounded-full shadow-md object-fit object-cover"
                            alt={"school"}
                            src={data?.imageUrl}
                            loading="lazy"
                            onClick={() => {
                                // setImageModalOpen(true);
                            }}
                        />
                        <div className="flex-col gap-y-1">
                            <h2 className="text-[1.2rem] font-bold text-blackText mt-2">{data?.schoolName}</h2>
                            <p className="text-darkGray text-[1rem]">{data?.schoolEmail}</p>
                        </div>
                    </div>
                    {user.role === "superAdmin" && (
                        <div className=" flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap items-end justify-end">
                            <Link to={"/admin/school/edit"}>
                                <ButtonFilled text="Edit School Details" />
                            </Link>
                        </div>
                    )}
                </header>

                <div className="w-full flex gap-x-4 gap-y-4 xl:flex-row flex-col mt-8">
                    <div className="flex-[60%]">
                        <ContentBoxWithAvatar avatar={<MdSchool />} header={"School Information"} hasButton={false}>
                            <section className={styles.content_rows}>
                                <SingleProfileDetail title={"School Name"} subtitle={data?.schoolName} />
                                <SingleProfileDetail title={"School Email"} subtitle={data?.schoolEmail} />
                                <SingleProfileDetail title={"Current Session"} subtitle={data?.session} />
                                <SingleProfileDetail title={"Current Term"} subtitle={data?.term} />

                                <SingleProfileDetail title={"State"} subtitle={data?.state?.name} />
                                <SingleProfileDetail title={"City"} subtitle={data?.city} />
                                <SingleProfileDetail title={"Zip Code"} subtitle={data?.zipCode} />
                                <SingleProfileDetail title={"School Address"} subtitle={data?.address} />
                            </section>
                        </ContentBoxWithAvatar>
                    </div>
                    <div className="flex-[40%]">
                        <ContentBoxWithAvatar hasButton={false} header="Actions" avatar={<GiClick />}>
                            <div className="p-4 flex flex-wrap gap-[1rem]">
                                <ButtonFilled
                                    text="Start new term/session"
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                />
                                {user.role === "superAdmin" && (
                                    <>
                                        <Link to={"/admin/school/add-admin"}>
                                            <ButtonFilled text="Add Admin" />
                                        </Link>
                                        <Link to={"/admin/school/remove-admin"}>
                                            <ButtonFilled text="View Admins" />
                                        </Link>
                                    </>
                                )}
                            </div>
                        </ContentBoxWithAvatar>
                    </div>
                </div>
            </section>
        </DashboardLayout>
    );
};

const styles = {
    back_icon: "flex items-center gap-x-2 text-[1rem] font-bold",
    header: "mt-8 flex lg:items-center gap-x-4 gap-y-4 md:flex-row flex-col justify-between",
    content_rows: "grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 p-4 gap-x-4 gap-y-5",
};

export default School;
