import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreateSchool } from "../../../api/thunks/admin/admin.types";

const initialState: ICreateSchool = {
    schoolName: "",
    schoolEmail: "",
    schoolType: undefined,
    country: "",
    state: "",
    district: undefined,
    isPublic: false,
    city: "",
    imageUrl: "",
    address: "",
    sessionPrefix: "",
    sessionSuffix: "",
    term: "",
    zipCode: "",
    result_grading_format: "",
};

const createSchoolSlice = createSlice({
    name: "school/create",
    initialState,
    reducers: {
        updateSchoolDetails: (
            state: ICreateSchool,
            action: PayloadAction<{ key: string; value: string | boolean }>
        ) => {
            return { ...state, [action.payload.key]: action.payload.value };
        },
        updateSchool: (state: ICreateSchool, action: PayloadAction<ICreateSchool>) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { updateSchoolDetails, updateSchool } = createSchoolSlice.actions;
export const createSchoolReducer = createSchoolSlice.reducer;
