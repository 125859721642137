import React, { ChangeEvent, useState } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import { GiVote } from 'react-icons/gi';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { sendMessage } from '../../api/thunks/admin/admin.service';
import { ContentBoxWithAvatar } from '../UI/ContentBox';
import Input from '../UI/Input';

const Contact = () => {
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const dispatch = useDispatch();

  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = await dispatch(sendMessage({ subject, message }));

    if (!data?.error) {
      Swal.fire({
        icon: 'success',
        title: 'Message Sent',
        text: 'Thanking for reaching out to edssistance, we have received your message, and we will review it as soon as possible',
        showConfirmButton: false,
        timer: 4000,
      });

      setMessage('');
      setSubject('');
    }
  };

  return (
    <ContentBoxWithAvatar
      header='Send edssistance your suggestion'
      avatar={<GiVote />}
      hasButton={false}
    >
      <form onSubmit={onSubmit} className='w-full rounded-md p-4'>
        <div className='mb-2'>
          <label className='mb-1 block' htmlFor='subject'>
            Subject
          </label>
          <Input
            type='text'
            value={subject}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSubject(e.target.value)
            }
            placeholder='Enter subject'
            name='subject'
          />
        </div>
        <div className='mb-2'>
          <label className='mb-1 block' htmlFor='message'>
            Message
          </label>
          <textarea
            className='w-full input-field min-h-[150px] resize-none'
            value={message}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setMessage(e.target.value)
            }
            placeholder='Enter your message'
            name='message'
          />
        </div>

        <button
          type='submit'
          className='mt-4 p-2 w-full rounded-md bg-blackText text-white text-center'
        >
          Send{' '}
          <span className='inline-block ml-2 align-middle'>
            <BiPaperPlane />
          </span>
        </button>
      </form>
    </ContentBoxWithAvatar>
  );
};

export default Contact;
