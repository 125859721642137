import React, { useState } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import Step4 from "../../../components/Registration/Step4";
import {
  AddAdmin,
  AddStaffAsAdmin,
} from "../../../components/Admin/AddSchoolAdmin";
import ToggleSwitch from "../../../components/UI/ToggleSwitch";

const AddSchoolAdmins = () => {
  const [process, setProcess] = useState<"Admin" | "Staff">("Admin");

  const toggleProcess = () =>
    setProcess(process === "Admin" ? "Staff" : "Admin");

  return (
    <DashboardLayout pageTitle={"Add Admin"}>
      <section className="w-full py-6 sm:p-6 min-h-screen">
        <div className="flex items-center gap-x-4 w-full justify-center font-bold mt-2 mb-[2rem]">
          <p>Admin</p>
          <ToggleSwitch
            istoggled={process === "Staff"}
            onClick={toggleProcess}
          />
          <p>Staff</p>
        </div>

        {process === "Admin" ? <AddAdmin /> : <AddStaffAsAdmin />}
      </section>
    </DashboardLayout>
  );
};

export default AddSchoolAdmins;
