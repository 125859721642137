import React, { useEffect, useState } from "react";
import { AiOutlineRotateLeft } from "react-icons/ai";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  useGetClassScores,
  useGetSingleClass,
  useGetStudentsAverage,
} from "../../../../api/hooks/admin/generators/generators.service";
import { gradeStudents } from "../../../../api/thunks/teacher/teacher.service";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import SelectContainer from "../../../../components/UI/SelectContainer";
import {
  IClassScores,
  IGeneratedSubjects,
  ISchoolResultGradingFormat,
  IStudentScores,
  IUser,
} from "../../../../types/db.types";
import {
  getAllSubjects,
  getAverage,
} from "../../../../utils/result-calculator";
import { getUserFromLocalStorage } from "../../../../utils/localStorage";
import useStoreResultAverage from "../../../../utils/hooks/useStoreResultAverage";

/**
 *
 * @logic - get all the studnets and store them in a state with extra field passed: boolean
 * Use the rearranged students to list the button
 */

export interface IStudentResult {
  studentId: string;
  passed: boolean;
}

const rearrangeStudents = (allScores: IClassScores[]): IStudentResult[] => {
  const allStudents = allScores.map((score) => {
    return { studentId: score.studentId._id, passed: true };
  });

  return allStudents;
};

const ThirdTerm = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isAdminPage = location.pathname.includes("admin");

  const averagesEndpoint = isAdminPage
    ? "/school/class-average"
    : "/teacher/class-average";

  const { classId } = useParams();

  const { data: classDetail } = useGetSingleClass(classId as string);

  const { data: averages } = useGetStudentsAverage(
    averagesEndpoint,
    classId as string
  );

  const { subjects, classScores } = useStoreResultAverage(
    classId as string,
    isAdminPage
  );

  const [students, setStudents] = useState<IStudentResult[]>([]);

  const school = (getUserFromLocalStorage() as IUser)?.school;

  const getSubjectIndex = (subjectId: string) =>
    subjects.findIndex((subject) => subject._id === subjectId);

  const sortScore = (score: IStudentScores[]) => {
    const scoresSorted = score.sort(
      (scoreA, scoreB) =>
        getSubjectIndex(scoreA.subjectId._id) -
        getSubjectIndex(scoreB.subjectId._id)
    );

    return scoresSorted;
  };

  const getFirstTermAverage = (studentId: string) => {
    const average = averages?.firstTerm?.results?.find(
      (result) => result.studentId._id === studentId
    );

    return average;
  };
  const getSecondTermAverage = (studentId: string) => {
    const average = averages?.secondTerm?.results?.find(
      (result) => result.studentId._id === studentId
    );

    return average;
  };

  const getTotalAverage = (
    firstTerm: number,
    secondTerm: number,
    thirdTerm: number
  ) => {
    let average: number;
    if (!firstTerm && !secondTerm) {
      average = thirdTerm;
    } else if (!firstTerm && secondTerm) {
      average = (secondTerm + thirdTerm) / 2;
    } else {
      average = (firstTerm + secondTerm + thirdTerm) / 3;
    }

    return (average = parseFloat(average.toFixed(2)));
  };

  const submit = async () => {
    const results = await dispatch(gradeStudents(students));

    if (!results.error) {
      await Swal.fire({
        title: `${classDetail?.className} ${classDetail?.classArm}`,
        text: "Students results have been uploaded successfully",
        icon: "success",
        showConfirmButton: false,
      });
    }
  };

  const toggleStudentStatus = (studentId: string) => {
    setStudents((prev) =>
      prev.map((student) => {
        if (student.studentId === studentId) {
          student.passed = !student.passed;
        }

        return student;
      })
    );
  };

  useEffect(() => {
    if (classScores) {
      setStudents(rearrangeStudents(classScores));
    }
  }, [classScores]);

  return (
    <DashboardLayout
      pageTitle={`${classDetail?.className} ${classDetail?.classArm} 3rd term results`}
    >
      <section className="sm:p-6 py-6 min-h-screen">
        <div className="mb-4 flex items-start gap-[1rem]">
          <img
            src={school?.imageUrl}
            className="w-[50px] h-[50px] rounded-full object-cover object-center"
            alt=""
          />
          <div>
            <p className="font-bold">{school?.schoolName}</p>
            <p className="text-[14px]">{school?.address}</p>
          </div>
        </div>
        <p className="mb-3 sm:hidden ">
          {" "}
          <span className="text-[24px] text-white bg-blackText w-[34px] h-[34px] mr-2 align-middle inline-flex items-center justify-center rounded-full">
            <AiOutlineRotateLeft />
          </span>{" "}
          Kindly tilt your device or use a larger screen for a better view
        </p>

        <div className="flex flex-row items-start mt-8">
          {/* This part is not scrollable */}
          <div className="flex flex-col items-center min-w-[150px] max-w-[150px]  border-r-[2.5px] border-blackText">
            <div className="h-[50px] w-full">
              <h2>Student Name</h2>
            </div>
            <div className="h-[50px] w-full"></div>
            {/* list the students */}
            {classScores?.map((score, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center gap-x-2 h-[50px] w-full cursor-pointer border-b-[2.5px] border-blackText"
                >
                  <img
                    className="w-[25px] h-[25px] rounded-full"
                    alt={score.studentId?.firstName}
                    loading="lazy"
                    src={score?.studentId?.imageUrl}
                  />
                  <p className="font-bold text-[#222] cursor-pointer hover:underline">
                    {score?.studentId?.firstName} {score?.studentId?.lastName}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            className="overflow-x-scroll w-full flex-1"
            style={{
              width: isAdminPage ? `calc(100% - 150px)` : `calc(100% - 150px)`,
            }}
          >
            {/* Map all the subjects first */}
            <div className="flex h-[50px] min-w-fit">
              {subjects?.map((subject, index) => {
                return (
                  <div
                    key={index}
                    className="text-white bg-blackText border-r-[2.5px] h-[50px] flex items-center justify-center"
                    style={{
                      width:
                        school.result_grading_format ===
                        ISchoolResultGradingFormat._70_30
                          ? 300
                          : 240,
                    }}
                  >
                    {subject.title}
                  </div>
                );
              })}
              {/* Extra column for the average */}
              <div className="text-white bg-blackText border-r-[2.5px] w-[240px] h-[50px] flex items-center justify-center">
                Average
              </div>{" "}
              <div className="text-white bg-blackText border-r-[2.5px] w-[200px] h-[50px] text-center flex items-center justify-center">
                Cummulative Average
              </div>
            </div>

            {/* Map the scores for each exam */}
            <div className="flex h-[50px] min-w-fit border-b-[2.5px] border-b-blackText">
              {subjects?.map((_, index) => {
                return (
                  <div key={index} className="h-[50px] flex">
                    {/* display each score*/}
                    <span className={styles.singleScore}>
                      1<sup>st</sup> C/A
                    </span>
                    <span className={styles.singleScore}>
                      2<sup>nd</sup> C/A
                    </span>
                    {school.result_grading_format ===
                      ISchoolResultGradingFormat._70_30 && (
                      <span className={styles.singleScore}>
                        3<sup>rd</sup> C/A
                      </span>
                    )}
                    <span className={styles.singleScore}>Exam</span>
                    <span
                      className={
                        styles.singleScore + " border-r-[2.5px] font-bold"
                      }
                    >
                      Total
                    </span>
                  </div>
                );
              })}

              {/* Extra row for the average */}
              <div className={styles.singleScore + " !w-[80px] font-bold"}>
                1<sup>st</sup> Term
              </div>
              <div className={styles.singleScore + " !w-[80px] font-bold"}>
                2<sup>nd</sup> Term
              </div>

              <div className={styles.singleScore + " !w-[80px] font-bold"}>
                3<sup>rd</sup> Term
              </div>

              <div
                className={
                  styles.singleScore + " !w-[200px] min-w-[200px] font-bold"
                }
              >
                (1<sup>st</sup> + 2<sup>nd</sup> + 3<sup>rd</sup>)/3
              </div>
            </div>

            {/* Map the score for each student */}
            <div className="flex flex-col min-w-fit">
              {classScores?.map((score, index) => {
                const scoresSorted = sortScore(score.scores);

                const studentAverage = getAverage(
                  subjects?.length,
                  score.scores
                );

                const firstTerm = getFirstTermAverage(score.studentId._id);
                const secondTerm = getSecondTermAverage(score.studentId._id);

                return (
                  <div
                    className="flex h-[50px] min-w-fit border-b-[2.5px] border-b-blackText"
                    key={index}
                  >
                    {scoresSorted?.map((score, index) => {
                      return (
                        <React.Fragment key={index}>
                          <span className={styles.singleScore}>
                            {score.firstCA}
                          </span>
                          <span className={styles.singleScore}>
                            {score.secondCA}
                          </span>
                          {school.result_grading_format ===
                            ISchoolResultGradingFormat._70_30 && (
                            <span className={styles.singleScore}>
                              {score.thirdCA}
                            </span>
                          )}
                          <span className={styles.singleScore}>
                            {score.examScore}
                          </span>
                          <span
                            className={
                              styles.singleScore + " border-r-[2.5px] font-bold"
                            }
                          >
                            {score.firstCA +
                              score.secondCA +
                              score.thirdCA +
                              score.examScore}
                          </span>
                        </React.Fragment>
                      );
                    })}

                    <>
                      {/* 1st term */}
                      <div
                        className={
                          styles.singleScore +
                          " !w-[80px] min-w-[80px] font-bold"
                        }
                      >
                        <p>
                          {firstTerm
                            ? `${firstTerm.average.toFixed(2)}%`
                            : "N/A"}
                        </p>
                      </div>

                      {/* 2nd term */}

                      <div
                        className={
                          styles.singleScore +
                          " !w-[80px] min-w-[80px] font-bold"
                        }
                      >
                        <p>
                          {secondTerm
                            ? `${secondTerm.average.toFixed(2)}%`
                            : "N/A"}
                        </p>
                      </div>

                      <div
                        className={
                          styles.singleScore +
                          " !w-[80px] min-w-[80px] font-bold"
                        }
                      >
                        <p>{studentAverage.toFixed(2)}%</p>
                      </div>

                      <div
                        className={
                          styles.singleScore +
                          " !w-[200px] min-w-[200px] font-bold"
                        }
                      >
                        <p>
                          {getTotalAverage(
                            firstTerm?.average as number,
                            secondTerm?.average as number,
                            studentAverage
                          )}
                          %
                        </p>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col min-w-[120px] max-w-[120px] items-center pt-[100px] gap-2">
            {students?.map((student, index: number) => {
              // get student's full details

              const studentDetails = classScores?.find(
                (score) => score.studentId._id === student.studentId
              )?.studentId;

              // display an editable select container for the teacher's screen and a div for the admin

              return (
                <>
                  {isAdminPage ? (
                    <div
                      className={`p-2 text-[0.9rem] w-full rounded-md uppercase font-bold text-center max-w-[100px] ${
                        studentDetails?.teacherResultDecision === "pass"
                          ? "bg-green-500"
                          : studentDetails?.teacherResultDecision === "fail"
                          ? "bg-red-600"
                          : "bg-blackText"
                      } text-white shadow-md`}
                    >
                      {studentDetails?.teacherResultDecision}
                    </div>
                  ) : (
                    <StatusSelector
                      key={index}
                      passed={student.passed}
                      updateStudent={() =>
                        toggleStudentStatus(student.studentId)
                      }
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
        <>
          {!isAdminPage && (
            <div className="mt-8 flex flex-row justify-end w-full">
              <ButtonFilled text="Approve Results" onClick={submit} />
            </div>
          )}
        </>
      </section>
    </DashboardLayout>
  );
};

const StatusSelector = (props: {
  passed: boolean;
  updateStudent: Function;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="max-w-[100px] text-center cursor-pointer relative">
      <div
        className="input-field w-full text-center cursor-pointer"
        onClick={toggleAccordion}
      >
        <p
          className={`inline-block font-bold ${
            props.passed ? "text-green-500" : "text-red-500"
          }`}
        >
          {props.passed ? "PASS" : "FAIL"}
        </p>
        <span className="inline-block ml-1 align-middle text-[20px]">
          {!isOpen ? <BiChevronDown /> : <BiChevronUp />}
        </span>
      </div>
      {isOpen && (
        <div
          className={`w-full input-field !border-none shadow-md absolute top-[105%] left-0 z-[4] font-bold ${
            props.passed ? "!text-red-500" : "!text-green-500"
          }`}
          onClick={() => {
            props.updateStudent();
            setIsOpen(false);
          }}
        >
          {props.passed ? "FAIL" : "PASS"}
        </div>
      )}
    </div>
  );
};

const styles = {
  // 60px from 300/5
  singleScore:
    "flex-1 border-r-[1.5px] border-r-blackText h-[50px] flex items-center justify-center w-[60px] text-[0.8rem]",
};

export default ThirdTerm;
