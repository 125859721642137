import React, { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { InputFields, StaffFormProps } from "../../types/componentsProps.types";
import { countryList } from "../../assets/static";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
    addNewDocument,
    removeDocument,
    updateDocumentDetails,
    updateNextOfKinDetails,
    updateRoleDetails,
    updateStaffDetails,
} from "../../store/slices/post/addStaffMemberSlice";
import SelectContainer from "../UI/SelectContainer";
import { OutlineButton } from "../UI/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../UI/Input";
import { useFileUpload } from "../../utils/hooks/file-upload";
import { GiCancel } from "react-icons/gi";
import { monthsType } from "../../types/static.types";
import { MdCancel } from "react-icons/md";
import { validateAddStaff } from "../../utils/formRequired";
import { openErrorModal } from "../../store/slices/others/handlerSlice";
import { positions } from "../../static/admin&teacher.data";
import { getYearsInterval } from "../../utils/dateConverter";

const months: monthsType[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const StaffForm: FC<StaffFormProps> = ({ firstStepTitle, secondStepTitle, onSubmit }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [years, setYears] = useState<number[]>([]);
    const addStaffSlice = useSelector((state: RootState) => state.addStaff);
    const uploadFile = useFileUpload();
    const dispatch = useDispatch();
    const editStaffDetails = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateStaffDetails({ key: e.target.name, value: e.target.value }));
    };
    const editStaffRole = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateRoleDetails({ key: e.target.name, value: e.target.value }));
    };
    const updateMonth = (value: string) => {
        dispatch(updateStaffDetails({ key: "monthOfEmployment", value }));
    };
    const updateYear = (value: string) => {
        dispatch(updateStaffDetails({ key: "yearOfEmployment", value }));
    };
    const updateGender = (value: string) => {
        dispatch(updateStaffDetails({ key: "gender", value }));
    };
    const updateReligion = (value: string) => {
        dispatch(updateStaffDetails({ key: "religion", value }));
    };
    const updateCountry = (value: string) => {
        dispatch(updateStaffDetails({ key: "country", value }));
    };

    const updatePosition = (value: string) => {
        dispatch(updateRoleDetails({ key: "position", value }));
    };
    const updateClass = (value: string) => {
        dispatch(updateRoleDetails({ key: "class", value }));
    };
    const editNextOfKinDetails = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateNextOfKinDetails({ key: e.target.name, value: e.target.value }));
    };

    const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (currentStep === 1) {
            setCurrentStep(2);
        } else {
            // Display some custom errors that cannot be displayed by the default form element
            const error = validateAddStaff(addStaffSlice);
            if (!error) {
                onSubmit?.();
            } else {
                dispatch(openErrorModal({ errorText: error }));
            }
        }
    };

    useEffect(() => {
        // create an array of all the years from 1990 to this year
        const years = getYearsInterval(1990);
        setYears(years);
    }, []);

    return (
        <section className="w-full mt-8 pb-8">
            <div className="w-full flex justify-between items-center gap-x-4 gap-y-4 flex-wrap">
                <div>
                    <h1 className="font-bold text-[1.25rem] mb-1 text-blackText">
                        {currentStep === 1 ? firstStepTitle : secondStepTitle}
                    </h1>
                    <p>Kindly provide the required details</p>
                </div>

                <div className="flex items-center flex-nowrap">
                    <div
                        className={
                            styles.step1Container +
                            ` ${currentStep === 2 ? "after:bg-blackText" : "after:bg-lightGray"}`
                        }
                    >
                        <span className={styles.step + " border-blackText"}>1</span>
                        <p className="max-w-[50px] text-[14px] text-center leading-[1.1]">Profile Set-up</p>
                    </div>
                    <div className="ml-[80px]">
                        <span
                            className={
                                styles.step +
                                ` ${currentStep === 2 ? "border-blackText" : "bg-lightGray text-gray-600"}`
                            }
                        >
                            2
                        </span>
                        <p className="max-w-[50px] text-[14px] text-center leading-[1.1]">Role Details</p>
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="w-full border-[1.5px] rounded-md pt-8 pb-4 mt-8">
                {currentStep === 1 && (
                    <div className="w-full max-w-[800px] mx-auto">
                        <article className="px-6">
                            <h1 className={styles.formSectionTitle}>Personal Information</h1>

                            <div className={styles.gridContainer}>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        First Name
                                    </label>
                                    <Input
                                        value={addStaffSlice.firstname}
                                        onChange={editStaffDetails}
                                        type={"text"}
                                        name={"firstname"}
                                        placeholder={"First Name"}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Middle Name {"(optional)"}
                                    </label>
                                    <Input
                                        value={addStaffSlice.middlename}
                                        onChange={editStaffDetails}
                                        type={"text"}
                                        name={"middlename"}
                                        placeholder={"Middle Name"}
                                        required={false}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Last Name
                                    </label>
                                    <Input
                                        value={addStaffSlice.lastname}
                                        onChange={editStaffDetails}
                                        type={"text"}
                                        name={"lastname"}
                                        placeholder={"Last Name"}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Date Of Birth
                                    </label>
                                    <Input
                                        value={addStaffSlice.dob}
                                        onChange={editStaffDetails}
                                        type={"date"}
                                        name={"dob"}
                                        placeholder={"Date of birth (MM-DD-YYYY)"}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Gender
                                    </label>
                                    <SelectContainer
                                        list={["male", "Female"]}
                                        currentItem={addStaffSlice.gender}
                                        fitContent={true}
                                        updateItem={updateGender}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Religion
                                    </label>
                                    <SelectContainer
                                        list={["christianity", "islam", "others"]}
                                        currentItem={addStaffSlice.religion || "Select Religion"}
                                        fitContent={true}
                                        updateItem={updateReligion}
                                    />
                                </div>
                            </div>
                        </article>
                        <article className="px-6">
                            <h1 className={styles.formSectionTitle}>Contact Information</h1>

                            <div className={styles.gridContainer}>
                                {!pathname.includes("edit") && (
                                    <>
                                        <div>
                                            <label htmlFor="" className={styles.labelText}>
                                                Email (optional)
                                            </label>
                                            <Input
                                                type={"email"}
                                                value={addStaffSlice.email}
                                                onChange={editStaffDetails}
                                                placeholder={"Email Address"}
                                                name={"email"}
                                                required={false}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="" className={styles.labelText}>
                                                Phone Number
                                            </label>
                                            <Input
                                                type={"number"}
                                                value={addStaffSlice.phoneNumber}
                                                onChange={editStaffDetails}
                                                placeholder={"Phone Number"}
                                                name={"phoneNumber"}
                                                isPhoneNumber={true}
                                            />
                                        </div>
                                    </>
                                )}

                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Country
                                    </label>
                                    <SelectContainer
                                        list={countryList}
                                        fitContent={false}
                                        currentItem={addStaffSlice.country || "Select Country"}
                                        updateItem={updateCountry}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        State
                                    </label>
                                    <Input
                                        type={"text"}
                                        value={addStaffSlice.state}
                                        onChange={editStaffDetails}
                                        placeholder={"State"}
                                        name={"state"}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        City
                                    </label>
                                    <Input
                                        type={"text"}
                                        value={addStaffSlice.city}
                                        onChange={editStaffDetails}
                                        placeholder={"City"}
                                        name={"city"}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Address
                                    </label>
                                    <Input
                                        type={"text"}
                                        value={addStaffSlice.address}
                                        onChange={editStaffDetails}
                                        placeholder={"Address"}
                                        name={"address"}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className={styles.labelText}>Profile Picture</p>

                                    <label htmlFor="file" className="input-field w-full block">
                                        {addStaffSlice?.picture && typeof addStaffSlice?.picture !== "string"
                                            ? (addStaffSlice.picture as File)?.name.slice(0, 30)
                                            : "Upload Picture"}
                                    </label>
                                    <input
                                        type="file"
                                        className="hidden"
                                        id="file"
                                        accept="image/*"
                                        // required
                                        onChange={(e) => {
                                            uploadFile(e, updateStaffDetails, "picture");
                                        }}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Month of employment
                                    </label>
                                    <SelectContainer
                                        list={months}
                                        currentItem={addStaffSlice.monthOfEmployment || "Month of employment"}
                                        fitContent={false}
                                        updateItem={updateMonth}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Year of employment
                                    </label>
                                    <SelectContainer
                                        list={years}
                                        currentItem={addStaffSlice.yearOfEmployment || "Year of employment"}
                                        fitContent={false}
                                        updateItem={updateYear}
                                    />
                                </div>
                            </div>
                        </article>

                        <article className="px-6">
                            <h1 className={styles.formSectionTitle}>Next of Kin information</h1>

                            <div className={styles.gridContainer}>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Name
                                    </label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={addStaffSlice.nextOfKin.name}
                                        placeholder="Enter Name"
                                        onChange={editNextOfKinDetails}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Phone Number
                                    </label>
                                    <Input
                                        type="number"
                                        name="phoneNumber"
                                        value={addStaffSlice.nextOfKin.phoneNumber}
                                        placeholder="Enter Phone Number"
                                        onChange={editNextOfKinDetails}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Address
                                    </label>
                                    <Input
                                        type="text"
                                        name="address"
                                        value={addStaffSlice.nextOfKin.address}
                                        placeholder="Enter Address"
                                        onChange={editNextOfKinDetails}
                                    />
                                </div>
                            </div>
                        </article>
                        <article className="px-6">
                            <h1 className={styles.formSectionTitle}>Employment Documents</h1>
                            <div className={styles.gridContainer}>
                                {addStaffSlice?.documents?.map((doc, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className="w-full flex relative">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Document Name"
                                                    value={doc.name}
                                                    className="input-field w-full"
                                                    onChange={(e) => {
                                                        dispatch(
                                                            updateDocumentDetails({
                                                                id: doc.id,
                                                                key: "name",
                                                                value: e.target.value,
                                                            })
                                                        );
                                                    }}
                                                />
                                                <span
                                                    className="absolute right-[10px] top-[50%] -translate-y-[50%] text-[1.25rem] cursor-pointer"
                                                    onClick={() => {
                                                        // don't remove it if it is the last document
                                                        addStaffSlice.documents.length > 1 &&
                                                            dispatch(removeDocument({ id: doc.id }));
                                                    }}
                                                >
                                                    <MdCancel />
                                                </span>
                                            </div>
                                            <label htmlFor="document" className="cursor-pointer block  input-field">
                                                {doc?.file
                                                    ? (doc?.file as File)?.name
                                                        ? (doc?.file as File)?.name?.slice(0, 30)
                                                        : (doc?.file as string)?.slice(0, 30)
                                                    : "Upload Document"}
                                            </label>
                                            <input
                                                type="file"
                                                className="hidden"
                                                id="document"
                                                onChange={(e) => {
                                                    const file = e?.target?.files?.[0];
                                                    dispatch(
                                                        updateDocumentDetails({
                                                            id: doc.id,
                                                            key: "file",
                                                            value: file as File,
                                                        })
                                                    );
                                                }}
                                            />
                                        </Fragment>
                                    );
                                })}
                                <button
                                    className="py-2 px-4 rounded-md bg-black -full text-white"
                                    type="button"
                                    onClick={() => {
                                        dispatch(addNewDocument());
                                    }}
                                >
                                    Add new document
                                </button>
                            </div>
                        </article>
                    </div>
                )}
                {currentStep === 2 && (
                    <div className="w-full max-w-[800px] mx-auto">
                        <article className="px-6">
                            <div className={styles.gridContainer}>
                                <div>
                                    <label htmlFor="" className={styles.labelText}>
                                        Position
                                    </label>
                                    <SelectContainer
                                        list={[...positions, "Others"]}
                                        currentItem={addStaffSlice.role.position || "Position"}
                                        fitContent={false}
                                        updateItem={updatePosition}
                                    />
                                </div>
                                {addStaffSlice.role.position === "Others" && (
                                    <div>
                                        <label className={styles.labelText}>Specify Position</label>
                                        <Input
                                            type="text"
                                            value={addStaffSlice.role.otherPosition}
                                            name="otherPosition"
                                            placeholder="Kindly specify the position"
                                            onChange={editStaffRole}
                                        />
                                    </div>
                                )}
                                <div>
                                    <p className={styles.labelText}>Signature</p>
                                    <label htmlFor="signature" className="input-field block">
                                        {addStaffSlice?.role?.signature &&
                                        typeof addStaffSlice?.role?.signature !== "string"
                                            ? (addStaffSlice.role?.signature as File)?.name.slice(0, 30)
                                            : "Upload Signature"}
                                    </label>
                                    <input
                                        type="file"
                                        className="hidden"
                                        id="signature"
                                        accept="image/*"
                                        // required
                                        onChange={(e) => {
                                            uploadFile(e, updateRoleDetails, "signature");
                                        }}
                                    />
                                </div>
                            </div>
                        </article>
                    </div>
                )}

                <div
                    className={`w-full flex items-center ${
                        currentStep === 1 ? "sm:justify-end" : "sm:justify-between"
                    } justify-center mt-8 gap-x-4 gap-y-4 flex-wrap pt-6 border-t-[1.5px] px-6`}
                >
                    <button
                        className={styles.buttons + " text-blackText"}
                        type="button"
                        onClick={() => {
                            if (currentStep === 1) {
                                navigate("/admin/staffs");
                            } else {
                                setCurrentStep(1);
                            }
                        }}
                    >
                        {currentStep === 1 ? "Cancel" : "Previous"}
                    </button>
                    <button className={styles.buttons + " text-white bg-blackText"} type="submit">
                        {currentStep === 1 ? "Continue" : "Submit"}
                    </button>
                </div>
            </form>
        </section>
    );
};

const styles = {
    step1Container:
        "relative after:w-[93px] after:h-[2px] after:top-[25%] after:-translate-y-[50%] after:left-[90%]  after:absolute after:z-[2]",
    step: "w-[30px] h-[30px] border-[1.5px] rounded-full flex justify-center items-center mx-auto mb-1 font-bold",
    formSectionTitle: "text-[19px] font-bold text-blackText mb-3 mt-3",
    gridContainer: "w-full grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-4",
    buttons:
        "px-4 py-2 flex items-center border-[1.5px] border-blackText rounded-md font-bold gap-x-4 flex-nowrap sm:min-w-[150px] text-center justify-center w-full sm:w-fit",
    labelText: "block mb-2 font-bold text-blackText",
};

export default StaffForm;
