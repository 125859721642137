import { useQuery } from "react-query";
import { baseHttp } from "../../../../axios.config";
import { IStaffsFetch } from "./staffs.types";
import { IAllStaffs, IUser } from "../../../../types/db.types";

export const useGetStaffs = (gender?: string, position?: string) => {
  const { data, isLoading, isError, refetch } = useQuery<IStaffsFetch>({
    queryKey: ["admin/get-staffs"],
    queryFn: async () => {
      const { data } = await baseHttp.get(
        `/school/staffs?${gender ? `gender=${gender}&` : ""}${
          position ? `role=${position}` : ""
        }`
      );
      return data;
    },
  });

  return {
    isLoading,
    isError,
    refetch,
    data: data as IStaffsFetch,
  };
};

export const useGetTeachers = () => {
  const { data, isLoading, isError, refetch } = useQuery<IStaffsFetch>({
    queryKey: ["admin/get-staffs"],
    queryFn: async () => {
      const { data } = await baseHttp.get("/school/teachers");
      return data;
    },
  });

  return {
    isLoading,
    isError,
    refetch,
    data: data as IStaffsFetch,
  };
};

// If a teacher wants to make a request to get his/her profile, id isn't needed
export const useGetSingleStaff = (id?: string) => {
  const { data, isLoading, isError, refetch } = useQuery<IAllStaffs>({
    queryKey: ["admin/get-single-staff"],
    queryFn: async () => {
      const { data } = await baseHttp.get(
        id ? "/school/staff/" + id : "/teacher/profile"
      );
      return id ? data?.staff : data?.teacher;
    },
  });

  return {
    isLoading,
    isError,
    refetch,
    data,
  };
};

export const useGetStaffWithoutAdminAccess = () => {
  const { data, isLoading } = useQuery<IAllStaffs[]>({
    queryKey: "admin/getstaffwithout admin access",
    queryFn: async () => {
      const response = await baseHttp.get("/school/staff/not-admin");

      return response.data?.staff;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetStaffWithAdminAccess = () => {
  const { data, isLoading, refetch } = useQuery<IAllStaffs[]>({
    queryKey: "admin/getstaffwithout admin access",
    queryFn: async () => {
      const response = await baseHttp.get("/school/staff/admin");

      return response.data?.staff;
    },
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetAdmins = () => {
  const { data, isLoading, refetch } = useQuery<IUser[]>({
    queryKey: "admin/get-admins",
    async queryFn() {
      const response = await baseHttp.get("/school/get-admins");

      return response.data?.admins;
    },
  });

  return { data, isLoading, refetch };
};
