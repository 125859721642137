import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ButtonFilled } from "../UI/Buttons";

const links = [
  { text: "Home", href: "/" },
  { text: "About", href: "/about" },
  { text: "FAQs", href: "/faq" },
  { text: "Contact Us", href: "/contact" },
  { text: "Sign Up", href: "/register" },
];

export const DesktopNavLinks = () => {
  const { pathname } = useLocation();

  return (
    <ul className="items-center gap-[1rem] hidden md:flex">
      {links?.map((link, index) => {
        return (
          <Link to={link.href} className="font-bold" key={index}>
            {link?.text}

            {pathname === link?.href && (
              <hr className="border-blackText border-[1.5px]"></hr>
            )}
          </Link>
        );
      })}

      <Link to={"/login"}>
        <ButtonFilled text="Login" className="!min-w-[150px] ml-4" />
      </Link>
    </ul>
  );
};

interface MobileNavLinksProps {
  isOpen: boolean;
}

export const MobileNavLinks = ({ isOpen }: MobileNavLinksProps) => {
  const { pathname } = useLocation();

  return (
    <ul
      className={`md:hidden flex items-center justify-center flex-col gap-y-[1rem] fixed top-0 left-0 z-[5] h-screen w-full bg-[rgba(0,0,0,.92)] transition-all duration-500  ${
        isOpen ? "translate-x-0 opacity-100" : "translate-x-[-100%] opacity-50"
      }`}
    >
      {links?.map((link, index) => {
        return (
          <li key={index} className="text-[1.25rem] text-white">
            <Link to={link?.href}>{link?.text}</Link>

            {pathname === link?.href && (
              <hr className="border-white border-[1.5px]"></hr>
            )}
          </li>
        );
      })}

      <Link to={"/login"}>
        <ButtonFilled
          text="Login"
          className="!min-w-[200px] bg-white !text-blackText !text-[1.25rem]"
        />
      </Link>
    </ul>
  );
};
