import React, { useState } from "react";
import { countryList } from "../../../assets/static";
import StaffForm from "../../../components/Admin/StaffForm";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { InputFields } from "../../../types/componentsProps.types";
import { useDispatch } from "react-redux";
import { addStaff } from "../../../api/thunks/admin/admin.service";
import { useNavigate } from "react-router-dom";
import { resetStaffFields } from "../../../store/slices/post/addStaffMemberSlice";
import Swal from "sweetalert2";
import { IAddOption } from "../Students/AddStudent";
import StaffBulkForm from "../../../components/Admin/StaffBulkForm";
import StaffBulkUpload from "./BulkUpload";

const AddStaff = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selection, setSelection] = useState<IAddOption>(IAddOption.individual);
    const updateSelection = (selection: IAddOption) => {
        setSelection(selection);
    };

    const onSubmit = async () => {
        const data = await dispatch(addStaff());
        if (!data?.error) {
            navigate("/admin/staffs");
            await Swal.fire({
                title: "Add Staff",
                text: "Staff account has been created successfully",
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });
            dispatch(resetStaffFields());
        }
    };

    return (
        <DashboardLayout
            pageTitle={
                <>
                    Staff Memebers/
                    <span className="text-blackText font-bold">Add Staff</span>
                </>
            }
            documentTitle={"Add Staff"}
        >
            <section>
                <header className="w-full flex items-center gap-x-4 gap-y-4 justify-start flex-wrap mt-6">
                    <div
                        className={styles.radio_container}
                        onClick={() => {
                            updateSelection(IAddOption.individual);
                        }}
                    >
                        <span className={`radio ${selection === IAddOption.individual && "checked"}`}></span>
                        Individual
                    </div>
                    <div
                        className={styles.radio_container}
                        onClick={() => {
                            updateSelection(IAddOption.bulk);
                        }}
                    >
                        <span className={`radio ${selection === IAddOption.bulk && "checked"}`}></span>
                        Bulk
                    </div>
                </header>
                {selection === IAddOption.individual && (
                    <StaffForm firstStepTitle="Profile Set-Up" secondStepTitle="Role Details" onSubmit={onSubmit} />
                )}

                {selection === IAddOption.bulk && <StaffBulkUpload />}
            </section>
        </DashboardLayout>
    );
};

const styles = {
    radio_container: "flex items-center gap-x-2 flex-nowrap text-[1.25rem] text-blackText font-bold cursor-pointer",
};

export default AddStaff;
