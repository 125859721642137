import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetClassScores,
  useGetSingleClass,
  useGetStudentsAverage,
} from "../../../../api/hooks/admin/generators/generators.service";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import {
  IGeneratedSubjects,
  ISchoolResultGradingFormat,
  IStudentScores,
  IUser,
} from "../../../../types/db.types";
import {
  getAllSubjects,
  getAverage,
} from "../../../../utils/result-calculator";
import { getUserFromLocalStorage } from "../../../../utils/localStorage";
import useStoreResultAverage from "../../../../utils/hooks/useStoreResultAverage";

const SecondTerm = () => {
  const location = useLocation();
  const { classId } = useParams();

  const isAdminPage = location.pathname.includes("admin");

  const { data } = useGetSingleClass(classId as string);

  const { subjects, classScores } = useStoreResultAverage(
    classId as string,
    isAdminPage
  );

  const averagesEndpoint = isAdminPage
    ? "/school/class-average"
    : "/teacher/class-average";

  const { data: averages } = useGetStudentsAverage(
    averagesEndpoint,
    classId as string
  );

  const school = (getUserFromLocalStorage() as IUser)?.school;

  const getSubjectIndex = (subjectId: string) =>
    subjects.findIndex((subject) => subject._id === subjectId);

  const sortScore = (score: IStudentScores[]) => {
    const scoresSorted = score.sort(
      (scoreA, scoreB) =>
        getSubjectIndex(scoreA.subjectId._id) -
        getSubjectIndex(scoreB.subjectId._id)
    );

    return scoresSorted;
  };

  const getFirstTermAverage = (studentId: string) => {
    const average = averages?.firstTerm?.results?.find(
      (result) => result.studentId._id === studentId
    );

    return average;
  };

  return (
    <DashboardLayout
      pageTitle={`${data?.className} ${data?.classArm} 2nd term results`}
    >
      <section className="sm:p-6 py-6 min-h-screen">
        <div className="mb-4 flex items-start gap-[1rem]">
          <img
            src={school?.imageUrl}
            className="w-[50px] h-[50px] rounded-full object-cover object-center"
            alt=""
          />
          <div>
            <p className="font-bold">{school?.schoolName}</p>
            <p className="text-[14px]">{school?.address}</p>
          </div>
        </div>

        <div className="flex flex-row items-start mt-8">
          {/* This part is not scrollable */}
          <div className="flex flex-col items-center w-[150px] max-w-[150px]  border-r-[2.5px] border-blackText">
            <div className="h-[50px] w-full">
              <h2>Student Name</h2>
            </div>
            <div className="h-[50px] w-full"></div>
            {/* list the students */}
            {classScores?.map((score, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center gap-x-2 h-[50px] w-full cursor-pointer border-b-[2.5px] border-blackText"
                >
                  <img
                    className="w-[25px] h-[25px] rounded-full"
                    alt={score.studentId?.firstName}
                    loading="lazy"
                    src={score?.studentId?.imageUrl}
                  />
                  <p className="font-bold text-[#222] cursor-pointer hover:underline">
                    {score?.studentId?.firstName} {score?.studentId?.lastName}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            className="overflow-x-scroll"
            style={{
              width: `calc(100% - 150px)`,
            }}
          >
            {/* Map all the subjects first */}
            <div className="flex h-[50px] min-w-fit">
              {subjects?.map((subject, index) => {
                return (
                  <div
                    key={index}
                    className="text-white bg-blackText border-r-[2.5px] h-[50px] flex items-center justify-center"
                    style={{
                      width:
                        school.result_grading_format ===
                        ISchoolResultGradingFormat._70_30
                          ? 300
                          : 240,
                    }}
                  >
                    {subject.title}
                  </div>
                );
              })}

              {/* Extra column for the average */}
              <div className="text-white bg-blackText border-r-[2.5px] w-[150px] h-[50px] flex items-center justify-center">
                Average
              </div>
            </div>

            {/* Map the scores for each exam */}
            <div className="flex h-[50px] min-w-fit border-b-[2.5px] border-b-blackText">
              {subjects?.map((_, index) => {
                return (
                  <div key={index} className="h-[50px] flex">
                    {/* display each score*/}
                    <span className={styles.singleScore}>
                      1<sup>st</sup> C/A
                    </span>
                    <span className={styles.singleScore}>
                      2<sup>nd</sup> C/A
                    </span>
                    {school.result_grading_format ===
                      ISchoolResultGradingFormat._70_30 && (
                      <span className={styles.singleScore}>
                        3<sup>rd</sup> C/A
                      </span>
                    )}
                    <span className={styles.singleScore}>Exam</span>
                    <span
                      className={
                        styles.singleScore + " border-r-[2.5px] font-bold"
                      }
                    >
                      Total
                    </span>
                  </div>
                );
              })}

              {/* Extra row for the average */}
              <div className={styles.singleScore + " !w-[75px] font-bold"}>
                1<sup>st</sup> Term
              </div>
              <div className={styles.singleScore + " !w-[75px] font-bold"}>
                2<sup>nd</sup> Term
              </div>
            </div>

            {/* Map the score for each student */}
            <div className="flex flex-col min-w-fit">
              {classScores?.map((score, index) => {
                const scoresSorted = sortScore(score.scores);

                const studentAverage = getAverage(
                  subjects?.length,
                  score.scores
                );

                const firstTerm = getFirstTermAverage(score.studentId._id);

                return (
                  <div
                    className="flex h-[50px] min-w-fit border-b-[2.5px] border-b-blackText"
                    key={index}
                  >
                    {scoresSorted?.map((score, index) => {
                      return (
                        <React.Fragment key={index}>
                          <span className={styles.singleScore}>
                            {score.firstCA}
                          </span>
                          <span className={styles.singleScore}>
                            {score.secondCA}
                          </span>
                          {school.result_grading_format ===
                            ISchoolResultGradingFormat._70_30 && (
                            <span className={styles.singleScore}>
                              {score.thirdCA}
                            </span>
                          )}
                          <span className={styles.singleScore}>
                            {score.examScore}
                          </span>
                          <span
                            className={
                              styles.singleScore + " border-r-[2.5px] font-bold"
                            }
                          >
                            {score.firstCA +
                              score.secondCA +
                              score.thirdCA +
                              score.examScore}
                          </span>
                        </React.Fragment>
                      );
                    })}

                    <>
                      {/* 1st term */}
                      <div
                        className={
                          styles.singleScore +
                          " !w-[75px] min-w-[75px] font-bold"
                        }
                      >
                        <p>{firstTerm?.average.toFixed(2)}%</p>
                      </div>

                      {/* 2nd term */}
                      <div
                        className={
                          styles.singleScore +
                          " !w-[75px] min-w-[75px] font-bold"
                        }
                      >
                        <p>{studentAverage.toFixed(2)}%</p>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  // 60px from 300/5
  singleScore:
    "flex-1 border-r-[1.5px] border-r-blackText h-[50px] flex items-center justify-center w-[60px] text-[0.8rem]",
};

export default SecondTerm;
