import React from 'react';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import Contact from '../../components/Settings/Contact';
import FontEditor from '../../components/Settings/FontEditor';

const TeacherSettings = () => {
  return (
    <DashboardLayout pageTitle='Settings'>
      <section className='w-full py-6 md:p-6'>
        <div className='grid grid-cols-1 gap-3 lg:grid-cols-2'>
          <FontEditor />
          <Contact />
        </div>
      </section>
    </DashboardLayout>
  );
};

export default TeacherSettings;
