import { Location } from "react-router-dom";

enum IAccounts {
  ADMIN = "admin",
  PARENT = "parent",
  TEACHER = "teacher",
  STUDENT = "student",
}

const getAccountType = (location: Location): IAccounts => {
  if (location.pathname.includes("/admin")) {
    return IAccounts.ADMIN;
  } else if (location.pathname.includes("/parent")) {
    return IAccounts.PARENT;
  } else if (location.pathname.includes("/teacher")) {
    return IAccounts.TEACHER;
  } else {
    return IAccounts.STUDENT;
  }
};

export default getAccountType;
