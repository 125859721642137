import React, { useState } from "react";
import { BiLoader, BiLoaderAlt, BiSearch } from "react-icons/bi";
import { useGetChatBuddies } from "../../api/hooks/chat/chat.service";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { accessChat } from "../../api/thunks/chat/chat.service";
import { useLocation, useNavigate } from "react-router-dom";
import getAccountType from "../../utils/getAccountType";
const UserSearch = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: users,
    isLoading,
    refetch,
    isRefetching,
  } = useGetChatBuddies(searchValue, setIsOpen);

  const onClick = async (user_id: string) => {
    const data = await dispatch(accessChat(user_id));

    const accountType = getAccountType(location);

    if (!data?.error) {
      navigate(`/${accountType}/chat/${data?.payload}`);
    }
  };

  return (
    <div className="relative my-6 max-w-[700px] ml-auto">
      <div className="">
        <input
          type="text"
          placeholder='Try "Ademola Osanyin"'
          className={styles.search_input}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      {isOpen && (
        <div className="absolute top-[105%] w-full bg-white rounded-sm shadow-md ">
          <header className="flex items-end justify-end p-2">
            <MdClose
              color="darkred"
              size={24}
              cursor="pointer"
              onClick={() => setIsOpen(false)}
            />
          </header>

          {(isLoading || isRefetching) && (
            <div className="text-blackText flex gap-2 items-center justify-center">
              <BiLoaderAlt className="animate-spin" />
              <p>Fetching users</p>
            </div>
          )}

          <div className="mt-2">
            {users &&
              users?.length > 0 &&
              users?.map((user, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => onClick(user._id as string)}
                    className="flex items-center gap-2 justify-between p-2 cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center gap-2 max-w-[80%]">
                      <img
                        src={user?.imageUrl}
                        className="w-[30px] h-[30px] rounded-full object-center object-cover"
                        alt=""
                      />
                      <p className="truncate">
                        {user?.firstName} {user.lastName}
                      </p>
                    </div>

                    <p className="capitalize">{user?.role}</p>
                  </div>
                );
              })}

            {(!users || users.length === 0) && !isLoading && (
              <div className="pb-2 flex items-center justify-center">
                <p>No User Found</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  search_input:
    "w-full outline-none  h-[35px] px-4 flex-1 placeholder:text-darkGray bg-white border-[1.5px] focus:!border-blackText rounded-md",
  search_icon:
    "text-white font-bold bg-blackText rounded-md px-2 h-[35px] cursor-pointer flex items-center justify-center",
};

export default UserSearch;
