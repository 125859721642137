import React, { FC, ReactElement, useEffect, useState } from "react";
import { MdAdminPanelSettings, MdOutlineDashboard, MdQuiz, MdSchool, MdWorkOutline } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { FiUsers, FiCalendar, FiUser } from "react-icons/fi";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { GiHamburgerMenu, GiMoneyStack } from "react-icons/gi";
import { BiBus, BiChat, BiChevronDown, BiLogOut, BiNotepad } from "react-icons/bi";
import { RiBankFill, RiParentLine, RiStore2Line } from "react-icons/ri";
import { BsBank, BsBellFill, BsGear } from "react-icons/bs";
import { Navigate, useLocation } from "react-router-dom";
import DesktopSidebar from "../Sidebars/DesktopSidebar";
import MobileSidebar from "../Sidebars/MobileSidebar";
import logo from "../../assets/images/logo.png";
import { Squash } from "hamburger-react";
import { getStaffFromLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import { logout } from "../../api/thunks/auth/auth.service";
import { useDispatch } from "react-redux";
import { changeFontSize, openErrorModal } from "../../store/slices/others/handlerSlice";
import BackButton from "../UI/BackButton";
import Step2 from "../Registration/Step2";
import { ButtonFilled } from "../UI/Buttons";
import NoSchool from "../UI/NoSchool";
import ProtectedLayout from "./ProtectedLayout";
import BellIcon from "../Chat/BellIcon";
const adminLinks = [
    { icon: <MdSchool />, text: "My School", href: "/admin/school" },
    // { icon: <MdOutlineDashboard />, text: 'Dashboard', href: '/admin/dashboard' },
    { icon: <FaRegUser />, text: "Staff Members", href: "/admin/staffs" },
    { icon: <FiUsers />, text: "Students", href: "/admin/students" },
    { icon: <RiParentLine />, text: "Parents", href: "/admin/parents" },
    { icon: <BiChat />, text: "Message", href: "/admin/chat" },
    // { icon: <FiCalendar />, text: "Calendar", href: "/admin/calendar" },

    // { icon: <AiOutlinePlusSquare />, text: "Sick bay", href: "/admin/sick-bay" },
    { icon: <MdWorkOutline />, text: "Generator", href: "/admin/generator" },
    { icon: <GiMoneyStack />, text: "Bursary", href: "/admin/bursary" },
    { icon: <BsBank />, text: "Billing", href: "/admin/billing" },
    // { icon: <RiStore2Line />, text: "Store", href: "/admin/store" },
    { icon: <BsGear />, text: "Settings", href: "/admin/settings" },
];
const studentLinks = [
    { icon: <FaRegUser />, text: "Profile", href: "/student/profile" },
    { icon: <FiUsers />, text: "Classmates", href: "/student/classmates" },
    { icon: <FiUsers />, text: "Teachers", href: "/student/teachers" },
    { icon: <BiChat />, text: "Message", href: "/student/chat" },
    { icon: <MdQuiz />, text: "Quiz", href: "/student/quiz" },
    // { icon: <FiCalendar />, text: "Calendar", href: "/student/calendar" },
    { icon: <BsGear />, text: "Settings", href: "/student/settings" },
];

const parentLinks = [
    { icon: <FaRegUser />, text: "Profile", href: "/parent/profile" },
    { icon: <FiUsers />, text: "Child(ren)/Ward(s)", href: "/parent/kids" },
    { icon: <BiChat />, text: "Message", href: "/parent/chat" },
    // { icon: <RiStore2Line />, text: "Store", href: "/parent/store" },
    // { icon: <FiCalendar />, text: "Calendar", href: "/parent/calendar" },
    { icon: <BsGear />, text: "Settings", href: "/parent/settings" },
];

const teacherLinks = [
    { icon: <FaRegUser />, text: "Profile", href: "/teacher/profile" },
    { icon: <MdOutlineDashboard />, text: "My Class", href: "/teacher/class" },
    { icon: <FiUsers />, text: "Classes", href: "/teacher/teachings" },
    { icon: <BiChat />, text: "Message", href: "/teacher/chat" },
    // { icon: <FiCalendar />, text: "Calendar", href: "/teacher/calendar" },
    { icon: <BsGear />, text: "Settings", href: "/teacher/settings" },
];

// These are the pages that shouldn't have back buttons because they appear immediately after login
const initialPages = ["/admin/dashboard", "/parent/kids", "/teacher/profile", "student/profile"];

type DashboardProps = {
    children?: ReactElement[] | ReactElement;
    pageTitle: string | ReactElement;
    documentTitle?: string;
};
const DashboardLayout: FC<DashboardProps> = ({ children, pageTitle, documentTitle }) => {
    const location = useLocation();

    const user = getUserFromLocalStorage();
    const staff = getStaffFromLocalStorage();

    const fontSize = Number(localStorage.getItem("font-size"));

    const dispatch = useDispatch();

    const getLinks = () => {
        const pathname = location.pathname;

        if (pathname.startsWith("/admin")) {
            return adminLinks;
        } else if (pathname.startsWith("/parent")) {
            return parentLinks;
        } else if (pathname.startsWith("/student")) {
            return studentLinks;
        } else if (pathname.startsWith("/teacher")) {
            let links = teacherLinks;
            if (staff?.adminAccess) {
                links = [
                    teacherLinks[0],
                    {
                        icon: <FiUsers />,
                        href: "/teacher/add-student",
                        text: "Add Student",
                    },
                    teacherLinks[1],
                    teacherLinks[2],
                    teacherLinks[3],
                ];
            } else {
                links = teacherLinks;
            }

            return links;
        }
    };
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
    const [links, setLinks] = useState<any>(getLinks());

    const prevPage = () => window.history.back();

    useEffect(() => {
        document.title = documentTitle ? `Edssistance | ${documentTitle}` : `Edssistance | ${pageTitle}`;
    }, []);

    useEffect(() => {
        setLinks(getLinks());
        if (typeof fontSize === "number") {
            dispatch(changeFontSize(fontSize));
        }
    }, [location.pathname]);

    return (
        <ProtectedLayout>
            <section className="w-full min-h-screen bg-white font-questrial">
                {/* Sidebar */}
                <MobileSidebar links={links} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <DesktopSidebar links={links} />
                <div className="lg:ml-[280px] sm:ml-[80px] ml-0">
                    <nav className="w-full p-4 border-b-[1.5px] flex items-center gap-x-3 justify-between">
                        <div className="flex items-center gap-x-3">
                            <span className="text-[1.25rem] sm:hidden cursor-pointer">
                                <Squash toggled={false} size={23} toggle={setSidebarOpen} />
                            </span>
                            <h1
                                className={`text-[24px] font-bold ${
                                    typeof pageTitle === "string" ? "#text-[#444]" : "text-[#666]"
                                } sm:block hidden`}
                            >
                                {pageTitle}
                            </h1>
                        </div>
                        <div className="flex items-center gap-x-3 sm:gap-x-5">
                            <BellIcon />
                            <div className="relative">
                                {/* Use this guy to toggle the user modal */}
                                <div
                                    className="flex items-center gap-x-3 cursor-pointer"
                                    onClick={() => {
                                        setUserModalOpen((prev) => !prev);
                                    }}
                                >
                                    <span className="font-bold bg-blue-200 text-blue-600 w-[35px] h-[35px] rounded-full flex justify-center items-center">
                                        {user?.firstName?.[0]}
                                        {user?.lastName?.[0]}
                                    </span>
                                    <div className="sm:block hidden">
                                        <h3 className="leading-[1] text-[1rem] font-bold">
                                            {user?.firstName} {user?.lastName}
                                        </h3>
                                        <p className="text-[14px] text-gray-700 capitalize">
                                            {user?.role === "superAdmin" ? "admin" : user?.role}
                                        </p>
                                    </div>
                                    <span className="text-[25px]">
                                        <BiChevronDown />
                                    </span>
                                </div>
                                {userModalOpen && (
                                    <aside className="absolute animate-fade top-[103%] right-0 w-[90vw] max-w-[300px] bg-white z-[3] shadow-md rounded-md p-5 min-h-[200px] flex flex-col justify-between items-start">
                                        {/* Display User Info */}
                                        <div className="flex items-center gap-x-4 gap-y-4 flex-wrap">
                                            <div className="min-w-[80px] h-[80px] rounded-full shadow-md border-4 border-white overflow-hidden">
                                                <div className="bg-blue-200 text-blue-600 w-full h-full text-[2rem] flex items-center justify-center">
                                                    {user?.firstName?.[0]}
                                                    {user?.lastName?.[0]}
                                                </div>
                                            </div>
                                            {/* User Info */}
                                            <div className="flex-1">
                                                <h1 className="font-bold text-blackText text-[1.5rem]">
                                                    {user?.firstName} {user?.lastName}
                                                </h1>

                                                <p className="capitalize text-darkerGray">
                                                    {user?.role === "superAdmin" ? "Admin" : user?.role}
                                                </p>
                                            </div>
                                        </div>

                                        {/* Logout button */}
                                        <button className="text-[1.2rem]" onClick={logout}>
                                            <span className="inline-block pr-2 align-middle">
                                                <BiLogOut />
                                            </span>{" "}
                                            Log out
                                        </button>
                                    </aside>
                                )}
                            </div>
                        </div>
                    </nav>
                    <main className="py-8 px-6 bg-mainBg">
                        {/* <img src={logo} alt="School Name" className="w-[160px] mx-6" /> */}
                        <h1
                            className={`text-[26px] font-bold mb-4 sm:hidden ${
                                typeof pageTitle === "string" ? "#text-[#444]" : "text-[#666]"
                            }`}
                        >
                            {pageTitle}
                        </h1>

                        {!initialPages.includes(location.pathname) && (
                            <section className="pt-6 sm:px-6 w-full">
                                <BackButton onClick={prevPage} />
                            </section>
                        )}
                        {user?.school ? children : <NoSchool />}
                    </main>
                </div>
            </section>
        </ProtectedLayout>
    );
};

export default DashboardLayout;
