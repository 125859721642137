import { Link, useLocation, useParams } from "react-router-dom";
import { ButtonFilled } from "../../UI/Buttons";
import { CashPayment } from "./CashPayment";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { PayAdviceModal } from "./PayAdviceModal";
import Transactions from "./Transactions";

export const TuitionDetails = () => {
  /**
   * @desc - The view pay advice button should open a modal that displays the payadvice of the student
   * If the paycash is opened display a view that lists the pay advice items for the student and  allows the admin to select what he wants to pay for, the items to be listed are those the child's parent hasn't paid for, i.e the partial and unpaid!!!
   * @IMPORTANT - Fetch the payadvice for the student!!!
   */

  const location = useLocation();
  const dispatch = useDispatch();
  const slug = useParams();

  const [payAdviceModalOpen, setPayAdviceModalOpened] =
    useState<boolean>(false);
  const [payCashOpened, setPayCashOpened] = useState<boolean>(false);

  return (
    <>
      {payAdviceModalOpen && (
        <PayAdviceModal setPayAdviceModalOpened={setPayAdviceModalOpened} />
      )}

      <div className="pt-2 flex items-center gap-x-4 gap-y-4 flex-wrap">
        <ButtonFilled
          text="View Pay Advice"
          onClick={() => {
            setPayAdviceModalOpened(true);
          }}
        />
        {location.pathname.includes("/admin") && (
          <ButtonFilled
            text="Pay Cash"
            onClick={() => {
              setPayCashOpened(true);
            }}
          />
        )}
        {location.pathname.includes("/parent") && (
          <Link to={`/parent/kid/${slug?.id}/payment`}>
            <ButtonFilled text="Make Payment" />
          </Link>
        )}
      </div>
      {payCashOpened && <CashPayment />}

      <Transactions />
    </>
  );
};
