import React from "react";
import stock1 from "../../assets/images/stock1.png";

interface FeatureProps {
  order: 1 | 2;
  image: string;
  title: string;
  body: string;
}

const Feature = ({ order, image, title, body }: FeatureProps) => {
  return (
    <article
      className={`flex gap-x-[4rem] gap-y-[1.5rem] md:my-[2.5rem] my-[2rem] items-center flex-col-reverse ${
        order === 1 ? "md:flex-row" : "md:flex-row-reverse"
      }`}
    >
      <div className="flex-[.6]">
        <img
          src={image}
          alt={title}
          className="w-full object-center object-cover"
        />
      </div>
      <div className="flex-[.4]">
        <h2 className="font-bold md:text-[1.4rem] text-[1.2rem] md:mb-8 mb-2">
          {title}
        </h2>
        <p className="text-[.9rem] leading-[2] text-darkGray">{body}</p>
      </div>
    </article>
  );
};

export default Feature;
