import profileImg from "../assets/images/profile-img.jpg";
import { tableHeaders } from "../types/static.types";

export const kidsHeaders: tableHeaders[] = [
  { text: "Full Name", width: 200 },
  { text: "Class", width: 120 },
  { text: "Location", width: 120 },
];

export const kids = [
  {
    name: "Adedamola Joseph",
    class: "JSS 2",
    location: "Abuja",
    image: profileImg,
  },
  {
    name: "Adedamola Joseph",
    class: "JSS 2",
    location: "Abuja",
    image: profileImg,
  },
  {
    name: "Adedamola Joseph",
    class: "JSS 2",
    location: "Abuja",
    image: profileImg,
  },
  {
    name: "Adedamola Joseph",
    class: "JSS 2",
    location: "Abuja",
    image: profileImg,
  },
  {
    name: "Adedamola Joseph",
    class: "JSS 2",
    location: "Abuja",
    image: profileImg,
  },
  {
    name: "Adedamola Joseph",
    class: "JSS 2",
    location: "Abuja",
    image: profileImg,
  },
];
