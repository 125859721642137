import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/UI/NavBar";
import { ErrorModal, Loader } from "./components/UI/Handlers";
import { CreateSchoolModal } from "./components/UI/CreateSchoolModal";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            {/* <NavBar/> */}
            <Provider store={store}>
                <App />
                <Loader />
                <ErrorModal />
                <CreateSchoolModal />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
