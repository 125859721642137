import React from "react";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";
import { IAffectiveDomains, tableHeaders } from "../../types/static.types";
import { BiCheck } from "react-icons/bi";

const fullWidth = 296;

const headers: tableHeaders[] = [
  { text: "AFFECTIVE DOMAIN", width: fullWidth * 0.6 },
  { text: "5", width: fullWidth * 0.08 },
  { text: "4", width: fullWidth * 0.08 },
  { text: "3", width: fullWidth * 0.08 },
  { text: "2", width: fullWidth * 0.08 },
  { text: "1", width: fullWidth * 0.08 },
];

interface Props {
  affectiveDomains: IAffectiveDomains;
}

const AffectiveDomain = (props: Props) => {
  const { _id, ...rest } = { ...(props.affectiveDomains || {}) };

  return (
    <div className="max-h-fit">
      <TableLayout>
        <TableHeader>
          {headers?.map((header, index) => {
            return (
              <SingleTableHeader
                key={index}
                bg="#181619"
                color="white"
                width={header.width}
                text={<p className="text-[.8rem]">{header.text}</p>}
              />
            );
          })}
        </TableHeader>

        <TableRowsContainer>
          {Object.entries(rest).map((item, index) => {
            return (
              <TableRow key={index}>
                <SingleTableRowItem width={headers[0].width} noPadding={true}>
                  <p className="font-bold capitalize text-[.75rem] border-r-2 py-2 p-2">
                    {item[0]}
                  </p>
                </SingleTableRowItem>
                <SingleTableRowItem width={headers[1].width} noPadding={true}>
                  <p className={styles.checkbox}>
                    {item[1] === 5 && <BiCheck />}
                  </p>
                </SingleTableRowItem>

                <SingleTableRowItem width={headers[2].width} noPadding={true}>
                  <p className={styles.checkbox}>
                    {item[1] === 4 && <BiCheck />}
                  </p>
                </SingleTableRowItem>

                <SingleTableRowItem width={headers[3].width} noPadding={true}>
                  <p className={styles.checkbox}>
                    {item[1] === 3 && <BiCheck />}
                  </p>
                </SingleTableRowItem>

                <SingleTableRowItem width={headers[4].width} noPadding={true}>
                  <p className={styles.checkbox}>
                    {item[1] === 2 && <BiCheck />}
                  </p>
                </SingleTableRowItem>

                <SingleTableRowItem width={headers[5].width} noPadding={true}>
                  <p className={styles.checkbox}>
                    {item[1] === 1 && <BiCheck />}
                  </p>
                </SingleTableRowItem>
              </TableRow>
            );
          })}
        </TableRowsContainer>
      </TableLayout>
    </div>
  );
};

const styles = {
  checkbox:
    "font-bold text-[1rem] border-r-2 text-green-500 h-full flex items-center justify-center",
};

export default AffectiveDomain;
