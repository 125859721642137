import { useQuery } from "react-query";
import React from "react";
import { baseHttp } from "../../../../axios.config";
import {
  closePreloader,
  openPreloader,
} from "../../../../store/slices/others/handlerSlice";
import { store } from "../../../../store/store";
import {
  IClassAverage,
  IClassScores,
  IGeneratedClasses,
  IGeneratedPayAdvice,
  IGeneratedSubjects,
} from "../../../../types/db.types";

export const useGetClasses = () => {
  const dispatch = store.dispatch;

  const { data, isLoading, refetch } = useQuery<IGeneratedClasses[]>({
    queryKey: "admin/get-classes",
    queryFn: async () => {
      dispatch(openPreloader({ loadingText: "Fetching" }));
      try {
        const { data } = await baseHttp.get("/school/generator/classes");
        dispatch(closePreloader());
        return data?.classes;
      } catch (error) {
        dispatch(closePreloader());
      }
    },
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetSingleClass = (classId: string) => {
  const { data, isLoading, refetch } = useQuery<IGeneratedClasses>({
    queryKey: "admin/get-class",
    queryFn: async () => {
      const { data } = await baseHttp.get(
        "/school/generator/get-class/" + classId
      );
      return data?.classDetail;
    },
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetSubjects = (id: string) => {
  const dispatch = store.dispatch;

  const { data, isLoading, refetch } = useQuery<IGeneratedSubjects[]>({
    queryKey: "admin/get-subjects",

    queryFn: async () => {
      try {
        if (id) {
          const { data } = await baseHttp.get(
            "/school/generator/subjects/" + id
          );

          return data?.subjects;
        } else {
          return [];
        }
      } catch (error) {}
    },

    keepPreviousData: true,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetPayAdivce = (classId: string) => {
  const { data, refetch, isLoading } = useQuery<IGeneratedPayAdvice[]>({
    queryKey: "admin/get-pay-advice",
    queryFn: async () => {
      if (classId) {
        const response = await baseHttp.get(
          "/school/generator/pay-advice/" + classId
        );

        return response.data?.payAdvice;
      } else {
        return [];
      }
    },
    keepPreviousData: true,
  });

  return {
    data,
    refetch,
    isLoading,
  };
};

export const useGetClassScores = (url: string, classId: string) => {
  const { data, refetch, isLoading } = useQuery<IClassScores[]>({
    queryKey: "admin/get-class-scores",
    queryFn: async () => {
      const dispatch = store.dispatch;

      dispatch(openPreloader({ loadingText: "Fetching class result..." }));
      const response = await baseHttp.get(`${url}/${classId}`);
      dispatch(closePreloader());
      return response?.data?.scores;
    },
    enabled: !!classId,
  });

  React.useEffect(() => {
    if (classId) refetch();
  }, [classId]);

  return { data, refetch, isLoading };
};

export const useGetStudentsAverage = (url: string, classId: string) => {
  const { data, refetch, isLoading } = useQuery<{
    firstTerm?: IClassAverage;
    secondTerm?: IClassAverage;
  }>({
    queryKey: "admin/get-class-average",
    queryFn: async () => {
      const response = await baseHttp.get(`${url}/${classId}`);

      return response?.data;
    },
  });

  return { data, refetch, isLoading };
};

export const useGetClassesWithApprovedResults = () => {
  const { data, isLoading, refetch } = useQuery<IGeneratedClasses[]>({
    queryKey: "admin/classes-with-approved-results",
    queryFn: async () => {
      const response = await baseHttp.get("/school/result/approved/class");

      return response?.data?.data;
    },
  });

  return { data, isLoading, refetch };
};

export const useGetClassesWithoutApprovedResults = () => {
  const { data, isLoading, refetch } = useQuery<IGeneratedClasses[]>({
    queryKey: "admin/classes-without-approved-results",
    queryFn: async () => {
      const response = await baseHttp.get("/school/result/not-approved/class");

      return response?.data?.data;
    },
  });

  return { data, isLoading, refetch };
};
