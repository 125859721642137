import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSingleStaff } from '../../../api/hooks/admin/staffs/staffs.service';
import { editStaff } from '../../../api/thunks/admin/admin.service';
import { countryList } from '../../../assets/static';
import StaffForm from '../../../components/Admin/StaffForm';
import DashboardLayout from '../../../components/Layouts/DashboardLayout';
import {
  fetchStaffDetails,
  resetStaffFields,
} from '../../../store/slices/post/addStaffMemberSlice';
import { InputFields } from '../../../types/componentsProps.types';
import { IAllStaffs } from '../../../types/db.types';

const EditStaff = () => {
  const [selection, setSelection] = useState<'Bulk' | 'Individual'>(
    'Individual'
  );
  const updateSelection = (selection: 'Bulk' | 'Individual') => {
    setSelection(selection);
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useGetSingleStaff(id);

  const dispatch = useDispatch();

  const onSubmit = async () => {
    const data = await dispatch(editStaff(id));
    if (!data?.error) {
      navigate('/admin/staffs');
      // to make sure the state doesn't persist
      dispatch(resetStaffFields());
    }
  };

  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchStaffDetails(data as IAllStaffs));
    }
  }, [data]);

  return (
    <DashboardLayout
      pageTitle={
        <>
          Staff Memebers/
          <span className='text-blackText font-bold'>Edit Staff Profile</span>
        </>
      }
      documentTitle={'Add Staff'}
    >
      <section>
        {/* <header className="w-full flex items-center gap-x-4 gap-y-4 justify-start flex-wrap">
          <div
            className={styles.radio_container}
            onClick={() => {
              updateSelection("Individual");
            }}
          >
            <span
              className={`radio ${selection === "Individual" && "checked"}`}
            ></span>
            Individual
          </div>
          <div
            className={styles.radio_container}
            onClick={() => {
              updateSelection("Bulk");
            }}
          >
            <span
              className={`radio ${selection === "Bulk" && "checked"}`}
            ></span>
            Bulk
          </div>
        </header> */}
        <StaffForm
          firstStepTitle='Edit Staff Profile'
          secondStepTitle='Edit Staff Profile'
          onSubmit={onSubmit}
        />
      </section>
    </DashboardLayout>
  );
};

const styles = {
  radio_container:
    'flex items-center gap-x-2 flex-nowrap text-[1.25rem] text-blackText font-bold cursor-pointer',
};

export default EditStaff;
