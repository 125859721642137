import {
  IClassScores,
  IGeneratedSubjects,
  IStudentScores,
} from "../types/db.types";

export const getAverage = (
  subjectsLength: number,
  studentScores: IStudentScores[]
): number => {
  let scores = 0;

  studentScores.forEach((score) => {
    scores += score.firstCA + score.secondCA + score.thirdCA + score.examScore;
  });

  const average: number = scores / subjectsLength;

  return average;
};

export const computeAllStudentsAverage = (
  subjectsLength: number,
  classScores: IClassScores[]
) => {
  const averages: { studentId: string; average: number }[] = [];

  classScores.forEach((score) => {
    averages.push({
      studentId: score.studentId._id,
      average: getAverage(subjectsLength, score.scores),
    });
  });

  return averages;
};

export const getAllSubjects = (data: IClassScores) => {
  const subjects: IGeneratedSubjects[] = [];
  data?.scores?.forEach((score) => {
    subjects.push(score.subjectId);
  });

  return subjects;
};
