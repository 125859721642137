import React, { useState } from "react";
import { FaFileInvoice } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { GiShirt, GiGears } from "react-icons/gi";
import { MdLoop } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";

const Generators = () => {
  return (
    <DashboardLayout pageTitle={"Generator"}>
      <section className="sm:p-6 py-6">
        <div className="flex items-center gap-x-8 gap-y-8 flex-wrap">
          <article className={styles.content_box}>
            <h2 className={styles.content_box_title}>
              Generate{" "}
              <span className="align-middle inline-block ml-1">
                <BsGearFill />
              </span>
            </h2>
            <div className="flex flex-1 items-end justify-between w-full flex-wrap gap-x-2 gap-y-2">
              <Link
                to={"/admin/generator/class"}
                className={styles.content_box_subtitle}
              >
                Classes
              </Link>
              <Link
                to={"/admin/generator/pay-advice"}
                className={styles.content_box_subtitle}
              >
                Pay Advice
              </Link>

              <Link
                to={"/admin/generator/subject"}
                className={styles.content_box_subtitle}
              >
                Subjects
              </Link>

              <Link
                to={"/admin/generator/report-card"}
                className={styles.content_box_subtitle}
              >
                Report Card
              </Link>
            </div>
          </article>

          <article className={styles.content_box}>
            <h2 className={styles.content_box_title}>
              Generated{" "}
              <span className="align-middle inline-block ml-1">
                <GiGears />
              </span>
            </h2>
            <div className="flex items-end flex-1 justify-between w-full gap-x-2 gap-y-2 flex-wrap">
              <Link
                to={"/admin/generator/generated/class"}
                className={styles.content_box_subtitle}
              >
                Classes
              </Link>
              <Link
                to={"/admin/generator/generated/pay-advice"}
                className={styles.content_box_subtitle}
              >
                Pay Advice
              </Link>
              <Link
                to={"/admin/generator/generated/subject"}
                className={styles.content_box_subtitle}
              >
                Subjects
              </Link>

              <Link
                to={"/admin/generator/generated/report-card"}
                className={styles.content_box_subtitle}
              >
                Report Card
              </Link>
            </div>
          </article>
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  content_box:
    "p-4 w-full xl:max-w-[400px] h-[150px] rounded-md border-[1.5px] flex justify-between flex-col gap-x-4 gap-y-4 bg-white shadow-sm hover:shadow-md",
  content_box_icon:
    "w-[30px] h-[30px] rounded-full flex justify-center items-center",
  content_box_title:
    "text-[1.3rem] font-bold w-full text-center cursor-pointer max-w-fit mx-auto",
  content_box_subtitle:
    "text-[.8rem] px-2 py-1 font-medium  text-white bg-[#777] rounded-lg  hover:underline",
};

export default Generators;
