import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ButtonFilled } from "../UI/Buttons";
import { Squash } from "hamburger-react";
import { DesktopNavLinks, MobileNavLinks } from "./NavLinks";

const Navbar = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <nav className="flex items-center justify-between py-6">
      {/* image container */}
      <div>
        <img src={logo} loading="lazy" alt="logo" width={150} />
      </div>

      <DesktopNavLinks />
      <MobileNavLinks isOpen={mobileNavOpen} />

      <span
        className={`flex md:hidden z-[6] ${
          mobileNavOpen && "fixed top-[30px] right-[30px]"
        }`}
      >
        <Squash
          size={24}
          color={mobileNavOpen ? "white" : "black"}
          toggled={mobileNavOpen}
          toggle={setMobileNavOpen}
        />
      </span>
    </nav>
  );
};

export default Navbar;
