import { UseMutationResult, useMutation, useQuery } from "react-query";
import { baseHttp } from "../../../../axios.config";
import { closePreloader, openPreloader } from "../../../../store/slices/others/handlerSlice";
import { store } from "../../../../store/store";
import {
    District,
    IBank,
    IBursaryInfo,
    IDebtors,
    ISchoolDetails,
    ISubscription,
    ITransaction,
    ITransactionHistory,
    State,
} from "../../../../types/db.types";
import { useDispatch } from "react-redux";

export const useGetSchool = () => {
    const { data, isLoading, refetch } = useQuery<ISchoolDetails>({
        queryKey: "admin//get-school",
        queryFn: async () => {
            const response = await baseHttp.get("/school/get-school");
            return response?.data?.school;
        },
    });

    return { data, isLoading, refetch };
};

export const useGetTransactionHistory = () => {
    const { data, isLoading } = useQuery<ITransactionHistory>({
        queryKey: "admin/get-transactions",
        queryFn: async () => {
            const response = await baseHttp.get("/school/payments/transactions");

            return response?.data;
        },
    });

    return { data, isLoading };
};

export const useGetClassDebtors = (classId: string) => {
    const { data, isLoading } = useQuery<IDebtors[]>({
        queryKey: "admin/get-class-debtors",
        queryFn: async () => {
            const response = await baseHttp.get(`/school/debtors/class/${classId}`);

            return response.data.debtors;
        },
    });

    return {
        data,
        isLoading,
    };
};

export const useGetBursaryInfo = () => {
    const { data, isLoading } = useQuery<IBursaryInfo>({
        queryKey: "admin/bursary-info",
        queryFn: async () => {
            const response = await baseHttp.get("/school/bursary-info");

            return response?.data;
        },
    });

    return { data, isLoading };
};

export const useGetBanks = () => {
    const dispatch = store.dispatch;

    const { data, isLoading } = useQuery<IBank[]>({
        queryKey: "admin/get-banks",
        queryFn: async () => {
            dispatch(openPreloader({ loadingText: "Fetching banks" }));
            const response = await baseHttp.get("/wallet/banks");

            dispatch(closePreloader());

            return response?.data?.banks;
        },
    });

    return { data, isLoading };
};

export const useGetSubscriptionInfo = () => {
    const dispatch = useDispatch();

    const { data, isLoading } = useQuery<ISubscription>({
        queryKey: "admin/get-subscription",
        queryFn: async () => {
            const response = await baseHttp.get("/school/subscription");
            return response?.data;
        },
    });

    return { data, isLoading };
};

export const useGetStates = () => {
    const data = useQuery<State[]>({
        queryKey: "useGetState",
        queryFn: async () => {
            const response = await baseHttp.get("/state");

            return response?.data?.states;
        },
    });

    return data;
};

export const useGetDistricts = () => {
    const mutation = useMutation<District[], Error, string>({
        mutationKey: "useGetDistrits",
        mutationFn: async (stateId: string) => {
            const response = await baseHttp.get(`/state/${stateId}/district`);

            return response?.data?.districts;
        },
    });

    return mutation;
};
