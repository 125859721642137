import React from "react";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";
import { IStudentScores } from "../../types/db.types";
import { countGrades } from "../../utils/report-card";

const fullWidth = 346;

interface Props {
  scores: IStudentScores[];
}

const GradeAnalysis = ({ scores }: Props) => {
  const count = countGrades(scores);

  return (
    <div className="max-h-fit">
      <TableLayout>
        <TableHeader>
          <SingleTableHeader
            bg="#181619"
            color="white"
            width={fullWidth}
            text={<p className="text-[.8rem]">GRADE ANALYSIS</p>}
          />
        </TableHeader>
        <TableRowsContainer>
          {/* GRADES */}
          <TableRow>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.3}>
              <p className={styles.box}>GRADE</p>
            </SingleTableRowItem>

            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>A</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>B</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>C</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>D</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>E</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box + " !border-r-0"}>F</p>
            </SingleTableRowItem>
          </TableRow>

          {/* COUNT */}
          <TableRow>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.3}>
              <p className={styles.box}>NO</p>
            </SingleTableRowItem>

            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>{count.A || "-"}</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>{count.B || "-"}</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>{count.C || "-"}</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>{count.D || "-"}</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box}>{count.E || "-"}</p>
            </SingleTableRowItem>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.1}>
              <p className={styles.box + " !border-r-0"}>{count.F || "-"}</p>
            </SingleTableRowItem>
          </TableRow>

          {/* TOTAL SUBJECTS OFFERED */}
          <TableRow>
            <SingleTableRowItem noPadding={true} width={fullWidth * 0.7}>
              <p className={styles.box}>TOTAL SUBJECTS OFFERED</p>
            </SingleTableRowItem>

            <SingleTableRowItem noPadding={true} width={fullWidth * 0.3}>
              <p className={styles.box + " !border-r-0"}>
                {scores?.length || 0}
              </p>
            </SingleTableRowItem>
          </TableRow>
        </TableRowsContainer>
      </TableLayout>
    </div>
  );
};

const styles = {
  box: "text-[.8rem] p-2 text-center font-bold",
};

export default GradeAnalysis;
