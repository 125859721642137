/**
 * This file contains the two components that allows the superAdmin to select between adding a new admin account or selecting from teachers account
 */

import React, { useState, useEffect } from "react";
import { InputFields } from "../../types/componentsProps.types";
import { IAddAdmin } from "../../api/thunks/admin/admin.types";
import { addAdmin } from "../../api/thunks/admin/admin.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTokenFromLocalStorage } from "../../utils/localStorage";
import Swal from "sweetalert2";
import {
  useGetStaffWithoutAdminAccess,
  useGetStaffs,
} from "../../api/hooks/admin/staffs/staffs.service";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";
import { staffsHeader } from "../../static/admin&teacher.data";
import { Link } from "react-router-dom";
import { IAllStaffs } from "../../types/db.types";
import { ButtonFilled } from "../UI/Buttons";

export const AddAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();

  const [numberOfAdmins, setNumberOfAdmins] = useState<number>(1);

  const [adminsDetails, setAdminsDetails] = useState([
    {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
    },
  ]);
  const addNewAdmin = () => {
    setAdminsDetails([
      ...adminsDetails,
      {
        id: new Date().getTime(),
        firstName: "",
        lastName: "",
        email: "",
      },
    ]);
    setNumberOfAdmins(numberOfAdmins + 1);
  };
  const removeAdmin = (id: number) => {
    setAdminsDetails((prev) => prev.filter((admin) => admin.id !== id));
  };

  const updateAdminDetails = (id: number, key: string, value: string) => {
    setAdminsDetails(() => {
      return adminsDetails.map((detail) => {
        if (detail.id === id) {
          return { ...detail, [key]: value };
        }
        return detail;
      });
    });
  };

  const createAdminAccounts = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    // map to get the server desired result
    const admins: IAddAdmin[] = adminsDetails.map((detail) => ({
      firstName: detail.firstName,
      lastName: detail.lastName,
      email: detail.email,
      role: "admin",
    }));
    const data = await dispatch(addAdmin({ data: admins, token }));
    if (!data?.error) {
      await Swal.fire({
        title: "Create Admin Accounts",
        text: `You have successfully added ${admins.length} accounts`,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });

      navigate("/admin/school");
    }
  };

  return (
    <form onSubmit={createAdminAccounts}>
      <div className="mt-8 sm:px-3 mb-6 w-full max-w-[600px] mx-auto max-h-[500px] overflow-hidden overflow-y-scroll">
        {adminsDetails.map((singleAdmin, index: number) => {
          const inputFields: InputFields[] = [
            {
              placeholder: "Enter First Name",
              name: "firstName",
              type: "text",
              value: singleAdmin.firstName,
              label: "Create Firstname",
            },
            {
              placeholder: "Enter Last Name",
              name: "lastName",
              type: "text",
              value: singleAdmin.lastName,
              label: "Create Last Name",
            },
            {
              placeholder: "Enter your email",
              name: "email",
              type: "email",
              value: singleAdmin.email,
              label: "Email",
            },
          ];
          return (
            <article key={index} className="mb-4">
              <header className="w-full flex items-center justify-between gap-x-4 mb-3">
                <p className=" font-bold">Administrator {index + 1}</p>
                {adminsDetails.length > 1 && (
                  <button
                    type="button"
                    className="py-3 px-4 bg-black text-white cursor-pointer rounded-md"
                    onClick={() => {
                      removeAdmin(singleAdmin.id as number);
                    }}
                  >
                    Remove
                  </button>
                )}
              </header>{" "}
              <div className="w-full p-6 rounded-md bg-white shadow-md">
                {inputFields.map((field, index: number) => {
                  return (
                    <div key={index} className="mb-4">
                      <label
                        htmlFor={field.name}
                        className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                      >
                        {field.label}
                      </label>
                      <input
                        type={field.type}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={field.value}
                        className="input-field"
                        onChange={(e) => {
                          updateAdminDetails(
                            singleAdmin.id as number,
                            field.name,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </article>
          );
        })}
      </div>
      <div className="flex items-center justify-center w-full gap-x-4 gap-y-4 sm:flex-row flex-col max-w-[600px] mx-auto">
        <button
          className="w-full border-[1.5px] rounded-md text-[1rem] text-black border-black hover:text-white hover:bg-black cursor-pointer p-3 transition"
          onClick={addNewAdmin}
          type="button"
        >
          Add New Admin
        </button>
        <button
          className="w-full border-[1.5px] rounded-md text-[1rem] border-black text-white bg-black cursor-pointer p-3"
          type="submit"
        >
          Proceed
        </button>
      </div>
    </form>
  );
};

export interface IStaffsExtended extends IAllStaffs {
  selected?: boolean;
}

const addExtraField = (staff: IAllStaffs[]): IStaffsExtended[] => {
  return staff?.map((staff) => ({ ...staff, selected: false }));
};

export const AddStaffAsAdmin = () => {
  const token = getTokenFromLocalStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useGetStaffWithoutAdminAccess();

  const [list, setList] = React.useState<IStaffsExtended[]>([]);

  const toggleSelection = (staff_id: string, action: "Add" | "Remove") => {
    setList((list) =>
      list.map((staff) => {
        if (staff._id == staff_id) {
          staff.selected = action === "Add" ? true : false;
        }

        return staff;
      })
    );
  };

  const addAdmins = async () => {
    // get the ids of those that have been selected

    const teachers = list
      ?.filter((item) => item.selected)
      .map((item) => item._id);

    const data = await dispatch(addAdmin({ teachers, token }));

    if (!data.error) {
      await Swal.fire({
        title: "Add staff as admin",
        text: "The selected teachers have been selected as admins",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      navigate("/admin/school");
    }
  };

  useEffect(() => {
    if (data) setList(addExtraField(data));
  }, [data]);

  return (
    <section>
      <div className="w-full sm:px-0 px-2">
        <TableLayout>
          <TableHeader>
            <>
              {" "}
              <SingleTableHeader text={""} width={50} />
              {staffsHeader.map((header, index) => {
                return (
                  <SingleTableHeader
                    key={index}
                    text={header.text}
                    width={header.width}
                  />
                );
              })}
            </>
          </TableHeader>

          <TableRowsContainer>
            {list?.map((rowItem, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={50}>
                    <input
                      type="checkbox"
                      checked={rowItem.selected}
                      className="accent-blackText"
                      onChange={(e) =>
                        e?.target.checked
                          ? toggleSelection(rowItem._id, "Add")
                          : toggleSelection(rowItem._id, "Remove")
                      }
                    />
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[0].width}>
                    <Link
                      to={`/admin/staffs/${rowItem._id}`}
                      className="flex items-center gap-x-2 cursor-pointer"
                    >
                      <img
                        className="w-[25px] h-[25px] rounded-full"
                        alt={rowItem.firstName}
                        loading="lazy"
                        src={rowItem.imageUrl}
                      />
                      <p className="font-bold text-[#222] cursor-pointer hover:underline">
                        {rowItem.firstName} {rowItem.lastName}
                      </p>
                    </Link>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={staffsHeader[1].width}>
                    <p className="capitalize">{rowItem.gender}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[2].width}>
                    <p className="capitalize">{rowItem.role}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[3].width}>
                    <p>{rowItem.phoneNumber}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffsHeader[4].width}>
                    <p>{`${rowItem.city}, ${rowItem.state}`}</p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </div>

      {/* Add the button only when a staff has been selected */}
      {list.find((human) => human.selected) ? (
        <div className="max-w-fit ml-auto mt-8">
          <ButtonFilled text="Add as admin" onClick={addAdmins} />
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};
