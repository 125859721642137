import React, { ChangeEvent, useState } from 'react';
import AuthLayout from '../../../components/Layouts/AuthLayout';
import { useDispatch } from 'react-redux';
import PasswordInput from '../../../components/UI/PasswordInput';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../../../api/thunks/auth/auth.service';
import Swal from 'sweetalert2';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const { code } = useParams();

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = await dispatch(
      resetPassword({ code, password, confirmPassword })
    );
    if (!data?.error) {
      Swal.fire({
        icon: 'success',
        title: 'Password reset successful',
        text: 'You have successfully changed your password, redirecting to login in 3s',
        showConfirmButton: false,
      });

      setTimeout(() => {
        window.location.replace('/login');
      }, 3000);
    }
  };

  return (
    <AuthLayout>
      <form
        onSubmit={submit}
        className='flex justify-center w-[100%] md:w-[450px] '
      >
        <div className='bg-[#FFFFFF] shadow-xl min-h-[400px] w-[450px] flex  flex-col pl-5 md:pl-12 pr-9  py-8'>
          <h3 className='font-extrabold'>CHANGE PASSWORD </h3>
          <div className='bg-[#D9E2EC] h-[3px]  mt-2'>
            <div className='h-[100%] bg-black relative max-w-[80px]'></div>
          </div>

          <div className='mt-12 '>
            <p className='text-[#636363] text-[15px]'>New password</p>
            <PasswordInput
              className={password ? 'border-[#000000]' : 'border-[#2277c038]'}
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='******'
            />
          </div>
          <div className='mt-12 '>
            <p className='text-[#636363] text-[15px]'>Confirm New Password</p>
            <PasswordInput
              className={
                confirmPassword ? 'border-[#000000]' : 'border-[#2277c038]'
              }
              name='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder='******'
            />
          </div>
          <button
            disabled={
              password === '' ||
              confirmPassword === '' ||
              confirmPassword !== password
            }
            type='submit'
            className='bg-blackText text-white mt-16 py-2 rounded-lg disabled:bg-[#AAAAAA] font-semibold cursor-pointer'
          >
            Change Password
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
