import React from "react";
import TableLayout, {
  SingleTableHeader,
  TableHeader,
  TableRowsContainer,
} from "../Layouts/TableLayout";

const fullWidth = 595;

const GradeScale = () => {
  return (
    <div className="max-h-fit">
      <TableLayout>
        <TableHeader>
          <SingleTableHeader
            bg="#181619"
            color="white"
            width={fullWidth}
            center={true}
            text={<p className="text-[.8rem]">GRADE SCALE</p>}
          />
        </TableHeader>
        <TableRowsContainer>
          <p className="text-[.75rem] font-bold p-2 text-center">
            {
              "70-100%=A(EXCELLENT) 60-69.9%=B(VERY GOOD) 50-59.9%=C(GOOD) 40-49.9%=D(FAIR) 30-39.9%=E(PASS) 0-29.9%=F(FAIL)"
            }
          </p>
        </TableRowsContainer>
      </TableLayout>
    </div>
  );
};

export default GradeScale;
