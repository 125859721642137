/**
 * This are types from the database, the backend models!!
 */

import { IAddStaff, IAddStudent } from "../api/thunks/admin/admin.types";
import { IAffectiveDomains } from "./static.types";

// ======= ADMIN ===========
export enum ISchoolResultGradingFormat {
    _70_30 = "70/30",
    _60_40 = "60/40",
}

export enum SchoolType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    MIXED = "mixed",
}

export interface State {
    _id: string;
    name: string;
    country: string;
}

export interface District {
    _id: string;
    name: string;
    description: string;
    state: State;
}

export interface ISchoolDetails {
    schoolEmail: string;
    schoolName: string;
    country: string;
    zipCode: string;
    city: string;
    address: string;
    imageUrl: string;
    session: string;
    term: string;
    result_grading_format: ISchoolResultGradingFormat;
    _id?: string;
    v?: string;
    createdAt?: string;
    state: State;
    district: District;
    isPublic: boolean;
}

export interface IAllStudents {
    address: string;
    class: string;
    religion: "christianity" | "islam" | "others" | string | "";
    country: string;
    createdAt: string;
    dateOfBirth: string;
    admissionNumber: string;
    firstName: string;
    middleName: string;
    userName: string;
    gender: string;
    imageUrl: string;
    lastName: string;
    phoneNumber: string | number;
    schoolId: string;
    state: string;
    updatedAt: string;
    userId: string;
    monthOfAdmission: string;
    yearOfAdmission: string | number;
    __v: number;
    _id: string;
    parent: {
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string | number;
        role: string;
    };

    resultStatus: "undecided" | "approved" | "";
    teacherResultDecision: "pass" | "fail" | "";
}
export interface IUser {
    _id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    role: "superAdmin" | "admin" | "teacher" | "parent" | "student";
    userName?: string;
    school: ISchoolDetails;
    imageUrl?: string;
}
export interface IAllStaffs {
    address: string;
    city: string;
    country: string;
    religion: "christianity" | "islam" | "others" | string | "";
    createdAt: string;
    dateOfBirth: Date | string;
    documents: { documentName: string; documentUrl: string }[];
    email: string;
    firstName: string;
    gender: string;
    imageUrl: string;
    lastName: string;
    middleName: string;
    monthOfEmployment: string;
    nextOfKinAddress: string;
    nextOfKinName: string;
    nextOfKinPhoneNumber: number | string;
    phoneNumber: number | string;
    role: string;
    schoolId: string;
    signature: string;
    state: string;
    updatedAt: string;
    userId: string;
    subjects?: IGeneratedSubjects[];
    yearOfEmployment: number | string;
    __v: number;
    _id: string;
    adminAccess: boolean;
}

export interface IAllParents {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string | number;
    dateOfBirth: Date | string;
    role: "father" | "mother";
    userId: string;
    _id: string;
    imageUrl: string;
}

export interface IGeneratedClasses {
    classArm: string;
    className: string;
    classTeacher?: IAllStaffs;
    createdAt?: string;
    schoolId?: ISchoolDetails;
    students: IAllStudents[];
    updatedAt?: string;
    term?: string;
    session?: string;
    __v?: 0;
    _id?: string;
}

export interface IGeneratedSubjects {
    classId: IGeneratedClasses;
    createdAt: string;
    schoolId: string;
    teacher: IAllStaffs;
    title: string;
    updatedAt: string;
    term?: string;
    session?: string;
    __v: number;
    _id: string;
}

export interface IGeneratedPayAdvice {
    classId: IGeneratedClasses;
    item: string;
    charge: number;
    _id: string;
    __v: string;
}

export interface ITransaction {
    transaction_reference: string;
    transaction_acess_code: string;
    bill: Array<{
        item: IGeneratedPayAdvice;
        amount: number;
    }>;

    studentId: IAllStudents;
    amount: number;
    currency: "NGN";
    transaction_status: "initiated" | "completed";
    payment_status: "success" | "pending" | "failed";
    payment_gateway: "paystack" | "cash";
    schoolId: string;
    term: string;
    session: string;
    createdAt: Date;
    initiator: IUser;
}

export interface IWalletTransaction {
    amount: number;
    schoolId: ISchoolDetails;
    isInflow: boolean;
    transaction_reference: string;
    transaction_transfer_code: string;
    approved_to_available_balance: boolean;
    paymentMethod: "NGN";
    status: "pending" | "success" | "failed";
    term: string;
    session: string;
    createdAt: Date;
    initiator: IUser;
}

export interface ITransactionHistory {
    outflow: IWalletTransaction[];
    inflow: ITransaction[];
}

export interface IBursaryInfo {
    totalExpected: number;
    paystack: number;
    cash: number;
    balance: number;
}

export interface IFee {
    item: IGeneratedPayAdvice;
    amount_paid: number;
}

export interface IDebtors {
    student: IAllStaffs;
    fees: Array<IFee>;
}

export interface IBank {
    active: true;
    code: string;
    country: string;
    createdAt: Date;
    currency: string;
    gateway: null;
    id: number;
    is_deleted: false;
    longcode: string;
    name: string;
    pay_with_bank: boolean;
    slug: string;
    type: string;
    updatedAt: Date;
}

export interface IRecipient {
    account_number: string;
    account_name: string;
    bank_id: string;
}

// ======== TEACHER =============
export type IAllTeacherClasses = IGeneratedSubjects[];

export interface IFetchStudentsAttendance {
    attended: { morning: boolean; afternoon: boolean };
    studentId: IAllStudents;
}

export interface IClassAttendance {
    week: string | number;
    day: string;
    session: string;
    term: string;
    students: IFetchStudentsAttendance[];
    isHoliday: boolean;
}

export interface ISingleStudentAttendance {
    day: string;
    session: string;
    week: number;
    classId: string;
    term: string;
    isHoliday: boolean;
    attended: { morning: boolean; afternoon: boolean };
}

export interface IScore {
    subjectId: IGeneratedSubjects;
    scores: {
        studentId: IAllStudents;
        firstCA: number;
        secondCA: number;
        thirdCA: number;
        examScore: number;
    }[];
}

export interface IStudentScores {
    subjectId: IGeneratedSubjects;
    firstCA: number;
    secondCA: number;
    thirdCA: number;
    examScore: number;
}

export interface IClassScores {
    studentId: IAllStudents;
    scores: IStudentScores[];
}

export interface IClassAverage {
    classId: IGeneratedClasses;
    term: string;
    session: string;
    results: Array<{
        studentId: IAllStudents;
        average: number;
    }>;
}

// ========== PARENTS
export interface IBillsPaid extends IGeneratedPayAdvice {
    paid: number;
}

export interface IBillsBreakdown {
    payAdvice: IGeneratedPayAdvice;
    cash: number;
    paystack: number;
}

export interface IPartiallyPaid extends IGeneratedPayAdvice {
    balance: number;
}
export interface IFullyPaidItems {
    full: IGeneratedPayAdvice[];
    partial: IPartiallyPaid[];
}

export interface IStudentReportInfo {
    weight: number;
    height: number;
    officeHeld: string;
    classTeacherComment: string;
    affectiveDomains: IAffectiveDomains;
    _id: string;
    session: string;
    term: string;
    adminComment: string;
    studentId: string | IAllStudents;
    nextResumptionDate: Date | string;
}

export enum ISubscriptionStatus {
    FREE = "free",
    ONGOING = "ongoing",
    CANCELLED = "cancelled",
    EXPIRED = "expired",
    PENDING = "pending",
}

export enum ISubscriptionPlanName {
    BASIC = "basic",
    FREE = "free",
    STANDARD = "standard",
    EXPIRED = "expired",
}

export interface ISubscription {
    school: ISchoolDetails;
    amount: number;
    status: ISubscriptionStatus;
    freemiumEndDate: Date;
    plan: string;
    planName: ISubscriptionPlanName;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface IMessage {
    text: string;
    userId: IUser;
    conversationId: IChat;
    _id: string;
    isRead: {
        user: string;
        read: boolean;
    };
}

export interface IChat {
    users: IUser[];
    createdBy: IUser;
    latestMessage?: IMessage;
    isGroupChat: boolean;
    _id: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IBulkUploadRes<C = IAddStudent | IAddStaff> {
    isValid: boolean;
    data: C;
}
