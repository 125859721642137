import { IAllStaffs, IAllStudents, IGeneratedSubjects, IScore, IStudentScores } from "../types/db.types";
import { IAddStaffSlice, IAddStudentSlice } from "../types/states.types";
import { dateConverter, dateInputFormatter } from "../utils/dateConverter";
import { IAddStaff, IAddStudent, IGenerateSubject } from "../api/thunks/admin/admin.types";
import { IStudentAttendance } from "../pages/Teacher/Class/Attendance/MarkAttendance";
import { ScoreState } from "../types/static.types";
import { positions } from "../static/admin&teacher.data";

const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return "";

    phoneNumber = phoneNumber.toString();

    if (phoneNumber?.startsWith("+")) {
        phoneNumber = phoneNumber.slice(1);
        return phoneNumber;
    } else if (phoneNumber?.startsWith("234")) {
        return phoneNumber;
    } else if (phoneNumber?.startsWith("0")) {
        phoneNumber = phoneNumber.slice(1);
    } else {
        phoneNumber = phoneNumber;
    }

    phoneNumber = `234${phoneNumber}`;
    return phoneNumber;
};

const rearrangeStudentDetails = (data: IAddStudentSlice): IAddStudent => {
    return {
        resultStatus: data?.resultStatus,
        teacherResultDecision: data?.teacherResultDecision,
        admissionNumber: data.admissionNumber,
        firstName: data.firstname,
        lastName: data.lastname,
        middleName: data.middlename,
        religion: data.religion,
        dateOfBirth: new Date(data.dob),
        gender: data.gender.toLowerCase(),
        phoneNumber: formatPhoneNumber(data.phoneNumber.toString()),
        country: data.country,
        address: data.address,
        state: data.state,
        classDetail: data.class.name,
        yearOfAdmission: data.yearOfAdmission,
        monthOfAdmission: data?.monthOfAdmission,
        imageUrl: data.picture as string,
        parentFirstName: data.parent.firstname,
        parentEmail: data.parent.email,
        parentLastName: data.parent.lastname,
        parentPhoneNumber: formatPhoneNumber(data.parent.phoneNumber.toString()),
        parentRole: data.parent.gender === "male" ? "father" : "mother",
    };
};

const rearrangeStaffDetails = (data: IAddStaffSlice): IAddStaff => {
    return {
        firstName: data.firstname,
        lastName: data.lastname,
        middleName: data.middlename,
        religion: data.religion,
        dateOfBirth: new Date(data.dob),
        gender: data.gender.toLowerCase(),
        email: data.email,
        phoneNumber: formatPhoneNumber(data.phoneNumber as string),
        country: data.country,
        state: data.state,
        city: data.city,
        address: data.address,
        imageUrl: data.picture as string,
        monthOfEmployment: data.monthOfEmployment,
        yearOfEmployment: parseInt(data.yearOfEmployment),
        nextOfKinName: data.nextOfKin.name,
        nextOfKinPhoneNumber: data.nextOfKin.phoneNumber as string,
        nextOfKinAddress: data.nextOfKin.address,
        documents: data.documents
            .map((doc) => {
                if (doc.name && doc.file) {
                    return {
                        documentName: doc.name,
                        documentUrl: doc.file as string,
                    };
                } else {
                    return {
                        documentName: "",
                        documentUrl: "",
                    };
                }
            })
            .filter((doc) => doc.documentName && doc.documentUrl),
        role: data.role.position === "Others" ? data.role.otherPosition : data.role.position,
        signature: data.role.signature as string,
    };
};

const reverseStaffDetails = (data: IAllStaffs): IAddStaffSlice => {
    const isCustomPosition = !positions.map((item) => item.toLowerCase()).includes(data.role.toLowerCase());

    return {
        firstname: data?.firstName,
        lastname: data?.lastName,
        middlename: data?.middleName,
        religion: data?.religion,
        dob: dateInputFormatter(data?.dateOfBirth as Date),
        gender: data?.gender === "female" ? "Female" : "male",
        email: data?.email,
        phoneNumber: data?.phoneNumber,
        country: data?.country,
        state: data?.state,
        city: data?.city,
        address: data?.address,
        picture: data?.imageUrl,
        yearOfEmployment: data?.yearOfEmployment as string,
        monthOfEmployment: data?.monthOfEmployment,
        role: {
            position: isCustomPosition ? "Others" : data?.role.toLowerCase(),
            otherPosition: isCustomPosition ? data?.role : "",
            signature: data?.signature as string,
        },
        nextOfKin: {
            name: data?.nextOfKinName,
            phoneNumber: data?.nextOfKinPhoneNumber,
            address: data?.nextOfKinAddress,
        },
        documents: data?.documents?.map((doc) => {
            return {
                id: Math.random() * 98889898,
                name: doc?.documentName,
                file: doc?.documentUrl,
            };
        }),
    };
};

const reverseStudentDetails = (data: IAllStudents): IAddStudentSlice => {
    return {
        admissionNumber: data?.admissionNumber,
        firstname: data?.firstName,
        lastname: data?.lastName,
        middlename: data?.middleName,
        religion: data?.religion,
        dob: dateInputFormatter(data?.dateOfBirth as any),
        gender: data?.gender === "female" ? "Female" : "male",
        phoneNumber: data?.phoneNumber as string,
        country: data?.country,
        state: data?.state,
        address: data?.address,
        picture: data?.imageUrl,
        resultStatus: data?.resultStatus,
        teacherResultDecision: data?.teacherResultDecision,
        monthOfAdmission: data?.monthOfAdmission,
        yearOfAdmission: parseInt(data?.yearOfAdmission as string) as number,
        parent: {
            firstname: data?.parent?.firstName,
            lastname: data?.parent?.lastName,
            email: data?.parent?.email,
            phoneNumber: data?.parent?.phoneNumber,
            gender: data?.parent?.role === "father" ? "male" : "Female",
        },
        class: {
            name: data?.class,
        },
    };
};

// ==== ATTENDANCE ====
interface CustomIStudentAttendance {
    studentId: string;
    attended: { morning: boolean; afternoon: boolean };
}

const rearrangeStudentAttendance = (students: IStudentAttendance[]): CustomIStudentAttendance[] => {
    return students.map((student) => ({
        studentId: student.studentId?._id,
        attended: {
            morning: student.attended.morning,
            afternoon: student.attended.afternoon,
        },
    }));
};

// ==== Student Scores
export const formatScores = (data: IAllStudents[] | IScore): ScoreState[] => {
    if ((data as IScore)?.scores) {
        const scores = (data as IScore)?.scores;
        const allStudentsScores = scores?.map((score) => {
            return {
                student: score.studentId,
                firstCA: score.firstCA,
                secondCA: score.secondCA,
                thirdCA: score.thirdCA,
                examScore: score.examScore,
            };
        });

        return allStudentsScores;
    }
    return (data as IAllStudents[]).map((student) => {
        return { student, firstCA: 0, secondCA: 0, thirdCA: 0, examScore: 0 };
    });
};

// For scores that do not have the results for the new students in the class, append the students and set their scores to 0

const fillEmptyScores = (allStudents: IAllStudents[], scores: IScore | IAllStudents[]) => {
    if ((scores as IScore)?.scores) {
        const studentsNotRecorded: IAllStudents[] = [];

        allStudents?.forEach((student) => {
            // Check for the student in the score DB
            const studentInScoreDB = (scores as IScore)?.scores?.find((score) => score?.studentId?._id == student?._id);
            if (!studentInScoreDB) {
                studentsNotRecorded.push(student);
            }
        });

        return formatScores(studentsNotRecorded);
    } else {
        // Return empty string if the scores is an instance of IALLSTUDENTS[] because that already fetches for all the students in the class
        return [];
    }
};

const fuseSubjectAndScores = (subjects: IGeneratedSubjects[], scores: IStudentScores[]) => {
    const newScores: IStudentScores[] = subjects?.map((subject) => {
        const storedScore = scores?.find((score) => score.subjectId?._id === subject?._id);

        if (storedScore) return storedScore;
        return {
            subjectId: subject,
            firstCA: 0,
            secondCA: 0,
            thirdCA: 0,
            examScore: 0,
        };
    });

    return newScores;
};

export {
    rearrangeStudentDetails,
    rearrangeStaffDetails,
    reverseStaffDetails,
    reverseStudentDetails,
    rearrangeStudentAttendance,
    fillEmptyScores,
    fuseSubjectAndScores,
    formatPhoneNumber,
};
