import { SingleBulkStaff, SingleBulkStudent } from "../types/states.types";
import { tableHeaders } from "../types/static.types";
import { classes, positions } from "./admin&teacher.data";

export const studentBulkUploadTable: tableHeaders[] = [
    { text: "", width: 30 },
    { text: "", width: 60 },
    { text: "First Name*", width: 200 },
    { text: "Middle Name", width: 200 },
    { text: "Last Name*", width: 200 },
    { text: "Username*", width: 200 },
    { text: "Email", width: 210 },
    { text: "Phone Number", width: 200 },
    { text: "D.O.B*", width: 200 },
    { text: "Gender*", width: 80 },
    { text: "Religion*", width: 160 },
    { text: "Class*", width: 200 },
    { text: "Admission Number", width: 200 },
    { text: "Month of Admission", width: 200 },
    { text: "Year of Admission", width: 200 },
    { text: "Country", width: 200 },
    { text: "State", width: 200 },
    { text: "City", width: 200 },
    { text: "Parent - First Name*", width: 200 },
    { text: "Parent - Last Name*", width: 200 },
    { text: "Parent - Role*", width: 200 },
    { text: "Parent - Number*", width: 200 },
];

export const studentDefaultData: Partial<SingleBulkStudent> = {
    gender: "male",
    religion: "christainity",
    classDetail: classes[0],
    monthOfAdmission: "January",
    yearOfAdmission: 2000,
    country: "Nigeria",
    parentRole: "father",
};

export const staffDefaultData: Partial<SingleBulkStaff> = {
    religion: "christainity",
    gender: "male",
    monthOfEmployment: "January",
    yearOfEmployment: 1980,
    country: "Nigeria",
    role: positions[0],
};

export const staffBulkUploadTable: tableHeaders[] = [
    { text: "", width: 30 },
    { text: "", width: 60 },
    { text: "First Name*", width: 200 },
    { text: "Middle Name", width: 200 },
    { text: "Last Name*", width: 200 },
    { text: "Email", width: 210 },
    { text: "Phone Number*", width: 200 },
    { text: "D.O.B*", width: 200 },
    { text: "Gender*", width: 80 },
    { text: "Religion*", width: 160 },
    { text: "Position*", width: 200 },
    { text: "Month of Employemnt", width: 200 },
    { text: "Year of Employment", width: 200 },
    { text: "Country", width: 200 },
    { text: "State", width: 200 },
    { text: "City", width: 200 },
    { text: "Next Of Kin - Name", width: 200 },
    { text: "Next Of Kin - Number", width: 200 },
];
