import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import React, { FC, useState } from "react";
import { plans } from "../../../static/admin&teacher.data";
import { BiCheck, BiLoaderAlt } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import ToggleSwitch from "../../../components/UI/ToggleSwitch";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../../components/UI/ConfirmationModal";
import { useDispatch } from "react-redux";
import { subscribe } from "../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import FreePlan from "../../../components/Subscription/FreePlan";
import { useGetSubscriptionInfo } from "../../../api/hooks/admin/school/school.service";
import BasicPlan from "../../../components/Subscription/BasicPlan";
import ExpiredPlan from "../../../components/Subscription/ExpiredPlan";
import StandardPlan from "../../../components/Subscription/StandardPlan";
import { ISubscriptionPlanName, ISubscriptionStatus } from "../../../types/db.types";

const Billing = () => {
    const { data, isLoading } = useGetSubscriptionInfo();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const increaseUpdatedAt = (date?: Date) => {
        date = new Date(date as Date);

        date.setMonth(date?.getMonth() + 1);
        return date;
    };

    return (
        <DashboardLayout pageTitle={"Billing"}>
            <section className="py-6 sm:p-6 min-h-screen">
                {isLoading && (
                    <div className="flex items-center gap-2">
                        <BiLoaderAlt size={24} className="animate-spin" />
                        <p>Fetching subscription info</p>
                    </div>
                )}
                {(data?.planName === ISubscriptionPlanName.FREE || data?.status === ISubscriptionStatus.PENDING) && (
                    <FreePlan endDate={data?.freemiumEndDate} />
                )}
                {data?.planName === ISubscriptionPlanName.BASIC && data?.status !== ISubscriptionStatus.PENDING && (
                    <BasicPlan endDate={increaseUpdatedAt(data?.updatedAt)} />
                )}
                {data?.planName === ISubscriptionPlanName.STANDARD && data?.status !== ISubscriptionStatus.PENDING && (
                    <StandardPlan endDate={increaseUpdatedAt(data?.updatedAt)} />
                )}
                {data?.planName === ISubscriptionPlanName.EXPIRED && data?.status !== ISubscriptionStatus.PENDING && (
                    <StandardPlan endDate={data?.updatedAt as Date} />
                )}
            </section>
        </DashboardLayout>
    );
};

export default Billing;
