/**
 * @use - listing a select option, with user's and their images
 */

import React, { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { IAllStaffs, IAllStudents } from '../../types/db.types';

export interface UserSelectContainerProps {
  currentItem: IAllStudents | IAllStaffs | string;
  list: IAllStudents[] | IAllStaffs[];
  updateWithId: (id: string) => void;
  fitContent: boolean;
}

const UserSelectContainer = (props: UserSelectContainerProps) => {
  const [accordionOpened, setAccordionOpened] = useState<boolean>(false);

  const toggleAccordion = () => {
    setAccordionOpened((prev) => !prev);
  };

  const closeAccordion = () => {
    setAccordionOpened(false);
  };

  return (
    <div className={`w-full relative`}>
      <header
        className={`w-full cursor-pointer border-[1.5px] flex justify-between items-center gap-x-4 px-3 py-2 rounded-md bg-white ${
          accordionOpened && 'border-black'
        }`}
        onClick={toggleAccordion}
      >
        <p>
          {typeof props?.currentItem === 'string' ? (
            props?.currentItem
          ) : (
            <div className='flex items-center gap-x-2 cursor-pointer'>
              <img
                className='w-[25px] h-[25px] rounded-full'
                alt={props?.currentItem?.firstName}
                loading='lazy'
                src={props?.currentItem?.imageUrl}
              />
              <p className='text-[#222] cursor-pointer hover:underline'>
                {props?.currentItem?.firstName} {props?.currentItem?.lastName}
              </p>
            </div>
          )}
        </p>
        <span>{accordionOpened ? <BiChevronUp /> : <BiChevronDown />}</span>
      </header>
      {accordionOpened && (
        <div
          className='absolute top-[115%] shadow-md left-0 w-full overflow-hidden overflow-y-scroll rounded-md z-[5] bg-white'
          style={{ maxHeight: props?.fitContent ? 'fit-content' : '250px' }}
        >
          {props?.list.map((listItem, index) => {
            return (
              <div
                className='flex items-center gap-x-2 cursor-pointer p-2'
                onClick={() => {
                  props?.updateWithId(listItem?._id);
                  closeAccordion();
                }}
                key={index}
              >
                <img
                  className='w-[25px] h-[25px] rounded-full'
                  alt={listItem.firstName}
                  loading='lazy'
                  src={listItem.imageUrl}
                />
                <p className='text-[#222] cursor-pointer hover:underline'>
                  {listItem.firstName} {listItem.lastName}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UserSelectContainer;
