import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  login,
  registerAsSuperAdmin as register,
} from "../../../api/thunks/auth/auth.service";

const initialState = {
  username: "",
  email: "",
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
});

export const {} = user.actions;
export const authReducer = user.reducer;
