import React from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import TableLayout, {
    SingleTableHeader,
    SingleTableRowItem,
    TableHeader,
    TableRow,
    TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import { useGetParents } from "../../../api/hooks/admin/parents/parents.service";
import { BiSearch } from "react-icons/bi";
import { useSearch } from "../../../utils/hooks/useSearch";
import { IAllParents } from "../../../types/db.types";
import { Link } from "react-router-dom";
import { parentsHeader } from "../../../static/admin&teacher.data";
import NoItem from "../../../components/UI/NoItem";

export default function Parents() {
    const { data: parents, isLoading } = useGetParents();

    const { list, setList, searchValue, setSearchValue } = useSearch(parents as IAllParents[]);

    return (
        <DashboardLayout pageTitle={"Parents"}>
            <section className="sm:p-6 py-6 min-h-screen">
                <header className={styles.header_container}>
                    {/* search container */}
                    <div className={styles.search_box}>
                        <div className="flex-1">
                            <input
                                type="text"
                                placeholder='Try "Ademola Osanyin"'
                                className={styles.search_input}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                        <span className={styles.search_icon}>
                            <BiSearch />
                        </span>
                    </div>
                </header>
                {list?.length > 0 && (
                    <div className="w-full sm:px-0 px-2">
                        <TableLayout>
                            <TableHeader>
                                {parentsHeader.map((header, index) => {
                                    return <SingleTableHeader key={index} text={header.text} width={header.width} />;
                                })}
                            </TableHeader>

                            <TableRowsContainer>
                                {(list as IAllParents[])?.map((rowItem, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <SingleTableRowItem width={parentsHeader[0].width}>
                                                <Link
                                                    to={`/admin/parents/${rowItem._id}`}
                                                    className="flex items-center gap-x-2 cursor-pointer"
                                                >
                                                    <img
                                                        className="w-[25px] h-[25px] rounded-full"
                                                        alt={rowItem.firstName}
                                                        loading="lazy"
                                                        src={rowItem.imageUrl}
                                                    />
                                                    <p className="font-bold text-[#222] cursor-pointer hover:underline">
                                                        {rowItem.firstName + " " + rowItem.lastName}
                                                    </p>
                                                </Link>
                                            </SingleTableRowItem>

                                            <SingleTableRowItem width={parentsHeader[1].width}>
                                                <p className="capitalize">{rowItem.phoneNumber}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={parentsHeader[2].width}>
                                                <p>{rowItem.email}</p>
                                            </SingleTableRowItem>
                                        </TableRow>
                                    );
                                })}
                            </TableRowsContainer>
                        </TableLayout>
                    </div>
                )}
                {/* No Student */}
                {(parents?.length === 0 || isLoading) && parents?.length === 0 && (
                    <NoItem title="No Parents Yet" subtitle="" />
                )}
                {(parents?.length !== 0 && list?.length === 0) ||
                    (parents?.length === 0 && (
                        <div className="w-full h-[80vh] flex justify-center items-center flex-col gap-y-3">
                            <h2 className="text-darkGray text-[1.7rem] font-bold">No student matches your search!</h2>
                        </div>
                    ))}
            </section>
        </DashboardLayout>
    );
}

const styles = {
    header_container: "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
    search_box: "flex-[67%] flex w-full gap-[1rem] gap-y-[1rem] border-[1.5px] rounded-md hover:border-black bg-white",
    search_input: "w-full outline-none border-none h-[35px] px-4 placeholder:text-darkGray",
    search_icon: "flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray",
    buttons: "px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit",
};
