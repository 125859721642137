import React from "react";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";
import { IStudentScores } from "../../types/db.types";
import { getGrade, getRemark, getTotalScore } from "../../utils/report-card";

const fullWidth = 595;

interface Props {
  scores?: IStudentScores[];
}

const PerformanceSummary = ({ scores }: Props) => {
  const totalObtained = getTotalScore(scores as IStudentScores[]);

  const totalObtainable = (scores as IStudentScores[])?.length * 100;

  const percentage = (totalObtained / totalObtainable) * 100;

  return (
    <div className="max-h-fit">
      <TableLayout>
        <TableHeader>
          <SingleTableHeader
            bg="#181619"
            color="white"
            width={fullWidth}
            center={true}
            text={<p className="text-[.8rem]">PERFORMANCE SUMMARY</p>}
          />
        </TableHeader>
        <div className="flex">
          <div className="w-full flex-1">
            <TableRowsContainer>
              <TableRow>
                <SingleTableRowItem
                  width={fullWidth * 0.8 * 0.3}
                  noPadding={true}
                >
                  <p className={styles.p}>Total Obtained: </p>
                </SingleTableRowItem>

                {/* Share the remaining 70% equally amongst the three columns */}
                <SingleTableRowItem
                  width={fullWidth * 0.8 * (0.7 / 3)}
                  noPadding={true}
                >
                  <p className={styles.p + " !font-bold"}>{totalObtained}</p>
                </SingleTableRowItem>

                <SingleTableRowItem
                  width={fullWidth * 0.8 * (0.7 / 3)}
                  noPadding={true}
                >
                  <p className={styles.p}>Percentage: </p>
                </SingleTableRowItem>

                <SingleTableRowItem
                  width={fullWidth * 0.8 * (0.7 / 3)}
                  noPadding={true}
                >
                  <p className={styles.p + " !font-bold"}>
                    {percentage.toFixed(2)}%
                  </p>
                </SingleTableRowItem>
              </TableRow>

              <TableRow>
                <SingleTableRowItem
                  width={fullWidth * 0.8 * 0.3}
                  noPadding={true}
                >
                  <p className={styles.p}>Total Obtainable: </p>
                </SingleTableRowItem>

                {/* Share the remaining 70% equally amongst the three columns */}
                <SingleTableRowItem
                  width={fullWidth * 0.8 * (0.7 / 3)}
                  noPadding={true}
                >
                  <p className={styles.p + " !font-bold"}>{totalObtainable}</p>
                </SingleTableRowItem>

                <SingleTableRowItem
                  width={fullWidth * 0.8 * (0.7 / 3)}
                  noPadding={true}
                >
                  <p className={styles.p}>Grade:</p>
                </SingleTableRowItem>

                <SingleTableRowItem
                  width={fullWidth * 0.8 * (0.7 / 3)}
                  noPadding={true}
                >
                  <p className={styles.p + " !font-bold"}>
                    {getGrade(percentage)}
                  </p>
                </SingleTableRowItem>
              </TableRow>
            </TableRowsContainer>
          </div>
          <div
            className="flex items-center justify-center"
            style={{ width: fullWidth * 0.2 }}
          >
            <p className="font-bold">{getRemark(percentage)}</p>
          </div>
        </div>
      </TableLayout>
    </div>
  );
};

const styles = {
  p: "text-[.8rem] p-2 border-r-2",
};

export default PerformanceSummary;
