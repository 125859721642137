import React, { FC, useState } from "react";
import { BiChevronDown, BiChevronUp, BiLoaderAlt } from "react-icons/bi";

interface SelectContainerProps {
    list: string[] | number[];
    currentItem: string | React.ReactElement;
    label?: string;
    updateItem?: (value: string) => void;
    fitContent: boolean;
    // Incase you want to update items using the ids of each item and not the value
    updateWithId?: (id: number | string) => void;
    ids?: number[] | string[];
    // Single ID. Incase you want to update only a single item using its id
    id?: number | string;
    isLoading?: boolean;
}

const SelectContainer: FC<SelectContainerProps> = ({
    list,
    currentItem,
    updateItem,
    updateWithId,
    ids,
    id,
    fitContent,
    isLoading,
    label,
}) => {
    const [accordionOpened, setAccordionOpened] = useState<boolean>(false);

    const toggleAccordion = () => {
        setAccordionOpened((prev) => !prev);
    };

    const closeAccordion = () => {
        setAccordionOpened(false);
    };
    return (
        <div className={`w-full relative`}>
            <header
                className={`w-full cursor-pointer border-[1.5px] flex justify-between items-center gap-x-4 px-3 py-2 rounded-md bg-white capitalize ${
                    accordionOpened && "border-black"
                }`}
                onClick={toggleAccordion}
            >
                <p className="!capitalize">{currentItem || label}</p>
                <span>{accordionOpened ? <BiChevronUp /> : <BiChevronDown />}</span>
            </header>
            {accordionOpened && (
                <div
                    className="absolute top-[115%] shadow-md left-0 w-full overflow-hidden overflow-y-scroll rounded-md z-[5] bg-white"
                    style={{ maxHeight: fitContent ? "fit-content" : "250px" }}
                >
                    {isLoading ? (
                        <div className="w-full flex items-center justify-center p-2">
                            <BiLoaderAlt className="animate-spin" size={25} />
                        </div>
                    ) : (
                        <>
                            {" "}
                            {list?.map((listItem, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="p-3 text-darkGray hover:text-black w-full cursor-pointer"
                                        onClick={() => {
                                            updateItem && updateItem(listItem as string);

                                            updateWithId && ids && updateWithId(ids?.[index] as number);
                                            updateWithId && id && updateWithId(id as number);
                                            closeAccordion();
                                        }}
                                    >
                                        {listItem}
                                    </p>
                                );
                            })}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SelectContainer;
