import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getUserFromLocalStorage } from "../localStorage";
import {
  IAllStudents,
  IClassAttendance,
  IGeneratedClasses,
  IGeneratedSubjects,
  ISingleStudentAttendance,
  IStudentReportInfo,
  IStudentScores,
  IUser,
} from "../../types/db.types";
import {
  useGetSingleStudent,
  useGetStudentAttendance,
  useGetStudentClass,
  useGetStudentScore,
  useGetStudentSubjects,
} from "../../api/hooks/admin/students/students.service";
import {
  useGetSchoolAttendance,
  useGetStudentReportInfo,
} from "../../api/hooks/teachers/teachers.service";

export enum IRequestsToBeMade {
  fetchProfile = "fetchProfile",
  fetchScores = "fetchScores",
  fetchSubjects = "fetchSubjects",
  fetchAttendance = "fetchAttendance",
  fetchTermlyInfo = "fetchTermlyInfo",
  fetchStudentClass = "fetchStudentClass",
  fetchSchoolAttendance = "fetchSchoolAttendance",
}

export enum IUserTypes {
  admin = "admin",
  student = "student",
  parent = "parent",
  teacher = "teacher",
  users="users"
}

interface IFetchResultsRes {
  profile?: IAllStudents;
  scores?: IStudentScores[];
  subjects?: IGeneratedSubjects[];
  attendance?: ISingleStudentAttendance[];
  schoolAttendance?: IClassAttendance[];
  termlyInfo?: IStudentReportInfo;
  studentClass?: IGeneratedClasses;
  completed: IRequestsToBeMade[];
}

export class StudentLinks {
  private readonly studentId: string;
  private readonly term?: string;
  private readonly session?: string;
  private readonly user: IUser;

  constructor(studentId: string, session?: string, term?: string) {
    const user = getUserFromLocalStorage();

    this.studentId = studentId;
    this.term = term || user?.school?.term;
    this.session = session || user?.school?.session;
    this.user = user;
  }

  public getLink = (req: IRequestsToBeMade) => {
    const userType: IUserTypes =
      this.user.role === "superAdmin"
        ? IUserTypes.admin
        : IUserTypes[this.user.role];

    const links = {
      [IRequestsToBeMade.fetchProfile]: {
        [IUserTypes.admin]: `/school/student/${this?.studentId}`,
        [IUserTypes.parent]: `/parents/kid/profile/${this?.studentId}`,
        [IUserTypes.student]: "/student/profile",
        [IUserTypes.teacher]: `/teacher/student/${this?.studentId}`,
      },
      [IRequestsToBeMade.fetchScores]: {
        [IUserTypes.admin]: `/school/scores/student/${this?.studentId}`,
        [IUserTypes.parent]: `/parents/kid/scores/${this?.studentId}`,
        [IUserTypes.student]: `/student/scores`,
        [IUserTypes.teacher]: `/teacher/scores/student/${this?.studentId}`,
      },
      [IRequestsToBeMade.fetchSubjects]: {
        [IUserTypes.admin]: `/school/student/subjects/${this?.studentId}`,
        [IUserTypes.parent]: `/parents/kid/subjects/${this?.studentId}`,
        [IUserTypes.student]: `/student/subjects`,
        [IUserTypes.teacher]: `/teacher/student/subjects/${this?.studentId}`,
      },

      [IRequestsToBeMade.fetchAttendance]: {
        [IUserTypes.admin]: `/school/attendance/student/${this?.studentId}`,
        [IUserTypes.parent]: `/parents/kid/attendance/${this?.studentId}`,
        [IUserTypes.student]: `/student/attendance`,
        [IUserTypes.teacher]: `/teacher/attendance/student/${this?.studentId}`,
      },

      [IRequestsToBeMade.fetchTermlyInfo]: {
        [IUserTypes.admin]: `/school/student/${this?.studentId}/report-card-information`,
        [IUserTypes.parent]: `/parents/kid/${this?.studentId}/report-card-information`,
        [IUserTypes.student]: `/student/report-card-information`,
        [IUserTypes.teacher]: `/teacher/student/${this?.studentId}/report-card-information`,
      },

      [IRequestsToBeMade.fetchStudentClass]: {
        [IUserTypes.admin]: `/school/student/${this?.studentId}/class`,
        [IUserTypes.parent]: `/parents/kid/${this?.studentId}/class`,
        [IUserTypes.student]: `/student/class`,
        [IUserTypes.teacher]: `/teacher/student/${this?.studentId}/class`,
      },

      [IRequestsToBeMade.fetchSchoolAttendance]: {
        [IUserTypes.admin]: `/school/attendance/all`,
        [IUserTypes.parent]: `/school/attendance/all`,
        [IUserTypes.student]: `/school/attendance/all`,
        [IUserTypes.teacher]: `/school/attendance/all`,
      },
    };

    return `${links?.[req]?.[userType]}?term=${this?.term}&session=${this?.session}`;
  };
}

const useFetchResult = (): IFetchResultsRes => {
  const { studentId } = useParams();
  const location = useLocation();
  const [completedReq, setCompletedReq] = useState<Array<IRequestsToBeMade>>(
    []
  );

  const [searchParams] = useSearchParams();

  const term = searchParams.get("term");
  const session = searchParams.get("session");

  //   fetch the requests
  const { getLink } = new StudentLinks(
    studentId as string,
    session as string,
    term as string
  );

  const { data: profile, isLoading: profileLoading } = useGetSingleStudent(
    studentId as string,
    getLink(IRequestsToBeMade.fetchProfile)
  );

  const { data: scores, isLoading: scoresLoading } = useGetStudentScore(
    getLink(IRequestsToBeMade.fetchScores)
  );

  const { data: subjects, isLoading: subjectsLoading } = useGetStudentSubjects(
    getLink(IRequestsToBeMade.fetchSubjects)
  );

  const { data: attendance, isLoading: attendanceLoading } =
    useGetStudentAttendance(
      studentId as string,
      getLink(IRequestsToBeMade.fetchAttendance)
    );

  const { data: termlyInfo, isLoading: termlyInfoLoading } =
    useGetStudentReportInfo(getLink(IRequestsToBeMade.fetchTermlyInfo));

  const { data: studentClass, isLoading: studentClassLoading } =
    useGetStudentClass(getLink(IRequestsToBeMade.fetchStudentClass));

  const { data: schoolAttendance, isLoading: schoolAttendanceLoading } =
    useGetSchoolAttendance();

  // use effect to update the completed req array
  useEffect(() => {
    if (
      !attendanceLoading &&
      !profileLoading &&
      !scoresLoading &&
      !subjectsLoading &&
      !termlyInfoLoading &&
      !studentClassLoading &&
      !schoolAttendanceLoading
    ) {
      setCompletedReq(Object.keys(IRequestsToBeMade) as IRequestsToBeMade[]);
    }
  }, [
    attendanceLoading,
    profileLoading,
    scoresLoading,
    subjectsLoading,
    termlyInfoLoading,
    studentClassLoading,
    schoolAttendanceLoading,
  ]);

  return {
    profile,
    scores,
    subjects,
    termlyInfo,
    attendance,
    completed: completedReq,
    studentClass,
    schoolAttendance,
  };
};

export default useFetchResult;
