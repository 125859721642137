import React, { useState } from "react";
import StudentForm from "../../../components/Admin/StudentForm";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { useDispatch } from "react-redux";
import { addStudent } from "../../../api/thunks/admin/admin.service";
import { useLocation, useNavigate } from "react-router-dom";
import { clearFields } from "../../../store/slices/post/addStudent";
import Swal from "sweetalert2";
import StudentBulkForm from "../../../components/Admin/StudentBulkForm";
import StudentBulkUpload from "./BulkUpload";

export enum IAddOption {
    individual = "individual",
    bulk = "bulk",
}

const AddStudent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [option, setOption] = useState<IAddOption>(IAddOption.bulk);

    const updateSelection = (option: IAddOption) => setOption(option);

    const onSubmit = async () => {
        const data = await dispatch(addStudent());
        if (!data?.error) {
            await Swal.fire({
                title: "Add Student",
                text: "Student account has been created successfully",
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });
            if (location.pathname.includes("admin")) {
                navigate("/admin/students");
            } else {
                navigate("/teacher/profile");
            }
            dispatch(clearFields());
        }
    };

    return (
        <DashboardLayout
            pageTitle={
                <>
                    Students/
                    <span className="text-blackText font-bold">Add Student</span>
                </>
            }
            documentTitle={"Add Student"}
        >
            <section>
                <header className="w-full flex items-center gap-x-4 gap-y-4 justify-start flex-wrap mt-6">
                    <div
                        className={styles.radio_container}
                        onClick={() => {
                            updateSelection(IAddOption.individual);
                        }}
                    >
                        <span className={`radio ${option === IAddOption.individual && "checked"}`}></span>
                        Individual
                    </div>
                    <div
                        className={styles.radio_container}
                        onClick={() => {
                            updateSelection(IAddOption.bulk);
                        }}
                    >
                        <span className={`radio ${option === IAddOption.bulk && "checked"}`}></span>
                        Bulk
                    </div>
                </header>
                {option === IAddOption.individual && <StudentForm onSubmit={onSubmit} />}
                {option === IAddOption.bulk && <StudentBulkUpload />}
            </section>
        </DashboardLayout>
    );
};

const styles = {
    radio_container: "flex items-center gap-x-2 flex-nowrap text-[1.25rem] text-blackText font-bold cursor-pointer",
};

export default AddStudent;
