import React, { Dispatch, FC, SetStateAction } from "react";
import { MdClose } from "react-icons/md";
import ModalOverlay from "../UI/ModalOverlay";
import { ITransaction, IUser, IWalletTransaction } from "../../types/db.types";
import { dateConverter } from "../../utils/dateConverter";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import exportPDF from "../../utils/exportPDF";

interface BursaryReceiptProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  receipt?: ITransaction;
}

const BursaryReceipt: FC<BursaryReceiptProps> = (props) => {
  const total = props?.receipt?.bill?.reduce((acc, curr) => {
    acc += curr.amount;

    return acc;
  }, 0);

  const school = (getUserFromLocalStorage() as IUser).school;

  const print = async () => {
    const container = document.getElementById(
      "receipt-container"
    ) as HTMLElement;

    container.style.padding = "1rem";

    await exportPDF(container);

    container.style.padding = "0rem";
  };

  return (
    <ModalOverlay modalWidth={500}>
      <div id="receipt-container">
        <header className=" flex text-darkerGray font-bold justify-between">
          <div className="mb-4 flex items-start gap-[1rem]">
            <img
              src={school?.imageUrl}
              className="w-[50px] h-[50px] rounded-full object-cover object-center"
              alt=""
            />
            <div>
              <p className="font-bold">{school?.schoolName}</p>
              <p className="text-[14px]">{school?.address}</p>
            </div>
          </div>
          <span
            className="text-[24px] text-red-600 cursor-pointer"
            onClick={() => {
              props.setModalOpen(false);
            }}
          >
            <MdClose />
          </span>
        </header>

        <h1 className="text-[0.94rem] mb-2">
          Transaction Receipt {props.receipt?.term} term{" "}
          {props?.receipt?.session}
        </h1>
        <h1 className={styles.header}>RECEIVED FROM</h1>
        <div className="flex flex-col gap-x-4 gap-y-4 mb-4 mt-6">
          <BursaryItem
            money={true}
            title="Full Name"
            answer={`${props?.receipt?.initiator?.firstName || ""} ${
              props?.receipt?.initiator?.lastName || ""
            }`}
          />
          <BursaryItem
            money={true}
            title="Email"
            answer={`${props?.receipt?.initiator?.email || ""}`}
          />
          <BursaryItem
            money={true}
            title="Date"
            answer={
              props?.receipt?.createdAt
                ? dateConverter(props?.receipt?.createdAt as Date)
                : ""
            }
          />
        </div>
        <h1 className={styles.header}>PAYMENT FOR</h1>
        <div className="flex flex-col gap-x-4 gap-y-4 mb-4 mt-6">
          <BursaryItem
            money={true}
            title="Full Name"
            answer={`${props?.receipt?.studentId?.firstName || ""} ${
              props?.receipt?.studentId?.lastName || ""
            }`}
          />
          <BursaryItem
            money={true}
            title="Class"
            answer={props?.receipt?.studentId?.class as string}
          />
        </div>

        <h1 className={styles.header}>PAYMENT DETAILS {"(₦)"}</h1>
        <div className="flex flex-col gap-x-4 gap-y-4 mt-6">
          <BursaryItem
            money={true}
            title="Transaction Type"
            answer={
              props?.receipt?.payment_gateway === "cash" ? "Cash" : "E-Cash"
            }
          />
          {props?.receipt?.bill?.map((bill, index) => (
            <BursaryItem
              key={index}
              money={true}
              title={bill?.item?.item}
              answer={bill?.amount}
            />
          ))}

          <BursaryItem money={true} title={"Total"} answer={total as number} />
        </div>
      </div>

      <button
        className={styles.header + " text-center w-full mt-5 mb-3"}
        onClick={async () => {
          await print();
          props.setModalOpen(false);
        }}
      >
        Print
      </button>
    </ModalOverlay>
  );
};

export interface WithdrawalReceiptProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  receipt?: IWalletTransaction;
}

export const WithdrawalReceipt: FC<WithdrawalReceiptProps> = (props) => {
  const school = (getUserFromLocalStorage() as IUser).school;

  return (
    <ModalOverlay modalWidth={500}>
      <header className=" flex text-darkerGray font-bold justify-between">
        <div className="mb-4 flex items-start gap-[1rem]">
          <img
            src={school?.imageUrl}
            className="w-[50px] h-[50px] rounded-full object-cover object-center"
            alt=""
          />
          <div>
            <p className="font-bold">{school?.schoolName}</p>
            <p className="text-[14px]">{school?.address}</p>
          </div>
        </div>
        <span
          className="text-[24px] text-red-600 cursor-pointer"
          onClick={() => {
            props.setModalOpen(false);
          }}
        >
          <MdClose />
        </span>
      </header>

      <h1 className="text-[0.94rem] mb-2">
        Transaction Receipt {props.receipt?.term} term,{" "}
        {props?.receipt?.session}
      </h1>

      <h1 className={styles.header}>WITHDRAWAL INITIATED BY</h1>
      <div className="flex flex-col gap-x-4 gap-y-4 mb-4 mt-6">
        <BursaryItem
          money={true}
          title="Full Name"
          answer={`${props?.receipt?.initiator?.firstName || ""} ${
            props?.receipt?.initiator?.lastName || ""
          }`}
        />
        <BursaryItem
          money={true}
          title="Email"
          answer={`${props?.receipt?.initiator?.email || ""}`}
        />
        <BursaryItem
          money={true}
          title="Date"
          answer={
            props?.receipt?.createdAt
              ? dateConverter(props?.receipt?.createdAt as Date)
              : ""
          }
        />
      </div>

      <h1 className={styles.header}>PAYMENT DETAILS {"(₦)"}</h1>
      <div className="flex flex-col gap-x-4 gap-y-4 mt-6">
        <BursaryItem
          money={true}
          title="Amount"
          answer={props?.receipt?.amount as number}
        />
      </div>

      <button
        className={styles.header + " text-center w-full mt-5 mb-3"}
        onClick={() => {
          props.setModalOpen(false);
        }}
      >
        Print
      </button>
    </ModalOverlay>
  );
};

const BursaryItem: FC<{
  title: string;
  answer: string | number;
  money: boolean;
}> = (props) => {
  return (
    <article>
      <h2
        className={`text-darkGray text-[0.9rem] inline-block pr-5 font-bold ${
          props.money && "min-w-[140px]"
        }`}
      >
        {props.title || ""}
      </h2>
      <h3 className="text-blackText text-[1rem] inline-block">
        {props.answer || ""}
      </h3>
    </article>
  );
};

const styles = {
  header: "bg-blackText text-white p-3 rounded-md",
};

export default BursaryReceipt;
