import React from "react";
import TableLayout, {
  SingleTableHeader,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";

const fullWidth = 296;

const RatingIndices = () => {
  return (
    <div className="max-h-fit">
      <TableLayout>
        <TableHeader>
          <SingleTableHeader
            bg="#181619"
            color="white"
            width={fullWidth}
            text={<p className="text-[.8rem]">RATING INDICES</p>}
          />
        </TableHeader>
        <TableRowsContainer>
          <p className={styles.p}>
            5. Maintains an excellent degree of observable trait
          </p>
          <p className={styles.p}>
            4. Maintains a high level of observable trait
          </p>
          <p className={styles.p}>3. Acceptable level of observable trait</p>
          <p className={styles.p}>
            2. Shows minimal regard for observable trait
          </p>
          <p className={styles.p}>1. Has no regard for observable trait</p>
        </TableRowsContainer>
      </TableLayout>
    </div>
  );
};

const styles = {
  p: "text-[.75rem] font-bold px-2 py-1",
};

export default RatingIndices;
