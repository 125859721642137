import { MdCheck, MdClose } from "react-icons/md";
import {
    IClassAttendance,
    IFetchStudentsAttendance,
    IGeneratedClasses,
    ISingleStudentAttendance,
} from "../types/db.types";

// For displaying the present & absent icon
const displayIcon = (present: boolean): React.ReactElement => {
    if (present === true) {
        return <MdCheck className="text-[1rem] text-green-600" />;
    } else if (present === false) {
        return <MdClose className="text-[1rem] text-red-600" />;
    } else {
        return <></>;
    }
};

export const getStudentDayAttendance = (
    attendance: ISingleStudentAttendance[],
    week: number,
    day: string,
    time: "morning" | "afternoon"
) => {
    const studentAttendance = attendance
        ?.filter((attendance) => attendance.week == week)
        ?.find((attendance) => attendance.day === day);

    if (studentAttendance?.isHoliday) return <span>H</span>;

    const isPresent = studentAttendance?.attended[time];

    return displayIcon(isPresent as boolean);
};

export const getClassDayAttendance = (
    attendance: IClassAttendance[],
    studentId: string,
    week: string,
    day: string,
    time: "morning" | "afternoon"
) => {
    const dayAttendance = attendance?.find((attendance) => attendance.week == week && attendance.day === day);

    // h means holiday
    if (dayAttendance?.isHoliday) return <span>H</span>;

    const student = dayAttendance?.students?.find((student) => student?.studentId?._id == studentId);

    return displayIcon(student?.attended?.[time] as boolean);
};

export const getStudentTotalAttendance = (attendance: IClassAttendance[], studentId: string) => {
    const total = attendance?.reduce((total, singleDay) => {
        if (!singleDay?.isHoliday) {
            const students = singleDay?.students;
            const studentAttendance = students?.find(
                (student) => student.studentId._id === studentId
            ) as IFetchStudentsAttendance;

            if (studentAttendance) {
                if (studentAttendance.attended.morning) {
                    total++;
                }

                if (studentAttendance.attended.afternoon) {
                    total++;
                }
            }
        }
        return total;
    }, 0);

    return total;
};

export const getStudentTotalDayAttendance = (attendance: IClassAttendance[], studentId: string) => {
    const total = attendance?.reduce((total, singleDay) => {
        if (!singleDay?.isHoliday) {
            const students = singleDay?.students;
            const studentAttendance = students?.find(
                (student) => student.studentId._id === studentId
            ) as IFetchStudentsAttendance;

            if (studentAttendance) total++;
        }
        return total;
    }, 0);

    return total;
};

export const getTotalGenderAttendace = (attendance: IClassAttendance[], gender: string) => {
    const total = attendance?.reduce((total, singleDay) => {
        if (!singleDay?.isHoliday) {
            const allStudentsByGender = singleDay?.students?.filter((student) => student.studentId.gender === gender);

            // map through the students and check if they attended for that day
            allStudentsByGender?.forEach((student) => {
                if (student?.attended?.morning) {
                    total++;
                }
                if (student?.attended?.afternoon) {
                    total++;
                }
            });
        }

        return total;
    }, 0);

    return total;
};

export const getTotalGenderAttendaceAbsence = (attendance: IClassAttendance[], gender: string) => {
    const total = attendance?.reduce((total, singleDay) => {
        if (!singleDay?.isHoliday) {
            const allStudentsByGender = singleDay?.students?.filter((student) => student.studentId.gender === gender);

            // map through the students and check if they attended for that day
            allStudentsByGender?.forEach((student) => {
                if (!student?.attended?.morning) {
                    total++;
                }
                if (!student?.attended?.afternoon) {
                    total++;
                }
            });
        }

        return total;
    }, 0);

    return total;
};

export const getTotalStudentsByGender = (classData: IGeneratedClasses, gender: string) => {
    const students = classData?.students?.filter((student) => student.gender === gender);

    return students?.length;
};

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
interface SchoolTotalRes {
    weeks: number;
    days: number;
}

export const getTotalDaysSchoolOpened = (attendance: IClassAttendance[]): SchoolTotalRes => {
    // get the max week
    const maxWeek = attendance?.reduce((max, attendance) => {
        if (max < (attendance.week as number)) {
            max = attendance.week as number;
        }
        return max;
    }, 0);

    let count = 0;
    // loop through the weeks, then for each of the days, if school is opened add it to the count
    Array(maxWeek)
        ?.fill(null)
        ?.forEach((_, index) => {
            days?.forEach((day) => {
                const dayAttendance = attendance?.find(
                    (attendance) => attendance.day === day && attendance.week === index + 1
                );

                if (dayAttendance && !dayAttendance.isHoliday) {
                    count++;
                }
            });
        });

    return {
        weeks: maxWeek,
        days: count,
    };
};

export const getTotalHolidays = (attendance: IClassAttendance[]): number => {
    let count = 0;

    if (attendance) {
        for (const singleDay of attendance) {
            if (singleDay.isHoliday) {
                count++;
            }
        }
    }

    return count;
};
