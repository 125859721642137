import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useLocation, useParams } from "react-router-dom";
import getAccountType from "../../../utils/getAccountType";
import { useQuery } from "react-query";
import { baseHttp } from "../../../axios.config";
import { IChat, IMessage, IUser } from "../../../types/db.types";

export const useGetChatBuddies = (search: string, setIsOpen: Dispatch<SetStateAction<boolean>>) => {
    const location = useLocation();

    const { data, isLoading, refetch, isRefetching } = useQuery({
        queryKey: "getChatBuddies",
        queryFn: async () => {
            if (search) {
                const response = await baseHttp.get<IUser[]>(`/chat/users/${search}`);
                return response?.data;
            } else {
                return [];
            }
        },
    });

    useEffect(() => {
        if (search.length >= 3) {
            setIsOpen(true);
            refetch();
        }
    }, [search]);

    return { data, isLoading, refetch, isRefetching };
};

export const useGetUserConversations = () => {
    const { data, isLoading } = useQuery<IChat[]>({
        queryKey: "GetUserConversations",
        queryFn: async () => {
            const response = await baseHttp.get<IChat[]>("/chat");
            return response?.data;
        },
    });

    return {
        data,
        isLoading,
    };
};

export const useGetSingleConversation = () => {
    const { id } = useParams();

    const { data, isLoading } = useQuery<IChat>({
        queryKey: "getSingleConversation",
        queryFn: async () => {
            const response = await baseHttp.get(`/chat/${id}`);
            return response?.data;
        },
    });

    return { data, isLoading };
};

export const useGetConversationMessages = () => {
    const { id } = useParams();

    const { data, isLoading } = useQuery<IMessage[]>({
        queryKey: "getConversationMessages",
        queryFn: async () => {
            const response = await baseHttp.get(`/chat/${id}/message`);
            return response?.data;
        },
    });

    return { data, isLoading };
};

export const useGetUnreadMessages = () => {
    const { pathname } = useLocation();

    const { data, refetch, isLoading } = useQuery<number>({
        queryKey: `/${pathname}/getUnreadMessages`,
        queryFn: async () => {
            const response = await baseHttp.get("/chat/user/message/unread");
            return response?.data?.count;
        },
    });

    useEffect(() => {
        refetch();
    }, [pathname]);

    return { data, isLoading };
};
