import React, { FC, ReactElement } from "react";
import { ButtonsType } from "../../types/componentsProps.types";

const OutlineButton: FC<ButtonsType> = ({
  icon,
  text,
  fullWidth,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={
        styles.buttons +
        (fullWidth ? "min-w-full " : "min-w-fit ") +
        className +
        " text-darkGray"
      }
    >
      {icon && <span>{icon}</span>}
      {text}
    </button>
  );
};

export const BlackOutlineButton: FC<ButtonsType> = ({
  icon,
  text,
  fullWidth,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={
        styles.buttons +
        (fullWidth ? "min-w-full " : "min-w-fit ") +
        className +
        " text-blackText border-blackText hover:text-white hover:bg-blackText transition-all duration-300"
      }
    >
      {icon && <span>{icon}</span>}
      {text}
    </button>
  );
};

const ButtonFilled: FC<ButtonsType> = ({
  icon,
  text,
  fullWidth,
  onClick,
  type,
  className,
}) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      className={
        styles.buttons +
        ` ${className} ` +
        (fullWidth ? "min-w-full" : "min-w-fit") +
        " border-blackText bg-blackText text-white"
      }
    >
      {icon && <span>{icon}</span>}
      {text}
    </button>
  );
};

const styles = {
  buttons:
    "px-3 py-[.46rem] text-[13px] flex items-center justify-center capitalize text-center border-[1.5px] rounded-md gap-x-2 flex-nowrap ",
};

export { ButtonFilled, OutlineButton };
