/**
 * Page for adding student information for the term
 */

import React, { ChangeEvent, useEffect, useState } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { useGetSingleStudent } from "../../../api/hooks/admin/students/students.service";
import { useParams } from "react-router-dom";
import { ButtonFilled } from "../../../components/UI/Buttons";
import Input from "../../../components/UI/Input";
import { IAffectiveDomains } from "../../../types/static.types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addStudentReportInfo } from "../../../api/thunks/teacher/teacher.service";
import Swal from "sweetalert2";
import { useGetStudentReportInfo } from "../../../api/hooks/teachers/teachers.service";

const ReportCardInformation = () => {
  const [height, setHeight] = useState<string | number>("");
  const [weight, setWeight] = useState<string | number>("");
  const [officeHeld, setOfficeHeld] = useState<string>("");
  const [classTeacherComment, setClassTeacherComment] = useState<string>("");
  const [adminComment, setAdminComment] = useState<string>("");
  const [affectiveDomains, setAffectiveDomains] = useState<IAffectiveDomains>({
    sport: 0,
    attentiveness: 0,
    neatness: 0,
    fluency: 0,
    handwriting: 0,
    punctuality: 0,
  });

  const { id: studentId } = useParams();
  const dispatch = useDispatch();
  const {
    data: reportInfo,
    isLoading,
    refetch,
  } = useGetStudentReportInfo(
    `/teacher/student/${studentId}/report-card-information`
  );

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = await dispatch(
      addStudentReportInfo({
        weight,
        height,
        classTeacherComment,
        affectiveDomains,
        officeHeld,
        _id: studentId,
      })
    );

    if (!data.error) {
      await Swal.fire({
        title: "Student Information",
        text: "You have successfully added student report card information",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      refetch();
    }
  };

  useEffect(() => {
    if (reportInfo && !isLoading) {
      setHeight(reportInfo?.height);
      setWeight(reportInfo?.weight);
      setAffectiveDomains(() => {
        if (reportInfo?.affectiveDomains) {
          const { _id, ...rest } = reportInfo?.affectiveDomains;
          return rest;
        } else {
          return {
            sport: 0,
            attentiveness: 0,
            neatness: 0,
            fluency: 0,
            handwriting: 0,
            punctuality: 0,
          };
        }
      });
      setClassTeacherComment(reportInfo?.classTeacherComment);
      setOfficeHeld(reportInfo?.officeHeld);
    }
  }, [reportInfo]);

  return (
    <form className="" onSubmit={submit}>
      <h2 className="text-[1.3rem] font-medium">Add Report Card Information</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-8 gap-[1rem]">
        <div>
          <label htmlFor="" className={styles.labelText}>
            Height {"(inches)"}
          </label>
          <Input
            required={false}
            type="number"
            name="height"
            placeholder="Enter Height"
            value={height}
            onChange={(e: any) => {
              setHeight(e.target.value);
            }}
          />
        </div>

        <div>
          <label htmlFor="" className={styles.labelText}>
            Weight {"(kg)"}
          </label>
          <Input
            required={false}
            type="number"
            name="weight"
            placeholder="Enter Weight"
            value={weight}
            onChange={(e: any) => {
              setWeight(e.target.value);
            }}
          />
        </div>

        <div>
          <label htmlFor="" className={styles.labelText}>
            Office Held
          </label>
          <Input
            required={false}
            type="text"
            name="officeHeld"
            placeholder="Enter Office Held"
            value={officeHeld}
            onChange={(e: any) => {
              setOfficeHeld(e.target.value);
            }}
          />
        </div>
      </div>

      {/* Affective domains */}
      <h3 className={styles.labelText + " mt-8 mb-4"}>
        Affective Domains {"(max: 5)"}
      </h3>
      <div className="flex items-center flex-wrap gap-[1rem]">
        {Object.entries(affectiveDomains || {})?.map((key, index) => {
          return (
            <div className="flex items-center gap-[.4rem]" key={index}>
              <p className="capitalize">{key[0]}</p>
              <input
                type="number"
                value={key[1] || ""}
                min={0}
                max={5}
                required={false}
                onChange={(e) => {
                  if (parseInt(e.target.value) <= 5 || !e.target.value) {
                    setAffectiveDomains({
                      ...affectiveDomains,
                      [key[0]]: e.target.value,
                    });
                  } else {
                    setAffectiveDomains({ ...affectiveDomains, [key[0]]: 5 });
                  }
                }}
                className="w-[50px] h-[40px] outline-none border-2 p-2 focus:border-blackText rounded-md"
              ></input>
            </div>
          );
        })}
      </div>

      {/* Comments */}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[1rem] mt-8">
        <div>
          <label htmlFor="classTeacherComment" className={styles.labelText}>
            Enter Comment
          </label>

          <Input
            type="text"
            placeholder="Enter Comment"
            value={classTeacherComment}
            name="classTeacherComment"
            required={false}
            onChange={(e: any) => {
              setClassTeacherComment(e.target.value);
            }}
          />
        </div>
      </div>

      <ButtonFilled
        type="submit"
        className="block max-w-fit ml-auto !min-w-[200px] mt-6"
        text="Add Information"
      />
    </form>
  );
};

const styles = {
  back_icon: "flex items-center gap-x-2 text-[1rem] font-bold",
  header:
    "mt-8 flex lg:items-center gap-x-4 gap-y-4 md:flex-row flex-col justify-between",
  labelText: "block mb-2 font-bold text-blackText",
};

export default ReportCardInformation;
