import { IStudentScores } from "../types/db.types";

enum IGrades {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
}

enum IRemarks {
  A = "EXCELLENT",
  B = "VERY GOOD",
  C = "GOOD",
  D = "FAIR",
  E = "PASS",
  F = "FAIL",
}

interface IGradeCount {
  A: number;
  B: number;
  C: number;
  D: number;
  E: number;
  F: number;
}

export const getGrade = (score: number): IGrades => {
  if (score >= 70) {
    return IGrades?.A;
  } else if (score < 70 && score >= 60) {
    return IGrades.B;
  } else if (score < 60 && score >= 50) {
    return IGrades.C;
  } else if (score < 50 && score >= 40) {
    return IGrades.D;
  } else if (score < 40 && score >= 30) {
    return IGrades.E;
  } else {
    return IGrades.F;
  }
};

export const getRemark = (score: number): IRemarks => {
  return IRemarks?.[getGrade(score)];
};

export const getTotalScore = (subjects: IStudentScores[]): number => {
  let total = 0;

  subjects?.forEach((subject) => {
    total +=
      subject?.examScore +
      subject?.firstCA +
      subject?.secondCA +
      subject?.thirdCA;
  });

  return total;
};

export const countGrades = (scores: IStudentScores[]): IGradeCount => {
  let count: IGradeCount = { A: 0, B: 0, C: 0, D: 0, E: 0, F: 0 };

  for (const score of scores) {
    const total =
      score.firstCA + score.secondCA + score.thirdCA + score.examScore;

    count[getGrade(total)]++;
  }

  return count;
};
