import React, { useState } from "react";
import ModalOverlay from "../../UI/ModalOverlay";
import { MdClose } from "react-icons/md";
import Input from "../../UI/Input";
import { ButtonFilled } from "../../UI/Buttons";
import { useDispatch } from "react-redux";
import { editUserPhoneNumber } from "../../../api/thunks/admin/admin.service";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { formatPhoneNumber } from "../../../utils/dataHandling";
import { openErrorModal } from "../../../store/slices/others/handlerSlice";
import getAccountType from "../../../utils/getAccountType";

interface Props {
    userId: string;
}

export default function ChangeUserPhoneNumber({ userId }: Props) {
    const [number, setNumber] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const location = useLocation();

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);

        if (number.toString().length < 10) {
            dispatch(openErrorModal({ errorText: "Phone number should not be less than 10 characters" }));
            return;
        }

        const data = await dispatch(editUserPhoneNumber({ phoneNumber: formatPhoneNumber(number), id: userId }));

        setIsLoading(false);
        if (!data.error) {
            await Swal.fire({
                title: "Change User Phone Number",
                text: "You have successfully edited this user's phone number",
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });
            setModalOpen(false);
        }
    };

    return (
        <>
            {modalOpen && (
                <>
                    <ModalOverlay modalWidth={500}>
                        <header className="flex items-center gap-2 justify-between">
                            <h2 className="font-bold text-[1.1rem]">Change Phone Number</h2>
                            <span onClick={() => setModalOpen(false)}>
                                <MdClose size={26} className="text-red-500" />
                            </span>
                        </header>

                        <form className="mt-8" onSubmit={onSubmit}>
                            <Input
                                type="number"
                                placeholder="Enter phone number"
                                required={true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumber(e.target.value)}
                                isPhoneNumber={true}
                                value={number}
                                name="phone-number"
                                minLength={10}
                            />

                            <ButtonFilled type="submit" fullWidth={true} text={"Submit"} className="!mt-8" />
                        </form>
                    </ModalOverlay>
                </>
            )}

            <ButtonFilled onClick={() => setModalOpen(true)} text={"Change Phone Number"} />
        </>
    );
}
