import { tableHeaders } from "../types/static.types";
import profileImg from "../assets/images/profile-img.jpg";

export const teachersHeaders: tableHeaders[] = [
    { text: "Name", width: 200 },
    { text: "Subject", width: 150 },
];

export const teachers = [
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },

    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
    {
        image: profileImg,
        name: "Adedayo Akindayomi",
        subject: "Agricultural Science",
    },
];

export const classmatesHeaders: tableHeaders[] = [
    { text: "Name", width: 200 },
    { text: "Gender", width: 120 },
];

export const classmatesList = [
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
    { image: profileImg, name: "Jeroboam", gender: "male" },
];

export const subjectScoresHeaders: tableHeaders[] = [
    {
        text: "Subject",
        width: 200,
    },
    {
        text: "First C/A",
        width: 100,
    },
    {
        text: "Second C/A",
        width: 100,
    },
    {
        text: "Third C/A",
        width: 100,
    },
    {
        text: "Exam",
        width: 100,
    },
    {
        text: "Total",
        width: 100,
    },
    // NOT AVAILABLE IN FREE VERSION
    // {
    //   text: "Status",
    //   width: 200,
    // },
];

export const subjectsScores = [
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
    {
        subject: "English",
        firstCA: 10,
        secondCA: 10,
        thirdCA: 10,
        exam: 35,
    },
];
