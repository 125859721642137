import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IAllParents, IAllStaffs, IAllStudents } from '../../types/db.types';

type listType = IAllStudents | IAllStaffs | IAllParents;

type useSearchReturn = {
  list: listType[];
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setList: Dispatch<SetStateAction<listType[]>>;
};

export const useSearch = (data: listType[]): useSearchReturn => {
  const [list, setList] = useState<listType[]>(data || []);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (data != undefined) {
      setList(data);
    }
  }, [data]);

  useEffect(() => {
    if (searchValue === '') {
      setList(data);
    } else {
      setList(
        (data as any)?.filter(
          (acct: IAllStaffs | IAllParents | IAllStudents) => {
            const fullName = `${acct.firstName} ${acct.lastName}`;

            return fullName.toLowerCase().startsWith(searchValue.toLowerCase());
          }
        )
      );
    }
  }, [searchValue]);

  return {
    list,
    searchValue,
    setSearchValue,
    setList,
  };
};
