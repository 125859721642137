import React from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";

const socials = [
  { icon: <BsFacebook />, href: "facebook.com" },
  { icon: <BsTwitter />, href: "https://twitter.com/EdssistanceNg" },
  {
    icon: <BsInstagram />,
    href: "https://instagram.com/edssistance?igshid=OGQ5ZDc2ODk2ZA==",
  },
  {
    icon: <BsLinkedin />,
    href: "https://www.linkedin.com/company/edssistance/",
  },
  {
    icon: <BsYoutube />,
    href: "https://youtube.com/@Edssistance?si=Di8Jjg_hlm5H6pLH",
  },
];

const Footer = () => {
  return (
    <footer className="bg-lightGray mt-10">
      <div className="max-w-[1200px]  p-[1rem] lg:px-[3rem] py-[3rem] md:py-[4rem] mx-auto min-h-[100px] grid grid-cols-1 md:grid-cols-2 gap-[1.5rem]">
        <div>
          <img src={logo} alt="logo" className="w-[150px]" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <ul>
            <li className="text-[0.9rem] font-bold block mb-4">
              <Link to={"/about"}>About Us</Link>
            </li>

            <li className="text-[0.9rem] font-bold block mb-4">
              <Link to={"/faq"}>FAQs</Link>
            </li>

            <li className="text-[0.9rem] font-bold block mb-4">
              <Link to={"/contact"}>Contact</Link>
            </li>
          </ul>

          <ul>
            <li className="text-[0.9rem] font-bold block mb-4">
              <a href="mailto:edssistance@gmail.com">edssistance@gmail.com</a>
            </li>

            <li className="text-[0.9rem] font-bold block mb-4">
              <a href="tel:2347033317553">+234 703 331 7553</a>
            </li>
          </ul>

          <ul>
            <li className="text-[0.9rem] font-bold mb-4 block">
              Follow Edssistance
            </li>
            <div className="flex items-center gap-[15px]">
              {socials?.map((social, index) => {
                return (
                  <a href={social.href} key={index}>
                    {social.icon}
                  </a>
                );
              })}
            </div>
          </ul>
        </div>
      </div>

      <hr className="border-[1.5px] mt-[1rem] border-white h-[0]" />

      <div className="max-w-[1200px] px-[1rem] flex flex-wrap items-center justify-center md:justify-between lg:px-[3rem] mx-auto py-[2rem] text-[.9rem] gap-[1rem]">
        <p className="cursor-pointer">
          &copy; {new Date().getFullYear()} Edssistance - All Rights Reserved
        </p>

        <div className="flex items-center gap-[1rem] justify-center md:justify-start">
          <p>Terms of use</p>
          <p>Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
