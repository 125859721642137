/**
 * Utils for the functions used to filter the inputs in subject generator and edit subjects
 */
import React from 'react';
import { newTeachersAndSubjectsProps } from '../pages/Admin/Generator/Generate/SubjectGenerator';
import { IAllStaffs } from '../types/db.types';

const addNewSubject = (
  subjects: newTeachersAndSubjectsProps[]
): newTeachersAndSubjectsProps[] => {
  return [
    ...subjects,
    { id: new Date().getTime(), teacher: '', teacherId: '', subject: '' },
  ];
};

const removeSubject = (
  subjects: newTeachersAndSubjectsProps[],
  id: number
): newTeachersAndSubjectsProps[] => {
  return subjects.filter((subject) => subject.id !== id);
};

const getSingleSubject = (
  subjects: newTeachersAndSubjectsProps[],
  id: number
): newTeachersAndSubjectsProps => {
  return subjects?.find?.(
    (subject) => subject.id === id
  ) as newTeachersAndSubjectsProps;
};

const updateSubject = (
  subjects: newTeachersAndSubjectsProps[],
  id: number,
  value: string
): newTeachersAndSubjectsProps[] => {
  return subjects.map((subject) => {
    if (subject.id === id) {
      subject.subject = value;
    }
    return subject;
  });
};

const updateSubjectTeacher = (
  subjects: newTeachersAndSubjectsProps[],
  subjectId: number,
  teacherId: number | string,
  teacher: IAllStaffs
) => {
  return subjects.map((subject) => {
    if (subject.id === subjectId) {
      subject.teacherId = teacherId;
      subject.teacher = teacher;
    }
    return subject;
  });
};

export {
  addNewSubject,
  updateSubject,
  removeSubject,
  getSingleSubject,
  updateSubjectTeacher,
};
