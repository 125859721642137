import React, { useEffect, useMemo, useCallback, useState } from "react";
import { SingleTableRowItem, TableRow } from "../Layouts/TableLayout";
import Input from "../UI/Input";
import { studentBulkUploadTable } from "../../static/bulk-upload.data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { SingleBulkStudent } from "../../types/states.types";
import { editStudent, removeStudent } from "../../store/slices/post/studentBulkUpload";
import SelectContainer from "../UI/SelectContainer";
import { classes } from "../../static/admin&teacher.data";
import { months } from "moment";
import { getYearsInterval } from "../../utils/dateConverter";
import { countryList } from "../../assets/static";
import ImageContainer from "./ImageContainer";
import { BiTrash } from "react-icons/bi";
import {
    useCheckEmail,
    useCheckParentPhoneNumber,
    useCheckPhoneNumber,
    useCheckUsername,
} from "../../api/mutations/auth.mutation";
import DEFAULT_MATCHERS from "../../constants/regex.const";
import { useValidateStudentUpload } from "../../utils/hooks/bulk-upload";
import { StudentBulkChecks } from "../../types/bulk.types";

interface Props {
    index: number;
    updateErrors(errors: StudentBulkChecks, index: number): void;
}

const StudentRow = (props: Props) => {
    const students = useSelector((state: RootState) => state.studentBulk.students);
    const student = useMemo(() => students[props.index], [students]);
    const dispatch = useDispatch();

    const changeValue = useCallback((key: keyof SingleBulkStudent, value: any) => {
        dispatch(editStudent([props.index, { key, value }]));
    }, []);

    const checks = useValidateStudentUpload(student);

    useEffect(() => {
        props.updateErrors(checks.errors, props.index);
    }, [checks.errors]);

    return (
        <TableRow className="items-start">
            <SingleTableRowItem width={studentBulkUploadTable[0].width} fitContent={true} className="pt-6">
                <BiTrash
                    className="text-red-600"
                    size={20}
                    cursor={"pointer"}
                    title="remove student"
                    onClick={() => {
                        dispatch(removeStudent(props.index));
                    }}
                />
            </SingleTableRowItem>
            <SingleTableRowItem width={studentBulkUploadTable[1].width} fitContent={true}>
                <ImageContainer
                    update={(file) => {
                        changeValue("imageUrl", file);
                    }}
                    value={student.imageUrl as File}
                />
            </SingleTableRowItem>
            <SingleTableRowItem width={studentBulkUploadTable[2].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="firstName"
                    value={student.firstName}
                    placeholder="enter firstname"
                    className="!w-full"
                    onChange={(e) => changeValue("firstName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[3].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="middleName"
                    value={student.middleName}
                    placeholder="enter middlename"
                    className="!w-full"
                    onChange={(e) => changeValue("middleName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[4].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="lastName"
                    value={student.lastName}
                    placeholder="enter lastname"
                    className="!w-full"
                    onChange={(e) => changeValue("lastName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[5].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="userName"
                    value={student.userName}
                    placeholder="enter username"
                    className="!w-full"
                    onChange={(e) => changeValue("userName", e.target.value)}
                    isLoading={checks.loading.userName}
                    loadingText={"checking username"}
                    isError={checks.errors.userName}
                    errorText="already exists!"
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[6].width} fitContent={true}>
                <Input
                    required={false}
                    type="email"
                    name="email"
                    value={student.email}
                    placeholder="enter email"
                    className="!w-full"
                    onChange={(e) => changeValue("email", e.target.value)}
                    isLoading={checks.loading.email}
                    loadingText={"checking email"}
                    isError={checks.errors.email}
                    errorText="already exists!"
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[7].width} fitContent={true}>
                <Input
                    required={false}
                    type="number"
                    name="phoneNumber"
                    value={student.phoneNumber}
                    placeholder="enter phone number"
                    className="!w-full"
                    onChange={(e) => changeValue("phoneNumber", e.target.value)}
                    minLength={10}
                    isLoading={checks.loading.phone}
                    loadingText={"checking  number"}
                    isError={checks.errors.phone}
                    errorText="already exists!"
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[8].width} fitContent={true}>
                <Input
                    required={true}
                    type="date"
                    name="dateOfBirth"
                    value={student.dateOfBirth}
                    placeholder=""
                    className="!w-full"
                    onChange={(e) => changeValue("dateOfBirth", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[9].width} fitContent={true}>
                <select
                    value={student.gender}
                    required={true}
                    className="input-field w-full !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("gender", value);
                    }}
                >
                    <option value={"male"}>M</option>
                    <option value={"female"}>F</option>
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[10].width} fitContent={true}>
                <select
                    value={student.religion}
                    required={true}
                    className="input-field w-full !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("religion", value);
                    }}
                >
                    <option value={"christainity"}>Christain</option>
                    <option value={"islam"}>Islam</option>
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[11].width} fitContent={true}>
                <select
                    value={student.classDetail}
                    required={true}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("classDetail", value);
                    }}
                >
                    {classes.map((classDet, index) => {
                        return (
                            <option value={classDet} key={index}>
                                {classDet}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[12].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="admissionNumber"
                    value={student.admissionNumber}
                    placeholder="enter admission number"
                    className="!w-full"
                    onChange={(e) => changeValue("admissionNumber", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[13].width} fitContent={true}>
                <select
                    value={student.monthOfAdmission}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("monthOfAdmission", value);
                    }}
                >
                    {months().map((month, index) => {
                        return (
                            <option value={month} key={index}>
                                {month}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[14].width} fitContent={true}>
                <select
                    value={student.yearOfAdmission}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("yearOfAdmission", value);
                    }}
                >
                    {getYearsInterval(2000).map((year, index) => {
                        return (
                            <option value={year} key={index}>
                                {year}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[15].width} fitContent={true}>
                <select
                    value={student.country}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("country", value);
                    }}
                >
                    {countryList.map((country, index) => {
                        return (
                            <option value={country} key={index}>
                                {country}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[16].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="state"
                    value={student.state}
                    placeholder="enter state"
                    className="!w-full"
                    onChange={(e) => changeValue("state", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[17].width} fitContent={true}>
                <Input
                    required={false}
                    type="text"
                    name="city"
                    value={student.city}
                    placeholder="enter city"
                    className="!w-full"
                    onChange={(e) => changeValue("city", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[18].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="parentFirstName"
                    value={student.parentFirstName}
                    placeholder="enter parent first name"
                    className="!w-full"
                    onChange={(e) => changeValue("parentFirstName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[19].width} fitContent={true}>
                <Input
                    required={true}
                    type="text"
                    name="parentLastName"
                    value={student.parentLastName}
                    placeholder="enter parent last name"
                    className="!w-full"
                    onChange={(e) => changeValue("parentLastName", e.target.value)}
                />
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[20].width} fitContent={true}>
                <select
                    value={student.parentRole}
                    required={false}
                    className="input-field !py-[10px]"
                    onChange={(e) => {
                        const value = e.target.selectedOptions.item(0)?.value;

                        changeValue("parentRole", value);
                    }}
                >
                    {["father", "mother"].map((parentRole, index) => {
                        return (
                            <option value={parentRole} key={index}>
                                {parentRole}
                            </option>
                        );
                    })}
                </select>
            </SingleTableRowItem>

            <SingleTableRowItem width={studentBulkUploadTable[21].width} fitContent={true}>
                <Input
                    required={true}
                    type="number"
                    name="parentPhoneNumber"
                    value={student.parentPhoneNumber}
                    placeholder="enter parent phone number"
                    className="!w-full"
                    onChange={(e) => changeValue("parentPhoneNumber", e.target.value)}
                    minLength={10}
                    isLoading={checks.loading.parentPhone}
                    loadingText={"checking number"}
                    isError={checks.errors.parentPhone}
                    errorText="already exists!"
                    isWarning={checks.warnings.parentPhone}
                    warningText="a parent with this phone number exists, proceed if this is the parent's child"
                />
            </SingleTableRowItem>
        </TableRow>
    );
};

export default StudentRow;
