import React from 'react';
import { FiUsers } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import {
  useGetPayAdivce,
  useGetSingleClass,
} from '../../../../api/hooks/admin/generators/generators.service';
import EditPayAdviceContainers from '../../../../components/Admin/EditPayAdviceContainers';
import DashboardLayout from '../../../../components/Layouts/DashboardLayout';
import BackButton from '../../../../components/UI/BackButton';

const EditPayAdvice = () => {
  const { id } = useParams();

  const { data: classDetail } = useGetSingleClass(id as string);

  const { data: payAdviceItems, refetch } = useGetPayAdivce(id as string);

  return (
    <DashboardLayout pageTitle={'Edit Pay Adivce'}>
      <section className='py-6 sm:p-6 min-h-[90vh]'>
        <article className={styles.content_box + ' border-[#242424]'}>
          <div className='flex items-center gap-x-4 gap-y-4'>
            <span
              className={`${styles.content_box_icon} bg-[#14141436] text-[#242424] `}
            >
              <FiUsers />
            </span>
            <h2 className={styles.content_box_title}>
              {classDetail?.className} {classDetail?.classArm}
            </h2>
          </div>
          <div className='flex items-center justify-center gap-x-2 gap-y-2 mt-2'>
            <p className='text-[15px] cursor-pointer hover:underline text-darkGray font-[300]'>
              {payAdviceItems?.length} pay advice items generated
            </p>
          </div>
        </article>

        <div className='mt-8 flex flex-col gap-x-4 gap-y-4'>
          {payAdviceItems?.map((item) => {
            return (
              <EditPayAdviceContainers
                payAdvice={item}
                key={item?._id}
                refetch={refetch}
              />
            );
          })}
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    'flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center',
  search_icon:
    'flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray',
  buttons:
    'px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit',
  content_box: 'mt-8 p-4 w-full max-w-[300px] rounded-md border-[1.5px]',
  content_box_icon:
    'w-[30px] h-[30px] rounded-full flex justify-center items-center',
  content_box_title: 'text-[1rem] font-bold text-blackText flex flex-col',
};

export default EditPayAdvice;
