import React, { Dispatch, SetStateAction } from "react";
import { ISubscriptionPlanName } from "../../types/db.types";
import { IPlanInfo, plansProps } from "../../types/static.types";
import { BiCheck } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";

interface Props extends IPlanInfo {
  setSelectedPlan?: Dispatch<SetStateAction<ISubscriptionPlanName>>;
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
}

const PlanInfo = ({
  name,
  description,
  price,
  setModalOpen,
  setSelectedPlan,
  features,
  isComingSoon,
}: Props) => {
  return (
    <article className="w-full p-6 bg-white flex-1 shadow-md rounded-md hover:shadow-lg">
      <h1 className="text-[#333] text-[24px] font-[600] text-center capitalize">
        {name} {isComingSoon && "(coming soon)"}
      </h1>
      <p className="text-center text-darkGray text-[1rem] mt-1 max-w-[300px] mx-auto">
        {description}
      </p>
      <h2 className="text-[26px] mt-4 font-bold text-start">
        ₦ {price.toLocaleString()}{" "}
        <sup className="text-[1rem] text-darkGray mt-2 font-light">/ month</sup>
      </h2>
      {/* <Link to={`/admin/billing/${name.toLowerCase()}`}> */}
      <button
        className={` mt-3 w-full font-[500] border-[1.5px] border-black rounded-md text-black cursor-pointer p-3 hover:bg-blackText hover:!text-white transition-all duration-300 bg-white`}
        onClick={() => {
          setModalOpen && setModalOpen(true);
          setSelectedPlan && setSelectedPlan(name);
        }}
      >
        Get Started Now
      </button>
      {/* </Link> */}
      <main className="w-full flex flex-col gap-y-3 mt-4">
        {features.map((feature, index: number) => {
          return (
            <div className="w-full flex items-center gap-x-3" key={index}>
              <span
                className={`w-[25px] h-[25px] rounded-full flex justify-center items-center ${
                  feature.available
                    ? "bg-[#d1fae5] text-green-600 text-[1.25rem]"
                    : "text-[14px] bg-lightGray text-[#666]"
                }`}
              >
                {feature.available ? <BiCheck /> : <FaTimes />}
              </span>
              <p
                className={`${
                  feature.available ? "text-[#333]" : "text-[#666]"
                } text-[1rem]`}
              >
                {feature.name}
              </p>
            </div>
          );
        })}
      </main>
    </article>
  );
};

export default PlanInfo;
