import React from 'react';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import TableLayout, {
  TableRow,
  TableRowsContainer,
  SingleTableRowItem,
  TableHeader,
  SingleTableHeader,
} from '../../components/Layouts/TableLayout';
import { teachers, teachersHeaders } from '../../static/student.data';
import { ContentBoxWithAvatar } from '../../components/UI/ContentBox';
import { FiUser } from 'react-icons/fi';
import { useGetTeachers } from '../../api/hooks/students/students.service';

const Teachers = () => {
  const { data } = useGetTeachers();

  return (
    <DashboardLayout pageTitle={'Teachers'}>
      <section className='py-6 sm:p-6 min-h-screen'>
        <div className='mb-4 max-w-[500px]'>
          <ContentBoxWithAvatar
            hasButton={false}
            header={'Class Teacher'}
            avatar={<FiUser />}
          >
            <div className='p-4'>
              <div className='flex items-center gap-x-4 cursor-pointer'>
                <img
                  className='w-[80px] h-[80px] rounded-full object-cover'
                  alt={data?.classTeacher?.firstName}
                  loading='lazy'
                  src={data?.classTeacher?.imageUrl}
                />
                <p className='font-bold text-[#222] cursor-pointer hover:underline'>
                  {data?.classTeacher?.firstName} {data?.classTeacher?.lastName}
                </p>
              </div>
            </div>
          </ContentBoxWithAvatar>
        </div>
        <TableLayout>
          <TableHeader>
            {teachersHeaders.map((header, index) => {
              return <SingleTableHeader key={index} {...header} />;
            })}
          </TableHeader>
          <TableRowsContainer>
            {data?.subjectTeachers?.map((subject, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={teachersHeaders[0].width}>
                    <div className='flex items-center gap-x-2 cursor-pointer'>
                      <img
                        className='w-[25px] h-[25px] rounded-full object-cover'
                        alt={subject?.teacher?.firstName}
                        loading='lazy'
                        src={subject?.teacher?.imageUrl}
                      />
                      <p className='font-bold text-[#222] cursor-pointer hover:underline'>
                        {subject?.teacher?.firstName}{' '}
                        {subject?.teacher?.lastName}
                      </p>
                    </div>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={teachersHeaders[1].width}>
                    <p>{subject?.title}</p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </section>
    </DashboardLayout>
  );
};

export default Teachers;
