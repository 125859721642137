import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetPaymentBreakdown } from "../../../api/hooks/admin/students/students.service";
import { getUserFromLocalStorage } from "../../../utils/localStorage";
import { IUser } from "../../../types/db.types";
import ModalOverlay from "../../UI/ModalOverlay";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
  TableSubheader,
} from "../../Layouts/TableLayout";
import { studentPayAdviceHeaders } from "../../../static/admin&teacher.data";
import exportPDF from "../../../utils/exportPDF";

export const PayAdviceModal = ({
  setPayAdviceModalOpened,
}: {
  setPayAdviceModalOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const { id } = useParams();

  const url = location.pathname.includes("/admin")
    ? "/school/student/bills-breakdown/" + id
    : `/parents/kid/bills-breakdown/${id}`;

  const { data, isLoading } = useGetPaymentBreakdown(url);

  const [total, setTotal] = useState({ charge: 0, paid: 0 });

  const school = (getUserFromLocalStorage() as IUser).school;

  const print = async () => {
    const container = document.getElementById(
      "pay-advice-container"
    ) as HTMLElement;

    container.style.padding = "1rem";

    await exportPDF(container);

    container.style.padding = "0rem";
  };

  useEffect(() => {
    if (data && !isLoading) {
      const grandTotal = data?.reduce(
        (total, eachItem) => {
          total.charge += eachItem?.payAdvice.charge;

          total.paid += eachItem.paystack + eachItem.cash;

          return total;
        },
        { charge: 0, paid: 0 }
      );

      setTotal(grandTotal);
    }
  }, [data]);

  return (
    <ModalOverlay modalWidth={800}>
      <div id="pay-advice-container">
        <header className="mb-4 flex items-start gap-[1rem]">
          <img
            src={school?.imageUrl}
            className="w-[50px] h-[50px] rounded-full object-cover object-center"
            alt=""
          />
          <div>
            <p className="font-bold">{school?.schoolName}</p>
            <p className="text-[14px]">{school?.address}</p>
          </div>
        </header>

        <h3 className="text-blackText text-[1.6rem] mb-3 text-center font-[600]">
          Pay Advice 2022/2023 {school?.term} term
        </h3>
        <div className="w-full sm:px-0 px-2">
          <TableLayout>
            <TableHeader>
              {studentPayAdviceHeaders.map((header, index) => {
                return !header?.subtitles ? (
                  <SingleTableHeader
                    key={index}
                    {...header}
                    bg="#888"
                    color="white"
                  />
                ) : (
                  <TableSubheader
                    {...header}
                    bg="#888"
                    color="white"
                    key={index}
                    subtitles={header?.subtitles as any}
                  />
                );
              })}
            </TableHeader>
            <TableRowsContainer>
              <>
                {data?.map((bill, index) => {
                  const balance =
                    bill.payAdvice.charge - bill.cash - bill.paystack;

                  return (
                    <TableRow key={index}>
                      <SingleTableRowItem
                        width={studentPayAdviceHeaders[0].width}
                        bg="#181619"
                        color="white"
                      >
                        <h2 className="pl-2 flex justify-center items-center text-[13px] font-bold h-full">
                          {bill.payAdvice?.item}
                        </h2>
                      </SingleTableRowItem>

                      <SingleTableRowItem
                        width={studentPayAdviceHeaders[1].width}
                      >
                        <p>
                          {bill?.payAdvice.charge > 0
                            ? bill?.payAdvice.charge.toLocaleString()
                            : "-"}
                        </p>
                      </SingleTableRowItem>
                      <SingleTableRowItem
                        width={studentPayAdviceHeaders[2].width}
                        noPadding={true}
                      >
                        <div className="flex items-center text-center px-2">
                          <p className="flex-1 w-[50%] py-4 flex flex-col gap-y-1 text-[14px]` border-r-[1.5px]">
                            {bill.cash || "-"}
                          </p>
                          <p className="flex-1 w-[50%] py-4 flex flex-col gap-y-1 text-[14px]`">
                            <p>{bill.paystack || "-"}</p>
                          </p>
                        </div>
                      </SingleTableRowItem>
                      <SingleTableRowItem
                        width={studentPayAdviceHeaders[3].width}
                      >
                        <p>{balance || "-"}</p>
                      </SingleTableRowItem>
                      <SingleTableRowItem
                        width={studentPayAdviceHeaders[4].width}
                      >
                        <>
                          {balance === 0 ? (
                            <p className="px-2 py-1 m-0  max-w-fit rounded-[1.25rem] font-bold text-[13px] text-green-700 bg-green-100">
                              Paid
                            </p>
                          ) : balance > 0 && balance < bill.payAdvice.charge ? (
                            <p className="px-2 py-1 m-0  max-w-fit rounded-[1.25rem] font-bold text-[13px] text-orange-700 bg-orange-100">
                              Partial
                            </p>
                          ) : (
                            <p className="px-2 py-1 m-0  max-w-fit rounded-[1.25rem] font-bold text-[13px] text-red-700 bg-red-100">
                              Not Paid
                            </p>
                          )}
                        </>
                      </SingleTableRowItem>
                    </TableRow>
                  );
                })}
                {/* ============== FOR THE GRAND TOTAL =============== */}
                <TableRow>
                  <SingleTableRowItem
                    width={studentPayAdviceHeaders[0].width}
                    bg="#181619"
                    color="white"
                  >
                    <h2 className="pl-2 flex justify-center items-center text-[13px] font-bold h-full">
                      {"Grand Total"}
                    </h2>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={studentPayAdviceHeaders[1].width}>
                    <p className="font-bold">{total.charge}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem
                    width={studentPayAdviceHeaders[2].width}
                    noPadding={true}
                  >
                    <p className="font-bold w-full h-full flex justify-center items-center">
                      {total.paid}
                    </p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={studentPayAdviceHeaders[3].width}>
                    <p className="font-bold">{total.charge - total.paid}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={studentPayAdviceHeaders[4].width}>
                    <></>
                  </SingleTableRowItem>
                </TableRow>
              </>
            </TableRowsContainer>
          </TableLayout>
        </div>
      </div>
      <div className="flex items-center gap-x-4 gap-y-4 justify-between flex-wrap mt-6">
        <button
          onClick={() => {
            setPayAdviceModalOpened(false);
          }}
          className={`${styles.buttons} text-blackText w-full`}
        >
          Close
        </button>
        <button
          onClick={async () => {
            await print();
            setPayAdviceModalOpened(false);
          }}
          className={`${styles.buttons} text-white bg-blackText w-full`}
        >
          Print
        </button>
      </div>
    </ModalOverlay>
  );
};

const styles = {
  content_rows:
    "grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 p-4 gap-x-4 gap-y-5",
  content: "flex flex-col gap-y-[5px] block",
  content_title: "text-[0.94rem] text-darkGray capitalize font-bold",
  content_text: "text-blackText text-[1rem] capitalize",
  bold_font: "font-bold text-[1rem] text-darkerGray",
  buttons:
    "px-4 py-2 flex items-center border-[1.5px] border-blackText rounded-md font-bold gap-x-4 flex-nowrap sm:min-w-[150px] text-center justify-center w-full sm:w-fit",
};
