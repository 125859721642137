import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useGetTeacherClasses } from "../../../api/hooks/teachers/teachers.service";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
} from "../../../components/Layouts/TableLayout";
import NoItem from "../../../components/UI/NoItem";
import { teacherClassesHeader } from "../../../static/admin&teacher.data";

const Classes = () => {
  const { data } = useGetTeacherClasses();
  const location = useLocation();

  if (!data || data?.length === 0) {
    return (
      <DashboardLayout pageTitle={"My Class"}>
        <NoItem
          title="You have not been added as a class teacher for any class"
          subtitle="Kindly contact your school admin"
        />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle={"My Class"}>
      <section className="py-6 sm:p-6">
        <TableLayout>
          <TableHeader>
            {teacherClassesHeader?.map((header, index) => {
              return <SingleTableHeader key={index} {...header} />;
            })}
          </TableHeader>

          <>
            {data?.map((singleClass, index) => {
              // route to the report card page
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={teacherClassesHeader[0]?.width}>
                    <Link
                      to={
                        location.pathname?.includes("teacher/class")
                          ? `/teacher/class/${singleClass?._id}`
                          : `/teacher/report/${singleClass?._id}/students`
                      }
                      className="hover:underline"
                    >
                      {singleClass?.className} {singleClass?.classArm}
                    </Link>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={teacherClassesHeader[1]?.width}>
                    <p>{singleClass?.students?.length}</p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </>
        </TableLayout>
      </section>
    </DashboardLayout>
  );
};

export default Classes;
