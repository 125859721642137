import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetFullyPiadItems } from "../../../api/hooks/admin/students/students.service";
import { IPartiallyPaid } from "../../../types/db.types";
import { makeCashPayment } from "../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import ConfirmationModal from "../../UI/ConfirmationModal";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../Layouts/TableLayout";

interface IPartialItems extends IPartiallyPaid {
  amount: number;
}

export const CashPayment = () => {
  const slug = useParams();
  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useGetFullyPiadItems(slug?.id as string);

  const [items, setItems] = useState<IPartialItems[]>([]);

  const [confirmationModalOpened, setConfirmationModalOpened] =
    useState<boolean>(false);

  const editItemAmount = (id: string, amount: number) => {
    setItems(
      items?.map((item) => {
        if (item._id === id) {
          if ((amount || 0) <= item.balance) {
            item.amount = amount || 0;
          }
        }
        return item;
      })
    );
  };

  const pay = async () => {
    const totalAmount = items.reduce((acc, curr) => {
      acc += curr.amount;
      return acc;
    }, 0);

    const data = await dispatch(
      makeCashPayment({ items, studentId: slug?.id })
    );
    if (!data?.error) {
      Swal.fire({
        title: "Payment Successful",
        icon: "success",
        text: `You have successfully paid ₦${totalAmount} for this child`,
        timer: 2000,
        showConfirmButton: false,
      });
      setConfirmationModalOpened(false);
      refetch();
    }
  };

  React.useEffect(() => {
    if (data && !isLoading) {
      setItems(data?.partial?.map((item) => ({ ...item, amount: 0 })));
    }
  }, [data]);

  return (
    <>
      {confirmationModalOpened && (
        <ConfirmationModal
          text="Are you sure you want to make this payment"
          onCancel={() => setConfirmationModalOpened(false)}
          onConfirm={pay}
        />
      )}
      <div className="mt-4">
        <h1 className="font-bold text-blackText">Pay Cash</h1>
        <div className="mt-4">
          <p className="text-[1.1rem] text-blackText">
            Items that have been paid fully
          </p>
          <TableLayout>
            <TableHeader>
              <SingleTableHeader width={120} text="Item" />
              <SingleTableHeader width={120} text="Amount" />
            </TableHeader>
            <TableRowsContainer>
              {data?.full?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <SingleTableRowItem width={120}>
                      <p>{item?.item}</p>
                    </SingleTableRowItem>
                    <SingleTableRowItem width={120}>
                      <p>{item?.charge}</p>
                    </SingleTableRowItem>
                  </TableRow>
                );
              })}
            </TableRowsContainer>
          </TableLayout>
        </div>

        {/* display a message if the child has paid for everything */}
        {(data?.partial?.length as number) > 0 ? (
          <>
            <p className="my-4 font-bold text-blackText">
              Select item to pay for
            </p>
            {items?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className="mt-4 grid md:grid-cols-3 grid-cols-1 gap-x-4 gap-y-4">
                    {/* Should have a select container, a box that displays the amount left and an input field to type in the amount left*/}
                    <div className="col-span-2 grid grid-cols-3 gap-x-4 gap-y-4">
                      <div className="col-span-2">
                        <div className="input-field">{item.item}</div>
                      </div>
                      <div className="col-span-1 input-field">
                        {"₦ " + item?.balance}
                      </div>
                    </div>
                    <div className="col-span-1">
                      {/* The field that controls the input */}
                      <input
                        type="number"
                        placeholder="How much do you want pay?"
                        className="input-field"
                        value={item.amount || ""}
                        min={0}
                        max={item.balance}
                        onChange={(e) => {
                          editItemAmount(item._id, parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            })}
            <button
              className="w-full max-w-[400px] bg-blackText text-white rounded-md text-center mt-4 p-2"
              onClick={() => {
                setConfirmationModalOpened(true);
              }}
            >
              Pay
            </button>{" "}
          </>
        ) : (
          <div className="w-full h-[150px] flex justify-center items-center">
            <h2>This child has completed payment for this term</h2>
          </div>
        )}
      </div>
    </>
  );
};
