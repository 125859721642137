import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  useGetBanks,
  useGetBursaryInfo,
} from "../../../api/hooks/admin/school/school.service";
import {
  validateAccount,
  withdrawECash,
} from "../../../api/thunks/admin/admin.service";
import { BanksList } from "../../../components/Admin/BursaryModals";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../../components/UI/Buttons";
import ConfirmationModal from "../../../components/UI/ConfirmationModal";
import { IBank, IRecipient } from "../../../types/db.types";
import { useNavigate } from "react-router-dom";

/**
 * Fetch banks
 * Once they select a bank, allow them to enter account number
 * verify the details
 * ask them to enter amount and pay.
 *
 */

const WithdrawECash = () => {
  /***
   * Let user enter first three letters of the bank name, so we can search
   */

  // bank
  const [bankSearch, setBankSearch] = useState("");
  const [selectedBank, setSelectedBank] = useState<IBank | undefined>(
    undefined
  );
  const [bankListOpen, setBankListOpen] = useState<boolean>(false);
  const [banks, setBanks] = useState<IBank[]>([]);

  // account
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [accountValidated, setAccountValidated] = useState<boolean>(false);
  const [recepient, setRecepient] = useState<IRecipient>({
    account_number: "",
    account_name: "",
    bank_id: "",
  });
  const [amount, setAmount] = useState<string>("");

  //payment
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { data: info } = useGetBursaryInfo();
  const { data: banksList } = useGetBanks();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterBanks = () => {
    const search = bankSearch.toLowerCase();
    const banks = banksList?.filter((bank) => {
      const bank_name = bank.name.toLowerCase();

      if (bank_name.includes(search)) return bank;
    });

    setBanks(banks || []);

    setBankListOpen(true);
  };

  const validate = async () => {
    const data = await dispatch(
      validateAccount({
        account_number: accountNumber,
        bank_code: selectedBank?.code,
      })
    );

    if (!data?.error) {
      setRecepient(data?.payload);
      setAccountValidated(true);
    }
  };

  const withdraw = async () => {
    const data = await dispatch(
      withdrawECash({
        amount: parseInt(amount),
        name: recepient.account_name,
        account_number: recepient.account_number,
        bank_code: selectedBank?.code,
      })
    );

    if (!data?.error) {
      setModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "Pending",
        text: "Your request is being processed, kindly check your transaction history",
        timer: 2000,
      });

      setTimeout(() => {
        navigate("/admin/bursary");
      }, 2000);
    }
  };

  //   search for banks when they enter at least 3 characters
  useEffect(() => {
    if (bankSearch?.length >= 3) {
      filterBanks();
    } else {
      setBankListOpen(false);
    }
  }, [bankSearch]);

  //   always set validated to false if the account number changes
  useEffect(() => {
    setAccountValidated(false);
  }, [accountNumber]);

  return (
    <DashboardLayout pageTitle={"Withdraw E-Cash"}>
      <>
        {modalOpen && (
          <ConfirmationModal
            text={`Are you sure you want to withdraw ₦${
              Number(amount) + 50
            } from your wallet?`}
            onCancel={() => setModalOpen(false)}
            onConfirm={withdraw}
          />
        )}
      </>

      <section className="py-6 sm:p-6 w-full min-h-full">
        {/* Banks */}
        <div className="mt-8 relative">
          <i className="block mb-2 text-darkerGray font-[500]">
            Note: A ₦50 charge will be deducted for this withdrawal
          </i>
          <input
            type="text"
            className="input-field max-w-[300px]"
            placeholder="Enter first three letters of bank name"
            value={bankSearch}
            onChange={(e) => setBankSearch(e.target.value)}
          />
          <div>
            {bankListOpen && (
              <BanksList
                banks={banks}
                selectBank={(bank: IBank) => {
                  setSelectedBank(bank);
                  setBankListOpen(false);
                }}
              />
            )}
          </div>

          <h2 className="mt-2 text-[1.1rem]">
            Selected Bank:{" "}
            <span className="font-bold">{selectedBank?.name}</span>
          </h2>
        </div>

        {selectedBank && (
          <div className="mt-8 grid grid-cols-2 gap-[1.5rem]">
            <div>
              <h2 className="mb-2">Enter account number</h2>

              <input
                type="number"
                required
                minLength={10}
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                className="input-field"
                placeholder="Enter 10 digits account number"
              />

              {!accountValidated && accountNumber.length >= 10 && (
                <div className="mt-4">
                  <ButtonFilled text="Validate Account" onClick={validate} />
                </div>
              )}
            </div>

            <div>
              <h2 className="mb-2 font-[500] text-darkerGray">
                Available Balance
              </h2>
              <p className="input-field">₦{info?.balance}</p>
            </div>
            {accountValidated && recepient.account_name && (
              <>
                <div className="">
                  <label htmlFor="" className="block mb-2">
                    Account Name
                  </label>
                  <div className="input-field font-bold">
                    {recepient?.account_name}
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="block mb-2">
                    Amount
                  </label>
                  <input
                    type="number"
                    className={`input-field`}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter amount"
                  />
                </div>

                <div className="max-w-[300px]">
                  <ButtonFilled
                    text="Withdraw Money"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </section>
    </DashboardLayout>
  );
};

export default WithdrawECash;
