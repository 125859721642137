import React from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import UserSearch from "../../components/Chat/UserSearch";
import Conversations from "../../components/Chat/Conversations";

const StudentChats = () => {
  return (
    <DashboardLayout pageTitle="Chat">
      <section className="w-full min-h-screen py-6 md:p-6">
        <UserSearch />
        <Conversations />
      </section>
    </DashboardLayout>
  );
};

export default StudentChats;
