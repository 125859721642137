import React, { useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import TableLayout, {
    SingleTableHeader,
    TableHeader,
    TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import { staffBulkUploadTable } from "../../../static/bulk-upload.data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ButtonFilled } from "../../../components/UI/Buttons";
import StaffRow from "../../../components/BulkUpload/StaffRow";
import { useLocation, useNavigate } from "react-router-dom";
import { useValidateStaffBulkTable } from "../../../utils/hooks/bulk-upload";
import { StaffBulkChecks } from "../../../types/bulk.types";
import { openErrorModal } from "../../../store/slices/others/handlerSlice";
import { addBulkStaff } from "../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import { addNewStaff, resetStaffs } from "../../../store/slices/post/staffBulkUpload";

const StaffBulkUpload = () => {
    const staffs = useSelector((state: RootState) => state.staffBulk.staffs);
    const [errors, setErrors] = useState<StaffBulkChecks[]>([]);
    const { validate } = useValidateStaffBulkTable();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const updateErrors = (errors: StaffBulkChecks, index: number) => {
        setErrors((prev) => {
            prev[index] = errors;

            return prev;
        });
    };

    const submit = async (e: React.FormEvent) => {
        e.preventDefault();

        const valid = validate(errors);

        if (!valid) {
            dispatch(openErrorModal({ errorText: "Clear all errors before upload." }));
            return;
        }

        const data = await dispatch(addBulkStaff());

        if (!data.error) {
            await Swal.fire({
                title: "Staff bulk upload",
                text: "Staff uploaded successfully",
                timer: 2000,
                icon: "success",
                showConfirmButton: false,
            });

            navigate("/admin/staffs");

            dispatch(resetStaffs());
        }
    };

    return (
        <form onSubmit={submit}>
            <div className="relative">
                <BiPlusCircle
                    size={30}
                    title="add new student"
                    className="absolute right-0 -bottom-[10px] z-[10]"
                    cursor={"pointer"}
                    onClick={() => dispatch(addNewStaff())}
                />

                <TableLayout>
                    <TableHeader>
                        {staffBulkUploadTable.map((header, index) => {
                            return <SingleTableHeader {...header} key={index} center={false} />;
                        })}
                    </TableHeader>

                    <TableRowsContainer>
                        {staffs.map((staff, index) => {
                            return <StaffRow key={index} index={index} updateErrors={updateErrors} />;
                        })}
                    </TableRowsContainer>
                </TableLayout>
            </div>
            <ButtonFilled text={"Upload Staff"} type="submit" className="mt-6 !min-w-[150px]" />
        </form>
    );
};

export default StaffBulkUpload;
