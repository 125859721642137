import React from 'react';
import { MdClass } from 'react-icons/md';
import { useGetClassmates } from '../../api/hooks/students/students.service';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import TableLayout, {
  TableRow,
  TableRowsContainer,
  SingleTableRowItem,
  TableHeader,
  SingleTableHeader,
} from '../../components/Layouts/TableLayout';
import { ContentBoxWithAvatar } from '../../components/UI/ContentBox';
import { classmatesHeaders, classmatesList } from '../../static/student.data';

const Classmates = () => {
  const { data, isLoading } = useGetClassmates();

  return (
    <DashboardLayout pageTitle={'Classmates'}>
      <section className='py-6 sm:p-6 min-h-screen'>
        <div className='w-full max-w-[350px]'>
          <ContentBoxWithAvatar
            header={`${data?.studentClass?.className} ${data?.studentClass?.classArm}`}
            avatar={<MdClass />}
            hasButton={false}
          >
            <div className='p-4'>
              <article className={styles.flex_container}>
                <p className={styles.paragraph}>Population</p>
                <h3 className={styles.title}>
                  {data?.studentClass?.students?.length}
                </h3>
              </article>
            </div>
          </ContentBoxWithAvatar>
        </div>
        <TableLayout>
          <TableHeader>
            {classmatesHeaders.map((header, index) => {
              return <SingleTableHeader key={index} {...header} />;
            })}
          </TableHeader>
          <TableRowsContainer>
            {data?.classmates?.map((classmate, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={classmatesHeaders[0].width}>
                    <div className='flex items-center gap-x-2 cursor-pointer'>
                      <img
                        className='w-[25px] h-[25px] rounded-full'
                        alt={classmate?.firstName}
                        loading='lazy'
                        src={classmate?.imageUrl}
                      />
                      <p className='font-bold text-[#222] cursor-pointer hover:underline'>
                        {classmate?.firstName} {classmate?.lastName}
                      </p>
                    </div>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={classmatesHeaders[1].width}>
                    <p className='capitalize'>{classmate?.gender}</p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  flex_container: 'flex flex-col gap-x-2 gap-y-2',
  paragraph: 'text-[1rem] text-darkGray',
  title: 'text-blackText text-[1.1rem] font-[500]',
};

export default Classmates;
