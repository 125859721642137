/**
 * @desc - Fetch the class details. Each of the details will be passed as props into the modals.
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import DashboardLayout from '../../../../components/Layouts/DashboardLayout';
import {
  AddClassModal,
  ModalProps,
  StudentsList,
  TeachersList,
} from '../../../../components/Admin/ClassGeneratorModals';
import { IGenerateClass } from '../../../../api/thunks/admin/admin.types';
import { useGetStudents } from '../../../../api/hooks/admin/students/students.service';
import { useGetTeachers } from '../../../../api/hooks/admin/staffs/staffs.service';
import { useGetSingleClass } from '../../../../api/hooks/admin/generators/generators.service';
import { useParams } from 'react-router-dom';
import { ButtonFilled } from '../../../../components/UI/Buttons';
import ModalOverlay from '../../../../components/UI/ModalOverlay';
import { useDispatch } from 'react-redux';
import { editClass } from '../../../../api/thunks/admin/admin.service';
import Swal from 'sweetalert2';
import ConfirmationModal from '../../../../components/UI/ConfirmationModal';
import BackButton from '../../../../components/UI/BackButton';

type CurrentComponentType = 'Class' | 'Students' | 'Teachers';

const EditClass = () => {
  const [modal, setModal] = useState<ReactElement | null>(null);
  const [classDetail, setClassDetail] = useState<IGenerateClass>({
    className: '',
    classArm: '',
    students: [],
    classTeacher: '',
  });

  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const closeModal = () => {
    setModal(null);
  };

  const { id } = useParams();

  const dispatch = useDispatch();

  const { data: staffs } = useGetTeachers();
  const { data: students } = useGetStudents();
  const { data: dbClass, isLoading: fetchingClass } = useGetSingleClass(
    id as string
  );

  const openModal = (modalType: CurrentComponentType) => {
    // Create the props object for what will be passed into each of the components

    if (modalType === 'Class') {
      const props: ModalProps = {
        setNewClass: setClassDetail,
        closeModal: closeModal,
        newClass: classDetail,
        selectedClass: classDetail?.className,
        classBranch: classDetail?.classArm,
      };

      setModal(<AddClassModal {...props} />);
    } else if (modalType === 'Students') {
      const props: ModalProps = {
        setNewClass: setClassDetail,
        closeModal: closeModal,
        newClass: classDetail,
        data: students?.students,
        studentsInClass: classDetail?.students || [],
      };

      setModal(
        <ModalOverlay modalWidth={1200}>
          <StudentsList {...props} />
        </ModalOverlay>
      );
    } else if (modalType === 'Teachers') {
      const props: ModalProps = {
        setNewClass: setClassDetail,
        closeModal: closeModal,
        newClass: classDetail,
        data: staffs?.staffs,
        classTeacher: classDetail?.classTeacher || '',
      };

      setModal(
        <ModalOverlay modalWidth={1200}>
          <TeachersList {...props} />
        </ModalOverlay>
      );
    }
  };

  const saveNewClass = async () => {
    await dispatch(editClass({ classId: id as string, classDetail }));
    Swal.fire({
      icon: 'success',
      title: 'Class Edited Successfully',
      showConfirmButton: false,
      text: 'You will be redirected in 3s',
    });

    setTimeout(() => {
      window.location.replace('/admin/generator/generated/class');
    }, 3000);
  };

  useEffect(() => {
    if (dbClass && !fetchingClass) {
      setClassDetail({
        classArm: dbClass?.classArm,
        className: dbClass?.className,
        classTeacher: dbClass?.classTeacher?._id as string,
        students: dbClass?.students?.map((student) => student?._id),
      });
    }
  }, [dbClass]);

  return (
    <DashboardLayout pageTitle={'Edit Class'}>
      {/* Display the modal components here */}
      <>{modal}</>
      <>
        {confirmationModalOpen && (
          <ConfirmationModal
            onCancel={() => {
              setConfirmationModalOpen(false);
            }}
            onConfirm={saveNewClass}
            text='Are you sure you want to edit this class'
          />
        )}
      </>

      <section className='min-h-[90vh] py-6 sm:p-6'>
        <article className={styles.content_box + ' border-[#242424]'}>
          <div className='flex items-center gap-x-4 gap-y-4'>
            <span
              className={`${styles.content_box_icon} bg-[#14141436] text-[#242424] `}
            >
              <FiUsers />
            </span>
            <h2 className={styles.content_box_title}>
              {classDetail?.className} {classDetail?.classArm}
            </h2>
          </div>
          <div className='flex items-center justify-center gap-x-2 gap-y-2 mt-2'>
            <p className='text-[15px] cursor-pointer hover:underline text-darkGray font-[300]'>
              {classDetail?.classTeacher
                ? 'Class teacher added'
                : 'No class teacher added'}
            </p>

            <p className='text-[15px] cursor-pointer hover:underline text-darkGray font-[300]'>
              {classDetail?.students?.length || 0} student(s) added
            </p>
          </div>
        </article>
        <div className='mt-8 flex flex-wrap items-center justify-start gap-x-6 gap-y-6'>
          <ButtonFilled
            text='Edit Class Name'
            onClick={() => {
              openModal('Class');
            }}
          />
          <ButtonFilled
            text='Edit Class Teacher'
            onClick={() => {
              openModal('Teachers');
            }}
          />
          <ButtonFilled
            text='Edit Class Students'
            onClick={() => {
              openModal('Students');
            }}
          />

          <ButtonFilled
            text='Save Changes'
            onClick={() => {
              setConfirmationModalOpen(true);
            }}
          />
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    'flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center',
  search_icon:
    'flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray',
  buttons:
    'px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit',
  content_box: 'mt-8 p-4 w-full max-w-[300px] rounded-md border-[1.5px]',
  content_box_icon:
    'w-[30px] h-[30px] rounded-full flex justify-center items-center',
  content_box_title: 'text-[1rem] font-bold text-blackText flex flex-col',
};

export default EditClass;
