import { useQuery } from "react-query";
import { IStudentsFetch } from "./students.types";
import { baseHttp } from "../../../../axios.config";
import { IDefaultQueryResults } from "../../../../types/states.types";
import {
  IAllStudents,
  IBillsBreakdown,
  IClassAverage,
  IFullyPaidItems,
  IGeneratedPayAdvice,
  IGeneratedSubjects,
  ISingleStudentAttendance,
  IStudentScores,
} from "../../../../types/db.types";
import { useLocation } from "react-router-dom";

interface useGetStudentsProps extends IDefaultQueryResults {
  data: IStudentsFetch;
}
interface useGetStudentProps extends IDefaultQueryResults {
  data: IAllStudents;
}

export const useGetStudents = (
  gender?: string,
  classDetail?: string
): useGetStudentsProps => {
  const { data, isLoading, isError, refetch } = useQuery<IStudentsFetch>({
    queryKey: ["admin/get-students"],
    queryFn: async () => {
      const { data } = await baseHttp.get(
        `/school/students?${gender ? `gender=${gender}&` : ""}${
          classDetail ? `className=${classDetail}` : ""
        }`
      );
      return data;
    },
  });

  return {
    isLoading,
    isError,
    refetch,
    data: data as IStudentsFetch,
  };
};

export const useGetSingleStudent = (
  id: string,
  url: string
): useGetStudentProps => {
  const { data, isLoading, isError, refetch } = useQuery<IAllStudents>({
    queryKey: [url],
    queryFn: async () => {
      const { data } = await baseHttp.get(url);
      return data?.student || data?.studentProfile || data?.kids;
    },
  });

  return {
    isLoading,
    isError,
    refetch,
    data: data as IAllStudents,
  };
};

export const useGetStudentClass = (url: string) => {
  const { data, isLoading } = useQuery({
    queryKey: url,
    queryFn: async () => {
      const response = await baseHttp.get(url);
      return response?.data?.data;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetStudentAttendance = (studentId: string, url: string) => {
  const { data, isLoading } = useQuery<ISingleStudentAttendance[]>({
    queryKey: url,
    queryFn: async () => {
      const { data } = await baseHttp.get(url);
      return data?.attendance || data?.studentAttendance;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetStudentScore = (url: string) => {
  const { data, isLoading } = useQuery<IStudentScores[]>({
    queryKey: url,
    queryFn: async () => {
      const { data } = await baseHttp.get(url);
      return data?.studentScores;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetTuitionDetails = (url: string) => {
  const { data, isLoading } = useQuery<IGeneratedPayAdvice[]>({
    queryKey: "admin/get-tuition-details",
    queryFn: async () => {
      const { data } = await baseHttp.get(url);

      return data?.bills;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useGetStudentSubjects = (url: string) => {
  const { data, isLoading, refetch } = useQuery<IGeneratedSubjects[]>({
    queryKey: url,
    queryFn: async () => {
      const response = await baseHttp.get(url);
      return response.data.subjects;
    },
  });

  return { data, isLoading, refetch };
};

export const useGetPaymentBreakdown = (url: string) => {
  const { data, isLoading } = useQuery<IBillsBreakdown[]>({
    queryKey: "user/get-payment-breakdown",
    queryFn: async () => {
      const response = await baseHttp.get(url);

      return response.data?.fees;
    },
  });

  return { data, isLoading };
};

export const useGetStudentsWithoutClass = () => {
  const { data, isLoading, isError, refetch } = useQuery<IStudentsFetch>({
    queryKey: ["admin/get-students"],
    queryFn: async () => {
      const { data } = await baseHttp.get(`/school/students/no-class`);
      return data;
    },
  });

  return {
    data,
    isLoading,
    isError,
  };
};

export const useGetFullyPiadItems = (studentId: string) => {
  const { data, isLoading, refetch } = useQuery<IFullyPaidItems>({
    queryKey: "admin/get-student-fully-paid-items",
    queryFn: async () => {
      const { data } = await baseHttp.get(
        `/school/student/bill/fully-paid/${studentId}`
      );
      return data?.fees;
    },
  });

  return { data, isLoading, refetch };
};

export const useGetStudentApprovedResults = (studentId?: string) => {
  const location = useLocation();

  const url = location.pathname.includes("admin")
    ? `/school/result/approved/student/${studentId}`
    : location.pathname.includes("teacher")
    ? `/teacher/result/approved/student/${studentId}`
    : location?.pathname?.includes("parent")
    ? `/parents/result/approved/kid/${studentId}`
    : `/student/result/approved`;

  const { data, isLoading, refetch } = useQuery<IClassAverage[]>({
    queryKey: "get-student-approved-result",
    queryFn: async () => {
      const { data } = await baseHttp.get(url);

      return data?.data;
    },
  });

  return {
    data,
    isLoading,
    refetch,
  };
};
