import React, { useState, Dispatch, SetStateAction, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import NavBar from '../UI/NavBar';

const AuthLayout = ({ children }: { children: ReactElement }) => {
  return (
    <>
      <NavBar />
      <div className='min-h-screen bg-mainBg w-[100%] '>
        <div className='min-h-[100vh] flex  items-center max-w-7xl mx-auto  justify-between'>
          <div className='hidden lg:flex  mx-auto '>
            <img src={logo} alt='' />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
