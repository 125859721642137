import React from "react";
import { useGetClassDebtors } from "../../../../api/hooks/admin/school/school.service";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import { getUserFromLocalStorage } from "../../../../utils/localStorage";
import {
  IDebtors,
  IFee,
  IGeneratedPayAdvice,
  IUser,
} from "../../../../types/db.types";
import {
  useGetPayAdivce,
  useGetSingleClass,
} from "../../../../api/hooks/admin/generators/generators.service";

const Debtors = () => {
  const { classId } = useParams();

  const { data: classData } = useGetSingleClass(classId as string);

  const { data: debtors } = useGetClassDebtors(classId as string);

  const { data: pay_advice } = useGetPayAdivce(classId as string);

  const school = (getUserFromLocalStorage() as IUser)?.school;

  //   get the student fee for a pay advice item
  const getFee = (student: string, index: number): IFee | undefined => {
    const payAdviceId = (pay_advice?.[index] as IGeneratedPayAdvice)._id;

    let fee: IFee | undefined;
    debtors?.forEach((debtor) => {
      if (debtor.student._id === student) {
        const feeInData = debtor.fees.find(
          (fee) => fee.item._id === payAdviceId
        );
        fee = feeInData as IFee;
      }
    });

    return fee;
  };

  const getTotalUnpaid = (debtor: IDebtors) => {
    let total = 0;
    debtor.fees.forEach((fee, index) => {
      const item = getFee(debtor.student._id, index);

      total += (item?.item.charge as number) - (item?.amount_paid as number);
    });
    return total;
  };

  return (
    <DashboardLayout
      pageTitle={`${classData?.className} ${classData?.classArm} ${school?.term} term Debtors`}
    >
      <section className="py-6 sm:p-6 min-h-screen">
        <div className="mb-4 flex items-start gap-[1rem]">
          <img
            src={school?.imageUrl}
            className="w-[50px] h-[50px] rounded-full object-cover object-center"
            alt=""
          />
          <div>
            <p className="font-bold">{school?.schoolName}</p>
            <p className="text-[14px]">{school?.address}</p>
          </div>
        </div>

        <div className="flex flex-row items-start mt-8">
          {/* This part is not scrollable */}
          <div className="flex flex-col items-center w-[150px] max-w-[150px]  border-r-[2.5px] border-blackText">
            <div className="h-[50px] w-full">
              <h2>Student Name</h2>
            </div>
            {/* list the students */}
            {debtors?.map((debtor, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center gap-x-2 h-[50px] w-full cursor-pointer border-b-[2.5px] border-blackText"
                >
                  <img
                    className="w-[25px] h-[25px] rounded-full"
                    alt={debtor.student?.firstName}
                    loading="lazy"
                    src={debtor?.student?.imageUrl}
                  />
                  <p className="font-bold text-[#222] cursor-pointer hover:underline">
                    {debtor?.student?.firstName} {debtor?.student?.lastName}
                  </p>
                </div>
              );
            })}
          </div>

          <div
            className="overflow-x-scroll"
            style={{
              width: `calc(100% - 150px)`,
            }}
          >
            {/* Map all the pay advice items first */}
            <div className="flex h-[50px] min-w-fit">
              {pay_advice?.map((bill, index) => {
                return (
                  <div
                    key={index}
                    className="text-white bg-blackText border-r-[2.5px] w-[150px] h-[50px] flex items-center flex-col justify-center"
                  >
                    <p>{bill.item}</p>
                    <p className="text-[14px] p-0 m-0">₦ {bill.charge}</p>
                  </div>
                );
              })}

              <div className={styles.singleBox + " bg-blackText text-white"}>
                <p>Total</p>
              </div>
            </div>

            {/* Map the amount paid for each of the students */}
            <div className="flex flex-col min-w-fit">
              {debtors?.map((debtor, index) => {
                const totalUnpaid = getTotalUnpaid(debtor);

                return (
                  <div className="flex min-w-fit" key={index}>
                    {debtor?.fees?.map((item, index) => {
                      const fee = getFee(debtor.student._id, index) as IFee;
                      return (
                        <React.Fragment key={index}>
                          <div className={styles.singleBox} key={index}>
                            <p>
                              <span className="text-red-500 font-bold">
                                ₦ {fee.item.charge - fee.amount_paid}
                              </span>
                            </p>
                          </div>
                        </React.Fragment>
                      );
                    })}

                    <div className={styles.singleBox} key={index}>
                      <p>
                        <span className="text-red-500 font-bold">
                          ₦ {totalUnpaid}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  singleBox:
    "flex items-center justify-center h-[50px] w-[150px] border-b-[2.5px] border-b-blackText border-r-[1.5px] border-blackText",
};

export default Debtors;
