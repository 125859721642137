import React, { FC, ReactElement, useState } from "react";

type TableLayoutProps = {
    children?: ReactElement[] | ReactElement;
    color?: string;
    bg?: string;
    className?: string;
};

const TableLayout: FC<TableLayoutProps> = ({ children, className }) => {
    return (
        <div
            className={`w-full h-full bg-white max-w-full overflow-x-scroll mt-4 xl:pb-0 pb-2 border-[1.5px] rounded-md ${className}`}
        >
            {children}
        </div>
    );
};

export const TableHeader: FC<TableLayoutProps> = ({ children, color, bg }) => {
    return (
        <header
            className={`w-full bg-mainBg flex  min-w-fit max-h-fit`}
            style={{
                color: color ? color : "black",
                backgroundColor: bg ? bg : "#fafafa",
            }}
        >
            {children}
        </header>
    );
};

export const SingleTableHeader: FC<{
    width?: number;
    text?: string | ReactElement;
    center?: boolean;
    bg?: string;
    color?: string;
    noPadding?: boolean;
}> = ({ width, text, center, bg, color, noPadding }) => {
    return width ? (
        <h2
            className={`font-bold text-[.85rem] p-0 m-0 ${!noPadding && "py-3 px-2"} flex-1 ${center && "text-center"}`}
            style={{
                minWidth: `${width}px`,
                backgroundColor: bg ? bg : "transparent",
                color: color ? color : "#636363",
            }}
        >
            <>
                {typeof text != "string" && text}
                {typeof text === "string" &&
                    (text.includes("*") ? (
                        <div className="flex items-start">
                            {text.replace(/\*/, "")}
                            <span className="text-red-600 inline-block text-[1rem] ml-[1px] mb-1">*</span>
                        </div>
                    ) : (
                        text
                    ))}
            </>
        </h2>
    ) : (
        <></>
    );
};

export const TableRowsContainer: FC<TableLayoutProps> = ({ children, className }) => {
    return <main className={`w-full flex flex-col bg-white gap-x-2 ${className}`}>{children}</main>;
};

export const TableRow: FC<TableLayoutProps> = ({ children, className }) => {
    return <article className={`flex border-b-[1.5px] min-w-fit bg-white ${className}`}>{children}</article>;
};

export const SingleTableRowItem: FC<{
    children?: ReactElement | ReactElement[];
    width?: number;
    bg?: string;
    color?: string;
    noPadding?: boolean;
    height?: number | string;
    className?: string;
    fitContent?: boolean;
}> = ({ height, children, width, bg, color, noPadding, className, fitContent }) => {
    return (
        <span
            className={`block  text-[.85rem] align-middle flex-1 w-full ${!noPadding && "py-4 px-2"} ${className}`}
            style={{
                minWidth: `${width}px`,
                background: bg ? bg : "transparent",
                color: color ? color : "#181619",
                height: height ? `${height}px` : "auto",
                maxWidth: fitContent ? `${width}px` : "auto",
            }}
        >
            {children}
        </span>
    );
};

export const TableSubheader: FC<{
    width: number;
    text: string;
    center?: boolean;
    subtitles: [string, string];
    bg?: string;
    color?: string;
}> = ({ width, text, center, bg, color, subtitles }) => {
    return (
        <div
            className={`font-bold flex flex-col p-0 m-0 pt-3 px-2 flex-1 ${center && "text-center"}`}
            style={{
                minWidth: `${width}px`,
                backgroundColor: bg ? bg : "transparent",
                color: color ? color : "#636363",
            }}
        >
            <h2 className="text-center">{text}</h2>
            <div className="flex items-center text-center">
                <p className="flex-1 w-[50%] border-r-[1.5px]">{subtitles[0]}</p>
                <p className="flex-1 w-[50%]">{subtitles[1]}</p>
            </div>
        </div>
    );
};

export default TableLayout;
