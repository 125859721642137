import React, { FC, ReactElement } from "react";
import { BiUser } from "react-icons/bi";
import { GiBank, GiTie } from "react-icons/gi";
import {
  singleStaffDetails,
  staffEmploymentRecord,
  staffEmploymentRecordHeaders,
  staffLessonNoteHeaders,
  staffLessonNotes,
} from "../../static/admin&teacher.data";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../Layouts/TableLayout";
import {
  ContentBoxWithAvatar,
  ContentBoxWithAvatarHeader,
} from "../UI/ContentBox";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getDateInFull } from "../../utils/getDateInFull";
import { IGenerateSubject } from "../../api/thunks/admin/admin.types";
import { IGeneratedSubjects } from "../../types/db.types";

interface singleProfileDetailProps {
  title: string;
  subtitle?: string | string[];
  subjects?: IGeneratedSubjects[];
}

export const SingleProfileDetail: FC<singleProfileDetailProps> = (props) => {
  return (
    <article className={styles.content}>
      <p className={styles.content_title}>{props.title}</p>
      {props?.subtitle && (
        <h2 className={styles.content_text + " break-all"}>{props.subtitle}</h2>
      )}
      {props?.subjects && (
        <ul>
          {props?.subjects?.map((subject, index) => {
            return (
              <li key={index}>
                <span className="font-bold">
                  {subject?.classId?.className} {subject?.classId?.classArm} -{" "}
                </span>{" "}
                {subject.title}
              </li>
            );
          })}
        </ul>
      )}
    </article>
  );
};

export const Profile = () => {
  const staff = useSelector((state: RootState) => state.getStaff);
  const { user, nextOfKin, role, accountInfo } = singleStaffDetails;
  return (
    <>
      <div className="w-full flex gap-x-4 gap-y-4 xl:flex-row flex-col">
        <div className="flex-[60%]">
          <ContentBoxWithAvatar
            avatar={<BiUser />}
            header={"General Information"}
            hasButton={false}
          >
            <section className={styles.content_rows}>
              <SingleProfileDetail
                title={"Name"}
                subtitle={
                  staff?.firstName +
                  " " +
                  staff?.middleName +
                  " " +
                  staff?.lastName
                }
              />
              <SingleProfileDetail title={"Gender"} subtitle={staff?.gender} />
              <SingleProfileDetail
                title={"Religion"}
                subtitle={staff?.religion}
              />
              <SingleProfileDetail
                title={"Date Of Birth"}
                subtitle={getDateInFull(staff?.dateOfBirth) || ""}
              />
              <SingleProfileDetail
                title={"Telephone"}
                subtitle={staff?.phoneNumber as string}
              />
              <SingleProfileDetail title={"Email"} subtitle={staff?.email} />
              <SingleProfileDetail
                title={"Address"}
                subtitle={staff?.address}
              />
            </section>
            <hr className="my-4 border-t-[1.5px] block w-[80%] mx-auto" />
            <section>
              <ContentBoxWithAvatarHeader
                avatar={<BiUser />}
                header={"Reference/Next of Kin Information"}
                hasButton={false}
              />
              <div className={styles.content_rows}>
                <SingleProfileDetail
                  title={"Name"}
                  subtitle={staff?.nextOfKinName}
                />

                <SingleProfileDetail
                  title={"Telephone"}
                  subtitle={staff?.nextOfKinPhoneNumber as string}
                />
                <SingleProfileDetail
                  title={"Address"}
                  subtitle={staff?.nextOfKinAddress}
                />
              </div>
            </section>
          </ContentBoxWithAvatar>
        </div>
        <div className="flex-[40%] gap-y-4">
          <ContentBoxWithAvatar
            avatar={<GiTie />}
            header={"Role Details"}
            hasButton={false}
          >
            <section className={styles.content_rows}>
              <SingleProfileDetail title={"Position"} subtitle={staff?.role} />
              {/* <SingleProfileDetail title={"Class"} subtitle={staff?.cl} /> */}
              {(staff?.subjects as IGeneratedSubjects[])?.length > 0 && (
                <SingleProfileDetail
                  title={"Subject(s)"}
                  subjects={staff?.subjects}
                />
              )}

              <div>
                <SingleProfileDetail title="Signature" />
                <img
                  src={staff?.signature}
                  alt={staff?.firstName + "'s Signature"}
                  className="w-[120px] h-[60px]"
                />
              </div>
            </section>
          </ContentBoxWithAvatar>
        </div>
      </div>
    </>
  );
};

export const EmploymentRecord = () => {
  const staff = useSelector((state: RootState) => state.getStaff);
  return (
    <>
      <div className="w-full sm:px-0 px-2">
        <TableLayout>
          <TableHeader>
            {staffEmploymentRecordHeaders.map((header, index: number) => {
              return <SingleTableHeader key={index} {...header} />;
            })}
          </TableHeader>
          <TableRowsContainer>
            {staff?.documents?.map((doc, index: number) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem
                    width={staffEmploymentRecordHeaders[0].width}
                  >
                    <p className="text-[1rem] text-darkerGray">
                      {doc.documentName}
                    </p>
                  </SingleTableRowItem>
                  <SingleTableRowItem
                    width={staffEmploymentRecordHeaders[1].width}
                  >
                    <a
                      href={doc.documentUrl}
                      download={true}
                      target="_blank"
                      className="text-[1.08rem] text-darkerGray underline"
                    >
                      View document
                    </a>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </div>
    </>
  );
};

export const LessonNotes = () => {
  return (
    <>
      <div className="w-full sm:px-0 px-2">
        <TableLayout>
          <TableHeader>
            {staffLessonNoteHeaders.map((header, index: number) => {
              return <SingleTableHeader key={index} {...header} />;
            })}
          </TableHeader>
          <TableRowsContainer>
            {staffLessonNotes.map((note, index: number) => {
              return (
                <TableRow>
                  <SingleTableRowItem width={staffLessonNoteHeaders[0].width}>
                    <p className={styles.bold_font}>{note.session}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffLessonNoteHeaders[1].width}>
                    <p className={"text-[1rem] text-darkerGray"}>{note.term}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffLessonNoteHeaders[2].width}>
                    <p className={"text-[1rem] text-darkerGray"}>
                      {note.subject}
                    </p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={staffLessonNoteHeaders[3].width}>
                    <p
                      className={`text-[14px] py-1 px-3 rounded-[30px] max-w-fit min-w-fit font-bold ${
                        note.completed
                          ? "text-green-700 bg-green-200"
                          : "text-orange-700 bg-orange-200"
                      }`}
                    >
                      {note.completed ? "Completed" : "In progress"}
                    </p>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </div>
    </>
  );
};

const styles = {
  content_rows:
    "grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 p-4 gap-x-4 gap-y-5",
  content: "flex flex-col gap-y-[5px] block ",
  content_title: "text-[0.94rem] text-darkGray capitalize font-bold",
  content_text: "text-blackText text-[1rem]",
  bold_font: "font-bold text-[1rem] text-darkerGray",
};
