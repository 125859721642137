import React, { useEffect, useLayoutEffect, useState } from "react";
import { BiCreditCardFront, BiTransfer } from "react-icons/bi";
import { GiBank } from "react-icons/gi";
import { RiMastercardLine } from "react-icons/ri";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import { ContentBox } from "../../components/UI/ContentBox";
import mastercard from "../../assets/images/mastercard.png";
import { useGetTuitionDetails } from "../../api/hooks/admin/students/students.service";
import { useParams } from "react-router-dom";
import { IGeneratePayAdvice } from "../../api/thunks/admin/admin.types";
import { IBillsPaid, IGeneratedPayAdvice } from "../../types/db.types";
import { ButtonFilled } from "../../components/UI/Buttons";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import { IPaymentSession } from "../../api/thunks/parent/parent.types";
import { makePayment } from "../../api/thunks/parent/parent.service";
import { useDispatch } from "react-redux";
import { useGetBillsPaid } from "../../api/hooks/parents/parent.service";

export interface PayAdviceItem extends IBillsPaid {
  amountToPay: number | string;
}

const formatPaymentData = (data: PayAdviceItem[]): IPaymentSession[] =>
  data.map((item) => ({ item: item._id, amount: item.amountToPay as number }));

const Payment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [items, setItems] = useState<PayAdviceItem[]>([]);

  const { data: bills, isLoading } = useGetBillsPaid(id as string);

  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalBill, setTotalBill] = useState<number>(0);
  const [totalCharges, setTotalCharges] = useState<number>(0);
  // for the confirmation modal
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const editAmount = (_id: string, value: string | number) => {
    setItems((item) =>
      item.map((i) => {
        if (i._id === _id) {
          if ((value as number) <= i.charge - i.paid) {
            i.amountToPay = value as number;
          }
        }

        return i;
      })
    );
  };

  const getTotalAmount = (): number =>
    items?.reduce((acc, curr) => {
      if (curr?.amountToPay) {
        acc += parseFloat(curr?.amountToPay as string);
      }
      return acc;
    }, 0);

  const getTotalBill = (): number =>
    items?.reduce((acc, curr) => {
      acc += curr.charge;
      return acc;
    }, 0);

  const calculateCharges = (total: number) => {
    // if total is greater than 2500, charges= 1.5% + 100, else charges = 1.5%
    const calculatedTotal =
      total > 2500 ? (1.6 / 100) * total + 100 : (1.6 / 100) * total;

    return parseInt(calculatedTotal.toString());
  };

  const pay = async () => {
    const data = formatPaymentData(items);

    const res = await dispatch(makePayment({ studentId: id, fees: data }));

    if (!res?.error) {
      setModalOpen(false);
      window.open(res.payload.authorization_url);
    }
  };

  useEffect(() => {
    if (!isLoading && bills) {
      setItems(bills?.map((item) => ({ ...item, amountToPay: 0 })));
    }
  }, [isLoading, bills]);

  useEffect(() => {
    if (items) {
      setTotalAmount(getTotalAmount());
      setTotalBill(getTotalBill());
    }
  }, [items]);

  useEffect(() => {
    setTotalCharges(calculateCharges(totalAmount));
  }, [totalAmount]);

  return (
    <DashboardLayout pageTitle="Payment">
      <>
        {modalOpen && (
          <ConfirmationModal
            text="Are you sure you want to make this payment?"
            onCancel={() => setModalOpen(false)}
            onConfirm={pay}
          />
        )}
      </>
      <section className="py-6 sm:p-6 min-h-screen">
        <div className="flex gap-x-4 gap-y-4 justify-center flex-wrap flex-col md:flex-row">
          <div className="flex-1">
            <i className="block mb-2">
              <b className="underline">Note: </b> {"  "}A charge of
              <b>{"1.6% + ₦100"}</b> will be added for transactions above ₦2500,{" "}
              <b> {"1.6%"}</b> will be added if otherwise. Kindly ensure popups
              are blocked on your browser
            </i>
            <ContentBox defaultPadding={false}>
              <header className="pb-4 px-4 border-b-[1.5px]">
                <h2 className="text-[1rem] text-blackText mb-1 leading-[1]">
                  Order Summary
                </h2>
                <p className="text-darkGray text-[14px]">
                  Select the items you want to pay for.
                </p>
              </header>

              <div className="p-4">
                <div className={styles.flexContainer}>
                  <p>Item</p>
                  <p>Amount</p>
                  <p>Balance</p>
                </div>

                {items?.map((bill) => (
                  <div className={styles.flexContainer} key={bill._id}>
                    <p>{bill?.item}</p>
                    <p>{bill?.charge}</p>
                    <p>{bill.charge - bill.paid}</p>
                    <input
                      type="number"
                      className="input-field"
                      placeholder="How much do you want to pay?"
                      max={bill?.charge - bill?.paid}
                      value={bill?.amountToPay}
                      onChange={(e) => {
                        editAmount(bill._id, e.target.value);
                      }}
                    />
                  </div>
                ))}

                <div className={styles.flexContainer + " mt-4"}>
                  <p>Charge</p>
                  <p></p>
                  <p>NGN {totalCharges?.toLocaleString()}</p>
                </div>

                <div className={styles.flexContainer + " mt-4"}>
                  <p>Total</p>
                  <p>NGN {totalBill.toLocaleString()}</p>
                  <p>NGN {(totalAmount + totalCharges).toLocaleString()}</p>
                </div>

                {/* For making the payment */}
                {totalAmount > 0 && (
                  <div className="max-w-fit ml-auto mt-4">
                    <ButtonFilled
                      text="Make Payment"
                      onClick={() => setModalOpen(true)}
                    />
                  </div>
                )}
              </div>
            </ContentBox>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  button:
    "border-[1.5px] border-blackText p-2 rounded-md hover:text-white hover:bg-blackText min-w-[11.25rem]",
  buttonIcon: "inline-block align-middle pr-2",
  cardDetailsContainer:
    "w-full  flex flex-col gap-0 text-[14px] bg-gray-500 text-white rounded-md p-2",
  submitBtn:
    "min-w-[200px] bg-blackText text-white rounded-md px-4 py-2 mx-auto block",
  flexContainer:
    "grid grid-cols-4 items-center gap-x-2 gap-y-2 mb-2 text-blackText font-bold",
};

export default Payment;
