import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHandlerSlice } from '../../../types/states.types';

const initialState: IHandlerSlice = {
  errorModalOpen: false,
  errorText: 'Send Money!',
  loading: false,
  loadingText: '',
  fontSize: 100,
  createModalOpen: false,
};

const handlerSlice = createSlice({
  name: 'handlers',
  initialState,
  reducers: {
    openPreloader: (
      state: IHandlerSlice,
      action: PayloadAction<{ loadingText: string }>
    ) => {
      state.loading = true;
      state.loadingText = action.payload.loadingText;
    },
    closePreloader: (state: IHandlerSlice) => {
      state.loading = false;
      state.loadingText = '';
    },
    openErrorModal: (
      state: IHandlerSlice,
      action: PayloadAction<{ errorText: string }>
    ) => {
      state.errorModalOpen = true;
      state.errorText = action.payload.errorText;
    },
    closeErrorModal: (state: IHandlerSlice) => {
      state.errorModalOpen = false;
      state.errorText = '';
    },
    changeFontSize: (state: IHandlerSlice, action: PayloadAction<number>) => {
      state.fontSize = action.payload || 100;
      if (action.payload) {
        localStorage.setItem('font-size', action.payload.toString());
      }
    },
    openCreateSchoolModal: (state: IHandlerSlice, action: PayloadAction) => {
      state.createModalOpen = true;
    },
    closeCreateSchoolModal: (state: IHandlerSlice, action: PayloadAction) => {
      state.createModalOpen = false;
    },
  },
});

export const {
  openErrorModal,
  openPreloader,
  closePreloader,
  closeErrorModal,
  changeFontSize,
  openCreateSchoolModal,
  closeCreateSchoolModal,
} = handlerSlice.actions;
export default handlerSlice.reducer;
