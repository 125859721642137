import React from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { openCreateSchoolModal } from '../../store/slices/others/handlerSlice';
import { ButtonFilled } from './Buttons';

const NoSchool = () => {
  const dispatch = useDispatch();

  const openModal = () => dispatch(openCreateSchoolModal());

  return (
    <div className='text-center min-h-screen w-full flex flex-col items-center justify-center'>
      <h1 className='text-[25px] text-darkerGray font-bold mb-2'>
        You do not have a school yet
      </h1>

      <button
        onClick={openModal}
        className='flex items-center justify-center p-2 hover:bg-[#333333] hover:text-white hover:shadow-md rounded-md'
      >
        <span className='inline-block mr-2 align-middle'>
          <BiPlusCircle />
        </span>
        <p>Create School</p>
      </button>
    </div>
  );
};

export default NoSchool;
