import React from "react";
import FooterInfo from "./UI/FooterInfo";
import { IAllStudents, IStudentReportInfo } from "../../types/db.types";
import { getDateInFull } from "../../utils/getDateInFull";
import { useSearchParams } from "react-router-dom";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { classes } from "../../static/admin&teacher.data";

interface Props {
  termlyInfo?: IStudentReportInfo;
  profile?: IAllStudents;
}

const Footer = ({ termlyInfo, profile }: Props) => {
  let statusText = "";
  const { school } = getUserFromLocalStorage();

  const [searchParams] = useSearchParams();

  const term = (searchParams.get("term") as string) || school.term;

  const nextTerm = term === "1st" ? "2nd" : term === "2nd" ? "3rd" : "1st";

  /**
   * if it's not third term, status text should be advance to {nextTerm}
   * if its third term,  promoted to next class if student passes. if student fails, `repeat {className}`
   */

  if (term != "3rd") statusText = `Advance to ${nextTerm} term`;

  if (term === "3rd") {
    if ((profile as IAllStudents).teacherResultDecision === "pass") {
      statusText = `Promoted to ${
        classes[classes.findIndex((item) => item == profile?.class) + 1]
      }`;
    } else if ((profile as IAllStudents)?.teacherResultDecision === "fail") {
      statusText = `Repeating ${profile?.class}`;
    }
  }

  return (
    <footer className="mt-6 flex items-center justify-between w-full space-x-[1rem]">
      <FooterInfo question="Status" answer={statusText} />
      <FooterInfo
        question="Next Term Begins"
        answer={getDateInFull(termlyInfo?.nextResumptionDate as Date) || ""}
      />
    </footer>
  );
};

export default Footer;
