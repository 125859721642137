import React from "react";
import imgTest from "../../assets/images/download-icon.png";

interface SingleServiceProps {
  title: string;
  body: string;
  image: string;
}

const SingleService = ({ title, body, image }: SingleServiceProps) => {
  return (
    <article className="flex flex-col gap-[.5rem]">
      <img
        src={image}
        alt={title}
        className="md:w-[70px] md:h-[70px] w-[40px] h-[40px] cursor-pointer"
      />
      <h3 className="font-bold text-[1rem] md:text-[1.2rem]">{title}</h3>
      <p className="text-[.7rem] md:text-[.9rem] leading-[2] max-w-[300px]">
        {body}
      </p>
    </article>
  );
};

export default SingleService;
