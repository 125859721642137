import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import profile from "../../../../assets/images/profile-img.jpg";
import SelectContainer from "../../../../components/UI/SelectContainer";
import { Link } from "react-router-dom";
import {
  useGetDayAttendance,
  useGetTeacherClass,
} from "../../../../api/hooks/teachers/teachers.service";
import {
  IAllStudents,
  IFetchStudentsAttendance,
} from "../../../../types/db.types";
import { useDispatch } from "react-redux";
import { markAttendance } from "../../../../api/thunks/teacher/teacher.service";
import { rearrangeStudentAttendance } from "../../../../utils/dataHandling";
import { MdCheck, MdClose } from "react-icons/md";

export interface IStudentAttendance {
  attended: { morning: boolean; afternoon: boolean };
  studentId: IAllStudents;
}

// Function to map all the students to present by default
const setDefaultAttendace = (
  students: IAllStudents[]
): IStudentAttendance[] => {
  return students.map((student, index) => ({
    attended: { morning: true, afternoon: true },
    studentId: student,
  }));
};

const MarkAttendance = () => {
  /**
   * @desc - Get all the students from the backend. Map them and set them all to present.
   * There should be a select container that will allow them to select between present and absent
   */

  const location = useLocation();
  const navigate = useNavigate();
  // Get the week and the day from the URL
  const urlParams = new URLSearchParams(location.search);
  const week = urlParams.get("week");
  const day = urlParams.get("day");

  const [students, setStudents] = React.useState<IStudentAttendance[]>([]);
  const [isHoliday, setIsHoliday] = React.useState<boolean>(false);

  // Filter based on id
  const editStudentAttendance = (
    id: number | string,
    time: "morning" | "afternoon"
  ) => {
    setStudents(
      students?.map((student, index) => {
        if (id === student.studentId._id) {
          // If present change to absent, if absent chnage to present
          student.attended[time] =
            student.attended[time] === true ? false : true;
        }
        return student;
      })
    );
  };

  const { classId } = useParams();

  const { data, isLoading } = useGetTeacherClass(`/teacher/class/${classId}`);

  const { data: attendance, isLoading: attendanceLoading } =
    useGetDayAttendance(
      parseInt(week as string),
      day as string,
      classId as string
    );

  const dispatch = useDispatch();

  const getStudentAttendance = (
    studentId: string,
    time: "morning" | "afternoon"
  ) => {
    const studentAttendace = students?.find(
      (data) => data?.studentId?._id === studentId
    );

    if (!studentAttendace?.attended[time]) {
      return <MdClose className="text-red-500" />;
    } else {
      return <MdCheck className="text-green-500" />;
    }
  };

  const submitAttendance = async () => {
    const rearrangedStudentData =
      students && rearrangeStudentAttendance(students);
    await dispatch(
      markAttendance({
        day,
        week,
        isHoliday,
        students: rearrangedStudentData,
        classId,
      })
    );
    navigate(`/teacher/class/attendance/${classId}`);
  };

  useEffect(() => {
    if (
      (attendance?.students as IFetchStudentsAttendance[])?.length > 0 &&
      !attendanceLoading
    ) {
      setIsHoliday(attendance?.isHoliday as boolean);
      setStudents(attendance?.students as IFetchStudentsAttendance[]);
    } else if (data && !isLoading) {
      setStudents(setDefaultAttendace(data?.students));
    }
  }, [data, attendance]);

  return (
    <DashboardLayout pageTitle={"Mark Attendance"}>
      <section className="py-6 sm:p-6 min-h-screen">
        <header className="flex gap-[1rem] justify-between flex-wrap">
          <h2 className="text-[1.25rem] font-bold text-darkerGray mb-4">
            Week {week} - {day}
          </h2>

          <label htmlFor="holiday" className="cursor-pointer font-bold">
            <input
              type="checkbox"
              className="accent-blackText inline-block mr-2"
              checked={isHoliday}
              id="holiday"
              onChange={(e) => setIsHoliday(e.target.checked)}
            />
            Mark as holiday
          </label>
        </header>
        <div className="flex items-center gap-x-5 gap-y-5 flex-wrap justify-center md:justify-start mt-6">
          {students?.map((attendanceData, index) => {
            return (
              <article
                key={index}
                className="w-full sm:max-w-[300px] flex flex-col items-end justify-between px-6 py-4 rounded-md border-[1.5px] border-blackText bg-white"
              >
                <div className="w-full flex items-center gap-x-4 gap-y-4">
                  <img
                    src={attendanceData?.studentId?.imageUrl}
                    alt={attendanceData?.studentId?.firstName}
                    className="w-[35px] h-[35px] object-center object-cover rounded-full"
                  />
                  <h3 className="text-[1.05rem] font-bold">
                    {attendanceData?.studentId?.firstName}{" "}
                    {attendanceData?.studentId?.lastName}
                  </h3>
                </div>

                {/* don't display if theres holiday */}
                {!isHoliday && (
                  <div className="flex flex-row gap-[1rem] mt-6">
                    <div className="max-w-[150px]">
                      <p className="flex gap-[12px] items-center">
                        Morning{" "}
                        {getStudentAttendance(
                          attendanceData.studentId._id,
                          "morning"
                        )}
                      </p>

                      <div className="flex items-center">
                        <SelectContainer
                          list={["Present", "Absent"]}
                          currentItem={
                            attendanceData.attended.morning === true
                              ? "Present"
                              : "Absent"
                          }
                          fitContent={false}
                          updateWithId={(studentId) =>
                            editStudentAttendance(studentId, "morning")
                          }
                          id={attendanceData?.studentId?._id}
                        />
                      </div>
                    </div>{" "}
                    <div className="max-w-[150px]">
                      <p className="flex gap-[12px] items-center">
                        Afternoon{" "}
                        {getStudentAttendance(
                          attendanceData.studentId._id,
                          "afternoon"
                        )}
                      </p>
                      <SelectContainer
                        list={["Present", "Absent"]}
                        currentItem={
                          attendanceData.attended.afternoon === true
                            ? "Present"
                            : "Absent"
                        }
                        fitContent={false}
                        updateWithId={(studentId) =>
                          editStudentAttendance(studentId, "afternoon")
                        }
                        id={attendanceData?.studentId?._id}
                      />
                    </div>
                  </div>
                )}
              </article>
            );
          })}
        </div>
        <button
          onClick={submitAttendance}
          className="mt-6 block ml-auto w-full max-w-[300px] rounded-md bg-blackText text-white py-2 px-3"
        >
          Mark Attendance
        </button>
      </section>
    </DashboardLayout>
  );
};

export default MarkAttendance;
