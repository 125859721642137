import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { BsArrowRight, BsFileEarmarkArrowUpFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { createSchool } from "../../api/thunks/admin/admin.service";
import { uploadToCloud } from "../../api/upload";
import { StepProps } from "../../pages/Auth/Register";
import { closePreloader, openErrorModal, openPreloader } from "../../store/slices/others/handlerSlice";
import { updateSchoolDetails } from "../../store/slices/post/createSchool";
import { RootState } from "../../store/store";
import { useGetParams } from "../../utils/getLocationParams";
import { addUserToLocalStorage, getTokenFromLocalStorage } from "../../utils/localStorage";
import { SchoolType } from "../../types/db.types";
import SelectContainer from "../UI/SelectContainer";

const formatFileSize = (size: number) => {
    if (size < 1024) {
        return `${size} bytes`;
    } else if (size > 1024) {
        return `${(size / 1024).toFixed(1)} kb`;
    } else if (size > 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(1)} mb`;
    }
};

const Step3: FC<StepProps> = ({ increaseStep }) => {
    const details = useSelector((state: RootState) => state.createSchool);
    const userId = useGetParams("userId");
    const dispatch = useDispatch();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [file, setFile] = useState<any>("");

    const updateName = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSchoolDetails({ key: "schoolName", value: e.target.value }));
    };

    const [token, setToken] = useState(useGetParams("token"));

    const createOrganization = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        // upload image before creating school
        if (!file) {
            dispatch(openErrorModal({ errorText: "Please provide school file" }));
            return;
        }

        try {
            dispatch(openPreloader({ loadingText: "Creating School" }));
            const imageUrl = await uploadToCloud(file);
            dispatch(updateSchoolDetails({ key: "imageUrl", value: imageUrl }));

            const data = await dispatch(createSchool({ data: { ...details, imageUrl, userId }, token }));
            if (!data?.error) {
                increaseStep();

                addUserToLocalStorage(data?.payload?.user);

                // For the admin page, refresh the page to fetch the school again from localstorage
                if (location.pathname.includes("/admin")) window.location.reload();
                else
                    setSearchParams({
                        token: token as string,
                    });
            }
        } catch (error) {
            dispatch(closePreloader());
        }
    };

    useEffect(() => {
        if (!location.pathname.includes("register")) {
            setToken(getTokenFromLocalStorage());
        }
    }, []);

    return (
        <>
            <header className="text-darkGray text-center">
                <h1 className="text-[27px] font-bold mb-2">School Preference</h1>
                <p className="text-[1rem] font-[500]">Select your school{"'"}s preference for the application</p>
            </header>
            <form onSubmit={createOrganization}>
                <div className="mt-8 mb-6 w-full max-w-[600px] mx-auto">
                    <div className="mb-4 w-full">
                        <label
                            htmlFor="organization-name"
                            className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                        >
                            School name
                        </label>
                        <input
                            type="text"
                            id="organization-name"
                            className="input-field"
                            placeholder="E.g Jaded Future Schools"
                            value={details.schoolName}
                            required
                            onChange={updateName}
                        />
                    </div>

                    <div className="mb-4 w-full">
                        <p className="text-[1.08rem] font-bold mb-2 block cursor-pointer">School logo</p>
                        <>
                            <label
                                htmlFor="file-upload"
                                className="sm:min-w-[280px] max-w-fit flex items-center gap-x-4 bg-white px-3 py-2 rounded-md"
                            >
                                <span className="text-[30px]">
                                    <BsFileEarmarkArrowUpFill />
                                </span>
                                {!file && (
                                    <div className="">
                                        <p>Click here to upload</p>
                                        <p>Max Size: 5mb</p>
                                    </div>
                                )}
                                {file && (
                                    <div className="">
                                        <p>{file.name.length > 24 ? `${file.name.slice(0, 25)}...` : `${file.name}`}</p>
                                        <p>Size: {formatFileSize(file.size)}</p>
                                    </div>
                                )}
                            </label>
                            <input
                                type={"file"}
                                id="file-upload"
                                className="hidden"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if ((file?.size as number) < 1024 * 1024 * 5) {
                                        if (
                                            file?.type === "image/webp" ||
                                            file?.type === "image/jpeg" ||
                                            file?.type === "image/png" ||
                                            file?.type === "image/jpg"
                                        ) {
                                            setFile(file);
                                        }
                                    }
                                }}
                                required
                            />
                        </>
                    </div>
                </div>
                <button className="max-w-full min-w-full sm:min-w-fit sm:max-w-fit ml-auto py-3 px-6 bg-black text-mainBg rounded-md block mt-6">
                    Proceed{" "}
                    <span className="inline-block align-middle ml-2">
                        <BsArrowRight />
                    </span>
                </button>
            </form>
        </>
    );
};
export default Step3;
