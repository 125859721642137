import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import SelectContainer from "../UI/SelectContainer";
import Input from "../UI/Input";
import { updateClassInfo, updateParentInfo, updateStudentDetails } from "../../store/slices/post/addStudent";
import { countryList } from "../../assets/static";
import { updateStaffDetails } from "../../store/slices/post/addStaffMemberSlice";
import { MdUploadFile } from "react-icons/md";
import { useFileUpload } from "../../utils/hooks/file-upload";
import { validateAddStudent } from "../../utils/formRequired";
import { openErrorModal } from "../../store/slices/others/handlerSlice";
import { monthsType } from "../../types/static.types";
import { checkParent } from "../../api/thunks/admin/admin.service";
import { IUser } from "../../types/db.types";
import ConfirmationModal from "../UI/ConfirmationModal";
import { SingleProfileDetail } from "./StaffDetailsTabs";
import { classes } from "../../static/admin&teacher.data";

const months: monthsType[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

type StepsProps = {
    disableInputs?: boolean;
};

type StudentFormProps = {
    onSubmit?: () => void;
};

const StudentForm: FC<StudentFormProps> = (props) => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);

    // to disable the inputs when adding a child wiht a parent that already exists
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    // modal to be displayed if a parent exists
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [existingParent, setExistingParent] = useState<IUser | null>(null);
    const [parentChecked, setParentChecked] = useState(false);

    const addStudentSlice = useSelector((state: RootState) => state.addStudent);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    // the last step differs based on the pathname. When adding a student, it is 3, when editing it is 2
    const lastStep = pathname.includes("edit") ? 2 : 3;

    const closeModal = () => {
        setModalOpened(false);
    };

    const resetInputs = () => {
        setParentChecked(false);
        setExistingParent(null);
        setInputsDisabled(false);
    };

    const selectParent = () => {
        setInputsDisabled(true);
        setModalOpened(false);
        dispatch(
            updateParentInfo({
                key: "firstname",
                value: existingParent?.firstName as any,
            })
        );
        dispatch(
            updateParentInfo({
                key: "lastname",
                value: existingParent?.lastName as any,
            })
        );
        dispatch(updateParentInfo({ key: "email", value: existingParent?.email as any }));
    };

    const checkForParentAcc = async () => {
        const data = await dispatch(checkParent(addStudentSlice?.parent?.phoneNumber));

        if (!data?.error) {
            if (data?.payload?.exists === true) {
                setParentChecked(true);
                setExistingParent(data?.payload?.parent);
                setModalOpened(true);
            } else if (data?.payload?.exists === false) {
                setCurrentStep(currentStep + 1);
            }
        }
    };

    const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (currentStep === 2 && !pathname.includes("edit") && !parentChecked) {
            // For only adding a student
            // Run the check parent function, if the parents exists and the choose the parent, disable the input
            checkForParentAcc();
        } else if (currentStep !== lastStep) {
            setCurrentStep(currentStep + 1);
        } else {
            // Display some custom errors that cannot be displayed by the default form element
            const error = validateAddStudent(addStudentSlice);
            if (!error) {
                props.onSubmit?.();
            } else {
                dispatch(openErrorModal({ errorText: error }));
            }
        }
    };

    useEffect(() => {
        // To make sure the parent's is always checked whenever the step is changed
        if (currentStep === 2) {
            setParentChecked(false);
        }
    }, [currentStep]);

    return (
        <section className="w-full mt-8 pb-8">
            {/* Modal */}
            {modalOpened && (
                <ParentExistsModal parent={existingParent as IUser} onClose={closeModal} onConfirm={selectParent} />
            )}

            <div className="w-full flex justify-between items-center gap-x-4 gap-y-4 flex-wrap">
                <div>
                    <h1 className="font-bold text-[1.25rem] mb-1 text-blackText">
                        {currentStep === 1
                            ? "Profile Set-Up"
                            : currentStep === 2
                            ? "Parent/Guardian Details"
                            : "Class Details"}
                    </h1>
                    <p>Kindly provide the required details</p>
                </div>
                <div className="flex flex-nowrap">
                    {/* First step */}
                    <div
                        className={
                            styles.step1Container +
                            ` ${currentStep >= lastStep ? "after:bg-blackText" : "after:bg-lightGray"}`
                        }
                    >
                        <span className={styles.step + " border-blackText"}>1</span>
                        <p className="max-w-[50px] text-[14px] text-center leading-[1.1]">Profile Set-up</p>
                    </div>
                    {/* Second step only visible when adding a child */}
                    {!pathname.includes("edit") && (
                        <div
                            className={`ml-[40px] ${styles.step2Container} ${
                                currentStep === lastStep ? "after:bg-blackText" : "after:bg-lightGray"
                            }`}
                        >
                            <span
                                className={
                                    styles.step +
                                    ` ${
                                        currentStep >= lastStep - 1 ? "border-blackText" : "bg-lightGray text-gray-600"
                                    }`
                                }
                            >
                                2
                            </span>
                            <p className="max-w-[100px] text-[14px] text-center leading-[1.1]">
                                Parent/Guardian Details
                            </p>
                        </div>
                    )}

                    {/* Third step */}
                    <div className={`${pathname.includes("edit") ? "ml-[60px]" : "ml-[1.25rem]"}`}>
                        <span
                            className={
                                styles.step +
                                ` ${currentStep === lastStep ? "border-blackText" : "bg-lightGray text-gray-600"}`
                            }
                        >
                            {lastStep}
                        </span>
                        <p className="max-w-[50px] text-[14px] text-center leading-[1.1]">Class Details</p>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="w-full border-[1.5px] rounded-md pt-8 pb-4 mt-8">
                <div className="w-full max-w-[800px] mx-auto">
                    {currentStep === 1 && <Step1 />}
                    {currentStep === 2 && !pathname.includes("edit") && <Step2 disableInputs={inputsDisabled} />}
                    {currentStep === lastStep && <Step3 />}
                </div>

                <div
                    className={`w-full flex items-center ${
                        currentStep !== 2 ? "sm:justify-end" : "sm:justify-between"
                    } justify-center mt-8 gap-x-4 gap-y-4 flex-wrap pt-6 border-t-[1.5px] px-6`}
                >
                    <div className="flex w-full md:max-w-fit md:flex-row flex-col gap-4">
                        <button
                            className={styles.buttons + " text-blackText"}
                            type="button"
                            onClick={() => {
                                if (currentStep !== 1) {
                                    setCurrentStep(currentStep - 1);
                                } else {
                                    navigate("/admin/students");
                                }
                            }}
                        >
                            {currentStep === 1 ? "Cancel" : "Previous"}
                        </button>

                        {/* For resetting the inputs when a parent has been selected */}
                        {currentStep === 2 && inputsDisabled && (
                            <button className={styles.buttons + " text-blackText"} onClick={resetInputs}>
                                Change Parent
                            </button>
                        )}
                    </div>
                    <button className={styles.buttons + " text-white bg-blackText"} type={"submit"}>
                        {currentStep !== lastStep ? "Continue" : "Submit"}
                    </button>
                </div>
            </form>
        </section>
    );
};

const Step1 = (props: StepsProps) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const addStudentSlice = useSelector((state: RootState) => state.addStudent);
    const uploadFile = useFileUpload();

    const [years, setYears] = useState<number[]>([]);

    const editStudentDetails = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateStudentDetails({ key: e.target.name, value: e.target.value }));
    };
    const updateGender = (value: string) => {
        dispatch(updateStudentDetails({ key: "gender", value }));
    };

    const updateReligion = (value: string) => {
        dispatch(updateStudentDetails({ key: "religion", value }));
    };

    const updateCountry = (value: string) => {
        dispatch(updateStudentDetails({ key: "country", value }));
    };

    const updateMonth = (value: string) => {
        dispatch(updateStudentDetails({ key: "monthOfAdmission", value }));
    };
    const updateYear = (value: string) => {
        dispatch(updateStudentDetails({ key: "yearOfAdmission", value }));
    };

    useEffect(() => {
        // create an array of all the years from 1990 to this year
        const yearsArr: number[] = [];
        for (let i = 1990; i < new Date().getFullYear() + 1; i++) {
            yearsArr.push(i);
        }

        setYears(yearsArr);
    }, []);

    return (
        <>
            <article className="px-6">
                <h1 className={styles.formSectionTitle}>Personal Information</h1>

                <div className={styles.gridContainer}>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            First Name
                        </label>
                        <Input
                            value={addStudentSlice.firstname}
                            onChange={editStudentDetails}
                            type={"text"}
                            name={"firstname"}
                            placeholder={"First Name"}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Middle Name {"(optional)"}
                        </label>
                        <Input
                            value={addStudentSlice.middlename}
                            onChange={editStudentDetails}
                            type={"text"}
                            name={"middlename"}
                            placeholder={"Middle Name"}
                            required={false}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Last Name
                        </label>
                        <Input
                            value={addStudentSlice.lastname}
                            onChange={editStudentDetails}
                            type={"text"}
                            name={"lastname"}
                            placeholder={"Last Name"}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Date of birth
                        </label>
                        <Input
                            value={addStudentSlice.dob}
                            onChange={editStudentDetails}
                            type={"date"}
                            name={"dob"}
                            placeholder={"Date of birth (MM-DD-YYYY)"}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Gender
                        </label>
                        <SelectContainer
                            list={["male", "Female"]}
                            currentItem={addStudentSlice.gender}
                            fitContent={true}
                            updateItem={updateGender}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Religion
                        </label>
                        <SelectContainer
                            list={["christianity", "islam", "others"]}
                            currentItem={addStudentSlice.religion || "Select Religion"}
                            fitContent={true}
                            updateItem={updateReligion}
                        />
                    </div>
                </div>
            </article>

            <article className="px-6">
                <h1 className={styles.formSectionTitle}>Contact Information</h1>

                <div className={styles.gridContainer}>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Phone Number {"(optional)"}
                        </label>
                        <Input
                            type={"number"}
                            value={addStudentSlice.phoneNumber}
                            onChange={editStudentDetails}
                            placeholder={"Phone Number"}
                            isPhoneNumber={true}
                            name={"phoneNumber"}
                            required={false}
                            minLength={10}
                            maxLength={11}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Country
                        </label>
                        <SelectContainer
                            list={countryList}
                            fitContent={false}
                            currentItem={addStudentSlice.country || "Select Country"}
                            updateItem={updateCountry}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            State
                        </label>
                        <Input
                            type={"text"}
                            value={addStudentSlice.state}
                            onChange={editStudentDetails}
                            placeholder={"State"}
                            name={"state"}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Address
                        </label>
                        <Input
                            type={"text"}
                            value={addStudentSlice.address}
                            onChange={editStudentDetails}
                            placeholder={"Address"}
                            name={"address"}
                        />
                    </div>
                    <div>
                        <p className={styles.labelText}>Profile Picture</p>
                        <label htmlFor="file" className="input-field block">
                            {addStudentSlice?.picture
                                ? (addStudentSlice.picture as File)?.name
                                    ? (addStudentSlice?.picture as File)?.name
                                    : "Change Picture"
                                : "Upload Picture"}
                        </label>
                        <input
                            type="file"
                            className="hidden"
                            id="file"
                            accept="image/*"
                            onChange={(e) => {
                                uploadFile(e, updateStudentDetails, "picture");
                            }}
                        />
                    </div>
                    <div>
                        <label className={styles.labelText}>Month of admission</label>
                        <SelectContainer
                            list={months}
                            currentItem={addStudentSlice.monthOfAdmission || "Month of admission"}
                            fitContent={false}
                            updateItem={updateMonth}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Year of admission
                        </label>
                        <SelectContainer
                            list={years}
                            currentItem={(addStudentSlice.yearOfAdmission as string) || "Year of admission"}
                            fitContent={false}
                            updateItem={updateYear}
                        />
                    </div>
                </div>
            </article>
        </>
    );
};

const Step2 = (props: StepsProps) => {
    const dispatch = useDispatch();
    const { parent } = useSelector((state: RootState) => state.addStudent);
    const editParentDetails = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateParentInfo({ key: e.target.name, value: e.target.value as any }));
    };

    const updateGender = (value: any) => {
        dispatch(updateParentInfo({ key: "gender", value }));
    };
    return (
        <>
            <article className="px-6">
                <h1 className={styles.formSectionTitle}>Personal Information</h1>

                <div className={styles.gridContainer}>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Firstname
                        </label>
                        <Input
                            disabled={props?.disableInputs}
                            value={parent.firstname}
                            onChange={editParentDetails}
                            type={"text"}
                            name={"firstname"}
                            placeholder={"Enter First Name"}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Lastname
                        </label>
                        <Input
                            disabled={props?.disableInputs}
                            value={parent.lastname}
                            onChange={editParentDetails}
                            type={"text"}
                            name={"lastname"}
                            placeholder={"Enter Last Name"}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Email (Optional)
                        </label>
                        <Input
                            disabled={props?.disableInputs}
                            value={parent.email}
                            onChange={editParentDetails}
                            type={"email"}
                            name={"email"}
                            placeholder={"Enter Email"}
                            required={false}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Phone Number
                        </label>
                        <Input
                            disabled={props?.disableInputs}
                            value={parent.phoneNumber}
                            onChange={editParentDetails}
                            isPhoneNumber={true}
                            type={"number"}
                            name={"phoneNumber"}
                            placeholder={"Enter Phone Number"}
                            minLength={10}
                            maxLength={11}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Gender
                        </label>
                        <SelectContainer
                            list={["male", "Female"]}
                            currentItem={parent.gender}
                            fitContent={true}
                            updateItem={updateGender}
                        />
                    </div>
                </div>
            </article>
        </>
    );
};

const Step3 = (props: StepsProps) => {
    const dispatch = useDispatch();
    const { class: classDetails, admissionNumber } = useSelector((state: RootState) => state.addStudent);

    const updateClassName = (value: any) => {
        dispatch(updateClassInfo({ key: "name", value }));
    };

    const editStudentDetails = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateStudentDetails({ key: e.target.name, value: e.target.value }));
    };

    return (
        <>
            <article className="px-6">
                <h1 className={styles.formSectionTitle}>Class Information</h1>

                <div className={styles.gridContainer}>
                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Class
                        </label>
                        <SelectContainer
                            list={classes}
                            currentItem={classDetails.name || "Select Class"}
                            updateItem={updateClassName}
                            fitContent={false}
                        />
                    </div>

                    <div>
                        <label htmlFor="" className={styles.labelText}>
                            Admission Number(optional)
                        </label>
                        <Input
                            required={false}
                            disabled={props?.disableInputs}
                            value={admissionNumber}
                            onChange={editStudentDetails}
                            type={"text"}
                            name={"admissionNumber"}
                            placeholder={"Admission Number"}
                        />
                    </div>
                </div>
            </article>
        </>
    );
};

interface ParentExistsModalProps {
    parent: IUser;
    onClose: () => void;
    onConfirm: () => void;
}

export const ParentExistsModal = (props: ParentExistsModalProps) => {
    return (
        <ConfirmationModal onCancel={props.onClose} onConfirm={props.onConfirm}>
            <h1>A parent with this phoneNumber already exists. Is this your parent?</h1>
            <div className="grid grid-cols-2">
                <SingleProfileDetail title="Firstname" subtitle={props?.parent?.firstName} />
                <SingleProfileDetail title="Lastname" subtitle={props?.parent?.lastName} />
                <SingleProfileDetail title="Email" subtitle={props?.parent?.email} />
                <SingleProfileDetail title="Phone Number" subtitle={props?.parent?.phoneNumber} />
            </div>
        </ConfirmationModal>
    );
};

const styles = {
    step1Container:
        "relative after:w-[83px] after:h-[2px] after:top-[25%] after:-translate-y-[50%] after:left-[90%]  after:absolute after:z-[2]",
    step2Container:
        "relative after:w-[65px] after:h-[2px] after:top-[25%] after:-translate-y-[50%] after:left-[63%]  after:absolute after:z-[2]",
    step: "w-[30px] h-[30px] border-[1.5px] rounded-full flex justify-center items-center mx-auto mb-1 font-bold",
    formSectionTitle: "text-[19px] font-bold text-blackText mb-3 mt-3",
    gridContainer: "w-full grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-4",
    buttons:
        "px-4 py-2 flex items-center border-[1.5px] border-blackText rounded-md font-bold gap-x-4 flex-nowrap sm:min-w-[150px] text-center justify-center w-full sm:w-fit",
    labelText: "block mb-2 font-bold text-blackText",
};

export default StudentForm;
