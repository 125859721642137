import React, { useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { closeErrorModal } from "../../store/slices/others/handlerSlice";
import { RootState } from "../../store/store";
import { getTokenFromLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";

export const ErrorModal = () => {
    const user = getUserFromLocalStorage();
    const { errorText, errorModalOpen, createModalOpen } = useSelector((state: RootState) => state.handlers);
    // const { data, isLoading } = useGetSchool();

    const dispatch = useDispatch();

    if (!errorModalOpen) {
        return <></>;
    }

    return (
        <section className="w-full h-screen bg-[rgba(0,0,0,0.5)] fixed top-0 left-0 z-[99999] flex justify-center items-center">
            <div className="w-[90vw] max-w-[400px] bg-white rounded-[5px] overflow-hidden">
                <div className="p-8 text-center">
                    <div className="cursor-pointer flex items-center justify-center text-[24px] text-red-600">
                        <span>
                            <FaExclamationTriangle />
                        </span>
                    </div>
                    <p className="mt-4 text-[1.25rem] font-[500]">Oh Snap!</p>
                    <p className="text-[1rem] mt-1">{errorText}</p>
                </div>
                <button
                    onClick={() => {
                        dispatch(closeErrorModal());
                    }}
                    className="cursor-pointer font-bold tracking-wider py-3 px-4 w-full bg-red-600 text-white text-center"
                >
                    Dismiss
                </button>
            </div>
        </section>
    );
};

export const Loader = () => {
    const { loading, loadingText } = useSelector((state: RootState) => state.handlers);

    if (!loading) {
        return <></>;
    }
    return (
        <section className="w-full h-screen bg-[rgba(0,0,0,0.5)] fixed top-0 left-0 z-[99999] flex flex-col gap-y-3 justify-center items-center">
            <span className="w-[45px] h-[45px] z-[9999] rounded-full border-[5px] border-mainBg border-t-blackText border-r-blackText animate-spin" />

            <p className="mt-4 text-white font-bold text-[23px]">{loadingText}...</p>
        </section>
    );
};
