import React, { useState, Dispatch, SetStateAction, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import swal from "sweetalert";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { useDispatch } from "react-redux";
import { changePassword } from "../../api/thunks/auth/auth.service";
import {
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";
import PasswordInput from "../../components/UI/PasswordInput";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const handleClick = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = getTokenFromLocalStorage();
    const data = await dispatch(
      changePassword({ data: { oldPassword, newPassword }, token })
    );

    if (!data?.error) {
      const user = getUserFromLocalStorage();

      const nextRoute =
        user?.role === "admin" || user.role === "superAdmin"
          ? "/admin/school"
          : user?.role === "parent"
          ? "/parent/kids"
          : user?.role === "teacher"
          ? "/teacher/profile"
          : user?.role === "student"
          ? "student/profile"
          : "";

      Swal.fire({
        icon: "success",
        title: "Password Reset Successful",
        showConfirmButton: false,
        text: "Click proceed below to go to dashboard",
        footer: `<a href= ${nextRoute} className="bg-black">Go To Dashboard</a> `,
      });
    }
  };

  return (
    <AuthLayout>
      <form
        className="flex justify-center w-[100%] md:w-[450px] "
        onSubmit={handleClick}
      >
        <div className="bg-[#FFFFFF] shadow-xl h-[600px] w-[450px] flex  flex-col pl-5 md:pl-12 pr-9  ">
          <h3 className="font-extrabold mt-9">USER PASSWORD RESET </h3>
          <div className="bg-[#D9E2EC] h-[3px]  mt-2">
            <div className="h-[100%] bg-black relative max-w-[80px]"></div>
          </div>

          <div className="mt-12 ">
            <p className="text-[#636363] text-[15px]">Old Password </p>
            <PasswordInput
              className={
                oldPassword ? "border-[#000000]" : "border-[#2277c038]"
              }
              name="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder="******"
            />
          </div>
          <div className="mt-12 ">
            <p className="text-[#636363] text-[15px]">New Password</p>
            <PasswordInput
              className={
                newPassword ? "border-[#000000]" : "border-[#2277c038]"
              }
              name="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="******"
            />
          </div>
          <button
            disabled={oldPassword === "" || newPassword === ""}
            type="submit"
            className="bg-[#000000] text-white mt-16 py-2 rounded-lg disabled:bg-[#AAAAAA] font-semibold cursor-pointer"
          >
            Change Password
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default PasswordReset;
