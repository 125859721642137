/**
 * Lists all the subjects the subject is tied to
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useGetTeachings } from '../../../api/hooks/teachers/teachers.service';
import DashboardLayout from '../../../components/Layouts/DashboardLayout';
import { ContentBox } from '../../../components/UI/ContentBox';
import { teachersSubjects } from '../../../static/admin&teacher.data';
import { IGeneratedSubjects } from '../../../types/db.types';

const Teachings = () => {
  const { data: teachings } = useGetTeachings();

  return (
    <DashboardLayout pageTitle='Classes'>
      <section className='py-6 sm:p-6 min-h-screen'>
        <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-6 gap-y-6'>
          {teachings?.map((subject: IGeneratedSubjects, index: number) => {
            return (
              <Link
                to={`/teacher/teachings/subject/${subject?.classId?._id}/${subject?._id}`}
                className='cursor-pointer'
                key={index}
              >
                <ContentBox defaultPadding={true} hoverBorderChange={true}>
                  <div className={styles.detail_container}>
                    <div className={styles.flex_container}>
                      <p className={styles.paragraph}>Class</p>
                      <h3 className={styles.title}>
                        {subject.classId?.className}{' '}
                        {subject?.classId?.classArm}
                      </h3>
                    </div>

                    <div className={styles.flex_container}>
                      <p className={styles.paragraph}>Subject</p>
                      <h3 className={styles.title}>{subject?.title}</h3>
                    </div>
                  </div>
                </ContentBox>
              </Link>
            );
          })}
        </div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  detail_container: 'w-full grid grid-cols-2 gap-x-4 gap-y-4',
  flex_container: 'flex flex-col gap-x-2 gap-y-2',
  paragraph: 'text-[1rem] text-darkGray',
  title: 'text-blackText text-[1.1rem] font-[500]',
};

export default Teachings;
