import React, { useState } from "react";
import {
  useGetAdmins,
  useGetStaffWithAdminAccess,
} from "../../../api/hooks/admin/staffs/staffs.service";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import { BiTrash } from "react-icons/bi";
import ConfirmationModal from "../../../components/UI/ConfirmationModal";
import { useDispatch } from "react-redux";
import {
  deleteAdminAccounts,
  removeStaffAdminAccess,
} from "../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";

const tableHeader = [
  { text: "Full Name", width: 200 },
  { text: "Email", width: 300 },
  { text: "Position", width: 150 },
  { text: "", width: 60 },
];

const RemoveAdmins = () => {
  const { data: admins, refetch: refetch_admin } = useGetAdmins();
  const { data: staff, refetch: refetch_staff } = useGetStaffWithAdminAccess();
  const dispatch = useDispatch();

  const [deleteAdminModal, setDeleteAdminModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");

  const [editStaffModal, setEditStaffModal] = useState<boolean>(false);

  const deleteAdmin = async () => {
    if (selected) {
      const data = await dispatch(deleteAdminAccounts(selected));

      if (!data.error) {
        await Swal.fire({
          title: "Delete Account",
          text: "Admin account deleted successfully",
          timer: 2000,
          showConfirmButton: false,
          icon: "success",
        });

        setDeleteAdminModal(false);
        setEditStaffModal(false);
        refetch_admin();
      }
    }
  };

  const removeStaffAccess = async () => {
    if (selected) {
      const data = await dispatch(removeStaffAdminAccess(selected));

      if (!data.error) {
        Swal.fire({
          title: "Staff Admin Access",
          text: "This staff is no longer an admin",
          timer: 2000,
          icon: "success",
          showConfirmButton: false,
        });

        setEditStaffModal(false);
        setDeleteAdminModal(false);
        refetch_staff();
      }
    }
  };

  return (
    <DashboardLayout pageTitle={"School Admins"}>
      <>
        {deleteAdminModal && selected && (
          <ConfirmationModal
            text="Are you sure you want to delete this admin account?"
            onCancel={() => {
              setDeleteAdminModal(false);
            }}
            onConfirm={deleteAdmin}
          />
        )}

        {editStaffModal && selected && (
          <ConfirmationModal
            text="Are you sure you want to remove this staff admin access?"
            onCancel={() => {
              setEditStaffModal(false);
            }}
            onConfirm={removeStaffAccess}
          />
        )}
      </>
      <TableLayout>
        <TableHeader>
          {tableHeader?.map((header, index) => {
            return <SingleTableHeader key={index} {...header} />;
          })}
        </TableHeader>

        <TableRowsContainer>
          <>
            {" "}
            {admins?.map((admin, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={tableHeader[0]?.width}>
                    <div className="flex items-center gap-[1rem]">
                      <img
                        src={
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2LqvFEoQZlQGvveT3fWp1pt5bf5vmcGqKUqd38hQ&s"
                        }
                        alt=""
                        className="w-[30px] h-[30px] object-center object-cover rounded-full"
                      />
                      <p>
                        {admin?.firstName} {admin?.lastName}
                      </p>
                    </div>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={tableHeader[1]?.width}>
                    <p> {admin?.email}</p>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={tableHeader[2].width}>
                    <p>Admin</p>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={tableHeader[3].width}>
                    <span
                      className="text-red-500 cursor-pointer"
                      onClick={() => {
                        setSelected(admin._id as string);
                        setDeleteAdminModal(true);
                      }}
                    >
                      <BiTrash />
                    </span>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </>

          <>
            {staff?.map((staff, index) => {
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={tableHeader[0]?.width}>
                    <div className="flex items-center gap-[1rem]">
                      <img
                        src={staff?.imageUrl}
                        alt=""
                        className="w-[30px] h-[30px] object-center object-cover rounded-full"
                      />
                      <p>
                        {staff.firstName} {staff.lastName}
                      </p>
                    </div>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={tableHeader[1]?.width}>
                    <p> {staff.email}</p>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={tableHeader[2].width}>
                    <p>{staff.role}</p>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={tableHeader[3]?.width}>
                    <span
                      className="text-red-500 cursor-pointer"
                      onClick={() => {
                        setSelected(staff._id);
                        setEditStaffModal(true);
                      }}
                    >
                      <BiTrash />
                    </span>
                  </SingleTableRowItem>
                </TableRow>
              );
            })}
          </>
        </TableRowsContainer>
      </TableLayout>
    </DashboardLayout>
  );
};

export default RemoveAdmins;
