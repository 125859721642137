import React, { FC, ReactElement } from "react";

type ModalOverlayProps = {
    modalWidth: number;
    children?: ReactElement | ReactElement[];
};

const ModalOverlay: FC<ModalOverlayProps> = ({ modalWidth, children }) => {
    return (
        <div className="fixed z-[5] w-[100vw] top-0 left-0 min-h-screen bg-[rgba(0,0,0,.5)] flex justify-center items-center">
            <div
                className="animate-fade w-[92vw] bg-white rounded-md p-6 mx-auto relative max-h-[90vh] overflow-y-scroll"
                style={{ maxWidth: modalWidth }}
            >
                {children}
            </div>
        </div>
    );
};

export default ModalOverlay;
