import React, { Fragment, useState } from "react";
import { MdCancel, MdOutlineClass } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useGetClasses } from "../../../../api/hooks/admin/generators/generators.service";
import { generatePayAdvice } from "../../../../api/thunks/admin/admin.service";
import { IGeneratePayAdvice } from "../../../../api/thunks/admin/admin.types";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import BackButton from "../../../../components/UI/BackButton";
import ConfirmationModal from "../../../../components/UI/ConfirmationModal";
import NoItem from "../../../../components/UI/NoItem";
import { IGeneratedClasses } from "../../../../types/db.types";
import { convertLocaleToNumber } from "../../../../utils/localeToNumber";
import {
  addNewItem,
  getItemValues,
  PayAdviceItemProps,
  removeItem,
  updateItemChargeAndName,
} from "../../../../utils/payAdviceUtils";
import { openErrorModal } from "../../../../store/slices/others/handlerSlice";

/** @todos
 *  @todo1 - List all the classes and allow the admin to select the class he wants to generate the pay advice for!!!
 */

const PayadviceGenerator = () => {
  const [selectedClass, setSelectedClass] = useState<IGeneratedClasses>();

  const [payAdviceItems, setPayAdviceItems] = useState<PayAdviceItemProps[]>([
    { charge: "", item: "", id: 1 },
  ]);

  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const { data: classes } = useGetClasses();

  const dispatch = useDispatch();

  const createPayAdivce = async () => {
    if (!selectedClass) {
      dispatch(openErrorModal({ errorText: "Select class" }));
      setConfirmationModal(false);

      return;
    }

    const items: IGeneratePayAdvice[] = payAdviceItems.map((item) => ({
      item: item.item,
      charge: parseInt(item.charge as string),
    }));

    const data = await dispatch(
      generatePayAdvice({ items, classId: selectedClass?._id })
    );

    if (!data?.error) {
      setConfirmationModal(false);

      Swal.fire({
        icon: "success",
        title: "Pay Advice",
        text: `Pay advice for ${selectedClass?.className} ${selectedClass?.classArm} created successfully`,
        timer: 1000,
        showConfirmButton: false,
      });
      setPayAdviceItems([{ charge: "", item: "", id: 1 }]);
    }
  };

  return (
    <DashboardLayout pageTitle={"Pay Advice Generator"}>
      <section className="py-6 sm:p-6 min-h-screen">
        {confirmationModal && (
          <ConfirmationModal
            onCancel={() => setConfirmationModal(false)}
            onConfirm={createPayAdivce}
          >
            <h3 className="text-[2rem] mb-4 text-center">
              Are you sure you want to add this to the bill?
            </h3>
            <i className="block text-center w-full">
              Note: <b>It can not be edited/deleted</b>
            </i>
          </ConfirmationModal>
        )}

        {classes && classes?.length > 0 && (
          <>
            <h2 className="text-[1.25rem] font-bold text-darkerGray mb-4 mt-8">
              Select Class
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-x-6 gap-y-6">
              {classes?.map((singleClass, index) => {
                return (
                  <article
                    className={
                      styles.content_box +
                      ` ${
                        selectedClass?._id === singleClass?._id &&
                        "border-[#242424]"
                      }`
                    }
                    key={index}
                    onClick={() => {
                      // make it the selected class
                      setSelectedClass(singleClass);
                    }}
                  >
                    <span
                      className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                    >
                      <MdOutlineClass />
                    </span>
                    <h2 className={styles.content_box_title}>
                      {singleClass?.className} {singleClass?.classArm}
                    </h2>
                  </article>
                );
              })}
            </div>
            {/* Section to generate the pay advice */}
            <section className="w-full">
              <h2 className="text-[1.25rem] font-bold text-darkerGray mb-4 mt-8">
                Generate pay advice for{" "}
                {selectedClass
                  ? selectedClass?.className + " " + selectedClass?.classArm
                  : "class"}
              </h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmationModal(true);
                }}
                className="md:grid-cols-2 grid-cols-1 grid gap-x-4 gap-y-4 max-w-[1000px]"
              >
                {payAdviceItems.map((payAdvice, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="w-full relative">
                        <input
                          type="text"
                          required={true}
                          className="input-field"
                          placeholder="Enter item name"
                          value={
                            getItemValues(payAdviceItems, payAdvice.id).item
                          }
                          onChange={(e) => {
                            setPayAdviceItems(
                              updateItemChargeAndName(
                                payAdviceItems,
                                payAdvice.id,
                                "item",
                                e.target.value
                              )
                            );
                          }}
                        />
                        {/* display on the all items when the items length are greater than 1 */}
                        {payAdviceItems.length > 1 && (
                          <span
                            className="absolute right-[10px] top-[50%] -translate-y-[50%] text-[1.25rem] cursor-pointer"
                            onClick={() => {
                              // don't remove it if it is the last item
                              payAdviceItems.length > 1 &&
                                setPayAdviceItems(
                                  removeItem(payAdviceItems, payAdvice.id)
                                );
                            }}
                          >
                            <MdCancel />
                          </span>
                        )}
                      </div>
                      <input
                        type="number"
                        min={1}
                        className="input-field"
                        placeholder="Enter item charge"
                        required={true}
                        value={getItemValues(
                          payAdviceItems,
                          payAdvice.id
                        ).charge.toLocaleString()}
                        onChange={(e) => {
                          setPayAdviceItems(
                            updateItemChargeAndName(
                              payAdviceItems,
                              payAdvice.id,
                              "charge",
                              e.target.value
                            )
                          );
                        }}
                      />
                    </Fragment>
                  );
                })}

                <button
                  className="py-2 px-4 rounded-md border bg-blackText -full text-white"
                  type="button"
                  onClick={() => {
                    setPayAdviceItems(addNewItem(payAdviceItems));
                  }}
                >
                  Add new item
                </button>

                <button
                  type="submit"
                  className="py-2 px-4 rounded-md border border-blackText w-full text-blackText hover:bg-blackText hover:text-white"
                >
                  Generate Pay Advice
                </button>
              </form>
            </section>{" "}
          </>
        )}

        {(!classes || classes?.length === 0) && (
          <NoItem title="No Class Yet" subtitle="Kindly Add Class(es)" />
        )}
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
  content_box:
    "p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer",
  content_box_icon:
    "w-[30px] h-[30px] rounded-full flex justify-center items-center",
  content_box_subtitle: "text-[15px] underline text-darkGray font-[300]",
  content_box_title: "text-[1rem] font-bold text-blackText flex flex-col",
};

export default PayadviceGenerator;
