import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetFullyPiadItems,
  useGetStudentScore,
  useGetStudentSubjects,
} from "../../../api/hooks/admin/students/students.service";
import {
  IPartiallyPaid,
  ISchoolDetails,
  ISchoolResultGradingFormat,
  IStudentScores,
} from "../../../types/db.types";
import { makeCashPayment } from "../../../api/thunks/admin/admin.service";
import Swal from "sweetalert2";
import ConfirmationModal from "../../UI/ConfirmationModal";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../Layouts/TableLayout";
import { fuseSubjectAndScores } from "../../../utils/dataHandling";
import { studentScoresHeaders } from "../../../static/admin&teacher.data";
import { getUserFromLocalStorage } from "../../../utils/localStorage";

export const Scores = () => {
  const location = useLocation();

  const { id } = useParams();

  const [scores, setScores] = useState<IStudentScores[]>([]);
  const school = getUserFromLocalStorage()?.school as ISchoolDetails;

  const scoreEndpoint = location.pathname.includes("admin")
    ? `/school/scores/student/${id}`
    : location.pathname.includes("teacher")
    ? `/teacher/scores/student/${id}`
    : location.pathname.includes("parent")
    ? `/parents/kid/scores/${id}`
    : `/student/scores`;

  const subjectsEndpoint = location.pathname.includes("admin")
    ? `/school/student/subjects/${id}`
    : location.pathname.includes("teacher")
    ? `/teacher/student/subjects/${id}`
    : location.pathname.includes("parent")
    ? `/parents/kid/subjects/${id}`
    : `/student/subjects`;

  const { data, isLoading: scoresLoading } = useGetStudentScore(scoreEndpoint);
  const { data: subjects, isLoading: subjectsLoading } =
    useGetStudentSubjects(subjectsEndpoint);

  useEffect(() => {
    if (data && subjects && !scoresLoading && !subjectsLoading) {
      setScores(fuseSubjectAndScores(subjects, data));
    }
  }, [data, subjects, scoresLoading, subjectsLoading]);

  return (
    <>
      <div className="w-full sm:px-0 px-2">
        <TableLayout>
          <TableHeader>
            {studentScoresHeaders.map((header, index) => {
              return <SingleTableHeader key={index} {...header} />;
            })}
          </TableHeader>
          <TableRowsContainer>
            {scores.map((score, index) => {
              const totalScore =
                score.firstCA +
                score.secondCA +
                score.thirdCA +
                score.examScore;
              return (
                <TableRow key={index}>
                  <SingleTableRowItem width={studentScoresHeaders[0].width}>
                    <p>{score.subjectId?.title}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={studentScoresHeaders[1].width}>
                    <p>{score.firstCA}</p>
                  </SingleTableRowItem>
                  <SingleTableRowItem width={studentScoresHeaders[2].width}>
                    <p>{score.secondCA}</p>
                  </SingleTableRowItem>
                  <>
                    {school.result_grading_format ===
                      ISchoolResultGradingFormat?._70_30 && (
                      <SingleTableRowItem width={studentScoresHeaders[3].width}>
                        <p>{score.thirdCA}</p>
                      </SingleTableRowItem>
                    )}
                  </>
                  <SingleTableRowItem width={studentScoresHeaders[4].width}>
                    <p>{score.examScore}</p>
                  </SingleTableRowItem>

                  <SingleTableRowItem width={studentScoresHeaders[5].width}>
                    <p>{totalScore}</p>
                  </SingleTableRowItem>
                  {/* <SingleTableRowItem width={studentScoresHeaders[6].width}>
                      <p
                        className={`px-2 py-1 rounded-[1.25rem] max-w-fit text-[13px] ${
                          totalScore < 40
                            ? 'text-red-700 bg-red-100'
                            : 'text-green-700 bg-green-100'
                        }`}
                      >
                        {totalScore < 40 ? 'Failed' : 'Passed'}
                      </p>
                    </SingleTableRowItem> */}
                </TableRow>
              );
            })}
          </TableRowsContainer>
        </TableLayout>
      </div>
    </>
  );
};
