import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseHttp } from "../../../axios.config";
import { IStudentResult } from "../../../pages/Admin/Generator/Results/ThirdTerm";
import {
  closePreloader,
  openErrorModal,
  openPreloader,
} from "../../../store/slices/others/handlerSlice";
import { RootState } from "../../../store/store";
import { ScoreState } from "../../../types/static.types";
import { computeAllStudentsAverage } from "../../../utils/result-calculator";
import { IApproveResult, IMarkAttendance } from "./teacher.types";
import { IStudentReportInfo } from "../../../types/db.types";

export const markAttendance: any = createAsyncThunk(
  "teacher/mark-attendance",
  async (data: IMarkAttendance, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Marking Attendance" }));

    try {
      const response = await baseHttp.post(
        "/teacher/attendance/" + data?.classId,
        data
      );
      dispatch(closePreloader());

      return response?.data;
    } catch (error: any) {
      dispatch(closePreloader());
      dispatch(openErrorModal({ errorText: error.response.data.message }));
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const recordScore: any = createAsyncThunk(
  "teacher/record-score",
  async (subjectId: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    const { scores } = (thunkApi.getState() as RootState).getStudentsScores;

    // Format the scores
    const formatScore = (score: number | string) => (score === "" ? 0 : score);

    const score = (scores as ScoreState[])?.map((score) => {
      return {
        firstCA: formatScore(score.firstCA),
        secondCA: formatScore(score.secondCA),
        thirdCA: formatScore(score.thirdCA),
        examScore: formatScore(score.examScore),
        studentId: score?.student?._id,
      };
    });

    dispatch(openPreloader({ loadingText: "Recording Score" }));

    try {
      const response = await baseHttp.post(
        "/teacher/student/record-score/" + subjectId,
        { scores: score }
      );
      dispatch(closePreloader());

      return response?.data;
    } catch (error: any) {
      dispatch(closePreloader());
      dispatch(openErrorModal({ errorText: error.response.data.message }));
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const gradeStudents: any = createAsyncThunk(
  "teacher/grade-students",
  async (data: IStudentResult[], thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Saving Results" }));

    try {
      const response = await baseHttp.post(`/teacher/grade-students`, {
        students: data,
      });

      dispatch(closePreloader());

      return response.data;
    } catch (error: any) {
      dispatch(closePreloader());
      dispatch(openErrorModal({ errorText: error.response.data.message }));
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const addStudentReportInfo: any = createAsyncThunk(
  "teacher/add-student-info",
  async (data: Partial<IStudentReportInfo>, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(
      openPreloader({ loadingText: "Adding Student Report Information" })
    );

    try {
      const response = await baseHttp.post(
        `/teacher/student/${data._id}/report-card-information`,
        {
          affectiveDomains: data.affectiveDomains,
          classTeacherComment: data.classTeacherComment,
          officeHeld: data.officeHeld,
          weight: data.weight,
          height: data.height,
        }
      );

      dispatch(closePreloader());

      return response.data;
    } catch (error: any) {
      dispatch(closePreloader());
      dispatch(
        openErrorModal({
          errorText: error.response.data.message,
        })
      );
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const approveClassResult: any = createAsyncThunk(
  "teacher/approve-result",
  async (data: IApproveResult, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Saving Results" }));

    const results = computeAllStudentsAverage(data.subjectsLength, data.scores);

    try {
      const response = await baseHttp.post(
        `/school/class/${data.classId}/approve-results`,
        { results }
      );

      dispatch(closePreloader());

      return response.data;
    } catch (error: any) {
      dispatch(closePreloader());
      dispatch(openErrorModal({ errorText: error.response.data.message }));
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
