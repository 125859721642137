/**
 * For displaying the students scores
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScoreState } from '../../../types/static.types';

const initialState: { scores: ScoreState[] } = {
  scores: [],
};

const scoresSlice = createSlice({
  name: 'teacher/get-scores',
  initialState,
  reducers: {
    fetchScores: (state, action: PayloadAction<ScoreState[]>) => {
      state.scores = action.payload;
    },

    updateSingleStudentScore: (
      state,
      action: PayloadAction<{ studentId: string; key: string; value: string }>
    ) => {
      state.scores = state.scores?.map((score: ScoreState) => {
        if (score.student._id === action.payload.studentId) {
          score[action.payload.key as keyof ScoreState] = action.payload
            .value as any;
        }
        return score;
      });
    },
  },
});

export const scoresReducer = scoresSlice.reducer;
export const { updateSingleStudentScore, fetchScores } = scoresSlice.actions;
