import React, { ChangeEvent, FC, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { countryList } from "../../assets/static";
import { StepProps } from "../../pages/Auth/Register";
import { BsArrowRight } from "react-icons/bs";
import { InputFields, paymentDetailsProps } from "../../types/componentsProps.types";
import SelectContainer from "../UI/SelectContainer";
import Input from "../UI/Input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../store/store";
import { updateSchoolDetails } from "../../store/slices/post/createSchool";
import { ISchoolResultGradingFormat, SchoolType, State } from "../../types/db.types";
import { openErrorModal } from "../../store/slices/others/handlerSlice";
import { useGetDistricts, useGetStates } from "../../api/hooks/admin/school/school.service";

const getPreviousYears = () => {
    const years = [];

    for (let i = 2021; i <= new Date().getFullYear(); i++) {
        years.push(i);
    }

    return years;
};

const inputFields: InputFields[] = [
    {
        placeholder: "Enter Email",
        name: "schoolEmail",
        type: "email",
        required: true,
        label: "School Email",
    },
    {
        placeholder: "Country",
        name: "country",
        type: "country-select",
        required: true,
        label: "Country",
    },

    {
        placeholder: "Enter Address",
        name: "address",
        type: "text",
        required: true,
        label: "School Address",
    },
    {
        placeholder: "Enter State/Province",
        name: "state",
        type: "state-select",
        required: true,
        label: "State/Province",
    },
    {
        placeholder: "Select District",
        name: "district",
        type: "district-select",
        required: true,
        label: "District",
    },
    {
        placeholder: "Enter zip code",
        name: "zipCode",
        type: "number",
        required: false,
        label: "Zip Code (optional)",
    },
    {
        placeholder: "Enter City",
        name: "city",
        type: "text",
        required: true,
        label: "City",
    },
];

const resultGradingFormatInfo = {
    _70_30: "3 CAs (10 marks each), Exam (70 marks)",
    _60_40: "2 CAs (20 marks each), Exam (60 marks)",
};

const Step2: FC<StepProps> = ({ increaseStep }) => {
    const dispatch = useDispatch();
    const details = useSelector((state: RootState) => state.createSchool);
    const { data: states, isLoading: isStatesLoading } = useGetStates();
    const mutation = useGetDistricts();

    const [countrySelectorOpen, setCountrySelectorOpen] = useState<boolean>(false);

    const updatePaymentDetails = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSchoolDetails({ key: e.target.name, value: e.target.value }));
    };

    const updateCountry = (value: string) => {
        dispatch(updateSchoolDetails({ key: "country", value }));
    };

    const updateState = async (_id: string) => {
        dispatch(updateSchoolDetails({ key: "state", value: _id }));

        await mutation.mutateAsync(_id);
    };

    const updateDistrict = async (_id: string) => {
        dispatch(updateSchoolDetails({ key: "district", value: _id }));
    };

    const updateSchoolOwnership = (isPublic: boolean) => {
        dispatch(updateSchoolDetails({ key: "isPublic", value: isPublic }));
    };

    const updateSchoolType = (type: string) => {
        dispatch(updateSchoolDetails({ key: "schoolType", value: type as SchoolType }));
    };

    const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!details?.result_grading_format) {
            dispatch(openErrorModal({ errorText: "Select a result grading format" }));
            return;
        }

        if (!details?.country) {
            dispatch(openErrorModal({ errorText: "Select Country" }));
            return;
        }

        if (details.isPublic && !details.schoolType) {
            dispatch(openErrorModal({ errorText: "Select School Type" }));
            return;
        }

        if (!details?.state) {
            dispatch(openErrorModal({ errorText: "Select State" }));
            return;
        }

        if (!details?.term) {
            dispatch(openErrorModal({ errorText: "Select Term" }));
            return;
        }

        if (!details?.sessionPrefix) {
            dispatch(openErrorModal({ errorText: "Select Session" }));
            return;
        }

        increaseStep();
    };

    return (
        <>
            <header className="text-darkGray text-center">
                <h1 className="text-[27px] font-bold mb-2">School Details</h1>
                <p className="text-[1rem] font-[500]">Add School Details</p>
                <div className="my-4 w-full relative after:absolute after:top-[50%] after:-translate-y-[50%] after:right-0 after:bg-lightGray after:h-[2px] after:sm:w-[30%] after:w-[22%]  before:absolute before:top-[50%] before:-translate-y-[50%] before:left-0 before:bg-lightGray before:h-[2px] before:sm:w-[30%] before:w-[22%] font-bold max-w-[600px] mx-auto">
                    School Verification
                </div>
            </header>
            <form className="mt-8 mb-6 w-full max-w-[600px] mx-auto" onSubmit={handleSubmit}>
                <div className="w-full">
                    <label htmlFor="organization-name" className="text-[1.08rem] font-bold mb-4 block cursor-pointer">
                        School Ownership
                    </label>
                    <SelectContainer
                        list={["private", "public"]}
                        currentItem={details?.isPublic ? "public" : "private"}
                        updateItem={(item) =>
                            item === "private" ? updateSchoolOwnership(false) : updateSchoolOwnership(true)
                        }
                        fitContent={false}
                    />
                </div>

                {details.isPublic && (
                    <div className="w-full">
                        <label
                            htmlFor="organization-name"
                            className="text-[1.08rem] font-bold mb-4 block cursor-pointer"
                        >
                            School Type
                        </label>
                        <SelectContainer
                            list={Object.values(SchoolType)}
                            currentItem={details?.schoolType || "Select School Type"}
                            updateItem={updateSchoolType}
                            fitContent={false}
                        />
                    </div>
                )}
                {inputFields.map((input, index: number) => {
                    return (
                        <div key={index} className="w-full mb-4">
                            {!["country-select", "state-select", "district-select"].includes(input.type) && (
                                <>
                                    <label
                                        htmlFor={input.name}
                                        className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                                    >
                                        {input.label}
                                    </label>
                                    <Input
                                        className="!w-full block min-w-full"
                                        placeholder={input.placeholder}
                                        type={input.type}
                                        name={input.name}
                                        value={[details[input.name as keyof typeof details]]}
                                        onChange={updatePaymentDetails}
                                        required={input.required}
                                    />
                                </>
                            )}
                            {input.type === "country-select" && (
                                <>
                                    <label
                                        htmlFor={input.name}
                                        className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                                    >
                                        {input.label}
                                    </label>
                                    <SelectContainer
                                        list={countryList}
                                        currentItem={
                                            (details[input.name as keyof typeof details] as string) || "Select Country"
                                        }
                                        fitContent={false}
                                        updateItem={updateCountry}
                                    />
                                </>
                            )}

                            {input.type === "state-select" && (
                                <>
                                    <label
                                        htmlFor={input.name}
                                        className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                                    >
                                        {input.label}
                                    </label>
                                    <SelectContainer
                                        list={states?.map((state) => state.name) || []}
                                        currentItem={
                                            states?.find((state) => state._id === details.state)?.name || "Select State"
                                        }
                                        isLoading={isStatesLoading}
                                        fitContent={false}
                                        updateWithId={(id: string | number) => updateState(id as string)}
                                        ids={states?.map((state) => state._id) || []}
                                    />
                                </>
                            )}

                            {input.type === "district-select" && details.isPublic && (
                                <>
                                    <label
                                        htmlFor={input.name}
                                        className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                                    >
                                        {input.label}
                                    </label>
                                    <SelectContainer
                                        list={mutation?.data?.map((district) => district.name) || []}
                                        currentItem={
                                            mutation?.data?.find((state) => state._id === details.district)?.name ||
                                            "Select District"
                                        }
                                        isLoading={mutation.isLoading}
                                        fitContent={false}
                                        updateWithId={(id: string | number) => updateDistrict(id as string)}
                                        ids={mutation?.data?.map((district) => district._id) || []}
                                    />
                                </>
                            )}
                        </div>
                    );
                })}

                {/* Current Session e.g 2021/2022 */}
                <label htmlFor={"sessionPrefix"} className="text-[1.08rem] font-bold mb-2 block cursor-pointer">
                    Current Session e.g 2020/2021
                </label>

                <div className="flex flex-row flex-nowrap items-center gap-x-4 gap-y-4 mb-2">
                    {/* Session Prefix 2021 */}

                    <SelectContainer
                        list={getPreviousYears()}
                        currentItem={details.sessionPrefix || "Select Year"}
                        updateItem={(value) => {
                            dispatch(updateSchoolDetails({ key: "sessionPrefix", value }));
                            dispatch(updateSchoolDetails({ key: "sessionSuffix", value: value + 1 }));
                        }}
                        fitContent={false}
                    />

                    <p className="text-[24px]">/</p>

                    {/* Session Suffix 2022 */}

                    <div className="input-field">{details.sessionSuffix || "Select previous year"}</div>
                </div>

                {/* Term - 1st, 2nd, 3rd */}

                <label htmlFor={"term"} className="text-[1.08rem] font-bold mb-2 block cursor-pointer">
                    Current Term
                </label>

                <SelectContainer
                    list={["1st", "2nd", "3rd"]}
                    currentItem={details.term || "Select Term"}
                    updateItem={(value) => {
                        dispatch(updateSchoolDetails({ key: "term", value }));
                    }}
                    fitContent={false}
                />

                {/* result grading format */}
                <div className="w-full mt-4">
                    <label htmlFor={"term"} className="text-[1.08rem] font-bold mb-2 block cursor-pointer">
                        Select Result Grading Format
                    </label>

                    <div className="mt-2 flex items-center gap-[1rem] flex-wrap relative">
                        <span
                            className={`px-3 py-1 rounded-md bg-white border-2 cursor-pointer ${
                                details?.result_grading_format === ISchoolResultGradingFormat._70_30 &&
                                " !bg-blackText text-white"
                            }`}
                            title={resultGradingFormatInfo._70_30}
                            onClick={() => {
                                dispatch(
                                    updateSchoolDetails({
                                        key: "result_grading_format",
                                        value: ISchoolResultGradingFormat._70_30,
                                    })
                                );
                            }}
                        >
                            3 CAs, 1 Exam
                        </span>
                        <span
                            className={`px-3 py-1 rounded-md bg-white border-2 cursor-pointer ${
                                details?.result_grading_format === ISchoolResultGradingFormat._60_40 &&
                                " !bg-blackText text-white"
                            }`}
                            title={resultGradingFormatInfo._60_40}
                            onClick={() => {
                                dispatch(
                                    updateSchoolDetails({
                                        key: "result_grading_format",
                                        value: ISchoolResultGradingFormat._60_40,
                                    })
                                );
                            }}
                        >
                            2 CAs, 1 Exam
                        </span>
                    </div>
                </div>

                <button
                    className="max-w-full min-w-full sm:min-w-fit sm:max-w-fit ml-auto py-3 px-6 bg-black text-mainBg rounded-md block mt-6"
                    // onClick={increaseStep}
                    type={"submit"}
                >
                    Proceed{" "}
                    <span className="inline-block align-middle ml-2">
                        <BsArrowRight />
                    </span>
                </button>
            </form>
        </>
    );
};

export default Step2;
