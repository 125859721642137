/**
 * Displays info about the subject, it's lesson materials and the C.As for the subhect
 */

import React, { useEffect, useState } from 'react';
import { GiGraduateCap } from 'react-icons/gi';
import DashboardLayout from '../../../components/Layouts/DashboardLayout';
import {
  ContentBoxWithAvatar,
  ContentBoxWithAvatarHeader,
} from '../../../components/UI/ContentBox';
import { ScoreState, TabsProps } from '../../../types/static.types';
import {
  ClassResult,
  LessonMaterials,
} from '../../../components/Teacher/SubjectDetailsTabs';
import {
  useGetScores,
  useGetSingleSubject,
  useGetStudentsInClass,
} from '../../../api/hooks/teachers/teachers.service';
import { useParams } from 'react-router-dom';
import { fillEmptyScores, formatScores } from '../../../utils/dataHandling';
import { useDispatch } from 'react-redux';
import { fetchScores } from '../../../store/slices/get/scores.slice';
import { IAllStudents, IUser } from '../../../types/db.types';
import { getUserFromLocalStorage } from '../../../utils/localStorage';

const tabs: TabsProps[] = [
  // {title:"Lesson Materials", component: <LessonMaterials />}, ====== NOT IT MVP ====
  {
    title: 'Class Result',
    component: <ClassResult />,
  },
];

const SubjectDetails = () => {
  const [openedTab, setOpenedTab] = useState<TabsProps>({
    title: 'Class Result',
    component: <ClassResult />,
  });

  const { subjectId, classId } = useParams();

  const dispatch = useDispatch();

  const { data: subject } = useGetSingleSubject(subjectId as string);

  const { data: scores, isLoading } = useGetScores(
    subjectId as string,
    classId as string
  );

  const { data: allStudents, isLoading: studentsLoading } =
    useGetStudentsInClass(classId as string);

  const switchTab = (tab: TabsProps) => {
    setOpenedTab(tab);
  };

  const user: IUser = getUserFromLocalStorage();

  useEffect(() => {
    if (scores && allStudents && !isLoading && !studentsLoading) {
      dispatch(
        fetchScores([
          ...formatScores(scores),
          ...fillEmptyScores(allStudents as IAllStudents[], scores),
        ])
      );
    }
  }, [scores]);

  return (
    <DashboardLayout
      pageTitle={
        <>
          Class/
          <span className='text-blackText font-bold'>Result Upload</span>
        </>
      }
      documentTitle={'Classes'}
    >
      <section className='py-6 sm:p-6 min-h-screen'>
        <div className='mb-4 max-w-[500px]'>
          {/* The header should be the class name */}
          <ContentBoxWithAvatar
            header={
              subject?.classId?.className + ' ' + subject?.classId?.classArm
            }
            avatar={<GiGraduateCap />}
            hasButton={false}
          >
            <div className={styles.detail_container}>
              <article className={styles.flex_container}>
                <p className={styles.paragraph}>Session</p>
                <h3 className={styles.title}>2021/2022</h3>
              </article>

              <article className={styles.flex_container}>
                <p className={styles.paragraph}>Subject</p>
                <h3 className={styles.title}>{subject?.title}</h3>
              </article>
              <article className={styles.flex_container}>
                <p className={styles.paragraph}>Term</p>
                <h3 className={styles.title}>{user?.school?.term}</h3>
              </article>
            </div>
          </ContentBoxWithAvatar>
        </div>

        {/* tabs */}
        <div className='mt-8 sm:px-0 px-4'>
          <div className='w-full max-w-full overflow-x-scroll border-b-[1.5px] flex items-center gap-x-4 pb-2'>
            {tabs?.map((tab, index) => {
              return (
                <h3
                  key={index}
                  className={`pb-1 mb-0  leading-[1] border-b-[1.5px] cursor-pointer ${
                    index === 0 ? 'min-w-[11.25rem]' : 'min-w-fit'
                  } font-bold text-center ${
                    openedTab.title === tab.title
                      ? 'border-black text-blackText'
                      : 'border-transparent text-darkerGray'
                  }`}
                  onClick={() => {
                    switchTab(tab);
                  }}
                >
                  {tab.title}
                </h3>
              );
            })}
          </div>
        </div>
        <section className='w-full mt-8'>{openedTab.component}</section>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  detail_container: 'w-full grid grid-cols-3 gap-x-4 gap-y-4 p-6',
  flex_container: 'flex flex-col gap-x-2 gap-y-2',
  paragraph: 'text-[1rem] text-darkGray',
  title: 'text-blackText text-[1.1rem] font-[500] capitalize',
};

export default SubjectDetails;
