import React from "react";

interface Props {
  question: string;
  answer: string;
}

const FooterInfo = ({ question, answer }: Props) => {
  return (
    <div className="flex w-full flex-1 items-end text-[.9rem]">
      <p className="bg-blackText uppercase text-white pt-2 px-2 pb-1 mb-0 max-w-fit">
        {question}:
      </p>
      <div className="flex-1 font-bold mb-0 flex flex-col">
        <p className="px-2 uppercase text-center mb-1">{answer}</p>
        <hr className="h-[2px] bg-blackText w-full" />
      </div>
    </div>
  );
};

export default FooterInfo;
