import React, { FC, useEffect, useRef, useState } from "react";
import { BiPlus, BiSearch } from "react-icons/bi";
import { RiMenu2Line } from "react-icons/ri";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import TableLayout, {
    SingleTableHeader,
    SingleTableRowItem,
    TableHeader,
    TableRow,
    TableRowsContainer,
} from "../../../components/Layouts/TableLayout";
import { Link } from "react-router-dom";
import { staffsHeader, staffsList, studentsHeader } from "../../../static/admin&teacher.data";
import { ButtonFilled, OutlineButton } from "../../../components/UI/Buttons";
import { useGetStudents } from "../../../api/hooks/admin/students/students.service";
import { IAllStudents } from "../../../types/db.types";
import NoItem from "../../../components/UI/NoItem";
import { useSearch } from "../../../utils/hooks/useSearch";
import { StudentFilter } from "../../../components/UI/FilterModals";

const Students = () => {
    const [gender, setGender] = useState<string | undefined>(undefined);
    const [classDetail, setClass] = useState<string | undefined>(undefined);

    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

    const { data, isLoading, isError, refetch } = useGetStudents(gender as string, classDetail as string);

    const { list, setList, searchValue, setSearchValue } = useSearch(data?.students as IAllStudents[]);

    useEffect(() => {
        refetch({});
    }, [gender, classDetail]);

    return (
        <DashboardLayout pageTitle="Students">
            <>
                {filterModalOpen && (
                    <StudentFilter
                        class={classDetail}
                        setClass={setClass}
                        gender={gender}
                        setGender={setGender}
                        closeModal={() => setFilterModalOpen(false)}
                    />
                )}
            </>

            <section className="sm:p-6 py-6 min-h-screen">
                <header className={styles.header_container}>
                    {/* search container */}
                    <div className={styles.search_box}>
                        <div className="flex-1">
                            <input
                                type="text"
                                placeholder='Try "Ademola Osanyin"'
                                className={styles.search_input}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                        <span className={styles.search_icon}>
                            <BiSearch />
                        </span>
                    </div>
                    <div className="flex-[33%] flex gap-x-4 gap-y-4 flex-wrap sm:flex-nowrap">
                        {/* filter button */}
                        <OutlineButton
                            icon={<RiMenu2Line />}
                            text={"Filter"}
                            onClick={() => setFilterModalOpen(true)}
                        />

                        <Link to={"/admin/students/new"} className="min-w-fit">
                            <ButtonFilled icon={<BiPlus />} text={"Add Student"} />
                        </Link>
                    </div>
                </header>
                {list?.length > 0 && (
                    <div className="w-full sm:px-0 px-2">
                        <TableLayout>
                            <TableHeader>
                                {studentsHeader.map((header, index) => {
                                    return <SingleTableHeader key={index} text={header.text} width={header.width} />;
                                })}
                            </TableHeader>

                            <TableRowsContainer>
                                {(list as IAllStudents[])?.map((rowItem, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <SingleTableRowItem width={studentsHeader[0].width}>
                                                <Link
                                                    to={`/admin/students/${rowItem._id}`}
                                                    className="flex items-center gap-x-2 cursor-pointer"
                                                >
                                                    <img
                                                        className="w-[25px] h-[25px] rounded-full"
                                                        alt={rowItem.firstName}
                                                        loading="lazy"
                                                        src={rowItem.imageUrl}
                                                    />
                                                    <p className="font-bold text-[#222] cursor-pointer hover:underline">
                                                        {rowItem.firstName + " " + rowItem.lastName}
                                                    </p>
                                                </Link>
                                            </SingleTableRowItem>

                                            <SingleTableRowItem width={studentsHeader[1].width}>
                                                <p className="capitalize">{rowItem.gender}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={studentsHeader[2].width}>
                                                <p>{rowItem.class}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={studentsHeader[3].width}>
                                                <p>{rowItem.phoneNumber}</p>
                                            </SingleTableRowItem>
                                            <SingleTableRowItem width={studentsHeader[4].width}>
                                                <p>{rowItem.state}</p>
                                            </SingleTableRowItem>
                                        </TableRow>
                                    );
                                })}
                            </TableRowsContainer>
                        </TableLayout>
                    </div>
                )}
                {/* No Student */}
                {(data?.students?.length === 0 || isLoading) &&
                    !gender &&
                    !classDetail &&
                    data?.students?.length === 0 && <NoItem title="No Student Yet" subtitle="Kindly Add Students" />}
                {(data?.students?.length !== 0 && list?.length === 0) ||
                    (data?.students?.length === 0 && (gender || classDetail) && (
                        <div className="w-full h-[80vh] flex justify-center items-center flex-col gap-y-3">
                            <h2 className="text-darkGray text-[1.7rem] font-bold">No student matches your search!</h2>
                        </div>
                    ))}
            </section>
        </DashboardLayout>
    );
};

export default Students;

const styles = {
    header_container: "flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center",
    search_box: "flex-[67%] flex w-full gap-[1rem] gap-y-[1rem] border-[1.5px] rounded-md hover:border-black bg-white",
    search_input: "w-full outline-none border-none h-[35px] px-4 placeholder:text-darkGray",
    search_icon: "flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray",
    buttons: "px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit",
};
