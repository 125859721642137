import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { uploadToCloud } from "../../upload";
import { formatPhoneNumber, rearrangeStaffDetails, rearrangeStudentDetails } from "../../../utils/dataHandling";
import {
    IAddStaff,
    IAddStudent,
    IGenerateClass,
    IGeneratePayAdvice,
    IGenerateSubject,
    ISendMessage,
} from "./admin.types";
import { IAddAdmin, ICreateSchool } from "./admin.types";
import { closePreloader, openErrorModal, openPreloader } from "../../../store/slices/others/handlerSlice";
import { baseHttp } from "../../../axios.config";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { IBulkUploadRes, IFullyPaidItems } from "../../../types/db.types";

// ============ SCHOOL & ADMIN
export const moveToNextTerm: any = createAsyncThunk("/school/next-term", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Processing..." }));
    setTimeout(() => {
        dispatch(openPreloader({ loadingText: "Promoting Students..." }));
    }, 1000);

    try {
        const response = await baseHttp.get("/school/next-term");

        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const addAdmin: any = createAsyncThunk(
    "/school/admin/new",
    async (
        {
            data,
            token,
            teachers,
        }: {
            data: IAddAdmin[];
            teachers: string[];
            token: string;
            schoolName: string;
        },
        thunkApi
    ) => {
        const dispatch = thunkApi.dispatch;
        dispatch(openPreloader({ loadingText: "Adding school admins" }));

        const body: any = {};

        if (data) {
            body.admins = data;
        }

        if (teachers) {
            body.teachers = teachers;
        }
        try {
            const response = await baseHttp.post("/school/add-admin", body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(closePreloader());
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message || error.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const deleteAdminAccounts: any = createAsyncThunk("superAdmin/delete-admins", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Deleting Admin Account" }));

    try {
        const response = await baseHttp.delete(`/school/remove-admin/${id}`);
        dispatch(closePreloader());
        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message || error.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const removeStaffAdminAccess: any = createAsyncThunk(
    "superAdmin/removeStaffAdminAccess",
    async (id: string, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Removing staff admin access" }));

        try {
            const response = await baseHttp.post(`/school/staff/remove-admin/${id}`);
            dispatch(closePreloader());
            return response.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message || error.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const createSchool: any = createAsyncThunk(
    "school/create",
    async ({ data, token }: { data: ICreateSchool; token: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;
        dispatch(openPreloader({ loadingText: "Creating school" }));

        const session = data.sessionPrefix + "/" + data.sessionSuffix;
        const { sessionPrefix, sessionSuffix, ...rest } = data;

        try {
            const response = await baseHttp.post(
                "/school/create-school/",
                {
                    ...rest,
                    session,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(closePreloader());
            return response.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const editSchool: any = createAsyncThunk("admin/editSchool", async (image: string, thunkApi) => {
    const data = (thunkApi.getState() as RootState).createSchool;
    const dispatch = thunkApi.dispatch;

    try {
        const response = await baseHttp.put("/school/edit-school", {
            ...data,
            imageUrl: image || data.imageUrl,
        });

        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const validateAccount: any = createAsyncThunk(
    "admin/validate-account",
    async (data: { account_number: string; bank_code: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Validating Account" }));

        try {
            const response = await baseHttp.post("/wallet/validate-account", data);

            dispatch(closePreloader());

            return response?.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const withdrawECash: any = createAsyncThunk(
    "admin/withdraw",
    async (
        {
            amount,
            name,
            account_number,
            bank_code,
        }: {
            amount: number;
            name: string;
            account_number: number;
            bank_code: string;
        },
        thunkApi
    ) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Initiating withdrawal" }));

        try {
            const response = await baseHttp.post("/wallet/withdraw", {
                amount,
                name,
                account_number,
                bank_code,
            });

            dispatch(closePreloader());

            return response?.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

// ====================== STUDENTS
export const checkParent: any = createAsyncThunk("admin/check-parent", async (parentPhoneNumber: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Checking parent" }));
    try {
        const response = await baseHttp.post("/school/create-student/check-parent", {
            parentPhoneNumber: formatPhoneNumber(parentPhoneNumber),
        });

        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const addStudent: any = createAsyncThunk("/admin/add-student", async (_, thunkApi) => {
    // Get the student details from the redux state
    const studentDetails = (thunkApi.getState() as RootState).addStudent;
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Adding Student" }));

    try {
        // upload student picture
        const picture = await uploadToCloud(studentDetails.picture as string);

        // Rearrange to match
        const rearrangedStudentInfo = rearrangeStudentDetails({
            ...studentDetails,
            picture,
        });

        const response = await baseHttp.post("/school/create-student", {
            ...rearrangedStudentInfo,
        });
        dispatch(closePreloader());

        return await response?.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const editStudent: any = createAsyncThunk("admin/edit-student", async (studentId: string, thunkApi) => {
    const student = (thunkApi.getState() as RootState)?.addStudent;

    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Editing Student Details" }));

    try {
        const picture = typeof student?.picture === "string" ? student?.picture : await uploadToCloud(student?.picture);

        const rearrangedStudentInfo = rearrangeStudentDetails({
            ...student,
            picture,
        });

        const response = await baseHttp.put("/school/student/" + studentId, rearrangedStudentInfo);
        dispatch(closePreloader());

        return response?.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const deleteStudent: any = createAsyncThunk("admin/delete-student", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Deleting Student's Account" }));

    try {
        const response = await baseHttp.delete(`/school/student/${id}`);

        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const makeCashPayment: any = createAsyncThunk(
    "admin/student/cash-payment",
    async ({ items, studentId }: { items: IFullyPaidItems[]; studentId: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Loading..." }));

        try {
            const response = await baseHttp.post(`/school/student/cash-payment/${studentId}`, { items });

            dispatch(closePreloader());

            return response?.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

// ============ STAFFS

export const addStaff: any = createAsyncThunk("admin/add-staff", async (_, thunkApi) => {
    // Get the staff details from the redux state
    const staff = (thunkApi.getState() as RootState)?.addStaff;

    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Adding Staff" }));

    try {
        // Upload the image, signature and the documents
        const picture = await uploadToCloud(staff.picture as string);
        const signature = await uploadToCloud(staff.role.signature as string);

        const documents = await Promise.all(
            staff.documents.map(async (doc) => {
                const file = doc?.file ? await uploadToCloud(doc.file as File) : "";
                return { ...doc, file };
            })
        );

        const rearrangedStaffInfo = rearrangeStaffDetails({
            ...staff,
            picture,
            documents,
            role: { ...staff.role, signature },
        });

        const response = await baseHttp.post("/school/add-staff", rearrangedStaffInfo);
        dispatch(closePreloader());
        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const editStaff: any = createAsyncThunk("admin/edit-staff", async (staffId: string, thunkApi) => {
    const staff = (thunkApi.getState() as RootState).addStaff;
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Editing Staff Details" }));

    try {
        // Upload the image, signature and the documents
        // Note: those of type string are those that have been uploaded previously

        const picture = typeof staff.picture === "string" ? staff.picture : await uploadToCloud(staff.picture as File);

        const signature =
            typeof staff.role.signature === "string"
                ? staff.role.signature
                : await uploadToCloud(staff.role.signature as File);

        const documents = await Promise.all(
            staff.documents.map(async (doc) => {
                const file = typeof doc?.file === "string" ? doc.file : await uploadToCloud(doc.file as File);
                return { ...doc, file };
            })
        );

        const rearrangedStaffInfo = rearrangeStaffDetails({
            ...staff,
            picture,
            documents,
            role: { ...staff.role, signature },
        });

        const response = await baseHttp.put(`/school/staff/${staffId}`, {
            ...rearrangedStaffInfo,
        });

        dispatch(closePreloader());
        return response;
    } catch (error: any) {
        dispatch(closePreloader());

        dispatch(openErrorModal({ errorText: error.response.data.message }));

        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const deleteStaff: any = createAsyncThunk("admin/delete-staff", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Deleting Staff's Account" }));

    try {
        const response = await baseHttp.delete(`/school/staff/${id}`);

        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

// ================ PARENT

export const editUserPhoneNumber: any = createAsyncThunk(
    "editUserPhoneNumber",
    async (data: { phoneNumber: string; id: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Editing User Phone Number" }));

        try {
            const response = await baseHttp.put(`/school/user/${data.id}/phone-number`, {
                phoneNumber: data.phoneNumber,
            });

            dispatch(closePreloader());

            return response?.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

//======================= GENERATORS =====================

// ============ CLASS

export const generateClass: any = createAsyncThunk("school/generate-class", async (data: IGenerateClass, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Generating Class" }));

    try {
        const response = await baseHttp.post("/school/generator/create-class", {
            ...data,
        });

        dispatch(closePreloader());
        return data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const editClass: any = createAsyncThunk(
    "/admin/edit-class",
    async ({ classId, classDetail }: { classId: string; classDetail: IGenerateClass }, thunkApi) => {
        const dispatch = thunkApi.dispatch;
        dispatch(openPreloader({ loadingText: "Editing Class" }));

        try {
            const { data } = await baseHttp.put("/school/generator/edit-class/" + classId, classDetail);
            dispatch(closePreloader());

            return data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const deleteClass: any = createAsyncThunk("admin/delete-class", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Deleting Class" }));

    try {
        const { data } = await baseHttp.delete("/school/generator/delete-class/" + id);

        dispatch(closePreloader());

        return data;
    } catch (error: any) {
        dispatch(closePreloader());

        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );

        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

// ============ SUBJECTS

export const generateSubjects: any = createAsyncThunk(
    "school/generate-subject",
    async ({ classId, subjects }: { classId: string; subjects: IGenerateSubject[] }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Generating Subject" }));

        try {
            const response = await baseHttp.post("/school/generator/subjects", {
                classId,
                subjects,
            });
            dispatch(closePreloader());
            return response;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const editSubject: any = createAsyncThunk(
    "admin/edit-subject",
    async (data: { subject: IGenerateSubject; id: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Editing Subject" }));

        try {
            const response = await baseHttp.put("/school/generator/subjects/" + data?.id, data?.subject);
            dispatch(closePreloader());

            return response.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const deleteSubject: any = createAsyncThunk("admin/delete-subject", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Deleting Subject" }));

    try {
        const response = await baseHttp.delete("/school/generator/subjects/" + id);
        dispatch(closePreloader());
        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

// ============== PAY ADVICE
export const generatePayAdvice: any = createAsyncThunk(
    "admin/generate-pay-advice",
    async (data: { items: IGeneratePayAdvice[]; classId: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Generating Pay Advice" }));

        try {
            const response = await baseHttp.post("/school/generator/pay-advice", {
                items: data?.items,
                classId: data?.classId,
            });

            dispatch(closePreloader());
            return response.data;
        } catch (error: any) {
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            dispatch(closePreloader());
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const editPayAdvice: any = createAsyncThunk(
    "admin/edit-pay-advice",
    async (data: { payAdvice: IGeneratePayAdvice; id: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Editing Pay Advice" }));

        try {
            const response = await baseHttp.put("/school/generator/pay-advice/" + data?.id, data?.payAdvice);
            dispatch(closePreloader());

            return response.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const deletePayAdvice: any = createAsyncThunk("admin/delete-pay-advice", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Deleting Pay Advice" }));

    try {
        const response = await baseHttp.delete("/school/generator/pay-advice/" + id);
        dispatch(closePreloader());
        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const sendMessage: any = createAsyncThunk("user/send-message", async (data: ISendMessage, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Sending Message" }));

    try {
        const response = await baseHttp.post("/school/message", data);

        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});

export const commentOnStudentResult: any = createAsyncThunk(
    "/admin/comment-on-result",
    async (data: { adminComment: string; studentId: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Adding Comment..." }));

        try {
            const response = await baseHttp.post(`/school/student/${data.studentId}/report-card-information/comment`, {
                adminComment: data.adminComment,
            });

            dispatch(closePreloader());

            return response.data;
        } catch (error: any) {
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            dispatch(closePreloader());
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addNextResumptionDate: any = createAsyncThunk(
    "/admin/next-resumption-date",
    async (data: { classId: string; nextResumptionDate: Date | string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Adding Next Resumption Date..." }));

        try {
            const response = await baseHttp.post(
                `/school/class/${data?.classId}/report-card-information/next-resumption-date`,
                { nextResumptionDate: data?.nextResumptionDate }
            );

            dispatch(closePreloader());

            return response?.data;
        } catch (error: any) {
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            dispatch(closePreloader());
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const subscribe: any = createAsyncThunk("admin/subscribe", async (plan: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Creating subscription plan" }));

    try {
        const response = await baseHttp.post("/school/subscribe", { plan });

        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});

export const verifyStudentsBulkUpload: any = createAsyncThunk(
    "verifyStudentsBulkUpload",
    async (file: File, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Uploading document" }));

        const data = new FormData();
        data.append("file", file);

        try {
            const response = await baseHttp.post<{
                students: IBulkUploadRes<IAddStudent[]>;
                valid_students: IBulkUploadRes<IAddStudent[]>;
            }>("/school/create-student/bulk-upload/verify", data);

            dispatch(closePreloader());

            return response?.data;
        } catch (error: any) {
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            dispatch(closePreloader());
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addBulkStudentsFile: any = createAsyncThunk(
    "addBulkStudentsFile",
    async (students: IAddStudent[], thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Adding Bulk Students" }));

        try {
            const response = await baseHttp.post(`/school/create-student/bulk-upload/file`, { students });
            dispatch(closePreloader());
            return response?.data;
        } catch (error: any) {
            dispatch(openErrorModal({ errorText: error.response.data.message }));
            dispatch(closePreloader());
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const addBulkStudents: any = createAsyncThunk("addBulkStudents", async (_, thunkApi) => {
    const state = <RootState>thunkApi.getState();
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Uploading students info" }));

    const students = state.studentBulk.students;

    try {
        const data = [];
        for (const student of students) {
            const _student = { ...student };
            if (_student.imageUrl) {
                const imageUrl = await uploadToCloud(_student?.imageUrl as File);
                _student.imageUrl = imageUrl;
            }
            if (_student.phoneNumber) {
                _student.phoneNumber = formatPhoneNumber(_student.phoneNumber);
            }
            _student.parentPhoneNumber = formatPhoneNumber(_student.parentPhoneNumber!);
            data.push(_student);
        }
        const response = await baseHttp.post("/school/create-student/bulk-upload", { students: data });
        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});

export const verifyStaffBulkUpload: any = createAsyncThunk("verifyStaffBulkUpload", async (file: File, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Uploading document" }));

    const data = new FormData();
    data.append("file", file);

    try {
        const response = await baseHttp.post<{
            staffs: IBulkUploadRes<IAddStudent[]>;
            valid_staffs: IBulkUploadRes<IAddStudent[]>;
        }>("/school/add-staff/bulk-upload/verify", data);

        dispatch(closePreloader());

        return response?.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});

export const addBulkStaffFile: any = createAsyncThunk("addBulkStaffFile", async (staffs: IAddStaff[], thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Adding Bulk Staff" }));

    try {
        const response = await baseHttp.post(`/school/add-staff/bulk-upload/file`, { staffs });
        dispatch(closePreloader());
        return response?.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});

export const addBulkStaff: any = createAsyncThunk("addBulkStaff", async (_, thunkApi) => {
    const state = <RootState>thunkApi.getState();
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Uploading staff info" }));

    const staffs = state.staffBulk.staffs;

    try {
        const data = [];
        for (const staff of staffs) {
            const _staff = { ...staff };
            if (_staff.imageUrl) {
                const imageUrl = await uploadToCloud(_staff?.imageUrl as File);
                _staff.imageUrl = imageUrl;
            }
            _staff.phoneNumber = formatPhoneNumber(_staff.phoneNumber!);

            data.push(_staff);
        }
        const response = await baseHttp.post("/school/add-staff/bulk-upload", { staffs: data });
        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});

export const resetUserPassword: any = createAsyncThunk("resetUserPassword", async (id: string, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Changing Password" }));

    try {
        const response = await baseHttp.put(`/school/user/${id}/password`);
        dispatch(closePreloader());
        return response?.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error);
    }
});
