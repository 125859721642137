import React from "react";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import bg from "../../assets/images/stock3.png";
import { ButtonFilled } from "../../components/UI/Buttons";
import { useDispatch } from "react-redux";
import { authQuiz } from "../../api/thunks/auth/auth.service";

const QuizPage = () => {
    const dispatch = useDispatch();

    const submit = () => dispatch(authQuiz());

    return (
        <DashboardLayout pageTitle={"Quiz"}>
            <div className="h-[90vh] w-[90%] m-auto rounded-md my-5 overflow-hidden relative">
                <img src={bg} alt="bg-drop" className="w-full h-full object-cover object-center" />
                <div className="bg-[rgba(0,0,0,0.65)] w-full h-full absolute top-0 left-0 z-[20] flex items-center justify-center flex-col text-white">
                    <h1 className="text-[2rem] font-bold uppercase">Edssistance Quiz 🧠</h1>
                    <p className="mt-3 my-3">
                        Think you’ve got the smarts? 💡 Challenge yourself with edssistance quizzes and show off your
                        skills!
                    </p>

                    <ButtonFilled
                        onClick={submit}
                        className="bg-white !text-black !w-[150px]"
                        text={"Play Now 🧑🏽‍💻"}
                    ></ButtonFilled>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default QuizPage;
