import { createAsyncThunk } from "@reduxjs/toolkit";
import { authHttp } from "../../../axios.config";
import { closePreloader, openErrorModal, openPreloader } from "../../../store/slices/others/handlerSlice";
import {
    deleteTokenFromLocalStorage,
    deleteUserFromLocalStorage,
    getUserFromLocalStorage,
    removeStaffFromLocalStorage,
} from "../../../utils/localStorage";
import { IChangePassword, ILogin, IRegister, IVerifyOTP } from "./auth.types";
import { formatPhoneNumber } from "../../../utils/dataHandling";
import axios from "axios";

export const login: any = createAsyncThunk("login", async (data: ILogin, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    dispatch(openPreloader({ loadingText: "Logging you in" }));

    // format credential
    if (data.credential.includes("@")) {
        data.email = data.credential;
        data.userName = "";
        data.phoneNumber = "";
    } else if (parseInt(data.credential)) {
        data.email = "";
        data.userName = "";
        data.phoneNumber = formatPhoneNumber(data.credential);
    } else {
        data.userName = data.credential;
        data.email = "";
        data.phoneNumber = "";
    }

    try {
        const response = await authHttp.post("/login", data);
        dispatch(closePreloader());
        return response.data;
    } catch (error: any) {
        dispatch(openErrorModal({ errorText: error.response.data.message }));
        dispatch(closePreloader());
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const registerAsSuperAdmin: any = createAsyncThunk("register", async (data: IRegister, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    dispatch(openPreloader({ loadingText: "Creating your account" }));

    try {
        const response = await authHttp.post("/signup/", {
            ...data,
            role: "superAdmin",
        });
        dispatch(closePreloader());

        return response.data;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(openErrorModal({ errorText: error.response.data.message }));

        return thunkApi.rejectWithValue(error.response.data.message);
    }
});

export const verifyOTP: any = createAsyncThunk("verify-otp", async (data: IVerifyOTP, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    try {
        const response = await authHttp.post("/verify-otp/", {
            email: data.email,
            otp: data.otp,
        });
        return response?.data;
    } catch (error: any) {
        dispatch(
            openErrorModal({
                errorText: error.response.data.message || error.response.data.data,
            })
        );

        return thunkApi.rejectWithValue(error.response.data.toString());
    }
});

export const changePassword: any = createAsyncThunk(
    "auth/change-password",
    async ({ data, token }: { data: IChangePassword; token: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;
        dispatch(openPreloader({ loadingText: "Changing your password" }));
        try {
            const response = await authHttp.put(
                "/changePassword",
                {
                    oldPassword: data.oldPassword,
                    newPassword: data.newPassword,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(closePreloader());

            return response;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const requestPasswordResetLink: any = createAsyncThunk(
    "auth/requestResetLink",
    async (email: string, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Requesting Link" }));

        try {
            const response = await authHttp.post("/passwordResetLink", { email });
            dispatch(closePreloader());
            return response.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const resetPassword: any = createAsyncThunk(
    "auth/reset-password",
    async (data: { code: string; password: string; confirmPassword: string }, thunkApi) => {
        const dispatch = thunkApi.dispatch;

        dispatch(openPreloader({ loadingText: "Resetting your password" }));

        try {
            const response = await authHttp.patch("/resetPassword/" + data.code, {
                password: data.password,
                confirmPassword: data.confirmPassword,
            });
            dispatch(closePreloader());

            return response.data;
        } catch (error: any) {
            dispatch(closePreloader());
            dispatch(
                openErrorModal({
                    errorText: error.response.data.message,
                })
            );
            return thunkApi.rejectWithValue(error.response.data.message);
        }
    }
);

export const logout = () => {
    // Delete token & user from localStorage
    deleteTokenFromLocalStorage();
    deleteUserFromLocalStorage();
    removeStaffFromLocalStorage();
    window.location.replace("/login");
};

export const authQuiz: any = createAsyncThunk("auth/quiz", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;

    dispatch(openPreloader({ loadingText: "Redirecting..." }));

    try {
        const user = getUserFromLocalStorage();
        const quizApiUrl = process.env.REACT_APP_QUIZ_API_URL;
        const quizFrontendUrl = process.env.REACT_APP_QUIZ_FRONTEND_URL;

        const response = await axios.post(`${quizApiUrl}/user/auth/edssistance`, {
            fullName: user.firstName + " " + (user.lastName ?? ""),
            userName: user.userName,
            profileImage: user.imageUrl,
            phoneNumber: user.phoneNumber,
        });

        const token = response?.data?.token;

        dispatch(closePreloader());

        window.location.href = quizFrontendUrl + `/auth-edssistance?checker=${token}`;
    } catch (error: any) {
        dispatch(closePreloader());
        dispatch(
            openErrorModal({
                errorText: error.response.data.message,
            })
        );
        return thunkApi.rejectWithValue(error.response.data.message);
    }
});
