import React from "react";
import { BiChevronLeft, BiCircle } from "react-icons/bi";
import { Link, useLocation, useParams } from "react-router-dom";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout";
import { ButtonFilled } from "../../../../components/UI/Buttons";
import { classAttendance } from "../../../../static/admin&teacher.data";
import profile from "../../../../assets/images/profile-img.jpg";
import { MdCheck, MdClose } from "react-icons/md";
import {
  useGetClassAttendance,
  useGetSchoolAttendance,
  useGetTeacherClass,
} from "../../../../api/hooks/teachers/teachers.service";
import {
  IClassAttendance,
  IGeneratedClasses,
  IUser,
} from "../../../../types/db.types";
import {
  getClassDayAttendance,
  getStudentTotalAttendance,
  getTotalDaysSchoolOpened,
  getTotalGenderAttendace,
  getTotalGenderAttendaceAbsence,
  getTotalHolidays,
  getTotalStudentsByGender,
} from "../../../../utils/attendance";
import { FaSave } from "react-icons/fa";
import { getUserFromLocalStorage } from "../../../../utils/localStorage";
import { GiCircle, GiRing } from "react-icons/gi";
import TableLayout, {
  SingleTableHeader,
  SingleTableRowItem,
  TableHeader,
  TableRow,
  TableRowsContainer,
} from "../../../../components/Layouts/TableLayout";

// const getWeekAttendance = (
//   attendance: IClassAttendance[],
//   week: number | string
// ) => {
//   return attendance.filter((attendance) => attendance.week == week);
// };

const Attendance = () => {
  const location = useLocation();
  const { classId } = useParams();

  const isAdmin = location.pathname.includes("admin");

  const attendanceUrl = isAdmin
    ? `/school/attendance/class/${classId}`
    : `/teacher/attendance/${classId}`;

  const classUrl = isAdmin
    ? `/school/generator/get-class/${classId}`
    : `/teacher/class/${classId}`;

  const { data: attendance, isLoading } = useGetClassAttendance(attendanceUrl);

  const { data: classData } = useGetTeacherClass(classUrl);

  const school = (getUserFromLocalStorage() as IUser)?.school;

  return (
    <DashboardLayout
      pageTitle={`${classData?.className} ${classData?.classArm} ${school?.term} term attendance`}
      documentTitle="Class Attendance"
    >
      <section className="py-6 sm:p-6 min-h-screen flex-col justify-between gap-y-[5rem]">
        <div className="">
          <div className="mb-4 flex items-start gap-[1rem]">
            <img
              src={school?.imageUrl}
              className="w-[50px] h-[50px] rounded-full object-cover object-center"
              alt=""
            />
            <div>
              <p className="font-bold">{school?.schoolName}</p>
              <p className="text-[14px]">{school?.address}</p>
            </div>
          </div>
          {!isAdmin && (
            <div className="w-full flex justify-end">
              <Link to={`/teacher/class/attendance/${classId}/select-week`}>
                <ButtonFilled text="Mark Attendance" icon={<FaSave />} />
              </Link>{" "}
            </div>
          )}
          <div className="flex flex-row items-start mt-8">
            {/* This part is not scrollable */}
            <div className="flex flex-col items-center w-[200px] max-w-[200px]  border-r-[1.5px] border-blackText">
              <div className="h-[40px] w-full">
                <h2>Full Name</h2>
              </div>
              <div className="h-[40px] w-full"></div>
              {/* list the students */}
              {classData?.students?.map((student, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center gap-x-2 h-[40px] w-full cursor-pointer border-b-[1.5px] border-blackText"
                  >
                    <img
                      className="w-[25px] h-[25px] rounded-full"
                      alt={student?.firstName}
                      loading="lazy"
                      src={student?.imageUrl}
                    />
                    <p className="font-bold text-[#222] cursor-pointer hover:underline">
                      {student?.firstName} {student?.lastName}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* This part is scrollable */}
            <div
              className="overflow-x-scroll"
              style={{
                width: `calc(100% - 150px)`,
              }}
            >
              {/* Map All The Weeks First */}
              <div className="flex h-[40px] min-w-fit">
                {Array(14)
                  .fill(null)
                  .map((week, index) => {
                    return (
                      <div key={index} className={styles.tableHeader}>
                        Week {index + 1}
                      </div>
                    );
                  })}

                <div className={styles.tableHeader + " !w-[120px]"}>Total</div>
              </div>
              {/* Map the days for all the weeks */}
              <div className="flex h-[40px] min-w-fit border-b-[1.5px] border-b-blackText">
                {Array(14)
                  .fill(null)
                  .map((week, index) => {
                    return (
                      <div key={index} className="h-[40px] flex">
                        {/* display each day */}
                        <span className={styles.singleDay}>Mon</span>
                        <span className={styles.singleDay}>Tue</span>
                        <span className={styles.singleDay}>Wed</span>
                        <span className={styles.singleDay}>Thur</span>
                        <span className={styles.singleDay}>Fri</span>
                      </div>
                    );
                  })}

                <div className={styles.singleDay + " !w-[120px]"}></div>
              </div>
              {/* Map the attendance for those days */}
              <div className="flex flex-col min-w-fit">
                {classData?.students?.map((student, index) => {
                  return (
                    <div
                      className="flex h-[40px] min-w-fit border-b-[1.5px] border-b-blackText"
                      key={index}
                    >
                      {Array(14)
                        .fill(null)
                        .map((weeks, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className={styles.singleDay}>
                                <span className={styles.singleDayLeft}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Monday",
                                    "morning"
                                  )}
                                </span>

                                <span className={styles.singleDayRight}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Monday",
                                    "afternoon"
                                  )}
                                </span>
                              </div>
                              <div className={styles.singleDay}>
                                <span className={styles.singleDayLeft}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Tuesday",
                                    "morning"
                                  )}
                                </span>

                                <span className={styles.singleDayRight}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Tuesday",
                                    "afternoon"
                                  )}
                                </span>
                              </div>
                              <div className={styles.singleDay}>
                                <span className={styles.singleDayLeft}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Wednesday",
                                    "morning"
                                  )}
                                </span>

                                <span className={styles.singleDayRight}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Wednesday",
                                    "afternoon"
                                  )}
                                </span>
                              </div>
                              <div className={styles.singleDay}>
                                <span className={styles.singleDayLeft}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Thursday",
                                    "morning"
                                  )}
                                </span>
                                <span className={styles.singleDayRight}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Thursday",
                                    "afternoon"
                                  )}
                                </span>
                              </div>
                              <div className={styles.singleDay}>
                                <span className={styles.singleDayLeft}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Friday",
                                    "morning"
                                  )}
                                </span>

                                <span className={styles.singleDayRight}>
                                  {getClassDayAttendance(
                                    attendance as IClassAttendance[],
                                    student?._id,
                                    (index + 1).toString(),
                                    "Friday",
                                    "afternoon"
                                  )}
                                </span>
                              </div>
                            </React.Fragment>
                          );
                        })}

                      <div className="flex h-[40px] !w-[120px] text-center border-r-[2px] font-bold border-b-[1.5px] border-blackText justify-center items-center">
                        {getStudentTotalAttendance(
                          attendance as IClassAttendance[],
                          student._id
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* for attendance details */}
        <h2 className="text-center my-8 font-bold">ATTENDANCE STATISTICS</h2>
        <TableLayout>
          <TableHeader>
            <SingleTableHeader color="white" bg="#333" text={""} width={300} />
            <SingleTableHeader
              color="white"
              bg="#333"
              text={"COUNT"}
              width={100}
            />
          </TableHeader>

          <TableRowsContainer>
            {/* no of boys in class */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of boys in class
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalStudentsByGender(
                    classData as IGeneratedClasses,
                    "male"
                  )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of times boys attended */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of times boys attended
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalGenderAttendace(
                    attendance as IClassAttendance[],
                    "male"
                  )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of times boys were absent
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalGenderAttendaceAbsence(
                    attendance as IClassAttendance[],
                    "male"
                  )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of girls in class */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of girls in class
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalStudentsByGender(
                    classData as IGeneratedClasses,
                    "female"
                  )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of times girls attended */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of times girls attended
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalGenderAttendace(
                    attendance as IClassAttendance[],
                    "female"
                  )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of times girls were absent
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalGenderAttendaceAbsence(
                    attendance as IClassAttendance[],
                    "female"
                  )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of times everybody attended */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of times everybody attended
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalGenderAttendace(
                    attendance as IClassAttendance[],
                    "female"
                  ) +
                    getTotalGenderAttendace(
                      attendance as IClassAttendance[],
                      "male"
                    )}
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of holidays */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of holidays
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {getTotalHolidays(attendance as IClassAttendance[])}
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of weeks school opened */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of weeks school opened
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  {
                    getTotalDaysSchoolOpened(attendance as IClassAttendance[])
                      ?.weeks
                  }
                </p>
              </SingleTableRowItem>
            </TableRow>

            {/* no of days school opened */}
            <TableRow>
              <SingleTableRowItem bg="#ededed" width={300}>
                <p className="bg-[#d7d7d7] rounded-md py-1 px-2">
                  Number of days school opened
                </p>
              </SingleTableRowItem>
              <SingleTableRowItem bg="#ededed" width={100}>
                <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                  <p className="font-bold bg-[#d7d7d7] rounded-md py-1 px-2 max-w-fit">
                    {getTotalDaysSchoolOpened(attendance as IClassAttendance[])
                      ?.days * 2}
                  </p>
                </p>
              </SingleTableRowItem>
            </TableRow>
          </TableRowsContainer>
        </TableLayout>
        <div></div>
      </section>
    </DashboardLayout>
  );
};

const styles = {
  // 30px from 150/5
  singleDay:
    "flex-1 border-r-[2px] border-r-blackText h-[40px] flex items-center justify-center w-[40px] text-[0.8rem] font-bold relative",
  singleDayLeft:
    "border-r-[1.5px] border-r-[#969696] w-[20px] h-[40px] flex items-center justify-center",
  singleDayRight: "w-[20px] h-[40px] flex items-center justify-center",
  tableHeader:
    "text-white bg-blackText border-r-[1.5px] w-[200px] h-[40px] flex items-center justify-center",
};

export default Attendance;
