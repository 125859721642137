import React, { Dispatch, SetStateAction, useState } from 'react';
import DashboardLayout from '../../../../components/Layouts/DashboardLayout';
import {
  BlackOutlineButton,
  ButtonFilled,
  OutlineButton,
} from '../../../../components/UI/Buttons';
import { Link, useNavigate } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import ModalOverlay from '../../../../components/UI/ModalOverlay';
import { FiUsers } from 'react-icons/fi';
import {
  useGetStaffs,
  useGetTeachers,
} from '../../../../api/hooks/admin/staffs/staffs.service';
import {
  useGetStudents,
  useGetStudentsWithoutClass,
} from '../../../../api/hooks/admin/students/students.service';
import { IAllStaffs, IAllStudents } from '../../../../types/db.types';
import { useDispatch } from 'react-redux';
import { generateClass } from '../../../../api/thunks/admin/admin.service';
import { IGenerateClass } from '../../../../api/thunks/admin/admin.types';
import {
  AddClassModal,
  StudentsList,
  TeachersList,
} from '../../../../components/Admin/ClassGeneratorModals';
import BackButton from '../../../../components/UI/BackButton';

/** =============================== TODOS =================================
 * @todo1 - Create different modals for each step
 * @todo2 - Make it as simple as possible
 * @todo3 - The modals to be created are the add class modal, add teacher and add student modal
 * @todo4 - For each modal create two buttons, one for closing the modal and one for updating the newClass state and closong the modal
 */

const ClassGenerators = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [steps, setSteps] = useState<number>(0);

  const defaultClass: IGenerateClass = {
    className: '',
    classArm: '',
    students: [],
    classTeacher: '',
  };

  const [newClass, setNewClass] = useState<IGenerateClass>(defaultClass);

  const closeModal = () => {
    setModalOpened(false);
  };

  const { data: staffs } = useGetTeachers();
  const { data: students } = useGetStudentsWithoutClass();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addClass = async () => {
    const data = await dispatch(generateClass({ ...newClass }));
    if (!data?.error) {
      navigate('/admin/generator');
    }
  };

  return (
    <DashboardLayout pageTitle={'Class Generator'}>
      <header className={styles.header_container}>
        <h2 className='text-[1.25rem] font-bold text-darkerGray'>
          Add a new class
        </h2>
        {[0, 1, 2].includes(steps) && (
          <div
            className='flex-[33%]'
            onClick={() => {
              setModalOpened(true);
            }}
          >
            {steps === 0 && (
              <ButtonFilled icon={<BiPlus />} text={'Add Class'} />
            )}
            {steps === 1 && (
              <ButtonFilled icon={<BiPlus />} text={'Add Students'} />
            )}
            {steps === 2 && (
              <ButtonFilled icon={<BiPlus />} text={'Add Class Teacher'} />
            )}
          </div>
        )}
      </header>
      {/* grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 */}
      <div className='w-full mt-8'>
        {modalOpened &&
          (steps === 0 ? (
            <AddClassModal
              setNewClass={setNewClass}
              newClass={newClass}
              closeModal={closeModal}
              setSteps={setSteps}
            />
          ) : (
            <ModalOverlay modalWidth={1200}>
              <>
                {steps === 1 && (
                  <StudentsList
                    setNewClass={setNewClass}
                    newClass={newClass}
                    closeModal={closeModal}
                    setSteps={setSteps}
                    data={students?.students as IAllStudents[]}
                  />
                )}
                {steps === 2 && (
                  <TeachersList
                    setNewClass={setNewClass}
                    newClass={newClass}
                    closeModal={closeModal}
                    setSteps={setSteps}
                    data={staffs?.staffs as IAllStaffs[]}
                  />
                )}
              </>
            </ModalOverlay>
          ))}
        {newClass.className && (
          <article className={styles.content_box + ' border-[#242424]'}>
            <div className='flex items-center gap-x-4 gap-y-4'>
              <span
                className={`${styles.content_box_icon} bg-[#14141436] text-[#242424] `}
              >
                <FiUsers />
              </span>
              <h2 className={styles.content_box_title}>
                {newClass.className} {newClass.classArm}
              </h2>
            </div>
            <div className='flex items-center justify-center gap-x-2 gap-y-2 mt-2'>
              {newClass.classTeacher && (
                <p className='text-[15px] hover:underline text-darkGray font-[300]'>
                  Class teacher added
                </p>
              )}
              {newClass.students.length > 0 && (
                <p className='text-[15px] hover:underline text-darkGray font-[300]'>
                  {newClass.students.length} student(s) added
                </p>
              )}
            </div>
          </article>
        )}

        <div className='flex flex-row flex-wrap space-y-4 space-x-3 mt-6'>
          {/* Display this only when a class has been selected */}
          {newClass.className && (
            <BlackOutlineButton
              text='Reset Selections'
              onClick={() => {
                setNewClass(defaultClass);
                setSteps(0);
              }}
            />
          )}

          {steps === 3 && (
            <button
              className='w-full md:max-w-[200px] bg-blackText text-white rounded-md font-bold p-3'
              onClick={addClass}
            >
              Generate Class
            </button>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    'mt-8 flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center',
  search_icon:
    'flex-1 min-w-[40px] max-w-[40px] h-[35px] flex justify-center items-center text-darkGray',
  buttons:
    'px-4 py-2 flex items-center border-[1.5px] rounded-md font-bold gap-x-4 flex-nowrap min-w-fit',
  content_box: 'p-4 w-full max-w-[300px] rounded-md border-[1.5px]',
  content_box_icon:
    'w-[30px] h-[30px] rounded-full flex justify-center items-center',
  content_box_title: 'text-[1rem] font-bold text-blackText flex flex-col',
};

export default ClassGenerators;
