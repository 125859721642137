import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdCancel, MdClose } from 'react-icons/md';
import { classes, positions } from '../../static/admin&teacher.data';
import { ButtonFilled } from './Buttons';
import ModalOverlay from './ModalOverlay';
import SelectContainer from './SelectContainer';

interface StudentFilterProps {
  gender: string | undefined;
  setGender: Dispatch<SetStateAction<string | undefined>>;
  class: string | undefined;
  setClass: Dispatch<SetStateAction<string | undefined>>;
  closeModal: () => void;
}

export const StudentFilter = (props: StudentFilterProps) => {
  // Recreate this value to have a local val
  const [classDetail, setClass] = useState<string | undefined>(props.class);
  const [gender, setGender] = useState<string | undefined>(props.gender);

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.headerText}>Filter Students</h2>

          <span className={styles.closeIcon} onClick={props.closeModal}>
            <MdClose />
          </span>
        </header>

        <div className='pb-4'>
          <div className={styles.inputContainers}>
            <div>
              <label htmlFor='' className='block mb-1'>
                Gender
              </label>
              <SelectContainer
                list={['male', 'female']}
                updateItem={(value) => setGender(value)}
                currentItem={gender || 'Select Gender'}
                fitContent={false}
              />
            </div>

            <div>
              <label htmlFor='' className='block mb-1'>
                Class
              </label>
              <SelectContainer
                list={classes}
                updateItem={(value) => setClass(value)}
                currentItem={classDetail || 'Select Class'}
                fitContent={false}
              />
            </div>
          </div>

          <div className={styles.buttonContainers}>
            <div className='flex-1 w-full'>
              <ButtonFilled
                text='Filter Students'
                fullWidth={true}
                onClick={() => {
                  // Update the value in the main component
                  props.setClass(classDetail);
                  props.setGender(gender);
                  props.closeModal();
                }}
              />
            </div>
            <div className='flex-1 w-full'>
              <ButtonFilled
                text='Reset Filters'
                fullWidth={true}
                onClick={() => {
                  // Update the value in the main component
                  setClass(undefined);
                  setGender(undefined);
                  props.setClass(undefined);
                  props.setGender(undefined);
                  props.closeModal();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface StaffFilterProps {
  gender: string | undefined;
  setGender: Dispatch<SetStateAction<string | undefined>>;
  position: string | undefined;
  setPosition: Dispatch<SetStateAction<string | undefined>>;
  closeModal: () => void;
}

export const StaffFilter = (props: StaffFilterProps) => {
  const [position, setPosition] = useState<string | undefined>(props.position);
  const [gender, setGender] = useState<string | undefined>(props.gender);

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <header className={styles.header}>
          <h2 className={styles.headerText}>Filter Staff</h2>

          <span className={styles.closeIcon} onClick={props.closeModal}>
            <MdClose />
          </span>
        </header>

        <div className='pb-4'>
          <div className={styles.inputContainers}>
            <div>
              <label htmlFor='' className='block mb-1'>
                Gender
              </label>
              <SelectContainer
                list={['male', 'female']}
                updateItem={(value) => setGender(value)}
                currentItem={gender || 'Select Gender'}
                fitContent={false}
              />
            </div>

            <div>
              <label htmlFor='' className='block mb-1'>
                Position
              </label>
              <SelectContainer
                list={positions}
                updateItem={(value) => setPosition(value.toLowerCase())}
                currentItem={position || 'Select Position'}
                fitContent={false}
              />
            </div>
          </div>

          <div className={styles.buttonContainers}>
            <div className='flex-1 w-full'>
              <ButtonFilled
                text='Filter Staff'
                fullWidth={true}
                onClick={() => {
                  // Update the value in the main component
                  props.setPosition(position);
                  props.setGender(gender);
                  props.closeModal();
                }}
              />
            </div>
            <div className='flex-1 w-full'>
              <ButtonFilled
                text='Reset Filters'
                fullWidth={true}
                onClick={() => {
                  // Update the value in the main component
                  setPosition(undefined);
                  setGender(undefined);
                  props.setPosition(undefined);
                  props.setGender(undefined);
                  props.closeModal();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const styles = {
  overlay:
    'w-full fixed z-[5] top-0 left-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center min-h-screen',
  modal: 'p-6 bg-white max-w-[500px] w-[90vw]',
  header: 'flex items-center justify-between',
  headerText: 'text-blackText font-bold text-[1.1rem]',
  closeIcon: 'text-[20px] text-red-500 cursor-pointer',
  inputContainers: 'grid grid-cols-1 2xs:grid-cols-2 my-4 gap-4',
  buttonContainers: 'flex items-center gap-x-4 gap-y-2 sm:flex-row flex-col',
};
