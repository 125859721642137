import React, { useState } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { useGetSchool } from "../../../api/hooks/admin/school/school.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { updateSchool, updateSchoolDetails } from "../../../store/slices/post/createSchool";
import { InputFields } from "../../../types/componentsProps.types";
import SelectContainer from "../../../components/UI/SelectContainer";
import Input from "../../../components/UI/Input";
import { countryList } from "../../../assets/static";
import { ButtonFilled } from "../../../components/UI/Buttons";
import { ISchoolDetails } from "../../../types/db.types";
import { editSchool } from "../../../api/thunks/admin/admin.service";
import { uploadToCloud } from "../../../api/upload";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { closePreloader, openPreloader } from "../../../store/slices/others/handlerSlice";
import { ICreateSchool } from "../../../api/thunks/admin/admin.types";

const inputFields: InputFields[] = [
    {
        placeholder: "Enter School Name",
        name: "schoolName",
        type: "text",
        label: "School Name",
        required: true,
    },
    {
        placeholder: "Country",
        name: "country",
        type: "country-select",
        label: "Country",
        required: true,
    },
    {
        placeholder: "Enter State/Province",
        name: "state",
        type: "text",
        label: "State/Province",
        required: true,
    },
    {
        placeholder: "Enter zip code",
        name: "zipCode",
        type: "number",
        label: "Zip Code(Optional)",
        required: false,
    },
    {
        placeholder: "Enter City",
        name: "city",
        type: "text",
        label: "City",
        required: true,
    },
    {
        placeholder: "Enter Address",
        name: "address",
        type: "text",
        label: "School Address",
        required: true,
    },
];

const EditSchool = () => {
    const { data: school, isLoading } = useGetSchool();
    const dispatch = useDispatch();

    const [newImage, setNewImage] = useState<File | undefined>();

    const details = useSelector((state: RootState) => state.createSchool);
    const navigate = useNavigate();

    const updateCountry = (value: string) => {
        dispatch(updateSchoolDetails({ key: "country", value }));
    };

    const updateSchoolInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSchoolDetails({ key: e.target.name, value: e.target.value }));
    };

    const updateImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewImage(e.target?.files?.[0]);
    };

    const submit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch(openPreloader({ loadingText: "Editing school info" }));

        try {
            const image = newImage ? await uploadToCloud(newImage) : "";

            const data = await dispatch(editSchool(image));

            dispatch(closePreloader());

            if (!data.error) {
                await Swal.fire({
                    title: "Edit School",
                    text: "School Info edited successfully",
                    timer: 2000,
                    icon: "success",
                    showConfirmButton: false,
                });

                navigate("/admin/school");
            }
        } catch (error) {}
    };

    React.useEffect(() => {
        if (school) {
            dispatch(updateSchool(school as any));
        }
    }, [school]);

    return (
        <DashboardLayout pageTitle={"Edit School Details"}>
            <section className="min-h-screen py-6 sm:p-6">
                <form onSubmit={submit}>
                    <div className="mb-[1.1rem] flex flex-wrap items-center  gap-[1.2rem]">
                        <img
                            className="w-[150px] h-[150px] shadow-md rounded-full object-cover object-center"
                            src={newImage ? URL.createObjectURL(newImage) : school?.imageUrl}
                            alt={school?.schoolName + " logo"}
                        />
                        <div className="flex flex-wrap gap-[1.2rem]">
                            <label htmlFor={"select-image"} className={styles.buttons + " bg-blackText text-white"}>
                                Change Logo
                            </label>

                            <input
                                type="file"
                                id="select-image"
                                placeholder="Change Logo"
                                className="hidden"
                                accept="image/*"
                                onChange={updateImage}
                            />

                            {newImage && (
                                <ButtonFilled text="Clear Selected Logo" onClick={() => setNewImage(undefined)} />
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[1rem]">
                        {inputFields.map((input, index: number) => {
                            return (
                                <div key={index} className="w-full mb-4">
                                    {input.type !== "country-select" && (
                                        <>
                                            <label
                                                htmlFor={input.name}
                                                className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                                            >
                                                {input.label}
                                            </label>
                                            <Input
                                                placeholder={input.placeholder}
                                                type={input.type}
                                                name={input.name}
                                                value={[details[input.name as keyof typeof details]]}
                                                onChange={updateSchoolInfo}
                                                required={input.required}
                                            />
                                        </>
                                    )}
                                    {input.type === "country-select" && (
                                        <>
                                            <label
                                                htmlFor={input.name}
                                                className="text-[1.08rem] font-bold mb-2 block cursor-pointer"
                                            >
                                                {input.label}
                                            </label>
                                            <SelectContainer
                                                list={countryList}
                                                currentItem={
                                                    (details[input.name as keyof typeof details] as string) ||
                                                    "Select Country"
                                                }
                                                fitContent={false}
                                                updateItem={updateCountry}
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        })}
                        <div className=""></div>
                        <div className="mt-4">
                            <ButtonFilled fullWidth={true} text="Submit" type="submit" />
                        </div>{" "}
                    </div>
                </form>
            </section>
        </DashboardLayout>
    );
};

export default EditSchool;

const styles = {
    buttons:
        "px-3 py-2 text-[13px] flex items-center justify-center capitalize text-center border-[2px] border-blackText rounded-md gap-x-2 flex-nowrap ",
};
