import React, { Fragment, useState } from 'react';
import { MdCancel, MdOutlineClass } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetClasses } from '../../../../api/hooks/admin/generators/generators.service';
import {
  useGetStaffs,
  useGetTeachers,
} from '../../../../api/hooks/admin/staffs/staffs.service';
import { generateSubjects } from '../../../../api/thunks/admin/admin.service';
import { IGenerateSubject } from '../../../../api/thunks/admin/admin.types';
import DashboardLayout from '../../../../components/Layouts/DashboardLayout';
import BackButton from '../../../../components/UI/BackButton';
import NoItem from '../../../../components/UI/NoItem';
import SelectContainer from '../../../../components/UI/SelectContainer';
import UserSelectContainer from '../../../../components/UI/UserSelectContainer';
import { IAllStaffs, IGeneratedClasses } from '../../../../types/db.types';
import {
  addNewSubject,
  removeSubject,
  updateSubject,
  updateSubjectTeacher,
  getSingleSubject,
} from '../../../../utils/subjectUtils';

export type newTeachersAndSubjectsProps = {
  id: number;
  teacher: IAllStaffs | '';
  teacherId: number | string;
  subject: string;
};

const SubjectGenerators = () => {
  const [selectedClass, setSelectedClass] = useState<IGeneratedClasses | null>(
    null
  );

  const [newTeachersAndSubjects, setNewTeachersAndSubjects] = useState<
    newTeachersAndSubjectsProps[]
  >([
    {
      id: 1,
      teacher: '',
      teacherId: '',
      subject: '',
    },
  ]);

  const { data: classes } = useGetClasses();

  const { data } = useGetTeachers();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createSubject = async () => {
    const subjects: IGenerateSubject[] = newTeachersAndSubjects?.map(
      (subject) => {
        return {
          title: subject?.subject,
          teacher: subject?.teacherId as string,
        };
      }
    );

    // Check if there's a subject without a teacher or a teacher without a subject

    const emptySubjectAndTeacher = subjects?.find(
      (subject) => !subject.teacher || !subject.title
    );

    if (!emptySubjectAndTeacher) {
      const data = await dispatch(
        generateSubjects({ classId: selectedClass?._id, subjects })
      );

      if (!data?.error) {
        navigate('/admin/generator');
      }
    }
  };

  return (
    <DashboardLayout pageTitle={'Subjects Generator'}>
      <section className='py-6 sm:p-6 min-h-screen'>
        {classes && classes?.length > 0 && (
          <>
            <h2 className='text-[1.25rem] font-bold text-darkerGray mb-4 mt-8'>
              Select Class
            </h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-x-6 gap-y-6'>
              {classes?.map((singleClass, index) => {
                return (
                  <article
                    className={
                      styles.content_box +
                      ` ${
                        selectedClass?._id === singleClass?._id &&
                        'border-[#242424]'
                      }`
                    }
                    key={index}
                    onClick={() => {
                      // make it the selected class
                      setSelectedClass(singleClass);
                    }}
                  >
                    <span
                      className={`${styles.content_box_icon}  bg-[#14141436] text-[#242424] `}
                    >
                      <MdOutlineClass />
                    </span>
                    <h2 className={styles.content_box_title}>
                      {singleClass?.className} {singleClass?.classArm}
                    </h2>
                  </article>
                );
              })}
            </div>

            {/* Section to add a subject and the class */}
            {selectedClass && (
              <section className='w-full'>
                <>
                  <h2 className='text-[1.25rem] font-bold text-darkerGray mb-4 mt-8'>
                    Generate subjects for{' '}
                    {selectedClass?.className
                      ? `${selectedClass?.className} ${selectedClass?.classArm}`
                      : 'class'}
                  </h2>
                  <div className='md:grid-cols-2 grid-cols-1 grid gap-x-4 gap-y-4 max-w-[1000px]'>
                    <>
                      {newTeachersAndSubjects.map((subject, index) => {
                        return (
                          <Fragment key={index}>
                            <div className='w-full relative'>
                              <input
                                type='text'
                                className='input-field'
                                placeholder='Enter Subject'
                                value={
                                  getSingleSubject(
                                    newTeachersAndSubjects,
                                    subject.id
                                  )?.subject
                                }
                                onChange={(e) => {
                                  setNewTeachersAndSubjects(
                                    updateSubject(
                                      newTeachersAndSubjects,
                                      subject.id,
                                      e.target.value
                                    )
                                  );
                                }}
                              />
                              {/* display on the all items when the items length are greater than 1 */}
                              {newTeachersAndSubjects.length > 1 && (
                                <span
                                  className='absolute right-[10px] top-[50%] -translate-y-[50%] text-[1.25rem] cursor-pointer'
                                  onClick={() => {
                                    // don't remove it if it is the last item
                                    setNewTeachersAndSubjects(
                                      removeSubject(
                                        newTeachersAndSubjects,
                                        subject.id
                                      )
                                    );
                                  }}
                                >
                                  <MdCancel />
                                </span>
                              )}
                            </div>
                            <UserSelectContainer
                              list={data?.staffs}
                              currentItem={
                                (getSingleSubject(
                                  newTeachersAndSubjects,
                                  subject?.id
                                )?.teacher as IAllStaffs) ||
                                'Select Subject Teacher'
                              }
                              fitContent={false}
                              updateWithId={(id) => {
                                const teacher = data?.staffs?.find(
                                  (staff) => staff?._id === id
                                );
                                setNewTeachersAndSubjects(
                                  updateSubjectTeacher(
                                    newTeachersAndSubjects,
                                    subject.id,
                                    id,
                                    teacher as IAllStaffs
                                  )
                                );
                              }}
                            />
                          </Fragment>
                        );
                      })}

                      <button
                        className='py-2 px-4 rounded-md border bg-blackText -full text-white'
                        type='button'
                        onClick={() => {
                          setNewTeachersAndSubjects(
                            addNewSubject(newTeachersAndSubjects)
                          );
                        }}
                      >
                        Add new subject
                      </button>

                      <button
                        className='py-2 px-4 rounded-md border border-blackText w-full text-blackText hover:bg-blackText hover:text-white'
                        type='button'
                        onClick={createSubject}
                      >
                        Generate Subjects
                      </button>
                    </>
                  </div>
                </>
              </section>
            )}
          </>
        )}
        {(!classes || classes?.length === 0) && (
          <NoItem title='No Class Yet' subtitle='Kindly Add Class(es)' />
        )}
      </section>
    </DashboardLayout>
  );
};

const styles = {
  header_container:
    'flex flex-wrap xl:flex-nowrap gap-[1.5rem] w-full items-center',
  content_box:
    'p-4 w-full max-w-[300px] rounded-md border-[1.5px] flex gap-x-3 gap-y-3 cursor-pointer',
  content_box_icon:
    'w-[30px] h-[30px] rounded-full flex justify-center items-center',
  content_box_subtitle: 'text-[15px] underline text-darkGray font-[300]',
  content_box_title: 'text-[1rem] font-bold text-blackText flex flex-col',
};

export default SubjectGenerators;
