import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStaffBulkSlice, SingleBulkStaff } from "../../../types/states.types";
import { staffDefaultData } from "../../../static/bulk-upload.data";

const initialState: IStaffBulkSlice = {
    staffs: [{ ...staffDefaultData }],
};

const staffBulkSlice = createSlice({
    name: "staffBulkSlice",
    initialState,
    reducers: {
        addNewStaff: (state) => {
            state.staffs = [...state.staffs, staffDefaultData];
        },

        removeStaff: (state, actions: PayloadAction<number>) => {
            state.staffs = state.staffs.filter((staff, staff_idx) => staff_idx != actions.payload);
        },

        editStaff: (state, actions: PayloadAction<[number, { key: keyof SingleBulkStaff; value: any }]>) => {
            const index = actions.payload[0];
            const change = actions.payload[1];
            state.staffs = state.staffs.map((staff, staff_idx) => {
                if (staff_idx == index) {
                    staff[change.key] = change.value;
                }

                return staff;
            });
        },
        resetStaffs: (state) => {
            state.staffs = initialState.staffs;
        },
    },
});

const staffBulkReducer = staffBulkSlice.reducer;
export const { addNewStaff, removeStaff, editStaff, resetStaffs } = staffBulkSlice.actions;
export default staffBulkReducer;
